.search-filter-shortcodes {
    position: relative;
    overflow: visible;
    .content-title{
        margin-bottom: 70px;
    }


    @include nav-filter_input;


    .filter-results-data{
        >.row{
            margin: 0 -12px;
            >div{
                padding: 0 12px;
                margin-bottom: 40px
            }
        }
    }
    @include post-item-box;

    .pagination-results{
        margin-top: 24px;
        .pagination-nav{
             display: flex;
             justify-content: space-between;
             width: 100%;
            .page-a-lk{
                transition: all ease 0.3s;
                &.prev,
                &.next{
                    text-decoration: none!important;
                    color: $green;
                    display: flex;
                    align-items: center;
                    position: relative;
                    top: 50%;
                    transform: translate(0%, -50%);
                    transition: all ease 0.3s;
                    &::after{
                        content: none!important;
                    }
                    //&:hover,
                    &:not(.disabled){
                        .sr-only-text{
                            opacity: 1;
                        }
                        .circle-arrow{
                            background-color: $green;
                            svg{
                                path{
                                    stroke: #fff;
                                }
                            }
                        }
                    }
                    .sr-only-text{
                        font-size: 18px;
                        letter-spacing: 0.9px;
                        font-family: $font-title;
                        text-transform: uppercase;
                        display: inline-block;
                        color: $green;
                        text-decoration: none!important;
                        font-weight: 600;
                        margin: 0;
                        padding: 0;
                        opacity: 0;
                        &:after{
                            content: none!important;
                        }
                    }
                    .circle-arrow{
                        width: 50px;
                        height: 50px;
                        min-width: 50px;
                        display: inline-block;
                        text-decoration: none!important;
                        border: 1px solid #2A5F5A;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 100px;
                        margin: 0;
                        &:after{
                            content: none!important;
                        }
                    }
                    svg{
                        width: 24px;
                    }
                }

                &.prev{
                    left: 0;
                    .sr-only-text{
                        margin-left: 20px;
                        stroke: #fff;
                    }
                }
                &.next{
                    right: 0;
                    .sr-only-text{
                        margin-right: 20px;

                    }

                }
            }
        }
        .page-pagination-content{
            span,a{
                display: inline-block;
                font-size: 16px;
                color: $green;
                padding: 10px;
                text-decoration: none;
                text-align: center;
              /*   text-decoration-line: underline; */
               /*  text-decoration-thickness: 2px; */
          /*       text-underline-offset: 3px; */
                text-transform: uppercase;
                line-height: normal;
                /* text-decoration-color: $green; */
                font-family: $font-title;
                position: relative;
                padding: 0 5.5px 4px;
                margin:18px 18px;

                &:after{
                    content: "";
                    background-color: $green;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 3px;
                    width: 100%;
                }
                &.current{
                    font-weight: 700;
                 /*    text-decoration-line: underline;
                    text-decoration-thickness: 2px;
                    text-underline-offset: 3px;
                    text-transform: uppercase;
                    font-weight: 700; */
                 /*    text-decoration-color: transparent; */
                 &::after{
                    background-color: transparent;
                 }
                  text-decoration: none;
                }
            }
            .pagenumbers{
                display: flex;
                justify-content: center;
                .page-numbers.dots{
                    &:after{
                        content: none;
                    }
                }
            }
        }


    }
    @media(max-width: $breakpoint-down-lg){
        .content-title{
            margin-bottom: 40px;
        }

        .filter-results-data{
            >.row{
                margin: 0 -12px;
                >div{
                    padding: 0 12px;
                    margin-bottom: 30px
                }
            }
        }
    }
    @media(max-width: $breakpoint-down-md){
        .pagination-results{
            margin-top: 10px;
            .pagination-nav{
                 display: flex;
                 flex-wrap: wrap;
                 margin:  0;
                 width: 100%;
                 justify-content: space-between;
                 .prev.page-a-lk{
                    order: 1;
                    top: 0;
                    transform: initial;
                    padding: 0 15px 0 0;
                    .sr-only-text{
                        opacity: 1;
                        font-size: 16px;
                    }
                 }
                 .next.page-a-lk{
                    order: 2;
                    top: 0;
                    transform: initial;
                    padding: 0 0 0 15px;
                    .sr-only-text{
                        opacity: 1;
                        font-size: 16px;
                    }
                 }
                 .page-pagination-content{
                    order: 0;
                    width: 100%;
                    padding: 0 15px;
                 }

            }
            .page-pagination-content{
                span,a{

                }

            }


        }
    }
    @media(max-width: 360px){
        .pagination-results{
        .pagination-nav{
            .prev.page-a-lk{
               .sr-only-text{
                   display: none;
               }
            }
            .next.page-a-lk{
               .sr-only-text{
                display: none;
               }
            }
            .page-pagination-content{
            }
        }
       }
    }
}
.block-accordion-button {
    .content-text {
        p {
            font-size: 22px;
            max-width: 1094px;
        }

        .content-buttons {
            a {
                margin-top: 25px;
            }
        }
    }

    .midle-before {
        position: absolute;
        background-position: 50% 0;
        width: 100%;
        left: 0;
        bottom: 22%;
        opacity: 0.7;
        height: 314px;
        background: linear-gradient(0deg, rgb(87 209 139 / 50%) 0, transparent 40%);
        z-index: 2;
        mask-image: url(../img/bg-img-center.png);
        mask-size: 100% 100%;
        &:after{
            content: "";
            max-width: 839px;
            width: 100%;
            height: 619px;
            position: absolute;
            transform: translate(0, 0) rotate(178deg);
            background: radial-gradient(ellipse at center, #ffbd07 0, #FDFAF3 37%, transparent 64%);
            mix-blend-mode: color;
            opacity: 0.19;
            filter: blur(50px);
            left: -95px;
        }
    } 

    .accordion-column {
        margin-top: 98px;

        .content-accordion {
            display: flex;
            justify-content: center;
            max-width: 1096px;
            padding: 77px 82px;
            background-color: $white;
            margin: 0 auto;

            .accordion {
                width: 100%;

                --bs-accordion-border-color: initial;
                --bs-accordion-active-color: initial;
                --bs-accordion-active-bg: initial;
                --bs-accordion-btn-focus-border-color: initial;

                .accordion-item {
                    .accordion-header {
                        margin-bottom: 15px;

                        .accordion-button {
                            border: 2px solid $green;
                            border-radius: 50px;
                            padding: 12px;
                            position: relative;

                            h3 {
                                font-size: 22px;
                                line-height: 32px;
                                font-weight: bold;
                                color: $green !important;
                                padding: 0 30px;
                                max-width: 360px;
                            }

                            p {
                                font-size: 16px;
                                font-weight: 600;
                                font-family: 'Poppins', sans-serif;
                                color: $black_2;
                                margin-right: 50px;
                            }

                            h3,
                            p {
                                margin-bottom: 0;
                            }

                            &:focus {
                                border-color: initial;
                                box-shadow: initial;
                            }

                            &:after {
                                content: '';
                                margin-left: 0;
                                position: absolute;
                                right: 50px;
                                top: 50%;
                                transform: translateY(-50%);
                                color: $green;
                                display: inline-block;
                                transition: transform 0.3s;
                                background-image: var(--bs-accordion-btn-icon);
                            }

                            &.open:after {
                                transform: translateY(-50%) rotate(180deg);
                            }
                        }

                        .no-subheading h3 {
                            max-width: 100%;
                        }
                    }

                    .accordion-collapse {
                        .accordion-body {
                            padding: 38px 20px 37px 20px;

                            h6 {
                                strong {
                                    font-weight: 700;
                                }
                            }

                            hr {
                                border-top: 2px solid;
                            }

                            p {
                                color: $black_2 !important;
                                font-size: 22px;
                                margin-top: 40px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1110px) {
        .accordion-column {
            .content-accordion {
                padding: 50px 40px;
            }
        }
    }

    @media (max-width: 991px) {
        .accordion-column {
            margin-top: 45px;
        }
    }

    @media (max-width: 768px) {
        .accordion-column {
            .content-accordion {
                padding: 20px;

                .accordion {
                    .accordion-item {
                        .accordion-header {
                            .accordion-button {
                                border-radius: 10px;
                                flex-direction: column;
                                align-items: flex-start;

                                h3 {
                                    font-size: 18px;
                                    line-height: 28px;
                                }

                                p {
                                    font-size: 14px;
                                    padding: 0 30px;
                                    margin-right: 0;
                                }

                                &:after {
                                    right: 31px;
                                }
                            }
                        }

                        .accordion-collapse {
                            .accordion-body {
                                padding: 5px 20px 20px 20px;

                                p {
                                    font-size: 16px;
                                    margin-top: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 576px) {
        .content-text {
            p {
                font-size: 17px;
            }
        }

        .accordion-column {
            padding-right: 0;
            padding-left: 0;

            .content-accordion {
                padding: 20px 10px;

                .accordion {
                    .accordion-item {
                        .accordion-header {
                            .accordion-button {
                                h3 {
                                    font-size: 16px;
                                    line-height: 26px;
                                    padding: 0 12px;
                                }

                                p {
                                    font-size: 12px;
                                    padding: 0 12px;
                                }

                                &:after {
                                    right: 15px;
                                }
                            }
                        }

                        .accordion-collapse {
                            .accordion-body {
                                padding: 5px 20px 20px 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.block-cards-stats {
    .content-title {
        margin-bottom: clamp(30px,5vw,50px);
    }

    .content-repeater {
       /* display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        gap: 30px;*/

        .box {
            /*width: min(100%, 320px);*/
            background: transparent linear-gradient(298deg, $sky-blue 0%, $sky-green 100%) 0 0 no-repeat padding-box;
            box-shadow: 5px 5px 50px #00000026;
            border-radius: 15px;
            opacity: 1;
            padding: 48px 35px 60px;
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            max-width: 320px;
            min-height: 176px;
            width: 100%;
            height: 100%;
            &__title {
                display: flex;
                font-size: 40px;
                letter-spacing: -1.2px;
                color: $white;
                text-transform: capitalize;
                font-weight: 800;
                text-align: left;
            }

            &__text {
                letter-spacing: 0;
                color: $white !important;
                opacity: 1;
                line-height: 20px;
                text-align: left;
                font-size: 16px;
                text-align: left;
                margin-bottom: 0;
            }

            &.margin-right-auto {
                margin-right: auto;
            }

            &.margin-left-auto {
                margin-left: auto;
            }

            &.margin-left-right-auto {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    @media (max-width: 991px) {
        .content-repeater {
             .box {
                 padding: 35px;
                 min-height: 160px;
                 max-width: 100%;
                 &__title {
                    font-size: 30px;
                    line-height: 34px;
                 }

                 &__text {
                    font-size: 14px;
                    line-height: 16px;
                 }
             }
         }
    }
    @media (max-width: 576px) {
        .content-repeater {
             .box {
                 min-height: auto;
                 &__title {
                    justify-content: center;
                 }
                 &__text {
                    text-align: center;
                 }
             }
         }
    }
}
#wrapper-footer {
	background-color: $green;
	padding: 100px 0 81px;

	.logo-row-cta {
		margin-bottom: 37px;

		.logo-footer {
			margin: 0;
		}

		.logo-inner-left {
			max-width: 298px;

			img {
				width: 100%;
			}
		}

		.content-buttons {
			justify-content: flex-end;

			.btn {
				@media(min-width: 768px) {
					padding: 17.5px 37px;
					min-width: 213px;
				}
			}
		}
	}

	hr {
		border: 0;
		border-bottom: 2px solid $white;
		margin: 0;
	}

	h6 {
		font-size: 18px;
		letter-spacing: 0.9px;
		color: $white;
	}

	.logos-menu-list {
		margin-bottom: 55px;

		.row-div {
			display: flex;
			margin: 34px -25px 0;
			flex-wrap: nowrap;
		}

		.figure-img-box {
			flex: 1;
			width: 20%;
			padding: 0 25px;
			margin-bottom: 20px;
			img {
				border-radius: 10px;
				max-width: 200px;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.copyright_footer {
		text-align: right;
		font-family: $font-title;

		.row {
			align-items: center;
		}

		.inner-detail-copyright {
			> *:last-child {
				margin-bottom: 0;
			}
		}

		p {
			font-size: 14px;
			color: #fff;
			font-weight: 300;
			font-family: $font-title;
		}

		a {
			&:hover {
				color: $green-light-footer;
			}
		}
	}

	@media(max-width: $breakpoint-down-lg) {
		padding: 50px 0;
		.logos-menu-list {
			margin-bottom: 25px;

			.row-div {
				display: flex;
				margin: 34px -20px 0;
				flex-wrap: wrap;
				justify-content: center;
			}

			.figure-img-box {
				flex: initial;
				width: 16.666%;
				padding: 0 20px;

				img {
					max-width: 260px;
				}
			}
		}
	}

	@media(max-width: $breakpoint-down-md) {
		.logo-row-cta {
			max-width: 238px;
			margin: 0 auto 25px;

			.logo-inner-left {
				margin-bottom: 20px;
			}

			.content-buttons {

			}
		}
		.copyright_footer {
			p {
				text-align: center;
			}
		}

		.logos-menu-list {
			.figure-img-box {
				width: 20%;
			}
		}
	}
	@media(max-width: $breakpoint-down-sm) {
		.logos-menu-list {
			margin-bottom: 25px;
			.row-div{
				margin: 34px -15px 0;
			}
			.figure-img-box {
				width: 33.333%;
				padding: 0 15px;
			}
		}
	}
	@media(max-width: $breakpoint-down-sm) {
		.logos-menu-list {
			margin-bottom: 25px;
			.figure-img-box {
				width: 33.333%;
			}
		}
	}
	@media(max-width: 350px) {
		.logos-menu-list {
			.figure-img-box {
				width: 50%;
			}
		}
	}
}


.footer-menu-list {
	margin-top: 27px;
	margin-bottom: 38px;

	.col-menus {
		width: 100%;
		display: flex;
		column-gap: 44px;
		justify-content: space-between;
	}

	.col-menu {
		.menu {
			list-style: none;
			padding: 0;
			margin: 0;

			li {
				font-size: 14px;
				margin-bottom: 16px;
				text-align: left;

				a {
					color: $white;
					font-family: $font-title;
					font-size: 16px;
					font-weight: 600;
					letter-spacing: 0;
					line-height: 1.2;
					text-decoration: none;

					&:hover {
						color: $green-light-footer;
						text-decoration: underline;
					}
				}

				&:first-child {
					margin-bottom: 20px;

					a {
						font-size: 20px;
						letter-spacing: 2px;
						line-height: 1.5;
						text-transform: uppercase;
					}
				}
			}
		}

		&.col-item-footer-1 {
			@media(min-width: 1225px) {
				min-width: 199px;
			}
		}

		&.col-item-footer-2 {
			@media(min-width: 1225px) {
				min-width: 195px;
			}
		}

	}

	@media(max-width: 991px) {
		> .row {
			> div {
				flex: 0 0 auto;
				width: 100%;
			}

			.col-md-4 {
				order: 0;
			}

			.col-md-8 {
				order: 1;
			}
		}
	}

	@media(max-width: 768px) {
		.col-menus {
			flex-wrap: wrap;
			column-gap: 15px;
		}

		.col-menu {
			width: calc(50% - 15px);
			margin-bottom: 25px;

			.menu {
				li {
					margin-bottom: 15px;
				}
			}
		}
	}

	@media(max-width: 500px) {
		.col-menu {
			width: 100%;
			margin-bottom: 19px;

			> div {
				> div {
					margin-bottom: 25px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.menu {
				li {
					margin-bottom: 10px;
				}
			}
		}
	}
}


.list-social-link {
	ul {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		padding: 0;
		margin: 0 -10px;

		li {
			padding: 0 10px;
			margin: 0 0 10px;
			min-width: 49.57px;
			transition: all ease 0.3s;

			a {
				width: 49.57px;
				height: 49.57px;
				border: 2px solid #fff;
				border-radius: 100px;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 5px;
				box-sizing: border-box;
				transition: all ease 0.3s;

				img,
				svg {
					max-width: 18px;
					width: 100%;
				}
			}

			&.facebook-icon {
				img,
				svg {
					max-width: 12px;
				}
			}

			&.linkedin-icon {
				img,
				svg {
					max-width: 18px;
				}
			}

			&.youtube-icon {
				img,
				svg {
					max-width: 23px;
				}
			}

			&.crunchbase-icon {
				img,
				svg {
					max-width: 21px;
				}
			}

			&.x-twiter-icon {
				img,
				svg {
					max-width: 18.99px;
				}
			}

			&:hover {
				a {
					opacity: 1;
					border-color: $green-light-footer;
					transition: all ease 0.3s;

					svg {
						path {
							fill: $green-light-footer;
						}
					}
				}
			}
		}
	}

	@media(max-width: $breakpoint-down-lg) {
		ul {
			margin: 0 -8px;

			li {
				padding: 0 8px;
				margin: 0 0 8px;
				min-width: 40px;

				a {
					width: 40px;
					height: 40px;
				}
			}
		}
	}

	@media(max-width: $breakpoint-down-md) {
		ul {
			justify-content: center;
			margin-bottom: 10px;

			li {
				a {
					padding: 10px;
				}
			}
		}
	}
}
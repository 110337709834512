.promo-card {
    border-radius: 15px;
    padding: 18px 0 18px !important;

    &.alignfull {
        .box-left-pr-bar {
            max-width: 100%;
        }

        .figure-content-pr {
            max-width: 100%;
        }
    }

    .box-left-pr-bar {
        max-width: 392px;

        .badge {
            margin-bottom: 15px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 15px;
            font-family: $font-title;
            font-weight: 700;
        }

        p {
            font-size: 18px;
            line-height: 1.555555em;
            font-weight: 400;
            letter-spacing: 0;
            margin-bottom: 20px;
        }

        .btn {
            text-transform: uppercase;
            font-weight: 600;

            &:after {
                margin-left: 5px;
            }
        }
    }

    .figure-content-pr {
        max-width: 409px;
        position: relative;

        @media(min-width: 991px) {
            right: -6px;
        }

        img {
            width: 100%;
            display: block;
            margin: 0 auto;
        }
    }

    @media(max-width: $breakpoint-down-md) {
        .row {
            flex-direction: column-reverse;
        }

        .box-left-pr-bar {
            max-width: 100%;
        }

        .figure-content-pr {
            max-width: 100%;
            margin-bottom: 20px;
        }
    }
}
.block-page-404 {
    .content-text {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        p{
            font-size: 22px;
            width: 100%;
            max-width: 540px;
        }
        .content-buttons {
            margin-top: 15px;
        }
    }

    .content-404 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .box {
            max-width: 650px;
            display: flex;
            align-items: center;
            height: 100%;

            img {
                width: 100%;
                min-height: auto;
                object-fit: contain;
            }
        }
    }

    /****
     *** start background
     *****/
    .video-container {
        opacity: 0.2;
    }

    .overlay-content {
        background: url('../assets/img/overlay-404.png') !important;
        background-size: cover !important;
        background-position: 20% 100% !important;
    }

    /****
     *** end background
     *****/

    @include media-breakpoint-down(lg) {
        margin-top: 90px !important;
        .content-text {
            text-align: center;
            row-gap: 0;
            align-items: center;
            p{
                font-size: 20px;
            }
        }
    }
}
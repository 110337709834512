.alert-black {
    background-color: $green;
    color: $white;
    border-radius: 0;

    padding-top: 0.75rem;
    padding-bottom: 0.78rem;

    .fix-left {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .alert-left {
            p {
                margin-bottom: 0;
                color: $white;
            }

            @media (max-width: 540px) {
                max-width: 164px;
            }
        }

        .alert-right {
            margin-right: 46px;

            a {
                margin-bottom: 0;
                color: $white;
                text-underline-offset: 8px;
                text-decoration-thickness: 1px;
                text-wrap: nowrap;
                font-size: 16px;
                font-weight: 600;

                &:hover {
                    color: rgba(44, 176, 75, 0.75);

                }
            }

            @media (max-width: 540px) {
                margin-right: 0;
                padding-left: 27px;
            }
        }

        @media (max-width: 540px) {
            justify-content: flex-start;
        }
    }

    .btn-link {
        @media (max-width: 400px) {
            max-width: 221px;
        }
    }

    &.alert-dismissible {
        .btn-close {
            position: absolute;
            top: 10px;
            right: 27px;
            z-index: 2;
            box-sizing: content-box;
            width: 15px;
            height: 15px;
            padding: .25em .25em;
            color: #000;
            background: transparent url(../assets/img/x-bottom.svg) center / 35px auto no-repeat;
            border: 0;
            border-radius: 0;
            opacity: 1;
            padding: 0;
            transition: all 0.3s ease;


            &:hover {
                transform: scale(1.1);
            }

            @media (max-width: 540px) {
                top: 17px;
                right: 14px;
            }
        }
    }

    .container {
        position: relative;
    }
}



.logged-in.admin-bar {
    #wrapper-navbar {
        top: 32px;
    }

    @media(max-width: 1100px) {

        top: 46px;
    }
}



#wrapper-navbar {
    position: sticky;
    width: 100%;
    background-color: $white;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;
    box-shadow: 0 3px 3px rgb(0 0 0 / 1%);
    margin-bottom: -132px;

    @media(max-width: 1148px) {

        margin-bottom: -90px;
    }

    .mega-menu-link,
    .mega-menu-link:hover {
        font-weight: 600 !important;
    }

    .mega-menu-link span {
        transition: all ease-in 0.3s;
    }

    .mega-menu-link:hover span {
        transform: rotate(180deg) !important;
    }

    @media (max-width: 1148px) {

        .mega-toggle-on .mega-menu-link:focus span:after,
        .mega-toggle-on .mega-menu-link:active span:after {
            transform: rotate(180deg) !important;
        }
    }


    #main-nav {
        padding: 0;
    }

    .container-logo {
        max-width: 298px;

        img {
            transition: all 0.3s ease;
        }
    }

    #mega-menu-wrap-primary #mega-menu-primary>li.mega-menu-flyout ul.mega-sub-menu {
        /*     box-shadow: 0 3px 3px rgb(0 0 0 / 3%); */
    }

    .navbar-toggler {
        display: none !important;
    }

    .btn-green-menu {
        display: flex;
        align-items: center;
        justify-content: center;

        text-align: center !important;
        margin-left: 30px !important;

        >a {
            text-decoration: none !important;
            height: auto !important;
            line-height: normal !important;
            min-width: 213px !important;
            transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease !important;
            padding: 20px 39px !important;
            font-weight: 600;
            color: $green !important;
            border: 2px solid $green !important;
            border-radius: 100px !important;
            background-color: transparent !important;
            width: auto !important;
            display: inline-block;

            &:hover {
                color: $white !important;
                background-color: $green !important;
            }
        }

    }

    @media(max-width: 1150px) {


        .space-link.mega-toggle-on {
            .mega-sub-menu {
                margin-bottom: 25px !important;
            }
        }

        .container {
            flex-wrap: nowrap;
        }

        .container-logo {
            padding: 10px 0;
            max-width: 220px;
        }

        .btn-green-menu {
            text-align: left !important;
            margin-top: 15px !important;
            margin-left: 10px !important;

            >a {
                background-color: transparent !important;
                border-color: $green !important;
                color: $green !important;
                display: inline-block !important;
                padding: 16px 36px !important;
                letter-spacing: 0;
                text-align: center !important;

                &:hover {
                    background-color: $green !important;
                    border-color: $green !important;
                    color: $white !important;
                }
            }

        }
    }

    @media(max-width: 768px) {
        .btn-green-menu {
            text-align: center !important;
        }
    }
}

.scrolled {
    background-color: #F9FAFE !important;
    .container-logo {
        img {
            max-width: 65%;
            @media(max-width: 1148px) {
                max-width: 90%;     
            }
        }
    }
    #mega-menu-wrap-primary{
        background-color: #F9FAFE ;
        height: 90px; 
        display: flex;
        align-items: center;
        #mega-menu-primary li.mega-menu-item > ul.mega-sub-menu{
            top: 110px;
        }
        @media(max-width: 1241px) {
            height: auto;   
            display: unset; 
            .mega-menu-toggle{
                background: #F9FAFE; 
            } 
        }

    }
}
.side-by-side-stats {
    .container::before {
        content: '';
        position: absolute;
        background: url('../assets/img/cleardemand-glow-bg.png');
        background-size: cover;
        width: 700px;
        height: 700px;
        right: 0;
        top: -13%;
    }

    .content-title {
        h2 {
            font-size: 50px;
        }

        p {
            font-size: 22px;
        }

        .content-buttons {
            margin-top: 40px;
        }
    }

    .content-image {
        position: relative;
        padding: 100px 25px;
        margin-top: 55px;
        max-width: 610px;
        margin-left: auto;

        img {
            border-radius: 25px;
            min-height: 250px;
            width: 100%;
            object-fit: cover;
        }

        .card-stat {
            max-width: 305px;
            column-gap: 15px;
            box-shadow: 5px 5px 50px #00000026;
            position: absolute;
            transition: all ease 0.3s;
            width: 100%;

            @media (min-width: 1200px) and (max-width: 1300px) {
                padding: 30px;
            }

            h3 {
                font-weight: 600;
            }

            p {
                max-width: 120px;
            }

            &.card-1 {
                top: 0;
                left: 0;
                opacity: 0.25;
                -webkit-transform: translate(0px, 0px);
                -ms-transform: translate(0px, 0px);
                transform: translate(0px, 0px);
            }

            &.card-2 {
                /*  top: 0;
                left: 0; */
                top: 0;
                left: 100%;
                -webkit-transform: translate(-100%, 0px);
                -ms-transform: translate(-100%, 0px);
                transform: translate(-100%, 0px);
                max-width: 274px;

            }

            &.card-3 {
                top: 100%;
                left: 0;
                opacity: .6;
                -webkit-transform: translate(0px, -100%);
                -ms-transform: translate(0px, -100%);
                transform: translate(0px, -100%);
            }

            &.card-4 {
                left: 100%;
                top: 100%;
                opacity: 0;
                -webkit-transform: translate(-100%, -100%);
                -ms-transform: translate(-100%, -100%);
                transform: translate(-100%, -100%);
            }

            @media (max-width: 560px) {
                flex-wrap: wrap;
                padding: 15px;
                max-width: 150px !important;

                h3 {
                    width: 100%;
                }

                p {
                    max-width: 100%;
                }
            }
        }

        //  &:hover,
        &.active-items {
            .card-stat {
                &.card-1 {
                    opacity: 0.8;
                }

                &.card-2 {
                    opacity: 0;
                }

                &.card-3 {
                    opacity: 1;
                }

                &.card-4 {

                    opacity: 0.8;
                }
            }
        }

        &.animation-1 {}

        &.animation-1.animation-2 {
            opacity: 1;

            .card-stat {
                &.card-1 {
                    top: 0;
                    left: 100%;
                    -webkit-transform: translate(-100%, 0px);
                    -ms-transform: translate(-100%, 0px);
                    transform: translate(-100%, 0px);
                    max-width: 274px;
                    width: 100%;
                    opacity: 1;
                    z-index: 2;
                }

                &.card-2 {
                    left: 100%;
                    top: 100%;
                    opacity: 0;
                    -webkit-transform: translate(-100%, -100%);
                    -ms-transform: translate(-100%, -100%);
                    transform: translate(-100%, -100%);
                    z-index: 0;
                }

                &.card-3 {
                    top: 0;
                    left: 0;
                    opacity: 0.25;
                    -webkit-transform: translate(0px, 0px);
                    -ms-transform: translate(0px, 0px);
                    transform: translate(0px, 0px);
                    opacity: 0.25;
                    z-index: 0;
                }

                &.card-4 {
                    top: 100%;
                    left: 0;
                    opacity: .6;
                    -webkit-transform: translate(0px, -100%);
                    -ms-transform: translate(0px, -100%);
                    transform: translate(0px, -100%);
                    z-index: 0;
                }
            }
        }

        &.animation-1.animation-2.animation-3 {
            .card-stat {
                &.card-1 {
                    left: 100%;
                    top: 100%;
                    opacity: 0;
                    -webkit-transform: translate(-100%, -100%);
                    -ms-transform: translate(-100%, -100%);
                    transform: translate(-100%, -100%);
                    z-index: 0;
                }

                &.card-2 {
                    top: 100%;
                    left: 0;
                    opacity: .6;
                    -webkit-transform: translate(0, -100%);
                    -ms-transform: translate(0, -100%);
                    transform: translate(0, -100%);
                    z-index: 0;

                }

                &.card-3 {
                    top: 0;
                    left: 100%;
                    -webkit-transform: translate(-100%, 0);
                    -ms-transform: translate(-100%, 0);
                    transform: translate(-100%, 0);
                    max-width: 274px;
                    width: 100%;
                    opacity: 1;
                    z-index: 2;
                }

                &.card-4 {
                    top: 0;
                    left: 0;
                    opacity: 0.25;
                    -webkit-transform: translate(0px, 0px);
                    -ms-transform: translate(0px, 0px);
                    transform: translate(0px, 0px);
                    opacity: 0.25;
                    z-index: 0;

                }
            }
        }

        &.animation-1.animation-2.animation-4 {
            .card-stat {
                &.card-1 {
                    top: 100%;
                    left: 0;
                    opacity: .6;
                    -webkit-transform: translate(0, -100%);
                    -ms-transform: translate(0, -100%);
                    transform: translate(0, -100%);
                    z-index: 0;

                }

                &.card-2 {
                    top: 0;
                    left: 0;
                    opacity: .25;
                    -webkit-transform: translate(0, 0);
                    -ms-transform: translate(0, 0);
                    transform: translate(0, 0);
                    opacity: .25;
                    z-index: 0;

                }

                &.card-3 {
                    left: 100%;
                    top: 100%;
                    opacity: 0;
                    -webkit-transform: translate(-100%, -100%);
                    -ms-transform: translate(-100%, -100%);
                    transform: translate(-100%, -100%);
                    z-index: 0;

                }

                &.card-4 {
                    top: 0;
                    left: 100%;
                    -webkit-transform: translate(-100%, 0);
                    -ms-transform: translate(-100%, 0);
                    transform: translate(-100%, 0);
                    max-width: 274px;
                    width: 100%;
                    opacity: 1;
                    z-index: 2;

                }
            }
        }
    }




    @media (max-width: 1200px) {
        .content-image {
            margin-top: 40px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @media (max-width: 767px) {
        .container::before {
            right: initial;
            left: 50%;
            -webkit-transform: translate(-50%, 0%);
            -ms-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
            top: 33%;
        }

        .content-title {
            h2 {
                font-size: 30px;
            }

            p {
                font-size: 18px;
            }

            .content-buttons {
                margin-top: 30px;
            }
        }

        .content-image {
            .card-stat {
                //  opacity: 1!important;
            }
        }
    }
}
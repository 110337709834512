.content-buttons {
    display: flex;
    gap: 26px;
    flex-wrap: wrap;
    &.buttons-justification-star{
        justify-content: flex-start;
    }
    &.buttons-justification-center{
        justify-content: center;
    }
    &.buttons-justification-end{
        justify-content: flex-end;
    }
    &.buttons-justification-between{
        justify-content: space-between;
    }
    @media (max-width: 767px) {
        justify-content: center!important;
    }
}

@mixin btn-primary-all{
    background-color: $green;
    color: $white;
    border: 2px solid $green;
    font-weight: 700;
    &:hover{
        color: $green;
        border-color: $green;
        background-color: transparent;
    }
}
@mixin btn-outline-all{
    font-weight: 600;
    color: $green;
    border-color: $green;
    background-color: transparent;
    &:hover{
        color: $white; 
        background-color: $green;
    }
}
.wp-block-buttons{
    .wp-block-button{
        &:not(.is-style-outline){
            .wp-block-button__link{
                background-color: $green;
                color: $white;
                border: 0px solid $green;
                font-weight: 700;
                border-width: 0px !important;
                border-color: transparent !important;
                position: relative;
                &:before{
                    content: "";
                    width: 100%;
                    height: 100%;
                    border-radius: initial;
                    border-color: transparent;
                    position: absolute;
                    left: 0;
                    top: 0;
                    border: 2px solid transparent;
                }
                &:hover{
                    color: $green;
                    border-color: initial !important;
                    background-color: transparent;
                    border-width: 0px !important;
                    &:before{
                        border-radius: inherit;
                        border-color: initial;
                    }
                }
            }
        }
      
        &.is-style-outline{
       
            .wp-block-button__link{
             //   @include btn-outline-all;
                border-color: initial !important;
                border-width: 2px!important;
                font-weight: 600;
                color: $green;
                border-color: initial;
                
                &::before{
                    content: none;
                }
                &:hover{
                    color: $white; 
                    background-color: $green;
                    border-color: initial;
                }
            }
           
        }
    }
}
.wp-block-button__link{
    @include btn-primary-all;
    border-color: initial !important;
}
.btn{
    font-size: 16px;
    letter-spacing: 0;
    padding: 17.5px 39px;
    font-weight: 700;
    border-radius: 100px;
    font-family: $font-title;
    line-height: 1.5em;
    border-width: 2px;
    &.btn-primary{
        @include btn-primary-all;
        &.btn-outline{
            @include btn-outline-all; 
        }  
    }
    &.btn-outline-primary{
       @include btn-outline-all;  
       
    }
    &.btn-secondary{
        background-color: $white;
        border-color: $white;
        color: $black;
        &:hover{
            color: $white; 
            border-color: $white;
            color:  $white;
        }
        &.btn-outline{
            background-color: transparent;
            border-color: $white;
            color:  $white;
            &:hover{
                background-color: $white;
                border-color: $white;
                color: $black;
            }
        }  
    }
    &.btn-outline-secondary{
        background-color: transparent;
        border-color: $white;
        color:  $white;
        &:hover{
            background-color: $white;
            border-color: $white;
            color: $black;
        }
    }
    &.btn-link{
        font-size: 16px;
        transition: all 0.3s ease-in-out;
        color:  $green;
        display: inline-block;
        padding: 0;
        text-decoration: none;
        font-weight: 600;
        border: 0;
        &:after{
            content: '';
            background-image: url(../img/arrow-right.svg);
            display: inline-block;
            width: 24px;
            height: 24px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            vertical-align: middle;
            margin-left: 3px;
        }
        &:hover{
            color:  $green;
            text-decoration: underline;
        }
    }

    &.btn-link-white{
        font-size: 16px;
        transition: all 0.3s ease-in-out;
        color:  #fff;
        display: inline-block;
        padding: 0;
        text-decoration: none;
        font-weight: 600;
        border: 0;
        &:after{
            content: '';
            background-image: url(../img/arrow-right-white.svg);
            display: inline-block;
            width: 24px;
            height: 24px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            vertical-align: middle;
            margin-left: 3px;
        }
        &:hover{
            color:  #fff;
            text-decoration: underline;
        }
    }

    @media(max-width: $breakpoint-down-md) {
        padding: 16px 36px;
        letter-spacing: 0;
    }
}


 .btn-site{
    font-size: 16px;
    letter-spacing: 0;
    padding: 20px 39px;
    font-weight: 700;
    border-radius: 100px;
    font-family: $font-title;
    line-height: 1.5em;
    text-decoration: none;    
    @include btn-primary-all;
    
   /*  &:hover{
        background: transparent;
        color: $black;
    } */
    &.btn-outline{
       @include btn-outline-all;
    }
    
    
} 

/* .btn-link{
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    color:  $green;
    display: inline-block;
    padding: 0;
    text-decoration: none;
    &:after{
        content: '';
        background-image: url(../img/arrow-right.svg);
        display: inline-block;
        width: 24px;
        height: 24px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        vertical-align: middle;
        margin-left: 3px;
    }
    &:hover{
        color:  $green;
        text-decoration: underline;
    }
     
} */
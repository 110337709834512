.columns-with-top-line{
    .content-text{
        h2,h3,h4,h5,h6{
            max-width: 1120px;
        }
        h2{
            font-size: 50px;
            letter-spacing: -1.5px;
            margin-bottom: 30px;
         }
         p{
            font-size: 22px;
            max-width: 1096px;
            &:last-child{
                margin-bottom: 50px;
            }
        }
    }
    .content-columns{
        margin-bottom: 40px;
        &__item{
            border-top: 1px solid $black_2;
            padding-top: 20px;
            margin-bottom: 50px;
            p{
              font-size: 18px;
            }
        }
    }
    @media (max-width: 991px) {
        .content-text{
            h2{
                font-size: 40px;
            }
        }
    }
    @media (max-width: 767px) {
        .content-columns{
            margin-bottom: 0;
        }
        .content-text{
            h2{
                font-size: 30px;
            }
        }
    }
}
.process-order{

    .content-title{
        margin-bottom: 50px;
        p{
            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    .col-step:last-child .step-item::before{
        content: none;
    }
    
    .step-item{
        position: relative;
        &::before{
            content: "";
            position: absolute;
            background: url('../assets/img/arrow-step.svg');
            background-size: cover;
            width: 50px;
            height: 27px;
            right: 0;
            top: 25px;
        }
        .step-item--title {
            position: relative;
            max-width: 280px;
            border: 3px solid #000;
            border-radius: 100px;
            padding: 17px 17px 17px 90px;
            font-size: 18px;
            line-height: 1.2em;
            font-weight: 700;
            letter-spacing: 0.9px;
            font-family: $font-title;
            margin-right: 40px;
            background: transparent;
            &::before{
                content: '';
                position: absolute;
                background: url('../assets/img/checkmark-step.svg');
                background-size: cover;
                width: 50px;
                height: 50px;
                left: 17px;
                top: 50%;
                transform: translate(0px, -50%);
            }
            @media (min-width: 991px) and (max-width: 1200px) {
                padding: 17px 15px 17px 55px;
                font-size: 17px;
                &::before{
                    width: 30px;
                    height: 30px;
                }
            }
        }
        .step-item--list {
            padding-top: 30px;
            border-top: 1px solid $black_2;
            margin: 45px 25px 0 0;
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                li{
                    position: relative;
                    padding-left: 40px;
                    margin-bottom: 15px;
                    font-family: $font-title;
                    font-weight: 700;
                    line-height: 1.5em;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &::before{
                        content: '';
                        background: url('../assets/img/checkmark-list.svg');
                        position: absolute;
                        background-size: contain;
                        width: 19px;
                        height: 14px;
                        left: 0;
                        top: 8px;
                    }
                }
            }
            @media (min-width: 991px) and (max-width: 1200px) {
                ul {
                    li{
                        font-size: 17px;
                    }
                }
            }
        }
    }
    @media (max-width: 991px) {
        .step-item{
            margin-bottom: 35px;
            &::before{
                content: none;
            }
        }
    }
    @media (max-width: 767px) {
        .step-item{
            max-width: 350px;
            margin: 0 auto 45px;
            .step-item--title {
                padding: 17px 17px 17px 75px;
                font-size: 17px;
                &::before{
                    width: 36px;
                    height: 36px;
                }
            }
        }
    }
}
.hero_banner-starts{
    .content-title{
        h2{
            font-size: 50px;
        }
        p{
            font-size: 22px;
        }
        .content-buttons {
            margin-top: 40px;
        }
    }

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 140px;
        z-index: 2;
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    }
    .overlay-content{
        background-image: url('../assets/img/overlay-hero-home.png') !important;
        background-size: cover !important;
        background-position: 50% 100% !important;
    }
    .video-container{
        opacity: 0.2;
        z-index: 1;
    }
    .content-image-bx-list{
        margin-top: 18px;

        &.active-items{
            .card-stat{

              /*   &.card-1{
                    opacity: .25;
                }
                &.card-2{
                    opacity: 1;
                    z-index: 1;

                }
                &.card-3{
                    opacity: 1;
                    z-index: 1;
                }
                &.card-4{
                    opacity: .25;
                
                } */
         }
        }
          
        .card-stat{

            &.card-1{
               
                opacity: 0.25;
               /*  h3{
                    width: 100%;
                }
                p{
                    width: 100%;
                    max-width: 167px;
                } */
            }
            &.card-2{
                opacity: 1;
            }
            &.card-3{
                max-width: 320px;
                opacity: .6;
            }
            &.card-4{
               
              /*   flex-wrap: wrap; */
                opacity: 0;
               /*  h3{
                    width: 100%;
                }
                p{
                    width: 100%;
                } */
            }
            
        }


        &.animation-1{

        }
        &.animation-1.animation-2{
            opacity: 1;
            .card-stat{
                &.card-1 {
                    top: 0;
                    left: 100%;
                    transform: translate(-100%, 0px);
                    // max-width: 319px;
                    z-index: 1;
                    opacity: 1;
                    // flex-wrap: nowrap;
                    h3{
                        width: auto;
                    }
                    p{
                        // max-width: 141px;
                        max-width: 100%;
                    }
                }
                &.card-2 {
                    left: 100%;
                    top: 100%;
                    transform: translate(-100%, -100%);
                    z-index: 2;
                    // max-width: 212px;
                    opacity: 0;
                    // flex-wrap: wrap;
                    // flex-wrap: nowrap;
                    
                    p{
                        max-width: 100%;
                    }
                }
                &.card-3 {
                    top: 0;
                    left: 0;
                    transform: translate(0px, 0px);
                    // max-width: 249px;
                    z-index: 0;
                    // flex-wrap: wrap;
                    // flex-wrap: nowrap;
                    opacity: 0.25;
                    z-index: 2;
                    p{
                        max-width: 100%;
                    }
                }
                &.card-4 {
                    top: 100%;
                    left: 0;
                    transform: translate(0px, -100%);
                    z-index: 0;
                    // max-width: 320px;
                    opacity: 0.6;
                    // flex-wrap: nowrap;
                    z-index: 1;
                    h3{
                        width: auto;
                    }
                    p{
                        // max-width: 141px;
                    }
                }
            }
        }
    
        &.animation-1.animation-2.animation-3{
            .card-stat{
                &.card-1 {
                    left: 100%;
                    top: 100%;
                    transform: translate(-100%, -100%);
                    z-index: 2;
                    // max-width: 212px;
                    opacity: 0;
                    // flex-wrap: wrap;
                    // flex-wrap: nowrap;
                    p{
                        max-width: 100%;
                    }
                }
                &.card-2 {
                    top: 100%;
                    left: 0;
                    transform: translate(0, -100%);
                    z-index: 0;
                    // max-width: 320px;
                    opacity: 0.6;
                    // flex-wrap: nowrap;
                    z-index: 1;
                    p{
                        // max-width: 120px;
                        max-width: 100%;
                    }
                }
                &.card-3 {
                    top: 0;
                    left: 100%;
                    transform: translate(-100%, 0);
                    z-index: 2;
                    // max-width: 319px;
                    opacity: 1;
                    // flex-wrap: nowrap;
                    z-index: 1;
                    p{
                        // max-width: 120px;
                        max-width: 100%;
                    }
                }
                &.card-4 {
                    top: 0;
                    left: 0;
                    transform: translate(0px, 0px);
                    z-index: 0;
                    // max-width: 249px;
                    opacity: 0.25;
                    // flex-wrap: wrap;
                    // flex-wrap: nowrap;
                    z-index: 2;
                    p{
                        max-width: 100%;
                    }
                }
            }
        }
        &.animation-1.animation-2.animation-4{
            .card-stat{
                &.card-1 {
                    top: 100%;
                    left: 0;
                    opacity: 0.6;
                    transform: translate(0, -100%);
                    z-index: 0;
                    // max-width: 320px;
                    // flex-wrap: nowrap;
                    z-index: 1;
                    p{
                        // max-width: 120px;
                        max-width: 100%;
                    }
                }
                &.card-2 {
                    top: 0;
                    left: 0;
                    transform: translate(0, 0);
                    opacity: 0.25;
                    z-index: 2;
                    // max-width: 249px;
                    // flex-wrap: wrap;
                    // flex-wrap: nowrap;
                    p{
                        max-width: 100%;
                    }
                }
                &.card-3 {
                    left: 100%;
                    top: 100%;
                    transform: translate(-100%, -100%);
                    z-index: 2;
                    // max-width: 212px;
                    opacity: 0;
                    // flex-wrap: wrap;
                    // flex-wrap: nowrap;
                    p{
                        max-width: 100%;
                    }
                }
                &.card-4 {
                    top: 0;
                    left: 100%;
                    transform: translate(-100%, 0);
                    z-index: 0;
                    // max-width: 319px;
                    opacity: 1;
                    // flex-wrap: nowrap;
                    p{
                        // max-width: 120px;
                        max-width: 100%;
                    }
                }
            }
        }
    }
    
    @media (max-width: 767px) {
        .content-title{
            h2{
                font-size: 30px;
            }
            p{
                font-size: 18px;
            }
            .content-buttons {
                margin-top: 30px;
            }
        }
    }
    @media (max-width: 558px) {


        
        .content-image-bx-list{

            &.active-items{
                .card-stat{
    
                    &.card-1{
                        opacity: .25;
                        z-index: 0;
                    }
                    &.card-2{
                        opacity: 1;
                        z-index: 1;
    
                    }
                    &.card-3{
                        opacity: 1;
                        z-index: 1;
                    }
                    &.card-4{
                        opacity: .25;
                        z-index: 0;
                    }
             }
            }


            .card-stat{
            
                &.card-1{
                    max-width: 165px;
                    z-index: 1;
                }
                &.card-2{
                    max-width: 165px;
                }
                &.card-3{
                    max-width: 165px;
                }
                &.card-4{
                    max-width: 165px;
                    z-index: 1;
                }
                
            }






        }
       
    }
}

.content-image-bx-list{
    position: relative;
    padding: 100px 25px;
    margin-top: 55px;
    max-width: 610px;
    margin-left: auto;
    img{
        border-radius: 25px;
        min-height: 250px;
        width: 100%;
        object-fit: cover;
    }
    .card-stat{
        max-width: 305px;
        column-gap: 15px;
        box-shadow: 5px 5px 50px #00000026;
        position: absolute;
        transition: all ease 0.8s;
        @media (min-width: 1200px) and (max-width: 1300px) {
            padding: 30px;
        }
        h3{
            font-weight: 600;
            width: auto;
        }
        p{
            max-width: 120px;
        }
        &.card-1 {
            top: 0;
            left: 0;
            opacity: 1;
            transform: translate(0px, 0px);
            max-width: 249px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            h3{
                width: 100%;
            }
            p{
                width: 100%;
            }
        }
        &.card-2 {
            top: 0;
            left: 100%;
            transform: translate(-100%, 0px);
            max-width: 319px;
            width: 100%;
            opacity: .6;
            display: flex;
            flex-wrap: nowrap;
            h3{
                width: auto;
            }
            p{
                width: 100%;
            }
        }
        &.card-3 {
            top: 100%;
            left: 0;
            opacity: .6;
            transform: translate(0px, -100%);
            max-width: 320px;
            width: 100%;
            display: flex;
            // flex-wrap: nowrap;
            h3{
                width: auto;
            }
            p{
                width: 100%;
            }
        }
        &.card-4 {
            left: 100%;
            top: 100%;
            opacity: 1;
            transform: translate(-100%, -100%);
            max-width: 212px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            h3{
                width: 100%;
            }
            p{
                width: 100%;
            }
        }
        @media (max-width: 700px) {
            flex-wrap: wrap!important;
            padding: 15px;
            max-width: 150px!important;
            
            h3{
                width: 100%!important;
            }
            p{
                max-width: 100%!important;
            }
        }
    }

    @media (max-width: 1200px) {
            margin-top: 40px;
            margin-left: auto;
            margin-right: auto;
    }
    @media (max-width: 767px) {
            .card-stat{
                 
            }
    }
}
.page{
    .site-main{
        > .page{
            >.entry-content{
                >.wp-block-cover.alignfull{
                    padding-left: 0;
                    padding-right: 0;
                    padding-bottom: 0;
                    >.wp-block-cover__inner-container{
                        max-width: 1370px;
                        padding: 0 25px;
                        @media (max-width: $breakpoint-down-md) {
                            padding: 0 30px;
                        }
                    }
                }
            }
           
          
        }
    }
}
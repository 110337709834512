.meet-the-team{
    .content-title{
        margin-bottom: 46px;
    }
    .list-team-items{
        margin-bottom: -49px;
        .row{
            >div{
                margin-bottom: 49px;
            }
        }
    }
    @media(max-width: 768px){
        .list-team-items{
            .row{
                >div{
                    width: 33.333%;
                }
            }
        }
    }
    @media(max-width: 450px){
        .list-team-items{
            .row{
                >div{
                    width: 50%;
                    padding-left: 10%;
                }
            }
        }
    }
    @media(max-width: 325px){
        .list-team-items{
            .row{
                margin: 0 -5px;
                >div{
                    padding: 0 5px;
                }
            }
        }
    }
}

.post-team-bx{
    .figure-team{
        width: 120px;
        height: 120px;
        overflow: hidden;
        border-radius: 5px;
        position: relative;
        box-shadow: 0 0 10px rgb(0 0 0 / 10%);
        margin-bottom: 39px;
        &:after{
            content: "";
            opacity: 0.25;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            background: $gradiant-blue-green;
        }
        img{
            width: 100%;
            height: 100%;
            position: relative;
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            z-index: 1;
            filter: saturate(0);
        }
    }
    h5{
       font-family: $font-title; 
       font-weight: 600;
       margin-bottom: 10px;
    }
    h6{
        font-family: $font-text; 
        margin-bottom: 0;
        font-weight: 400;
    }
    >*:last-child{
        margin-bottom: 0;
    }
    @media(max-width: $breakpoint-down-md){
        .figure-team{
            margin-bottom: 20px;
        }
    }
    @media(max-width: 450px){
        .figure-team{
            width: 100px;
            height: 100px;
        }
    }
}
.block-columns-with-top-line-tabs {
    .content-title {
        margin-bottom: clamp(30px, 5vw, 65px);
    }

    .content-tabs {
        .box {
            display: flex;
            flex-direction: column;
            row-gap: 23px;

            &>ul {
                gap: clamp(10px, 5vw, 20px);

                .nav-link {
                    letter-spacing: 0;
                    color: #2A5F5A;
                    text-transform: uppercase;
                    border: 2px solid #2A5F5A;
                    border-radius: 100px;
                    font-size: 22px;
                    font-weight: 700;
                    font-family: Poppins, sans-serif;
                    padding: 16px 40px;

                    &.active {
                        color: #fff;
                        font-family: Poppins, sans-serif;
                        background: $green 0 0 no-repeat padding-box;
                        border: 2px solid $green;
                        border-radius: 100px;
                        padding: 16px 40px;

                    }

                }
            }

            .wrapper-tab {
                .tab-pane {
                    &>h3 {
                        margin-bottom: 18px;
                        letter-spacing: -0.68px;
                        color: #000000 !important;
                        text-align: left;
                    }

                    &>p {
                        letter-spacing: 0;
                        color: $black_2;
                        font-size: 22px !important;
                        text-align: left;
                    }

                    .content-lists {
                        margin-top: clamp(20px, 5vw, 35px);



                        .content-repeater {
                            display: flex;
                            flex-direction: column;
                            &>hr{
                                border-top: 2px solid rgba(0, 0, 0, 0.4);
                                opacity: 1;
                            }
                            p{
                                font-size: 18px;
                                color: $black_2 !important;
                                flex-grow: 1;
                            }
                            a{
                                text-align: start;
                            }
                        }
                    }
                }
            }
        }
    }

}
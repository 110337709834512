.cta-promo-bar{
    border-radius: 15px;
    opacity: 1;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6{
        margin-bottom: 0 !important;
        letter-spacing: 0;
        font-weight: 700;
    }
    h4{
        font-size: 24px;
        line-height: 1.125em;
    }

    .btn {

        @media(min-width: 768px){
            min-width: 209px;
            padding: 19px 36px;
        }
    }
    .row-banner{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0px 0 27px;
        .col-banner-detail{
            text-align: left;
            padding-right: 15px;
        }
        .col-banner-cta{
            text-align: right;
        }
    }

    @media (max-width: $breakpoint-down-lg) {
       margin-bottom: 40px!important;

    }
    @media (max-width: $breakpoint-down-md) {

        .row-banner{
            flex-wrap: wrap;
            padding: 0;
            .col-banner-detail{
                padding-right: 0;
                width: 100%;
                text-align: left;
                margin-bottom: 20px;
            }
            .col-banner-cta{
                text-align: center;
                width: 100%;
            }
        }
    }
}
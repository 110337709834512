.latest-resources{
    .content-title{
        margin-bottom: 70px;
        p{
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .content-buttons {
        margin-top: 20px;
    }
    .row {
        --bs-gutter-x: 30px;
        --bs-gutter-y: 0;
        .mb-post {
            margin-bottom: 30px;
        }
        .mb-rf {
            margin-bottom: 80px !important;
        }
        .mt-r {
            margin-top: -50px;
        }
    }
    @media (max-width: 991px) {
        .content-title{
            margin-bottom: 40px;
        }
        .content-buttons {
            margin-top: 10px;
        }
        .firts {
            order: 1;
        }
        .second {
            order: 0;
        }
        .row {
            .mb-rf {
                margin-bottom: 30px !important;
            }
            .mt-r {
                margin-top: 0;
            }
        }
    }
}

.item-resources{
    background: linear-gradient(227deg,#57D18B 0,#0167A6 100%);
    mix-blend-mode: overlay;
    height: 100%;
    border-radius: 15px;
    overflow: hidden;
    &.item-resouces-no-img{
      //  background: linear-gradient(234deg, #569EF0 0%, #0167A6 100%);
        background: transparent linear-gradient(71deg, #0167A6 0%, #569EF0 100%) 0% 0% no-repeat padding-box;
        .item-resources--img{
            display: none;
        }
        .item-resources--text {
            p{
                display: block;
            }
        }
    }
    &.item-featured-resouces {
        padding: 30px;
       // background: linear-gradient(250deg, #569EF0 0%, #240780 100%);
        background: transparent linear-gradient(71deg, #0167A6 0%, #569EF0 100%) 0% 0% no-repeat padding-box;
        display: flex;
        column-gap: 30px;
        height: 100%;
        flex-direction: row-reverse;
        align-items: center;
        .item-resources--img {
            width: 48%;
            height: 100%;
            max-height: 200px;
            img {
                mix-blend-mode: initial;
            }
        }
        .item-resources--text {
            width: 52%;
            padding: 0;
            h4{
                margin-bottom: 10px;
            }
            p{
                display: block;
            }
        }
        @media (max-width: 690px) {
            flex-wrap: wrap;
            height: auto !important;
            .item-resources--img {
                width: 100%;
                margin-bottom: 25px;
            }
            .item-resources--text {
                width: 100%;
            }
        }
    }
    .item-resources--img {
        position: relative;
        height: 195px;
        img {
            width: 100%;
            height: 100% !important;
            object-fit: cover;
            mix-blend-mode: soft-light;
        }
    }
    .item-resources--text {
        padding: 30px;
        span{
            background: $primary;
            border-radius: 5px;
            padding: 3px 15px;
            color: white!important;
            display: inline-block;
            margin-bottom: 15px;
            font-family: $font-title;
            font-weight: 500;
        }
        h4{
            color: white!important;
            margin-bottom: 20px;
            text-transform: uppercase;
        }
        p{
            color: white!important;
            margin-bottom: 20px;
            font-size: 18px;
            display: none;
        }
        @media (max-width: 767px) {
            h4{
                font-size: 20px!important;
            }
        }
    }
}
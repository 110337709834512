.side-by-side{
    @media (min-width: 1400px) {
        .row {
            --bs-gutter-x: 90px;
        }
    }
    .content-text{
        .content-buttons {
            margin-top: 40px;
        }
        .eyebrow {
            font-weight: 700;
            font-size: 22px;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li{
                position: relative;
                padding-left: 37px;
                margin-bottom: 15px;
                font-family: $font-title;
                font-weight: 600;
                line-height: 1.5em;
                &:last-child{
                    margin-bottom: 0;
                }
                &::before{
                    content: '';
                    background: url('../assets/img/checkmark-list.svg');
                    position: absolute;
                    background-size: contain;
                    width: 19px;
                    height: 14px;
                    left: 0;
                    top: 8px;
                }
            }
        }
    }
    .content-media{
        img{
            display: block;
            margin: auto;
            max-width: 370px;
            width: 100%;
        }
    }
}
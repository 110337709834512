.hero-banner-featured-resource{
    overflow: initial;
    &::before {
         content: "";
      /*   position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 140px;
        z-index: 2;
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); */
    }
  /*   .overlay-content{
        background-image: url('../assets/img/overlay-hero-home.png') !important;
        background-size: cover !important;
        background-position: 50% 100% !important;
    } */
    .video-container{
        //opacity: 0.2;
        z-index: 1;
    }
    h1{
        margin-bottom: 27px
    }
    p{
        @media(min-width: 991px){
            font-size: 22px;
        }
    }
    .content-buttons{
        margin-top: 39px
    }
    @include box-feactured-resource;
}



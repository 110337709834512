@charset "UTF-8";
/* $green-dark:       #2CB04B; */
/*!
 * Bootstrap  v5.2.3 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #240780;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #FFC72C;
  --bs-green: #00152F;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #00152F;
  --bs-secondary: #EB6852;
  --bs-success: #00152F;
  --bs-info: #0dcaf0;
  --bs-warning: #FFC72C;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 0, 21, 47;
  --bs-secondary-rgb: 235, 104, 82;
  --bs-success-rgb: 0, 21, 47;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 199, 44;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Figtree", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #00152F;
  --bs-link-hover-color: #001126;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff4d5;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  text-decoration-thickness: 3px;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote, .wp-block-quote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child, .wp-block-quote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer, .wp-block-quote cite {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before, .wp-block-quote cite::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}

.figure, figure.wp-block-image,
.wp-block-image > figure {
  display: inline-block;
}

.figure-img, figure.wp-block-image img,
.wp-block-image > figure img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption, figure.wp-block-image figcaption,
.wp-block-image > figure figcaption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 767px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 991px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 767px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 991px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table, .wp-block-table table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > *, .wp-block-table table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody, .wp-block-table table > tbody {
  vertical-align: inherit;
}
.table > thead, .wp-block-table table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > *, .wp-block-table table > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > *, .wp-block-table table > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #ccd0d5;
  --bs-table-border-color: #b8bbc0;
  --bs-table-striped-bg: #c2c6ca;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8bbc0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdc0c5;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #fbe1dc;
  --bs-table-border-color: #e2cbc6;
  --bs-table-striped-bg: #eed6d1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e2cbc6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e8d0cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #ccd0d5;
  --bs-table-border-color: #b8bbc0;
  --bs-table-striped-bg: #c2c6ca;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8bbc0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdc0c5;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff4d5;
  --bs-table-border-color: #e6dcc0;
  --bs-table-striped-bg: #f2e8ca;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dcc0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece2c5;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 766.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 990.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control, .wpcf7 .wpcf7-validation-errors, .wpcf7 input[type=text],
.wpcf7 input[type=search],
.wpcf7 input[type=url],
.wpcf7 input[type=tel],
.wpcf7 input[type=number],
.wpcf7 input[type=range],
.wpcf7 input[type=date],
.wpcf7 input[type=month],
.wpcf7 input[type=week],
.wpcf7 input[type=time],
.wpcf7 input[type=datetime],
.wpcf7 input[type=datetime-local],
.wpcf7 input[type=color],
.wpcf7 input[type=email],
.wpcf7 input[type=file],
.wpcf7 input[type=submit],
.wpcf7 select,
.wpcf7 textarea {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control, .wpcf7 .wpcf7-validation-errors, .wpcf7 input[type=text],
  .wpcf7 input[type=search],
  .wpcf7 input[type=url],
  .wpcf7 input[type=tel],
  .wpcf7 input[type=number],
  .wpcf7 input[type=range],
  .wpcf7 input[type=date],
  .wpcf7 input[type=month],
  .wpcf7 input[type=week],
  .wpcf7 input[type=time],
  .wpcf7 input[type=datetime],
  .wpcf7 input[type=datetime-local],
  .wpcf7 input[type=color],
  .wpcf7 input[type=email],
  .wpcf7 input[type=file],
  .wpcf7 input[type=submit],
  .wpcf7 select,
  .wpcf7 textarea {
    transition: none;
  }
}
.form-control[type=file], .wpcf7 [type=file].wpcf7-validation-errors,
.wpcf7 input[type=file],
.wpcf7 select[type=file],
.wpcf7 textarea[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]), .wpcf7 [type=file].wpcf7-validation-errors:not(:disabled):not([readonly]),
.wpcf7 input[type=file]:not(:disabled):not([readonly]),
.wpcf7 select[type=file]:not(:disabled):not([readonly]),
.wpcf7 textarea[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus, .wpcf7 .wpcf7-validation-errors:focus, .wpcf7 input[type=text]:focus,
.wpcf7 input[type=search]:focus,
.wpcf7 input[type=url]:focus,
.wpcf7 input[type=tel]:focus,
.wpcf7 input[type=number]:focus,
.wpcf7 input[type=range]:focus,
.wpcf7 input[type=date]:focus,
.wpcf7 input[type=month]:focus,
.wpcf7 input[type=week]:focus,
.wpcf7 input[type=time]:focus,
.wpcf7 input[type=datetime]:focus,
.wpcf7 input[type=datetime-local]:focus,
.wpcf7 input[type=color]:focus,
.wpcf7 input[type=email]:focus,
.wpcf7 input[type=file]:focus,
.wpcf7 input[type=submit]:focus,
.wpcf7 select:focus,
.wpcf7 textarea:focus {
  color: #212529;
  background-color: #fff;
  border-color: #808a97;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 21, 47, 0.25);
}
.form-control::-webkit-date-and-time-value, .wpcf7 .wpcf7-validation-errors::-webkit-date-and-time-value, .wpcf7 input[type=text]::-webkit-date-and-time-value,
.wpcf7 input[type=search]::-webkit-date-and-time-value,
.wpcf7 input[type=url]::-webkit-date-and-time-value,
.wpcf7 input[type=tel]::-webkit-date-and-time-value,
.wpcf7 input[type=number]::-webkit-date-and-time-value,
.wpcf7 input[type=range]::-webkit-date-and-time-value,
.wpcf7 input[type=date]::-webkit-date-and-time-value,
.wpcf7 input[type=month]::-webkit-date-and-time-value,
.wpcf7 input[type=week]::-webkit-date-and-time-value,
.wpcf7 input[type=time]::-webkit-date-and-time-value,
.wpcf7 input[type=datetime]::-webkit-date-and-time-value,
.wpcf7 input[type=datetime-local]::-webkit-date-and-time-value,
.wpcf7 input[type=color]::-webkit-date-and-time-value,
.wpcf7 input[type=email]::-webkit-date-and-time-value,
.wpcf7 input[type=file]::-webkit-date-and-time-value,
.wpcf7 input[type=submit]::-webkit-date-and-time-value,
.wpcf7 select::-webkit-date-and-time-value,
.wpcf7 textarea::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::-moz-placeholder, .wpcf7 .wpcf7-validation-errors::-moz-placeholder, .wpcf7 input[type=text]::-moz-placeholder, .wpcf7 input[type=search]::-moz-placeholder, .wpcf7 input[type=url]::-moz-placeholder, .wpcf7 input[type=tel]::-moz-placeholder, .wpcf7 input[type=number]::-moz-placeholder, .wpcf7 input[type=range]::-moz-placeholder, .wpcf7 input[type=date]::-moz-placeholder, .wpcf7 input[type=month]::-moz-placeholder, .wpcf7 input[type=week]::-moz-placeholder, .wpcf7 input[type=time]::-moz-placeholder, .wpcf7 input[type=datetime]::-moz-placeholder, .wpcf7 input[type=datetime-local]::-moz-placeholder, .wpcf7 input[type=color]::-moz-placeholder, .wpcf7 input[type=email]::-moz-placeholder, .wpcf7 input[type=file]::-moz-placeholder, .wpcf7 input[type=submit]::-moz-placeholder, .wpcf7 select::-moz-placeholder, .wpcf7 textarea::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder, .wpcf7 .wpcf7-validation-errors::placeholder, .wpcf7 input[type=text]::placeholder,
.wpcf7 input[type=search]::placeholder,
.wpcf7 input[type=url]::placeholder,
.wpcf7 input[type=tel]::placeholder,
.wpcf7 input[type=number]::placeholder,
.wpcf7 input[type=range]::placeholder,
.wpcf7 input[type=date]::placeholder,
.wpcf7 input[type=month]::placeholder,
.wpcf7 input[type=week]::placeholder,
.wpcf7 input[type=time]::placeholder,
.wpcf7 input[type=datetime]::placeholder,
.wpcf7 input[type=datetime-local]::placeholder,
.wpcf7 input[type=color]::placeholder,
.wpcf7 input[type=email]::placeholder,
.wpcf7 input[type=file]::placeholder,
.wpcf7 input[type=submit]::placeholder,
.wpcf7 select::placeholder,
.wpcf7 textarea::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .wpcf7 .wpcf7-validation-errors:disabled, .wpcf7 input[type=text]:disabled,
.wpcf7 input[type=search]:disabled,
.wpcf7 input[type=url]:disabled,
.wpcf7 input[type=tel]:disabled,
.wpcf7 input[type=number]:disabled,
.wpcf7 input[type=range]:disabled,
.wpcf7 input[type=date]:disabled,
.wpcf7 input[type=month]:disabled,
.wpcf7 input[type=week]:disabled,
.wpcf7 input[type=time]:disabled,
.wpcf7 input[type=datetime]:disabled,
.wpcf7 input[type=datetime-local]:disabled,
.wpcf7 input[type=color]:disabled,
.wpcf7 input[type=email]:disabled,
.wpcf7 input[type=file]:disabled,
.wpcf7 input[type=submit]:disabled,
.wpcf7 select:disabled,
.wpcf7 textarea:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button, .wpcf7 .wpcf7-validation-errors::file-selector-button, .wpcf7 input[type=text]::file-selector-button,
.wpcf7 input[type=search]::file-selector-button,
.wpcf7 input[type=url]::file-selector-button,
.wpcf7 input[type=tel]::file-selector-button,
.wpcf7 input[type=number]::file-selector-button,
.wpcf7 input[type=range]::file-selector-button,
.wpcf7 input[type=date]::file-selector-button,
.wpcf7 input[type=month]::file-selector-button,
.wpcf7 input[type=week]::file-selector-button,
.wpcf7 input[type=time]::file-selector-button,
.wpcf7 input[type=datetime]::file-selector-button,
.wpcf7 input[type=datetime-local]::file-selector-button,
.wpcf7 input[type=color]::file-selector-button,
.wpcf7 input[type=email]::file-selector-button,
.wpcf7 input[type=file]::file-selector-button,
.wpcf7 input[type=submit]::file-selector-button,
.wpcf7 select::file-selector-button,
.wpcf7 textarea::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button, .wpcf7 .wpcf7-validation-errors::file-selector-button, .wpcf7 input[type=text]::file-selector-button,
  .wpcf7 input[type=search]::file-selector-button,
  .wpcf7 input[type=url]::file-selector-button,
  .wpcf7 input[type=tel]::file-selector-button,
  .wpcf7 input[type=number]::file-selector-button,
  .wpcf7 input[type=range]::file-selector-button,
  .wpcf7 input[type=date]::file-selector-button,
  .wpcf7 input[type=month]::file-selector-button,
  .wpcf7 input[type=week]::file-selector-button,
  .wpcf7 input[type=time]::file-selector-button,
  .wpcf7 input[type=datetime]::file-selector-button,
  .wpcf7 input[type=datetime-local]::file-selector-button,
  .wpcf7 input[type=color]::file-selector-button,
  .wpcf7 input[type=email]::file-selector-button,
  .wpcf7 input[type=file]::file-selector-button,
  .wpcf7 input[type=submit]::file-selector-button,
  .wpcf7 select::file-selector-button,
  .wpcf7 textarea::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button, .wpcf7 .wpcf7-validation-errors:hover:not(:disabled):not([readonly])::file-selector-button, .wpcf7 input[type=text]:hover:not(:disabled):not([readonly])::file-selector-button,
.wpcf7 input[type=search]:hover:not(:disabled):not([readonly])::file-selector-button,
.wpcf7 input[type=url]:hover:not(:disabled):not([readonly])::file-selector-button,
.wpcf7 input[type=tel]:hover:not(:disabled):not([readonly])::file-selector-button,
.wpcf7 input[type=number]:hover:not(:disabled):not([readonly])::file-selector-button,
.wpcf7 input[type=range]:hover:not(:disabled):not([readonly])::file-selector-button,
.wpcf7 input[type=date]:hover:not(:disabled):not([readonly])::file-selector-button,
.wpcf7 input[type=month]:hover:not(:disabled):not([readonly])::file-selector-button,
.wpcf7 input[type=week]:hover:not(:disabled):not([readonly])::file-selector-button,
.wpcf7 input[type=time]:hover:not(:disabled):not([readonly])::file-selector-button,
.wpcf7 input[type=datetime]:hover:not(:disabled):not([readonly])::file-selector-button,
.wpcf7 input[type=datetime-local]:hover:not(:disabled):not([readonly])::file-selector-button,
.wpcf7 input[type=color]:hover:not(:disabled):not([readonly])::file-selector-button,
.wpcf7 input[type=email]:hover:not(:disabled):not([readonly])::file-selector-button,
.wpcf7 input[type=file]:hover:not(:disabled):not([readonly])::file-selector-button,
.wpcf7 input[type=submit]:hover:not(:disabled):not([readonly])::file-selector-button,
.wpcf7 select:hover:not(:disabled):not([readonly])::file-selector-button,
.wpcf7 textarea:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

textarea.form-control, .wpcf7 textarea.wpcf7-validation-errors,
.wpcf7 textarea {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #808a97;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 21, 47, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #808a97;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 21, 47, 0.25);
}
.form-check-input:checked {
  background-color: #00152F;
  border-color: #00152F;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #00152F;
  border-color: #00152F;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23808a97'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .wpcf7 .btn-check[disabled] + input[type=submit], .woocommerce-info .btn-check[disabled] + a.button, .woocommerce-notices-wrapper .woocommerce-message .btn-check[disabled] + a.button, .btn-check:disabled + .btn, .wpcf7 .btn-check:disabled + input[type=submit], .woocommerce-info .btn-check:disabled + a.button, .woocommerce-notices-wrapper .woocommerce-message .btn-check:disabled + a.button {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 21, 47, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 21, 47, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #00152F;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b3b9c1;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #00152F;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b3b9c1;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control, .wpcf7 .form-floating > .wpcf7-validation-errors, .wpcf7 .form-floating > input[type=text],
.wpcf7 .form-floating > input[type=search],
.wpcf7 .form-floating > input[type=url],
.wpcf7 .form-floating > input[type=tel],
.wpcf7 .form-floating > input[type=number],
.wpcf7 .form-floating > input[type=range],
.wpcf7 .form-floating > input[type=date],
.wpcf7 .form-floating > input[type=month],
.wpcf7 .form-floating > input[type=week],
.wpcf7 .form-floating > input[type=time],
.wpcf7 .form-floating > input[type=datetime],
.wpcf7 .form-floating > input[type=datetime-local],
.wpcf7 .form-floating > input[type=color],
.wpcf7 .form-floating > input[type=email],
.wpcf7 .form-floating > input[type=file],
.wpcf7 .form-floating > input[type=submit],
.wpcf7 .form-floating > select,
.wpcf7 .form-floating > textarea,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control, .wpcf7 .form-floating > .wpcf7-validation-errors, .wpcf7 .form-floating > input[type=text],
.wpcf7 .form-floating > input[type=search],
.wpcf7 .form-floating > input[type=url],
.wpcf7 .form-floating > input[type=tel],
.wpcf7 .form-floating > input[type=number],
.wpcf7 .form-floating > input[type=range],
.wpcf7 .form-floating > input[type=date],
.wpcf7 .form-floating > input[type=month],
.wpcf7 .form-floating > input[type=week],
.wpcf7 .form-floating > input[type=time],
.wpcf7 .form-floating > input[type=datetime],
.wpcf7 .form-floating > input[type=datetime-local],
.wpcf7 .form-floating > input[type=color],
.wpcf7 .form-floating > input[type=email],
.wpcf7 .form-floating > input[type=file],
.wpcf7 .form-floating > input[type=submit],
.wpcf7 .form-floating > select,
.wpcf7 .form-floating > textarea,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::-moz-placeholder, .wpcf7 .form-floating > .wpcf7-validation-errors::-moz-placeholder, .wpcf7 .form-floating > input[type=text]::-moz-placeholder, .wpcf7 .form-floating > input[type=search]::-moz-placeholder, .wpcf7 .form-floating > input[type=url]::-moz-placeholder, .wpcf7 .form-floating > input[type=tel]::-moz-placeholder, .wpcf7 .form-floating > input[type=number]::-moz-placeholder, .wpcf7 .form-floating > input[type=range]::-moz-placeholder, .wpcf7 .form-floating > input[type=date]::-moz-placeholder, .wpcf7 .form-floating > input[type=month]::-moz-placeholder, .wpcf7 .form-floating > input[type=week]::-moz-placeholder, .wpcf7 .form-floating > input[type=time]::-moz-placeholder, .wpcf7 .form-floating > input[type=datetime]::-moz-placeholder, .wpcf7 .form-floating > input[type=datetime-local]::-moz-placeholder, .wpcf7 .form-floating > input[type=color]::-moz-placeholder, .wpcf7 .form-floating > input[type=email]::-moz-placeholder, .wpcf7 .form-floating > input[type=file]::-moz-placeholder, .wpcf7 .form-floating > input[type=submit]::-moz-placeholder, .wpcf7 .form-floating > select::-moz-placeholder, .wpcf7 .form-floating > textarea::-moz-placeholder, .form-floating > .form-control-plaintext::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder, .wpcf7 .form-floating > .wpcf7-validation-errors::placeholder, .wpcf7 .form-floating > input[type=text]::placeholder,
.wpcf7 .form-floating > input[type=search]::placeholder,
.wpcf7 .form-floating > input[type=url]::placeholder,
.wpcf7 .form-floating > input[type=tel]::placeholder,
.wpcf7 .form-floating > input[type=number]::placeholder,
.wpcf7 .form-floating > input[type=range]::placeholder,
.wpcf7 .form-floating > input[type=date]::placeholder,
.wpcf7 .form-floating > input[type=month]::placeholder,
.wpcf7 .form-floating > input[type=week]::placeholder,
.wpcf7 .form-floating > input[type=time]::placeholder,
.wpcf7 .form-floating > input[type=datetime]::placeholder,
.wpcf7 .form-floating > input[type=datetime-local]::placeholder,
.wpcf7 .form-floating > input[type=color]::placeholder,
.wpcf7 .form-floating > input[type=email]::placeholder,
.wpcf7 .form-floating > input[type=file]::placeholder,
.wpcf7 .form-floating > input[type=submit]::placeholder,
.wpcf7 .form-floating > select::placeholder,
.wpcf7 .form-floating > textarea::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown), .wpcf7 .form-floating > .wpcf7-validation-errors:not(:-moz-placeholder-shown), .wpcf7 .form-floating > input[type=text]:not(:-moz-placeholder-shown), .wpcf7 .form-floating > input[type=search]:not(:-moz-placeholder-shown), .wpcf7 .form-floating > input[type=url]:not(:-moz-placeholder-shown), .wpcf7 .form-floating > input[type=tel]:not(:-moz-placeholder-shown), .wpcf7 .form-floating > input[type=number]:not(:-moz-placeholder-shown), .wpcf7 .form-floating > input[type=range]:not(:-moz-placeholder-shown), .wpcf7 .form-floating > input[type=date]:not(:-moz-placeholder-shown), .wpcf7 .form-floating > input[type=month]:not(:-moz-placeholder-shown), .wpcf7 .form-floating > input[type=week]:not(:-moz-placeholder-shown), .wpcf7 .form-floating > input[type=time]:not(:-moz-placeholder-shown), .wpcf7 .form-floating > input[type=datetime]:not(:-moz-placeholder-shown), .wpcf7 .form-floating > input[type=datetime-local]:not(:-moz-placeholder-shown), .wpcf7 .form-floating > input[type=color]:not(:-moz-placeholder-shown), .wpcf7 .form-floating > input[type=email]:not(:-moz-placeholder-shown), .wpcf7 .form-floating > input[type=file]:not(:-moz-placeholder-shown), .wpcf7 .form-floating > input[type=submit]:not(:-moz-placeholder-shown), .wpcf7 .form-floating > select:not(:-moz-placeholder-shown), .wpcf7 .form-floating > textarea:not(:-moz-placeholder-shown), .form-floating > .form-control-plaintext:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus, .wpcf7 .form-floating > .wpcf7-validation-errors:focus, .wpcf7 .form-floating > input[type=text]:focus,
.wpcf7 .form-floating > input[type=search]:focus,
.wpcf7 .form-floating > input[type=url]:focus,
.wpcf7 .form-floating > input[type=tel]:focus,
.wpcf7 .form-floating > input[type=number]:focus,
.wpcf7 .form-floating > input[type=range]:focus,
.wpcf7 .form-floating > input[type=date]:focus,
.wpcf7 .form-floating > input[type=month]:focus,
.wpcf7 .form-floating > input[type=week]:focus,
.wpcf7 .form-floating > input[type=time]:focus,
.wpcf7 .form-floating > input[type=datetime]:focus,
.wpcf7 .form-floating > input[type=datetime-local]:focus,
.wpcf7 .form-floating > input[type=color]:focus,
.wpcf7 .form-floating > input[type=email]:focus,
.wpcf7 .form-floating > input[type=file]:focus,
.wpcf7 .form-floating > input[type=submit]:focus,
.wpcf7 .form-floating > select:focus,
.wpcf7 .form-floating > textarea:focus, .form-floating > .form-control:not(:placeholder-shown), .wpcf7 .form-floating > .wpcf7-validation-errors:not(:placeholder-shown), .wpcf7 .form-floating > input[type=text]:not(:placeholder-shown),
.wpcf7 .form-floating > input[type=search]:not(:placeholder-shown),
.wpcf7 .form-floating > input[type=url]:not(:placeholder-shown),
.wpcf7 .form-floating > input[type=tel]:not(:placeholder-shown),
.wpcf7 .form-floating > input[type=number]:not(:placeholder-shown),
.wpcf7 .form-floating > input[type=range]:not(:placeholder-shown),
.wpcf7 .form-floating > input[type=date]:not(:placeholder-shown),
.wpcf7 .form-floating > input[type=month]:not(:placeholder-shown),
.wpcf7 .form-floating > input[type=week]:not(:placeholder-shown),
.wpcf7 .form-floating > input[type=time]:not(:placeholder-shown),
.wpcf7 .form-floating > input[type=datetime]:not(:placeholder-shown),
.wpcf7 .form-floating > input[type=datetime-local]:not(:placeholder-shown),
.wpcf7 .form-floating > input[type=color]:not(:placeholder-shown),
.wpcf7 .form-floating > input[type=email]:not(:placeholder-shown),
.wpcf7 .form-floating > input[type=file]:not(:placeholder-shown),
.wpcf7 .form-floating > input[type=submit]:not(:placeholder-shown),
.wpcf7 .form-floating > select:not(:placeholder-shown),
.wpcf7 .form-floating > textarea:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill, .wpcf7 .form-floating > .wpcf7-validation-errors:-webkit-autofill, .wpcf7 .form-floating > input[type=text]:-webkit-autofill,
.wpcf7 .form-floating > input[type=search]:-webkit-autofill,
.wpcf7 .form-floating > input[type=url]:-webkit-autofill,
.wpcf7 .form-floating > input[type=tel]:-webkit-autofill,
.wpcf7 .form-floating > input[type=number]:-webkit-autofill,
.wpcf7 .form-floating > input[type=range]:-webkit-autofill,
.wpcf7 .form-floating > input[type=date]:-webkit-autofill,
.wpcf7 .form-floating > input[type=month]:-webkit-autofill,
.wpcf7 .form-floating > input[type=week]:-webkit-autofill,
.wpcf7 .form-floating > input[type=time]:-webkit-autofill,
.wpcf7 .form-floating > input[type=datetime]:-webkit-autofill,
.wpcf7 .form-floating > input[type=datetime-local]:-webkit-autofill,
.wpcf7 .form-floating > input[type=color]:-webkit-autofill,
.wpcf7 .form-floating > input[type=email]:-webkit-autofill,
.wpcf7 .form-floating > input[type=file]:-webkit-autofill,
.wpcf7 .form-floating > input[type=submit]:-webkit-autofill,
.wpcf7 .form-floating > select:-webkit-autofill,
.wpcf7 .form-floating > textarea:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label, .wpcf7 .form-floating > .wpcf7-validation-errors:not(:-moz-placeholder-shown) ~ label, .wpcf7 .form-floating > input[type=text]:not(:-moz-placeholder-shown) ~ label, .wpcf7 .form-floating > input[type=search]:not(:-moz-placeholder-shown) ~ label, .wpcf7 .form-floating > input[type=url]:not(:-moz-placeholder-shown) ~ label, .wpcf7 .form-floating > input[type=tel]:not(:-moz-placeholder-shown) ~ label, .wpcf7 .form-floating > input[type=number]:not(:-moz-placeholder-shown) ~ label, .wpcf7 .form-floating > input[type=range]:not(:-moz-placeholder-shown) ~ label, .wpcf7 .form-floating > input[type=date]:not(:-moz-placeholder-shown) ~ label, .wpcf7 .form-floating > input[type=month]:not(:-moz-placeholder-shown) ~ label, .wpcf7 .form-floating > input[type=week]:not(:-moz-placeholder-shown) ~ label, .wpcf7 .form-floating > input[type=time]:not(:-moz-placeholder-shown) ~ label, .wpcf7 .form-floating > input[type=datetime]:not(:-moz-placeholder-shown) ~ label, .wpcf7 .form-floating > input[type=datetime-local]:not(:-moz-placeholder-shown) ~ label, .wpcf7 .form-floating > input[type=color]:not(:-moz-placeholder-shown) ~ label, .wpcf7 .form-floating > input[type=email]:not(:-moz-placeholder-shown) ~ label, .wpcf7 .form-floating > input[type=file]:not(:-moz-placeholder-shown) ~ label, .wpcf7 .form-floating > input[type=submit]:not(:-moz-placeholder-shown) ~ label, .wpcf7 .form-floating > select:not(:-moz-placeholder-shown) ~ label, .wpcf7 .form-floating > textarea:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label, .wpcf7 .form-floating > .wpcf7-validation-errors:focus ~ label, .wpcf7 .form-floating > input[type=text]:focus ~ label,
.wpcf7 .form-floating > input[type=search]:focus ~ label,
.wpcf7 .form-floating > input[type=url]:focus ~ label,
.wpcf7 .form-floating > input[type=tel]:focus ~ label,
.wpcf7 .form-floating > input[type=number]:focus ~ label,
.wpcf7 .form-floating > input[type=range]:focus ~ label,
.wpcf7 .form-floating > input[type=date]:focus ~ label,
.wpcf7 .form-floating > input[type=month]:focus ~ label,
.wpcf7 .form-floating > input[type=week]:focus ~ label,
.wpcf7 .form-floating > input[type=time]:focus ~ label,
.wpcf7 .form-floating > input[type=datetime]:focus ~ label,
.wpcf7 .form-floating > input[type=datetime-local]:focus ~ label,
.wpcf7 .form-floating > input[type=color]:focus ~ label,
.wpcf7 .form-floating > input[type=email]:focus ~ label,
.wpcf7 .form-floating > input[type=file]:focus ~ label,
.wpcf7 .form-floating > input[type=submit]:focus ~ label,
.wpcf7 .form-floating > select:focus ~ label,
.wpcf7 .form-floating > textarea:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.wpcf7 .form-floating > .wpcf7-validation-errors:not(:placeholder-shown) ~ label,
.wpcf7 .form-floating > input[type=text]:not(:placeholder-shown) ~ label,
.wpcf7 .form-floating > input[type=search]:not(:placeholder-shown) ~ label,
.wpcf7 .form-floating > input[type=url]:not(:placeholder-shown) ~ label,
.wpcf7 .form-floating > input[type=tel]:not(:placeholder-shown) ~ label,
.wpcf7 .form-floating > input[type=number]:not(:placeholder-shown) ~ label,
.wpcf7 .form-floating > input[type=range]:not(:placeholder-shown) ~ label,
.wpcf7 .form-floating > input[type=date]:not(:placeholder-shown) ~ label,
.wpcf7 .form-floating > input[type=month]:not(:placeholder-shown) ~ label,
.wpcf7 .form-floating > input[type=week]:not(:placeholder-shown) ~ label,
.wpcf7 .form-floating > input[type=time]:not(:placeholder-shown) ~ label,
.wpcf7 .form-floating > input[type=datetime]:not(:placeholder-shown) ~ label,
.wpcf7 .form-floating > input[type=datetime-local]:not(:placeholder-shown) ~ label,
.wpcf7 .form-floating > input[type=color]:not(:placeholder-shown) ~ label,
.wpcf7 .form-floating > input[type=email]:not(:placeholder-shown) ~ label,
.wpcf7 .form-floating > input[type=file]:not(:placeholder-shown) ~ label,
.wpcf7 .form-floating > input[type=submit]:not(:placeholder-shown) ~ label,
.wpcf7 .form-floating > select:not(:placeholder-shown) ~ label,
.wpcf7 .form-floating > textarea:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label, .wpcf7 .form-floating > .wpcf7-validation-errors:-webkit-autofill ~ label, .wpcf7 .form-floating > input[type=text]:-webkit-autofill ~ label,
.wpcf7 .form-floating > input[type=search]:-webkit-autofill ~ label,
.wpcf7 .form-floating > input[type=url]:-webkit-autofill ~ label,
.wpcf7 .form-floating > input[type=tel]:-webkit-autofill ~ label,
.wpcf7 .form-floating > input[type=number]:-webkit-autofill ~ label,
.wpcf7 .form-floating > input[type=range]:-webkit-autofill ~ label,
.wpcf7 .form-floating > input[type=date]:-webkit-autofill ~ label,
.wpcf7 .form-floating > input[type=month]:-webkit-autofill ~ label,
.wpcf7 .form-floating > input[type=week]:-webkit-autofill ~ label,
.wpcf7 .form-floating > input[type=time]:-webkit-autofill ~ label,
.wpcf7 .form-floating > input[type=datetime]:-webkit-autofill ~ label,
.wpcf7 .form-floating > input[type=datetime-local]:-webkit-autofill ~ label,
.wpcf7 .form-floating > input[type=color]:-webkit-autofill ~ label,
.wpcf7 .form-floating > input[type=email]:-webkit-autofill ~ label,
.wpcf7 .form-floating > input[type=file]:-webkit-autofill ~ label,
.wpcf7 .form-floating > input[type=submit]:-webkit-autofill ~ label,
.wpcf7 .form-floating > select:-webkit-autofill ~ label,
.wpcf7 .form-floating > textarea:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control, .wpcf7 .input-group > .wpcf7-validation-errors, .wpcf7 .input-group > input[type=text],
.wpcf7 .input-group > input[type=search],
.wpcf7 .input-group > input[type=url],
.wpcf7 .input-group > input[type=tel],
.wpcf7 .input-group > input[type=number],
.wpcf7 .input-group > input[type=range],
.wpcf7 .input-group > input[type=date],
.wpcf7 .input-group > input[type=month],
.wpcf7 .input-group > input[type=week],
.wpcf7 .input-group > input[type=time],
.wpcf7 .input-group > input[type=datetime],
.wpcf7 .input-group > input[type=datetime-local],
.wpcf7 .input-group > input[type=color],
.wpcf7 .input-group > input[type=email],
.wpcf7 .input-group > input[type=file],
.wpcf7 .input-group > input[type=submit],
.wpcf7 .input-group > select,
.wpcf7 .input-group > textarea,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus, .wpcf7 .input-group > .wpcf7-validation-errors:focus, .wpcf7 .input-group > input[type=text]:focus,
.wpcf7 .input-group > input[type=search]:focus,
.wpcf7 .input-group > input[type=url]:focus,
.wpcf7 .input-group > input[type=tel]:focus,
.wpcf7 .input-group > input[type=number]:focus,
.wpcf7 .input-group > input[type=range]:focus,
.wpcf7 .input-group > input[type=date]:focus,
.wpcf7 .input-group > input[type=month]:focus,
.wpcf7 .input-group > input[type=week]:focus,
.wpcf7 .input-group > input[type=time]:focus,
.wpcf7 .input-group > input[type=datetime]:focus,
.wpcf7 .input-group > input[type=datetime-local]:focus,
.wpcf7 .input-group > input[type=color]:focus,
.wpcf7 .input-group > input[type=email]:focus,
.wpcf7 .input-group > input[type=file]:focus,
.wpcf7 .input-group > input[type=submit]:focus,
.wpcf7 .input-group > select:focus,
.wpcf7 .input-group > textarea:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn, .input-group .wpcf7 input[type=submit], .wpcf7 .input-group input[type=submit], .input-group .woocommerce-info a.button, .woocommerce-info .input-group a.button, .input-group .woocommerce-notices-wrapper .woocommerce-message a.button, .woocommerce-notices-wrapper .woocommerce-message .input-group a.button {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus, .input-group .wpcf7 input[type=submit]:focus, .wpcf7 .input-group input[type=submit]:focus, .input-group .woocommerce-info a.button:focus, .woocommerce-info .input-group a.button:focus, .input-group .woocommerce-notices-wrapper .woocommerce-message a.button:focus, .woocommerce-notices-wrapper .woocommerce-message .input-group a.button:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.input-group-lg > .form-control, .wpcf7 .input-group-lg > .wpcf7-validation-errors, .wpcf7 .input-group-lg > input[type=text],
.wpcf7 .input-group-lg > input[type=search],
.wpcf7 .input-group-lg > input[type=url],
.wpcf7 .input-group-lg > input[type=tel],
.wpcf7 .input-group-lg > input[type=number],
.wpcf7 .input-group-lg > input[type=range],
.wpcf7 .input-group-lg > input[type=date],
.wpcf7 .input-group-lg > input[type=month],
.wpcf7 .input-group-lg > input[type=week],
.wpcf7 .input-group-lg > input[type=time],
.wpcf7 .input-group-lg > input[type=datetime],
.wpcf7 .input-group-lg > input[type=datetime-local],
.wpcf7 .input-group-lg > input[type=color],
.wpcf7 .input-group-lg > input[type=email],
.wpcf7 .input-group-lg > input[type=file],
.wpcf7 .input-group-lg > input[type=submit],
.wpcf7 .input-group-lg > select,
.wpcf7 .input-group-lg > textarea,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn,
.woocommerce-info .input-group-lg > a.button,
.woocommerce-notices-wrapper .woocommerce-message .input-group-lg > a.button {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control, .wpcf7 .input-group-sm > .wpcf7-validation-errors, .wpcf7 .input-group-sm > input[type=text],
.wpcf7 .input-group-sm > input[type=search],
.wpcf7 .input-group-sm > input[type=url],
.wpcf7 .input-group-sm > input[type=tel],
.wpcf7 .input-group-sm > input[type=number],
.wpcf7 .input-group-sm > input[type=range],
.wpcf7 .input-group-sm > input[type=date],
.wpcf7 .input-group-sm > input[type=month],
.wpcf7 .input-group-sm > input[type=week],
.wpcf7 .input-group-sm > input[type=time],
.wpcf7 .input-group-sm > input[type=datetime],
.wpcf7 .input-group-sm > input[type=datetime-local],
.wpcf7 .input-group-sm > input[type=color],
.wpcf7 .input-group-sm > input[type=email],
.wpcf7 .input-group-sm > input[type=file],
.wpcf7 .input-group-sm > input[type=submit],
.wpcf7 .input-group-sm > select,
.wpcf7 .input-group-sm > textarea,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn,
.woocommerce-info .input-group-sm > a.button,
.woocommerce-notices-wrapper .woocommerce-message .input-group-sm > a.button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.wpcf7 .input-group:not(.has-validation) > .form-floating:not(:last-child) > .wpcf7-validation-errors,
.wpcf7 .input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=text],
.wpcf7 .input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=search],
.wpcf7 .input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=url],
.wpcf7 .input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=tel],
.wpcf7 .input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=number],
.wpcf7 .input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=range],
.wpcf7 .input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=date],
.wpcf7 .input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=month],
.wpcf7 .input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=week],
.wpcf7 .input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=time],
.wpcf7 .input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=datetime],
.wpcf7 .input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=datetime-local],
.wpcf7 .input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=color],
.wpcf7 .input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=email],
.wpcf7 .input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=file],
.wpcf7 .input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=submit],
.wpcf7 .input-group:not(.has-validation) > .form-floating:not(:last-child) > select,
.wpcf7 .input-group:not(.has-validation) > .form-floating:not(:last-child) > textarea,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.wpcf7 .input-group.has-validation > .form-floating:nth-last-child(n+3) > .wpcf7-validation-errors,
.wpcf7 .input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=text],
.wpcf7 .input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=search],
.wpcf7 .input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=url],
.wpcf7 .input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=tel],
.wpcf7 .input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=number],
.wpcf7 .input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=range],
.wpcf7 .input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=date],
.wpcf7 .input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=month],
.wpcf7 .input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=week],
.wpcf7 .input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=time],
.wpcf7 .input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=datetime],
.wpcf7 .input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=datetime-local],
.wpcf7 .input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=color],
.wpcf7 .input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=email],
.wpcf7 .input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=file],
.wpcf7 .input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=submit],
.wpcf7 .input-group.has-validation > .form-floating:nth-last-child(n+3) > select,
.wpcf7 .input-group.has-validation > .form-floating:nth-last-child(n+3) > textarea,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control, .wpcf7 .input-group > .form-floating:not(:first-child) > .wpcf7-validation-errors, .wpcf7 .input-group > .form-floating:not(:first-child) > input[type=text],
.wpcf7 .input-group > .form-floating:not(:first-child) > input[type=search],
.wpcf7 .input-group > .form-floating:not(:first-child) > input[type=url],
.wpcf7 .input-group > .form-floating:not(:first-child) > input[type=tel],
.wpcf7 .input-group > .form-floating:not(:first-child) > input[type=number],
.wpcf7 .input-group > .form-floating:not(:first-child) > input[type=range],
.wpcf7 .input-group > .form-floating:not(:first-child) > input[type=date],
.wpcf7 .input-group > .form-floating:not(:first-child) > input[type=month],
.wpcf7 .input-group > .form-floating:not(:first-child) > input[type=week],
.wpcf7 .input-group > .form-floating:not(:first-child) > input[type=time],
.wpcf7 .input-group > .form-floating:not(:first-child) > input[type=datetime],
.wpcf7 .input-group > .form-floating:not(:first-child) > input[type=datetime-local],
.wpcf7 .input-group > .form-floating:not(:first-child) > input[type=color],
.wpcf7 .input-group > .form-floating:not(:first-child) > input[type=email],
.wpcf7 .input-group > .form-floating:not(:first-child) > input[type=file],
.wpcf7 .input-group > .form-floating:not(:first-child) > input[type=submit],
.wpcf7 .input-group > .form-floating:not(:first-child) > select,
.wpcf7 .input-group > .form-floating:not(:first-child) > textarea,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #00152F;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(0, 21, 47, 0.9);
  border-radius: 0.375rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .was-validated .wpcf7 .wpcf7-validation-errors:valid, .wpcf7 .was-validated .wpcf7-validation-errors:valid, .was-validated .wpcf7 input[type=text]:valid, .wpcf7 .was-validated input[type=text]:valid,
.was-validated .wpcf7 input[type=search]:valid,
.wpcf7 .was-validated input[type=search]:valid,
.was-validated .wpcf7 input[type=url]:valid,
.wpcf7 .was-validated input[type=url]:valid,
.was-validated .wpcf7 input[type=tel]:valid,
.wpcf7 .was-validated input[type=tel]:valid,
.was-validated .wpcf7 input[type=number]:valid,
.wpcf7 .was-validated input[type=number]:valid,
.was-validated .wpcf7 input[type=range]:valid,
.wpcf7 .was-validated input[type=range]:valid,
.was-validated .wpcf7 input[type=date]:valid,
.wpcf7 .was-validated input[type=date]:valid,
.was-validated .wpcf7 input[type=month]:valid,
.wpcf7 .was-validated input[type=month]:valid,
.was-validated .wpcf7 input[type=week]:valid,
.wpcf7 .was-validated input[type=week]:valid,
.was-validated .wpcf7 input[type=time]:valid,
.wpcf7 .was-validated input[type=time]:valid,
.was-validated .wpcf7 input[type=datetime]:valid,
.wpcf7 .was-validated input[type=datetime]:valid,
.was-validated .wpcf7 input[type=datetime-local]:valid,
.wpcf7 .was-validated input[type=datetime-local]:valid,
.was-validated .wpcf7 input[type=color]:valid,
.wpcf7 .was-validated input[type=color]:valid,
.was-validated .wpcf7 input[type=email]:valid,
.wpcf7 .was-validated input[type=email]:valid,
.was-validated .wpcf7 input[type=file]:valid,
.wpcf7 .was-validated input[type=file]:valid,
.was-validated .wpcf7 input[type=submit]:valid,
.wpcf7 .was-validated input[type=submit]:valid,
.was-validated .wpcf7 select:valid,
.wpcf7 .was-validated select:valid,
.was-validated .wpcf7 textarea:valid,
.wpcf7 .was-validated textarea:valid, .form-control.is-valid, .wpcf7 .is-valid.wpcf7-validation-errors, .wpcf7 input.is-valid[type=text],
.wpcf7 input.is-valid[type=search],
.wpcf7 input.is-valid[type=url],
.wpcf7 input.is-valid[type=tel],
.wpcf7 input.is-valid[type=number],
.wpcf7 input.is-valid[type=range],
.wpcf7 input.is-valid[type=date],
.wpcf7 input.is-valid[type=month],
.wpcf7 input.is-valid[type=week],
.wpcf7 input.is-valid[type=time],
.wpcf7 input.is-valid[type=datetime],
.wpcf7 input.is-valid[type=datetime-local],
.wpcf7 input.is-valid[type=color],
.wpcf7 input.is-valid[type=email],
.wpcf7 input.is-valid[type=file],
.wpcf7 input.is-valid[type=submit],
.wpcf7 select.is-valid,
.wpcf7 textarea.is-valid {
  border-color: #00152F;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300152F' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .was-validated .wpcf7 .wpcf7-validation-errors:valid:focus, .wpcf7 .was-validated .wpcf7-validation-errors:valid:focus, .was-validated .wpcf7 input[type=text]:valid:focus, .wpcf7 .was-validated input[type=text]:valid:focus,
.was-validated .wpcf7 input[type=search]:valid:focus,
.wpcf7 .was-validated input[type=search]:valid:focus,
.was-validated .wpcf7 input[type=url]:valid:focus,
.wpcf7 .was-validated input[type=url]:valid:focus,
.was-validated .wpcf7 input[type=tel]:valid:focus,
.wpcf7 .was-validated input[type=tel]:valid:focus,
.was-validated .wpcf7 input[type=number]:valid:focus,
.wpcf7 .was-validated input[type=number]:valid:focus,
.was-validated .wpcf7 input[type=range]:valid:focus,
.wpcf7 .was-validated input[type=range]:valid:focus,
.was-validated .wpcf7 input[type=date]:valid:focus,
.wpcf7 .was-validated input[type=date]:valid:focus,
.was-validated .wpcf7 input[type=month]:valid:focus,
.wpcf7 .was-validated input[type=month]:valid:focus,
.was-validated .wpcf7 input[type=week]:valid:focus,
.wpcf7 .was-validated input[type=week]:valid:focus,
.was-validated .wpcf7 input[type=time]:valid:focus,
.wpcf7 .was-validated input[type=time]:valid:focus,
.was-validated .wpcf7 input[type=datetime]:valid:focus,
.wpcf7 .was-validated input[type=datetime]:valid:focus,
.was-validated .wpcf7 input[type=datetime-local]:valid:focus,
.wpcf7 .was-validated input[type=datetime-local]:valid:focus,
.was-validated .wpcf7 input[type=color]:valid:focus,
.wpcf7 .was-validated input[type=color]:valid:focus,
.was-validated .wpcf7 input[type=email]:valid:focus,
.wpcf7 .was-validated input[type=email]:valid:focus,
.was-validated .wpcf7 input[type=file]:valid:focus,
.wpcf7 .was-validated input[type=file]:valid:focus,
.was-validated .wpcf7 input[type=submit]:valid:focus,
.wpcf7 .was-validated input[type=submit]:valid:focus,
.was-validated .wpcf7 select:valid:focus,
.wpcf7 .was-validated select:valid:focus,
.was-validated .wpcf7 textarea:valid:focus,
.wpcf7 .was-validated textarea:valid:focus, .form-control.is-valid:focus, .wpcf7 .is-valid.wpcf7-validation-errors:focus, .wpcf7 input.is-valid[type=text]:focus,
.wpcf7 input.is-valid[type=search]:focus,
.wpcf7 input.is-valid[type=url]:focus,
.wpcf7 input.is-valid[type=tel]:focus,
.wpcf7 input.is-valid[type=number]:focus,
.wpcf7 input.is-valid[type=range]:focus,
.wpcf7 input.is-valid[type=date]:focus,
.wpcf7 input.is-valid[type=month]:focus,
.wpcf7 input.is-valid[type=week]:focus,
.wpcf7 input.is-valid[type=time]:focus,
.wpcf7 input.is-valid[type=datetime]:focus,
.wpcf7 input.is-valid[type=datetime-local]:focus,
.wpcf7 input.is-valid[type=color]:focus,
.wpcf7 input.is-valid[type=email]:focus,
.wpcf7 input.is-valid[type=file]:focus,
.wpcf7 input.is-valid[type=submit]:focus,
.wpcf7 select.is-valid:focus,
.wpcf7 textarea.is-valid:focus {
  border-color: #00152F;
  box-shadow: 0 0 0 0.25rem rgba(0, 21, 47, 0.25);
}

.was-validated textarea.form-control:valid,
.was-validated .wpcf7 textarea:valid,
.wpcf7 .was-validated textarea:valid, textarea.form-control.is-valid,
.wpcf7 textarea.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #00152F;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300152F' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #00152F;
  box-shadow: 0 0 0 0.25rem rgba(0, 21, 47, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #00152F;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #00152F;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 21, 47, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00152F;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .was-validated .wpcf7 .input-group > .wpcf7-validation-errors:not(:focus):valid, .wpcf7 .was-validated .input-group > .wpcf7-validation-errors:not(:focus):valid, .was-validated .wpcf7 .input-group > input[type=text]:not(:focus):valid, .wpcf7 .was-validated .input-group > input[type=text]:not(:focus):valid,
.was-validated .wpcf7 .input-group > input[type=search]:not(:focus):valid,
.wpcf7 .was-validated .input-group > input[type=search]:not(:focus):valid,
.was-validated .wpcf7 .input-group > input[type=url]:not(:focus):valid,
.wpcf7 .was-validated .input-group > input[type=url]:not(:focus):valid,
.was-validated .wpcf7 .input-group > input[type=tel]:not(:focus):valid,
.wpcf7 .was-validated .input-group > input[type=tel]:not(:focus):valid,
.was-validated .wpcf7 .input-group > input[type=number]:not(:focus):valid,
.wpcf7 .was-validated .input-group > input[type=number]:not(:focus):valid,
.was-validated .wpcf7 .input-group > input[type=range]:not(:focus):valid,
.wpcf7 .was-validated .input-group > input[type=range]:not(:focus):valid,
.was-validated .wpcf7 .input-group > input[type=date]:not(:focus):valid,
.wpcf7 .was-validated .input-group > input[type=date]:not(:focus):valid,
.was-validated .wpcf7 .input-group > input[type=month]:not(:focus):valid,
.wpcf7 .was-validated .input-group > input[type=month]:not(:focus):valid,
.was-validated .wpcf7 .input-group > input[type=week]:not(:focus):valid,
.wpcf7 .was-validated .input-group > input[type=week]:not(:focus):valid,
.was-validated .wpcf7 .input-group > input[type=time]:not(:focus):valid,
.wpcf7 .was-validated .input-group > input[type=time]:not(:focus):valid,
.was-validated .wpcf7 .input-group > input[type=datetime]:not(:focus):valid,
.wpcf7 .was-validated .input-group > input[type=datetime]:not(:focus):valid,
.was-validated .wpcf7 .input-group > input[type=datetime-local]:not(:focus):valid,
.wpcf7 .was-validated .input-group > input[type=datetime-local]:not(:focus):valid,
.was-validated .wpcf7 .input-group > input[type=color]:not(:focus):valid,
.wpcf7 .was-validated .input-group > input[type=color]:not(:focus):valid,
.was-validated .wpcf7 .input-group > input[type=email]:not(:focus):valid,
.wpcf7 .was-validated .input-group > input[type=email]:not(:focus):valid,
.was-validated .wpcf7 .input-group > input[type=file]:not(:focus):valid,
.wpcf7 .was-validated .input-group > input[type=file]:not(:focus):valid,
.was-validated .wpcf7 .input-group > input[type=submit]:not(:focus):valid,
.wpcf7 .was-validated .input-group > input[type=submit]:not(:focus):valid,
.was-validated .wpcf7 .input-group > select:not(:focus):valid,
.wpcf7 .was-validated .input-group > select:not(:focus):valid,
.was-validated .wpcf7 .input-group > textarea:not(:focus):valid,
.wpcf7 .was-validated .input-group > textarea:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .wpcf7 .input-group > .wpcf7-validation-errors:not(:focus).is-valid, .wpcf7 .input-group > input[type=text]:not(:focus).is-valid,
.wpcf7 .input-group > input[type=search]:not(:focus).is-valid,
.wpcf7 .input-group > input[type=url]:not(:focus).is-valid,
.wpcf7 .input-group > input[type=tel]:not(:focus).is-valid,
.wpcf7 .input-group > input[type=number]:not(:focus).is-valid,
.wpcf7 .input-group > input[type=range]:not(:focus).is-valid,
.wpcf7 .input-group > input[type=date]:not(:focus).is-valid,
.wpcf7 .input-group > input[type=month]:not(:focus).is-valid,
.wpcf7 .input-group > input[type=week]:not(:focus).is-valid,
.wpcf7 .input-group > input[type=time]:not(:focus).is-valid,
.wpcf7 .input-group > input[type=datetime]:not(:focus).is-valid,
.wpcf7 .input-group > input[type=datetime-local]:not(:focus).is-valid,
.wpcf7 .input-group > input[type=color]:not(:focus).is-valid,
.wpcf7 .input-group > input[type=email]:not(:focus).is-valid,
.wpcf7 .input-group > input[type=file]:not(:focus).is-valid,
.wpcf7 .input-group > input[type=submit]:not(:focus).is-valid,
.wpcf7 .input-group > select:not(:focus).is-valid,
.wpcf7 .input-group > textarea:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.375rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .was-validated .wpcf7 .wpcf7-validation-errors:invalid, .wpcf7 .was-validated .wpcf7-validation-errors:invalid, .was-validated .wpcf7 input[type=text]:invalid, .wpcf7 .was-validated input[type=text]:invalid,
.was-validated .wpcf7 input[type=search]:invalid,
.wpcf7 .was-validated input[type=search]:invalid,
.was-validated .wpcf7 input[type=url]:invalid,
.wpcf7 .was-validated input[type=url]:invalid,
.was-validated .wpcf7 input[type=tel]:invalid,
.wpcf7 .was-validated input[type=tel]:invalid,
.was-validated .wpcf7 input[type=number]:invalid,
.wpcf7 .was-validated input[type=number]:invalid,
.was-validated .wpcf7 input[type=range]:invalid,
.wpcf7 .was-validated input[type=range]:invalid,
.was-validated .wpcf7 input[type=date]:invalid,
.wpcf7 .was-validated input[type=date]:invalid,
.was-validated .wpcf7 input[type=month]:invalid,
.wpcf7 .was-validated input[type=month]:invalid,
.was-validated .wpcf7 input[type=week]:invalid,
.wpcf7 .was-validated input[type=week]:invalid,
.was-validated .wpcf7 input[type=time]:invalid,
.wpcf7 .was-validated input[type=time]:invalid,
.was-validated .wpcf7 input[type=datetime]:invalid,
.wpcf7 .was-validated input[type=datetime]:invalid,
.was-validated .wpcf7 input[type=datetime-local]:invalid,
.wpcf7 .was-validated input[type=datetime-local]:invalid,
.was-validated .wpcf7 input[type=color]:invalid,
.wpcf7 .was-validated input[type=color]:invalid,
.was-validated .wpcf7 input[type=email]:invalid,
.wpcf7 .was-validated input[type=email]:invalid,
.was-validated .wpcf7 input[type=file]:invalid,
.wpcf7 .was-validated input[type=file]:invalid,
.was-validated .wpcf7 input[type=submit]:invalid,
.wpcf7 .was-validated input[type=submit]:invalid,
.was-validated .wpcf7 select:invalid,
.wpcf7 .was-validated select:invalid,
.was-validated .wpcf7 textarea:invalid,
.wpcf7 .was-validated textarea:invalid, .form-control.is-invalid, .wpcf7 .is-invalid.wpcf7-validation-errors, .wpcf7 input.is-invalid[type=text],
.wpcf7 input.is-invalid[type=search],
.wpcf7 input.is-invalid[type=url],
.wpcf7 input.is-invalid[type=tel],
.wpcf7 input.is-invalid[type=number],
.wpcf7 input.is-invalid[type=range],
.wpcf7 input.is-invalid[type=date],
.wpcf7 input.is-invalid[type=month],
.wpcf7 input.is-invalid[type=week],
.wpcf7 input.is-invalid[type=time],
.wpcf7 input.is-invalid[type=datetime],
.wpcf7 input.is-invalid[type=datetime-local],
.wpcf7 input.is-invalid[type=color],
.wpcf7 input.is-invalid[type=email],
.wpcf7 input.is-invalid[type=file],
.wpcf7 input.is-invalid[type=submit],
.wpcf7 select.is-invalid,
.wpcf7 textarea.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .was-validated .wpcf7 .wpcf7-validation-errors:invalid:focus, .wpcf7 .was-validated .wpcf7-validation-errors:invalid:focus, .was-validated .wpcf7 input[type=text]:invalid:focus, .wpcf7 .was-validated input[type=text]:invalid:focus,
.was-validated .wpcf7 input[type=search]:invalid:focus,
.wpcf7 .was-validated input[type=search]:invalid:focus,
.was-validated .wpcf7 input[type=url]:invalid:focus,
.wpcf7 .was-validated input[type=url]:invalid:focus,
.was-validated .wpcf7 input[type=tel]:invalid:focus,
.wpcf7 .was-validated input[type=tel]:invalid:focus,
.was-validated .wpcf7 input[type=number]:invalid:focus,
.wpcf7 .was-validated input[type=number]:invalid:focus,
.was-validated .wpcf7 input[type=range]:invalid:focus,
.wpcf7 .was-validated input[type=range]:invalid:focus,
.was-validated .wpcf7 input[type=date]:invalid:focus,
.wpcf7 .was-validated input[type=date]:invalid:focus,
.was-validated .wpcf7 input[type=month]:invalid:focus,
.wpcf7 .was-validated input[type=month]:invalid:focus,
.was-validated .wpcf7 input[type=week]:invalid:focus,
.wpcf7 .was-validated input[type=week]:invalid:focus,
.was-validated .wpcf7 input[type=time]:invalid:focus,
.wpcf7 .was-validated input[type=time]:invalid:focus,
.was-validated .wpcf7 input[type=datetime]:invalid:focus,
.wpcf7 .was-validated input[type=datetime]:invalid:focus,
.was-validated .wpcf7 input[type=datetime-local]:invalid:focus,
.wpcf7 .was-validated input[type=datetime-local]:invalid:focus,
.was-validated .wpcf7 input[type=color]:invalid:focus,
.wpcf7 .was-validated input[type=color]:invalid:focus,
.was-validated .wpcf7 input[type=email]:invalid:focus,
.wpcf7 .was-validated input[type=email]:invalid:focus,
.was-validated .wpcf7 input[type=file]:invalid:focus,
.wpcf7 .was-validated input[type=file]:invalid:focus,
.was-validated .wpcf7 input[type=submit]:invalid:focus,
.wpcf7 .was-validated input[type=submit]:invalid:focus,
.was-validated .wpcf7 select:invalid:focus,
.wpcf7 .was-validated select:invalid:focus,
.was-validated .wpcf7 textarea:invalid:focus,
.wpcf7 .was-validated textarea:invalid:focus, .form-control.is-invalid:focus, .wpcf7 .is-invalid.wpcf7-validation-errors:focus, .wpcf7 input.is-invalid[type=text]:focus,
.wpcf7 input.is-invalid[type=search]:focus,
.wpcf7 input.is-invalid[type=url]:focus,
.wpcf7 input.is-invalid[type=tel]:focus,
.wpcf7 input.is-invalid[type=number]:focus,
.wpcf7 input.is-invalid[type=range]:focus,
.wpcf7 input.is-invalid[type=date]:focus,
.wpcf7 input.is-invalid[type=month]:focus,
.wpcf7 input.is-invalid[type=week]:focus,
.wpcf7 input.is-invalid[type=time]:focus,
.wpcf7 input.is-invalid[type=datetime]:focus,
.wpcf7 input.is-invalid[type=datetime-local]:focus,
.wpcf7 input.is-invalid[type=color]:focus,
.wpcf7 input.is-invalid[type=email]:focus,
.wpcf7 input.is-invalid[type=file]:focus,
.wpcf7 input.is-invalid[type=submit]:focus,
.wpcf7 select.is-invalid:focus,
.wpcf7 textarea.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid,
.was-validated .wpcf7 textarea:invalid,
.wpcf7 .was-validated textarea:invalid, textarea.form-control.is-invalid,
.wpcf7 textarea.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .was-validated .wpcf7 .input-group > .wpcf7-validation-errors:not(:focus):invalid, .wpcf7 .was-validated .input-group > .wpcf7-validation-errors:not(:focus):invalid, .was-validated .wpcf7 .input-group > input[type=text]:not(:focus):invalid, .wpcf7 .was-validated .input-group > input[type=text]:not(:focus):invalid,
.was-validated .wpcf7 .input-group > input[type=search]:not(:focus):invalid,
.wpcf7 .was-validated .input-group > input[type=search]:not(:focus):invalid,
.was-validated .wpcf7 .input-group > input[type=url]:not(:focus):invalid,
.wpcf7 .was-validated .input-group > input[type=url]:not(:focus):invalid,
.was-validated .wpcf7 .input-group > input[type=tel]:not(:focus):invalid,
.wpcf7 .was-validated .input-group > input[type=tel]:not(:focus):invalid,
.was-validated .wpcf7 .input-group > input[type=number]:not(:focus):invalid,
.wpcf7 .was-validated .input-group > input[type=number]:not(:focus):invalid,
.was-validated .wpcf7 .input-group > input[type=range]:not(:focus):invalid,
.wpcf7 .was-validated .input-group > input[type=range]:not(:focus):invalid,
.was-validated .wpcf7 .input-group > input[type=date]:not(:focus):invalid,
.wpcf7 .was-validated .input-group > input[type=date]:not(:focus):invalid,
.was-validated .wpcf7 .input-group > input[type=month]:not(:focus):invalid,
.wpcf7 .was-validated .input-group > input[type=month]:not(:focus):invalid,
.was-validated .wpcf7 .input-group > input[type=week]:not(:focus):invalid,
.wpcf7 .was-validated .input-group > input[type=week]:not(:focus):invalid,
.was-validated .wpcf7 .input-group > input[type=time]:not(:focus):invalid,
.wpcf7 .was-validated .input-group > input[type=time]:not(:focus):invalid,
.was-validated .wpcf7 .input-group > input[type=datetime]:not(:focus):invalid,
.wpcf7 .was-validated .input-group > input[type=datetime]:not(:focus):invalid,
.was-validated .wpcf7 .input-group > input[type=datetime-local]:not(:focus):invalid,
.wpcf7 .was-validated .input-group > input[type=datetime-local]:not(:focus):invalid,
.was-validated .wpcf7 .input-group > input[type=color]:not(:focus):invalid,
.wpcf7 .was-validated .input-group > input[type=color]:not(:focus):invalid,
.was-validated .wpcf7 .input-group > input[type=email]:not(:focus):invalid,
.wpcf7 .was-validated .input-group > input[type=email]:not(:focus):invalid,
.was-validated .wpcf7 .input-group > input[type=file]:not(:focus):invalid,
.wpcf7 .was-validated .input-group > input[type=file]:not(:focus):invalid,
.was-validated .wpcf7 .input-group > input[type=submit]:not(:focus):invalid,
.wpcf7 .was-validated .input-group > input[type=submit]:not(:focus):invalid,
.was-validated .wpcf7 .input-group > select:not(:focus):invalid,
.wpcf7 .was-validated .input-group > select:not(:focus):invalid,
.was-validated .wpcf7 .input-group > textarea:not(:focus):invalid,
.wpcf7 .was-validated .input-group > textarea:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .wpcf7 .input-group > .wpcf7-validation-errors:not(:focus).is-invalid, .wpcf7 .input-group > input[type=text]:not(:focus).is-invalid,
.wpcf7 .input-group > input[type=search]:not(:focus).is-invalid,
.wpcf7 .input-group > input[type=url]:not(:focus).is-invalid,
.wpcf7 .input-group > input[type=tel]:not(:focus).is-invalid,
.wpcf7 .input-group > input[type=number]:not(:focus).is-invalid,
.wpcf7 .input-group > input[type=range]:not(:focus).is-invalid,
.wpcf7 .input-group > input[type=date]:not(:focus).is-invalid,
.wpcf7 .input-group > input[type=month]:not(:focus).is-invalid,
.wpcf7 .input-group > input[type=week]:not(:focus).is-invalid,
.wpcf7 .input-group > input[type=time]:not(:focus).is-invalid,
.wpcf7 .input-group > input[type=datetime]:not(:focus).is-invalid,
.wpcf7 .input-group > input[type=datetime-local]:not(:focus).is-invalid,
.wpcf7 .input-group > input[type=color]:not(:focus).is-invalid,
.wpcf7 .input-group > input[type=email]:not(:focus).is-invalid,
.wpcf7 .input-group > input[type=file]:not(:focus).is-invalid,
.wpcf7 .input-group > input[type=submit]:not(:focus).is-invalid,
.wpcf7 .input-group > select:not(:focus).is-invalid,
.wpcf7 .input-group > textarea:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn, .wpcf7 input[type=submit], .woocommerce-info a.button, .woocommerce-notices-wrapper .woocommerce-message a.button {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: Poppins, sans-serif;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 700;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn, .wpcf7 input[type=submit], .woocommerce-info a.button, .woocommerce-notices-wrapper .woocommerce-message a.button {
    transition: none;
  }
}
.btn:hover, .wpcf7 input[type=submit]:hover, .woocommerce-info a.button:hover, .woocommerce-notices-wrapper .woocommerce-message a.button:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover, .wpcf7 .btn-check + input[type=submit]:hover, .woocommerce-info .btn-check + a.button:hover, .woocommerce-notices-wrapper .woocommerce-message .btn-check + a.button:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible, .wpcf7 input[type=submit]:focus-visible, .woocommerce-info a.button:focus-visible, .woocommerce-notices-wrapper .woocommerce-message a.button:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn, .wpcf7 .btn-check:focus-visible + input[type=submit], .woocommerce-info .btn-check:focus-visible + a.button, .woocommerce-notices-wrapper .woocommerce-message .btn-check:focus-visible + a.button {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, .wpcf7 .btn-check:checked + input[type=submit], .woocommerce-info .btn-check:checked + a.button, .woocommerce-notices-wrapper .woocommerce-message .btn-check:checked + a.button, :not(.btn-check) + .btn:active, .wpcf7 :not(.btn-check) + input[type=submit]:active, .woocommerce-info :not(.btn-check) + a.button:active, .woocommerce-notices-wrapper .woocommerce-message :not(.btn-check) + a.button:active, .btn:first-child:active, .wpcf7 input[type=submit]:first-child:active, .woocommerce-info a.button:first-child:active, .woocommerce-notices-wrapper .woocommerce-message a.button:first-child:active, .btn.active, .wpcf7 input.active[type=submit], .woocommerce-info a.active.button, .woocommerce-notices-wrapper .woocommerce-message a.active.button, .btn.show, .wpcf7 input.show[type=submit], .woocommerce-info a.show.button, .woocommerce-notices-wrapper .woocommerce-message a.show.button {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, .wpcf7 .btn-check:checked + input[type=submit]:focus-visible, .woocommerce-info .btn-check:checked + a.button:focus-visible, .woocommerce-notices-wrapper .woocommerce-message .btn-check:checked + a.button:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .wpcf7 :not(.btn-check) + input[type=submit]:active:focus-visible, .woocommerce-info :not(.btn-check) + a.button:active:focus-visible, .woocommerce-notices-wrapper .woocommerce-message :not(.btn-check) + a.button:active:focus-visible, .btn:first-child:active:focus-visible, .wpcf7 input[type=submit]:first-child:active:focus-visible, .woocommerce-info a.button:first-child:active:focus-visible, .woocommerce-notices-wrapper .woocommerce-message a.button:first-child:active:focus-visible, .btn.active:focus-visible, .wpcf7 input.active[type=submit]:focus-visible, .woocommerce-info a.active.button:focus-visible, .woocommerce-notices-wrapper .woocommerce-message a.active.button:focus-visible, .btn.show:focus-visible, .wpcf7 input.show[type=submit]:focus-visible, .woocommerce-info a.show.button:focus-visible, .woocommerce-notices-wrapper .woocommerce-message a.show.button:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .wpcf7 input[type=submit]:disabled, .woocommerce-info a.button:disabled, .woocommerce-notices-wrapper .woocommerce-message a.button:disabled, .btn.disabled, .wpcf7 input.disabled[type=submit], .woocommerce-info a.disabled.button, .woocommerce-notices-wrapper .woocommerce-message a.disabled.button, fieldset:disabled .btn, fieldset:disabled .wpcf7 input[type=submit], .wpcf7 fieldset:disabled input[type=submit], fieldset:disabled .woocommerce-info a.button, .woocommerce-info fieldset:disabled a.button, fieldset:disabled .woocommerce-notices-wrapper .woocommerce-message a.button, .woocommerce-notices-wrapper .woocommerce-message fieldset:disabled a.button {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #00152F;
  --bs-btn-border-color: #00152F;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #001228;
  --bs-btn-hover-border-color: #001126;
  --bs-btn-focus-shadow-rgb: 38, 56, 78;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #001126;
  --bs-btn-active-border-color: #001023;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00152F;
  --bs-btn-disabled-border-color: #00152F;
}

.btn-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #EB6852;
  --bs-btn-border-color: #EB6852;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ee7f6c;
  --bs-btn-hover-border-color: #ed7763;
  --bs-btn-focus-shadow-rgb: 200, 88, 70;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ef8675;
  --bs-btn-active-border-color: #ed7763;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #EB6852;
  --bs-btn-disabled-border-color: #EB6852;
}

.btn-success, .woocommerce-notices-wrapper .woocommerce-message a.button {
  --bs-btn-color: #fff;
  --bs-btn-bg: #00152F;
  --bs-btn-border-color: #00152F;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #001228;
  --bs-btn-hover-border-color: #001126;
  --bs-btn-focus-shadow-rgb: 38, 56, 78;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #001126;
  --bs-btn-active-border-color: #001023;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00152F;
  --bs-btn-disabled-border-color: #00152F;
}

.btn-info, .woocommerce-info a.button {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #FFC72C;
  --bs-btn-border-color: #FFC72C;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffcf4c;
  --bs-btn-hover-border-color: #ffcd41;
  --bs-btn-focus-shadow-rgb: 217, 169, 37;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffd256;
  --bs-btn-active-border-color: #ffcd41;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FFC72C;
  --bs-btn-disabled-border-color: #FFC72C;
}

.btn-danger, .woocommerce-notices-wrapper .woocommerce-message.woocommerce-error a.button {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary, .wpcf7 input[type=submit] {
  --bs-btn-color: #00152F;
  --bs-btn-border-color: #00152F;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00152F;
  --bs-btn-hover-border-color: #00152F;
  --bs-btn-focus-shadow-rgb: 0, 21, 47;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00152F;
  --bs-btn-active-border-color: #00152F;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00152F;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00152F;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #EB6852;
  --bs-btn-border-color: #EB6852;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #EB6852;
  --bs-btn-hover-border-color: #EB6852;
  --bs-btn-focus-shadow-rgb: 235, 104, 82;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #EB6852;
  --bs-btn-active-border-color: #EB6852;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #EB6852;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #EB6852;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #00152F;
  --bs-btn-border-color: #00152F;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00152F;
  --bs-btn-hover-border-color: #00152F;
  --bs-btn-focus-shadow-rgb: 0, 21, 47;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00152F;
  --bs-btn-active-border-color: #00152F;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00152F;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00152F;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #FFC72C;
  --bs-btn-border-color: #FFC72C;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FFC72C;
  --bs-btn-hover-border-color: #FFC72C;
  --bs-btn-focus-shadow-rgb: 255, 199, 44;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FFC72C;
  --bs-btn-active-border-color: #FFC72C;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFC72C;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFC72C;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 38, 56, 78;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn, .wpcf7 .btn-group-lg > input[type=submit], .woocommerce-info .btn-group-lg > a.button, .woocommerce-notices-wrapper .woocommerce-message .btn-group-lg > a.button {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn, .wpcf7 .btn-group-sm > input[type=submit], .woocommerce-info .btn-group-sm > a.button, .woocommerce-notices-wrapper .woocommerce-message .btn-group-sm > a.button {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #00152F;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 767px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 991px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #00152F;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn, .wpcf7 .btn-group > input[type=submit], .woocommerce-info .btn-group > a.button, .woocommerce-notices-wrapper .woocommerce-message .btn-group > a.button,
.btn-group-vertical > .btn,
.wpcf7 .btn-group-vertical > input[type=submit],
.woocommerce-info .btn-group-vertical > a.button,
.woocommerce-notices-wrapper .woocommerce-message .btn-group-vertical > a.button {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn, .wpcf7 .btn-group > .btn-check:checked + input[type=submit], .woocommerce-info .btn-group > .btn-check:checked + a.button, .woocommerce-notices-wrapper .woocommerce-message .btn-group > .btn-check:checked + a.button,
.btn-group > .btn-check:focus + .btn,
.wpcf7 .btn-group > .btn-check:focus + input[type=submit],
.woocommerce-info .btn-group > .btn-check:focus + a.button,
.woocommerce-notices-wrapper .woocommerce-message .btn-group > .btn-check:focus + a.button,
.btn-group > .btn:hover,
.wpcf7 .btn-group > input[type=submit]:hover,
.woocommerce-info .btn-group > a.button:hover,
.woocommerce-notices-wrapper .woocommerce-message .btn-group > a.button:hover,
.btn-group > .btn:focus,
.wpcf7 .btn-group > input[type=submit]:focus,
.woocommerce-info .btn-group > a.button:focus,
.woocommerce-notices-wrapper .woocommerce-message .btn-group > a.button:focus,
.btn-group > .btn:active,
.wpcf7 .btn-group > input[type=submit]:active,
.woocommerce-info .btn-group > a.button:active,
.woocommerce-notices-wrapper .woocommerce-message .btn-group > a.button:active,
.btn-group > .btn.active,
.wpcf7 .btn-group > input.active[type=submit],
.woocommerce-info .btn-group > a.active.button,
.woocommerce-notices-wrapper .woocommerce-message .btn-group > a.active.button,
.btn-group-vertical > .btn-check:checked + .btn,
.wpcf7 .btn-group-vertical > .btn-check:checked + input[type=submit],
.woocommerce-info .btn-group-vertical > .btn-check:checked + a.button,
.woocommerce-notices-wrapper .woocommerce-message .btn-group-vertical > .btn-check:checked + a.button,
.btn-group-vertical > .btn-check:focus + .btn,
.wpcf7 .btn-group-vertical > .btn-check:focus + input[type=submit],
.woocommerce-info .btn-group-vertical > .btn-check:focus + a.button,
.woocommerce-notices-wrapper .woocommerce-message .btn-group-vertical > .btn-check:focus + a.button,
.btn-group-vertical > .btn:hover,
.wpcf7 .btn-group-vertical > input[type=submit]:hover,
.woocommerce-info .btn-group-vertical > a.button:hover,
.woocommerce-notices-wrapper .woocommerce-message .btn-group-vertical > a.button:hover,
.btn-group-vertical > .btn:focus,
.wpcf7 .btn-group-vertical > input[type=submit]:focus,
.woocommerce-info .btn-group-vertical > a.button:focus,
.woocommerce-notices-wrapper .woocommerce-message .btn-group-vertical > a.button:focus,
.btn-group-vertical > .btn:active,
.wpcf7 .btn-group-vertical > input[type=submit]:active,
.woocommerce-info .btn-group-vertical > a.button:active,
.woocommerce-notices-wrapper .woocommerce-message .btn-group-vertical > a.button:active,
.btn-group-vertical > .btn.active,
.wpcf7 .btn-group-vertical > input.active[type=submit],
.woocommerce-info .btn-group-vertical > a.active.button,
.woocommerce-notices-wrapper .woocommerce-message .btn-group-vertical > a.active.button {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.375rem;
}
.btn-group > :not(.btn-check:first-child) + .btn, .wpcf7 .btn-group > :not(.btn-check:first-child) + input[type=submit], .woocommerce-info .btn-group > :not(.btn-check:first-child) + a.button, .woocommerce-notices-wrapper .woocommerce-message .btn-group > :not(.btn-check:first-child) + a.button,
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .wpcf7 .btn-group > input[type=submit]:not(:last-child):not(.dropdown-toggle), .woocommerce-info .btn-group > a.button:not(:last-child):not(.dropdown-toggle), .woocommerce-notices-wrapper .woocommerce-message .btn-group > a.button:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.wpcf7 .btn-group > input.dropdown-toggle-split[type=submit]:first-child,
.woocommerce-info .btn-group > a.dropdown-toggle-split.button:first-child,
.woocommerce-notices-wrapper .woocommerce-message .btn-group > a.dropdown-toggle-split.button:first-child,
.btn-group > .btn-group:not(:last-child) > .btn,
.wpcf7 .btn-group > .btn-group:not(:last-child) > input[type=submit],
.woocommerce-info .btn-group > .btn-group:not(:last-child) > a.button,
.woocommerce-notices-wrapper .woocommerce-message .btn-group > .btn-group:not(:last-child) > a.button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3), .wpcf7 .btn-group > input[type=submit]:nth-child(n+3), .woocommerce-info .btn-group > a.button:nth-child(n+3), .woocommerce-notices-wrapper .woocommerce-message .btn-group > a.button:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.wpcf7 .btn-group > :not(.btn-check) + input[type=submit],
.woocommerce-info .btn-group > :not(.btn-check) + a.button,
.woocommerce-notices-wrapper .woocommerce-message .btn-group > :not(.btn-check) + a.button,
.btn-group > .btn-group:not(:first-child) > .btn,
.wpcf7 .btn-group > .btn-group:not(:first-child) > input[type=submit],
.woocommerce-info .btn-group > .btn-group:not(:first-child) > a.button,
.woocommerce-notices-wrapper .woocommerce-message .btn-group > .btn-group:not(:first-child) > a.button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .wpcf7 .btn-group-sm > input[type=submit] + .dropdown-toggle-split, .woocommerce-info .btn-group-sm > a.button + .dropdown-toggle-split, .woocommerce-notices-wrapper .woocommerce-message .btn-group-sm > a.button + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .wpcf7 .btn-group-lg > input[type=submit] + .dropdown-toggle-split, .woocommerce-info .btn-group-lg > a.button + .dropdown-toggle-split, .woocommerce-notices-wrapper .woocommerce-message .btn-group-lg > a.button + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn, .wpcf7 .btn-group-vertical > input[type=submit], .woocommerce-info .btn-group-vertical > a.button, .woocommerce-notices-wrapper .woocommerce-message .btn-group-vertical > a.button,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child), .wpcf7 .btn-group-vertical > input[type=submit]:not(:first-child), .woocommerce-info .btn-group-vertical > a.button:not(:first-child), .woocommerce-notices-wrapper .woocommerce-message .btn-group-vertical > a.button:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .wpcf7 .btn-group-vertical > input[type=submit]:not(:last-child):not(.dropdown-toggle), .woocommerce-info .btn-group-vertical > a.button:not(:last-child):not(.dropdown-toggle), .woocommerce-notices-wrapper .woocommerce-message .btn-group-vertical > a.button:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.wpcf7 .btn-group-vertical > .btn-group:not(:last-child) > input[type=submit],
.woocommerce-info .btn-group-vertical > .btn-group:not(:last-child) > a.button,
.woocommerce-notices-wrapper .woocommerce-message .btn-group-vertical > .btn-group:not(:last-child) > a.button {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn, .wpcf7 .btn-group-vertical > input[type=submit] ~ .btn, .wpcf7 .btn-group-vertical > .btn ~ input[type=submit], .wpcf7 .btn-group-vertical > input[type=submit] ~ input[type=submit], .woocommerce-info .btn-group-vertical > a.button ~ .btn, .woocommerce-info .wpcf7 .btn-group-vertical > a.button ~ input[type=submit], .wpcf7 .woocommerce-info .btn-group-vertical > a.button ~ input[type=submit], .woocommerce-info .btn-group-vertical > .btn ~ a.button, .woocommerce-info .wpcf7 .btn-group-vertical > input[type=submit] ~ a.button, .wpcf7 .woocommerce-info .btn-group-vertical > input[type=submit] ~ a.button, .woocommerce-info .btn-group-vertical > a.button ~ a.button, .woocommerce-notices-wrapper .woocommerce-message .btn-group-vertical > a.button ~ .btn, .woocommerce-notices-wrapper .woocommerce-message .wpcf7 .btn-group-vertical > a.button ~ input[type=submit], .wpcf7 .woocommerce-notices-wrapper .woocommerce-message .btn-group-vertical > a.button ~ input[type=submit], .woocommerce-notices-wrapper .woocommerce-message .btn-group-vertical > .btn ~ a.button, .woocommerce-notices-wrapper .woocommerce-message .wpcf7 .btn-group-vertical > input[type=submit] ~ a.button, .wpcf7 .woocommerce-notices-wrapper .woocommerce-message .btn-group-vertical > input[type=submit] ~ a.button, .woocommerce-notices-wrapper .woocommerce-message .btn-group-vertical > a.button ~ a.button,
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.wpcf7 .btn-group-vertical > .btn-group:not(:first-child) > input[type=submit],
.woocommerce-info .btn-group-vertical > .btn-group:not(:first-child) > a.button,
.woocommerce-notices-wrapper .woocommerce-message .btn-group-vertical > .btn-group:not(:first-child) > a.button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #00152F;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 767px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 991px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300132a'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #808a97;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(0, 21, 47, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #00132a;
  --bs-accordion-active-bg: #e6e8ea;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(0, 21, 47, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #00152F;
  --bs-pagination-active-border-color: #00152F;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.5rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.25rem;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge, .wpcf7 input[type=submit] .badge, .woocommerce-info a.button .badge, .woocommerce-notices-wrapper .woocommerce-message a.button .badge {
  position: relative;
  top: -1px;
}

.alert, .woocommerce-info, .woocommerce-notices-wrapper .woocommerce-message {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: #000d1c;
  --bs-alert-bg: #ccd0d5;
  --bs-alert-border-color: #b3b9c1;
}
.alert-primary .alert-link {
  color: #000a16;
}

.alert-secondary {
  --bs-alert-color: #8d3e31;
  --bs-alert-bg: #fbe1dc;
  --bs-alert-border-color: #f9d2cb;
}
.alert-secondary .alert-link {
  color: #713227;
}

.alert-success, .woocommerce-notices-wrapper .woocommerce-message {
  --bs-alert-color: #000d1c;
  --bs-alert-bg: #ccd0d5;
  --bs-alert-border-color: #b3b9c1;
}
.alert-success .alert-link, .woocommerce-notices-wrapper .woocommerce-message .alert-link {
  color: #000a16;
}

.alert-info, .woocommerce-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}
.alert-info .alert-link, .woocommerce-info .alert-link {
  color: #04414d;
}

.alert-warning {
  --bs-alert-color: #665012;
  --bs-alert-bg: #fff4d5;
  --bs-alert-border-color: #ffeec0;
}
.alert-warning .alert-link {
  color: #52400e;
}

.alert-danger, .woocommerce-notices-wrapper .woocommerce-message.woocommerce-error {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}
.alert-danger .alert-link, .woocommerce-notices-wrapper .woocommerce-message.woocommerce-error .alert-link {
  color: #6a1a21;
}

.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.375rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #00152F;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #00152F;
  --bs-list-group-active-border-color: #00152F;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 767px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 991px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #000d1c;
  background-color: #ccd0d5;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #000d1c;
  background-color: #b8bbc0;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #000d1c;
  border-color: #000d1c;
}

.list-group-item-secondary {
  color: #8d3e31;
  background-color: #fbe1dc;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #8d3e31;
  background-color: #e2cbc6;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #8d3e31;
  border-color: #8d3e31;
}

.list-group-item-success {
  color: #000d1c;
  background-color: #ccd0d5;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #000d1c;
  background-color: #b8bbc0;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #000d1c;
  border-color: #000d1c;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #665012;
  background-color: #fff4d5;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #665012;
  background-color: #e6dcc0;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #665012;
  border-color: #665012;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 21, 47, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 991px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 766.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 990.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.375rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 766.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 766.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 766.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 766.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 766.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 766.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 766.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 766.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 767px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 990.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 990.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 990.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 990.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 990.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 990.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 990.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 990.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 991px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before, .wpcf7 input.placeholder[type=submit]::before, .woocommerce-info a.placeholder.button::before, .woocommerce-notices-wrapper .woocommerce-message a.placeholder.button::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(0, 21, 47, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(235, 104, 82, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(0, 21, 47, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 199, 44, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #00152F !important;
}
.link-primary:hover, .link-primary:focus {
  color: #001126 !important;
}

.link-secondary {
  color: #EB6852 !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #ef8675 !important;
}

.link-success {
  color: #00152F !important;
}
.link-success:hover, .link-success:focus {
  color: #001126 !important;
}

.link-info {
  color: #0dcaf0 !important;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3 !important;
}

.link-warning {
  color: #FFC72C !important;
}
.link-warning:hover, .link-warning:focus {
  color: #ffd256 !important;
}

.link-danger {
  color: #dc3545 !important;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37 !important;
}

.link-light {
  color: #f8f9fa !important;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb !important;
}

.link-dark {
  color: #212529 !important;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21 !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 767px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 991px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto, .aligncenter {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 767px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 991px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
body {
  overflow-x: hidden;
}

.wrapper {
  padding: 1.5rem 0;
}

#wrapper-hero {
  padding: 0 !important;
}

.sticky,
.gallery-caption,
.bypostauthor,
.wp-caption,
.wp-caption-text {
  font-size: inherit;
}

.wrapper#wrapper-sticky {
  border-bottom: 1px solid #dee2e6;
}

#wrapper-footer-full,
#wrapper-static-hero {
  background-color: #e9ecef;
}

.screen-reader-text {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.alignleft {
  display: inline;
  float: left;
  margin-right: 1rem;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 1rem;
}

.aligncenter {
  display: block;
}

.widget_archive select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .widget_archive select {
    transition: none;
  }
}
.widget_archive select:focus {
  border-color: #808a97;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 21, 47, 0.25);
}
.widget_archive select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.entry-footer span {
  padding-right: 10px;
}

figure,
img,
img.wp-post-image,
article img,
#secondary img {
  max-width: 100%;
  height: auto;
}

a.skip-link:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.navbar-dark .navbar-nav .dropdown-menu .nav-link {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529 !important;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
}
.navbar-dark .navbar-nav .dropdown-menu .nav-link:hover, .navbar-dark .navbar-nav .dropdown-menu .nav-link:focus {
  color: #1e2125 !important;
  text-decoration: none;
  background-color: #e9ecef;
}
.navbar-dark .navbar-nav .dropdown-menu .nav-link.active, .navbar-dark .navbar-nav .dropdown-menu .nav-link:active {
  color: #fff !important;
  text-decoration: none;
  background-color: #00152F;
}
.navbar-dark .navbar-nav .dropdown-menu .nav-link.disabled, .navbar-dark .navbar-nav .dropdown-menu .nav-link:disabled {
  color: #adb5bd !important;
  background-color: transparent;
}

.navbar-light .navbar-brand a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand a:hover, .navbar-light .navbar-brand a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand a {
  color: #fff;
}
.navbar-dark .navbar-brand a:hover, .navbar-dark .navbar-brand a:focus {
  color: #fff;
}

.navbar h1, .navbar .h1 {
  font-weight: 400;
}

.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}
.gallery-columns-2 .gallery-item {
  max-width: 50%;
}
.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}
.gallery-columns-4 .gallery-item {
  max-width: 25%;
}
.gallery-columns-5 .gallery-item {
  max-width: 20%;
}
.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}
.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}
.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}
.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
}

.wp-block-search .wp-block-search__button {
  margin-left: 0.75rem;
}
.wp-block-search.wp-block-search__button-inside .wp-block-search__inside-wrapper {
  border: none;
  padding: 0;
}
.wp-block-search.wp-block-search__button-inside .wp-block-search__inside-wrapper .wp-block-search__input {
  border-radius: 0.375rem;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
}
.wp-block-search.wp-block-search__button-inside .wp-block-search__button {
  margin-left: 0;
}

.woocommerce-input-wrapper {
  width: 100%;
}

figure.woocommerce-product-gallery__wrapper {
  max-width: inherit !important;
}

@media (min-width: 768px) {
  .woocommerce-cart #coupon_code.input-text {
    width: 110px !important;
  }
}
#stripe-payment-data #wc-stripe-cc-form .form-row {
  display: flex;
  flex-direction: column;
}
#stripe-payment-data #wc-stripe-cc-form .form-row .wc-stripe-elements-field {
  max-height: 2rem;
}
#stripe-payment-data .woocommerce-SavedPaymentMethods-saveNew {
  display: inherit;
}

.woocommerce-notices-wrapper .woocommerce-message {
  padding: 1em 2em 1em 3.5em;
}
.woocommerce-info {
  padding: 1em 2em 1em 3.5em;
}
.woocommerce form .form-row .required {
  color: #dc3545;
}

.woocommerce form .form-row label.form-check-label {
  line-height: inherit;
}

.woocommerce form .form-row .checkbox.form-check {
  display: block;
  line-height: inherit;
}

.woocommerce form .form-row .input-checkbox.form-check-input {
  margin: 0.25em 0 0 -1.5em;
}

.woocommerce div.product p.price,
.woocommerce div.product span.price,
.woocommerce div.product .stock,
.woocommerce ul.products li.product .price,
#add_payment_method .cart-collaterals .cart_totals .discount td,
.woocommerce-cart .cart-collaterals .cart_totals .discount td,
.woocommerce-checkout .cart-collaterals .cart_totals .discount td {
  color: #00152F;
}

.woocommerce-page form .show-password-input {
  top: initial;
}

.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}
.gallery-columns-2 .gallery-item {
  max-width: 50%;
}
.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}
.gallery-columns-4 .gallery-item {
  max-width: 25%;
}
.gallery-columns-5 .gallery-item {
  max-width: 20%;
}
.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}
.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}
.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}
.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
}

body {
  font-size: 20px;
  line-height: 1.454545em;
  font-weight: 400;
  background-color: #fff;
  font-family: "Figtree", sans-serif;
  color: #00152F;
  letter-spacing: 0;
  visibility: hidden;
}
body.font-loaded {
  visibility: visible;
}
@media (max-width: 991px) {
  body {
    font-size: 17px;
    line-height: 1.55555em;
  }
}
@media (max-width: 767px) {
  body {
    font-size: 16px;
  }
}

a:focus-visible {
  outline: 1px solid #00152F !important;
}

a:hover {
  text-decoration-thickness: 1px;
}

#page-wrapper,
#content {
  padding: 0;
}

.content-area {
  max-width: 1370px;
  margin: 0 auto;
  padding: 0 25px;
}
@media (max-width: 767px) {
  .content-area {
    padding: 0 30px;
  }
}

.container {
  max-width: 1370px;
  padding: 0 25px;
}
@media (max-width: 767px) {
  .container {
    padding: 0 30px;
  }
}

.main-content-site {
  margin-top: 132px;
}
@media (max-width: 1148px) {
  .main-content-site {
    margin-top: 90px;
  }
}

.eyebrow {
  display: block;
  letter-spacing: 0.9px;
  color: #0B0B0B;
  text-transform: uppercase;
  font-weight: 600;
  border-bottom: 1px solid #00152F;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
}

.heading-white .eyebrow {
  color: white;
  border-bottom: 1px solid white;
}

h1, .h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: #00152F;
  font-family: "Poppins", sans-serif;
  margin: 0 0 10px;
}

h1, .h1 {
  font-size: 60px;
  line-height: 1.16667em;
  margin: 0 0 20px;
  letter-spacing: -0.6px;
  font-weight: 300;
}
@media (max-width: 991px) {
  h1, .h1 {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  h1, .h1 {
    font-size: 32px;
    line-height: 1.2941em;
  }
}

h2, .h2 {
  font-size: 45px;
  line-height: 1.333333em;
  letter-spacing: -1.35px;
  font-weight: 300;
}
@media (max-width: 991px) {
  h2, .h2 {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  h2, .h2 {
    font-size: 24px;
    line-height: 1.3125em;
  }
}

h3, .h3 {
  font-size: 34px;
  line-height: 1.294117em;
  letter-spacing: -0.68px;
  font-weight: 500;
}
@media (max-width: 991px) {
  h3, .h3 {
    font-size: 27px;
  }
}
@media (max-width: 767px) {
  h3, .h3 {
    font-size: 22px;
    line-height: 1.28em;
    letter-spacing: -0.22px;
  }
}

h4, .h4 {
  font-size: 22px;
  line-height: 1.03846em;
  letter-spacing: 0;
}
@media (max-width: 767px) {
  h4, .h4 {
    font-size: 25px;
    line-height: 1.2em;
  }
}

h5, .h5 {
  font-size: 20px;
  line-height: 1.22727em;
  letter-spacing: 0;
  font-weight: 600;
}
@media (max-width: 767px) {
  h5, .h5 {
    font-size: 18px;
    line-height: 1.3em;
  }
}

h6, .h6 {
  font-size: 18px;
  line-height: 1.4em;
  letter-spacing: 0;
  font-weight: 600;
}
@media (max-width: 767px) {
  h6, .h6 {
    font-size: 16px;
    line-height: 1.62em;
  }
}

a {
  font-size: 20px;
  line-height: 1.4444em;
  font-weight: 400;
  font-family: "Figtree", sans-serif;
  color: #00152F;
  outline: none !important;
}

li a {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-family: inherit;
}

p {
  font-size: 20px;
  line-height: 1.4444em;
  font-weight: 400;
  font-family: "Figtree", sans-serif;
  color: #000;
}
p.large-text {
  font-size: 26px;
}
p.small-text {
  font-size: 18px;
}
p a {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-weight: inherit;
}
@media (max-width: 991px) {
  p {
    font-size: 17px;
    line-height: 1.55555em;
  }
}
@media (max-width: 767px) {
  p {
    font-size: 16px;
  }
}

.large-text {
  font-size: 26px !important;
  line-height: 1.38em;
  letter-spacing: -0.26px;
}
.large-text p,
.large-text a {
  font-size: inherit !important;
  line-height: inherit !important;
  font-family: inherit !important;
}
@media (max-width: 767px) {
  .large-text {
    font-size: 22px !important;
    line-height: 1.45em;
    letter-spacing: -0.22px;
  }
}
@media (max-width: 575px) {
  .large-text {
    font-size: 20px !important;
  }
}

.medium-text {
  font-size: 23px !important;
  line-height: 1.45em;
  letter-spacing: -0.22px;
}
.medium-text p,
.medium-text a {
  font-size: inherit !important;
  line-height: inherit !important;
  font-family: inherit !important;
}
@media (max-width: 991px) {
  .medium-text {
    font-size: 20px !important;
    line-height: 1.5em;
    letter-spacing: -0.2px;
  }
}

.small-text {
  font-size: 18px !important;
  line-height: 1.55em;
  letter-spacing: 0.18px;
}
.small-text p,
.small-text a {
  font-size: inherit !important;
  line-height: inherit !important;
  font-family: inherit !important;
}
@media (max-width: 767px) {
  .small-text {
    font-size: 16px !important;
    line-height: 1.62em;
    letter-spacing: 0.16px;
  }
}

.content-video-media-module video, .content-video-media-module iframe {
  width: 100%;
}

.general-section {
  position: relative;
  background-size: cover !important;
  background-position: 50% 50% !important;
  z-index: 2;
  overflow: hidden;
}
.general-section .container {
  position: relative;
  z-index: 5;
}
.general-section .container-fluid, .general-section .container-sm, .general-section .container-md, .general-section .container-lg, .general-section .container-xl, .general-section .container-xxl {
  position: relative;
  z-index: 5;
}
.general-section .bg-over-content-img {
  opacity: 1;
  background: url("../assets/img/hero-img-y.png") !important;
  background-size: cover !important;
  background-position: 50% 100% !important;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  transition: opacity 0.5s ease-in-out;
}
.general-section .overlay-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}
.general-section .overlay-content.overlay-bg-bt {
  bottom: 0;
  background-position: bottom !important;
  background-repeat: no-repeat !important;
}
.general-section .overlay-content .overlay-bg-bt-2 {
  background-color: #fdfaf3;
  bottom: 0;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 314px;
}
.general-section .overlay-content.bg-style-2:after {
  content: "";
  background-image: url(../assets/img/overlay-hero-home.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.general-section.before-element:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 140px;
  z-index: 2;
  background: linear-gradient(0deg, #fdfaf3 0%, rgba(255, 255, 255, 0) 100%);
}
.general-section .bg-after-element-color {
  content: "";
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fdfaf3 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 84px;
  z-index: 2;
  top: auto;
}
.general-section.parallax-effect {
  background-attachment: fixed !important;
}
.general-section.size-default .container {
  max-width: 1370px;
}
.general-section.size-small .container {
  max-width: 922px;
}
.general-section.size-medium .container {
  max-width: 1146px;
}
.general-section.size-large .container {
  max-width: 1260px;
}
.general-section.size-big .container {
  max-width: 1530px;
}
.general-section.size-full .container {
  max-width: 100%;
  padding: 0;
}
.general-section.size-full.full-width-with-padding .container {
  padding: 0 25px;
}
.general-section.text-dark {
  color: #000 !important;
}
.general-section.text-dark p {
  color: #000 !important;
}
.general-section.text-dark ul li::marker {
  color: #000;
}
.general-section.heading-dark h1, .general-section.heading-dark .h1,
.general-section.heading-dark h2,
.general-section.heading-dark .h2,
.general-section.heading-dark h3,
.general-section.heading-dark .h3,
.general-section.heading-dark h4,
.general-section.heading-dark .h4,
.general-section.heading-dark h5,
.general-section.heading-dark .h5,
.general-section.heading-dark h6,
.general-section.heading-dark .h6 {
  color: #000 !important;
}
.general-section.text-white {
  color: #fff !important;
}
.general-section.text-white a:not(.btn-site):not(.btn-link):not(.btn),
.general-section.text-white p {
  color: #fff !important;
}
.general-section.text-white ul li::marker {
  color: #fff;
}
.general-section.heading-white h1, .general-section.heading-white .h1,
.general-section.heading-white h2,
.general-section.heading-white .h2,
.general-section.heading-white h3,
.general-section.heading-white .h3,
.general-section.heading-white h4,
.general-section.heading-white .h4,
.general-section.heading-white h5,
.general-section.heading-white .h5,
.general-section.heading-white h6,
.general-section.heading-white .h6 {
  color: #fff !important;
}
.general-section.text-dark_2 {
  color: #00152F !important;
}
.general-section.text-dark_2 a:not(.btn-site):not(.btn-link):not(.btn),
.general-section.text-dark_2 p {
  color: #00152F !important;
}
.general-section.text-dark_2 ul li::marker {
  color: #00152F;
}
.general-section.heading-dark_2 h1, .general-section.heading-dark_2 .h1,
.general-section.heading-dark_2 h2,
.general-section.heading-dark_2 .h2,
.general-section.heading-dark_2 h3,
.general-section.heading-dark_2 .h3,
.general-section.heading-dark_2 h4,
.general-section.heading-dark_2 .h4,
.general-section.heading-dark_2 h5,
.general-section.heading-dark_2 .h5,
.general-section.heading-dark_2 h6,
.general-section.heading-dark_2 .h6 {
  color: #00152F !important;
}
.general-section.text-green {
  color: #00152F !important;
}
.general-section.text-green a:not(.btn-site):not(.btn-link):not(.btn),
.general-section.text-green p {
  color: #00152F !important;
}
.general-section.text-green ul li::marker {
  color: #00152F;
}
.general-section.heading-green h1, .general-section.heading-green .h1,
.general-section.heading-green h2,
.general-section.heading-green .h2,
.general-section.heading-green h3,
.general-section.heading-green .h3,
.general-section.heading-green h4,
.general-section.heading-green .h4,
.general-section.heading-green h5,
.general-section.heading-green .h5,
.general-section.heading-green h6,
.general-section.heading-green .h6 {
  color: #00152F !important;
}
.general-section .video-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
  overflow: hidden;
  opacity: 0.8;
}
.general-section .video-container video {
  width: 100vw;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.general-section .video-container video::-webkit-media-controls {
  display: none !important;
}
.general-section .video-container video::-moz-media-controls {
  display: none !important;
}
.general-section .video-container video::-ms-media-controls {
  display: none !important;
}
.general-section .video-container video::-o-media-controls {
  display: none !important;
}
@media (max-width: 991px) {
  .general-section:not(.hero-pages):not(.cta-promo-bar):not(.promo-card):not(.hero-single-bg):not(.hero-home):not(.hero-with-form):not(.hero-banner-common) {
    padding: 60px 0 !important;
  }
}
@media (max-width: 767px) {
  .general-section:not(.hero-pages):not(.cta-promo-bar):not(.promo-card):not(.hero-single-bg):not(.hero-home):not(.hero-with-form):not(.hero-banner-common) {
    padding: 40px 0 !important;
  }
}

/* .breadcrumb-site{
    text-transform: uppercase;
    font-family: $font-text;

    a {
        text-decoration: none !important;
    }

    span{
        font-weight: 500;
        letter-spacing: 0.9px;
        color: $black;
        a{
            font-weight: inherit;
        }
    }

    .current-item{
        font-size: 20px;
        padding-bottom: 4px;
    }  

    .sep-breadcrumb {
        display: inline-block;
        width: 10px;
        height: 12px;
        margin: 0 10px;
        background-image: url("../assets/img/sep-breadcrumb.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    @include media-breakpoint-down(lg) {
        margin-bottom: 10px;
    }
} */
/* .breadcrumb-white .breadcrumb-site{
    span{
        color: $white;
        a{
            color: $white;
        }
    }
} */
.main-title-content {
  margin: 0 auto 53px;
}
.main-title-content h6, .main-title-content .h6 {
  color: #F15C49;
  font-size: 18px !important;
  line-height: 1.55em;
  letter-spacing: 2px !important;
  margin-bottom: 23.7px;
}
@media (max-width: 767px) {
  .main-title-content h6, .main-title-content .h6 {
    font-size: 14px !important;
    letter-spacing: 1.56px !important;
    margin-bottom: 10px;
  }
}
.main-title-content > *:last-child {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .main-title-content {
    margin: 0 auto 40px;
  }
}

.wp-block-cover .wp-block-columns .wp-block-column {
  display: flex;
  flex-direction: column;
  height: auto;
}
.wp-block-cover .wp-block-columns .wp-block-column .wp-block-buttons {
  margin-top: auto;
}

@media (max-width: 991px) {
  .wp-block-list-pad {
    padding-top: 20px !important;
  }
}
@media (max-width: 991px) {
  .wp-block-list-pad {
    padding-top: 52px !important;
  }
}

.style-box-shadow-none {
  box-shadow: none !important;
}

.has-blue-color,
.has-blue-color:visited {
  color: #240780;
}

.has-blue-background-color {
  background-color: #240780;
  border-color: #240780;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-blue-color {
  color: #240780;
}

.has-indigo-color,
.has-indigo-color:visited {
  color: #6610f2;
}

.has-indigo-background-color {
  background-color: #6610f2;
  border-color: #6610f2;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-indigo-color {
  color: #6610f2;
}

.has-purple-color,
.has-purple-color:visited {
  color: #6f42c1;
}

.has-purple-background-color {
  background-color: #6f42c1;
  border-color: #6f42c1;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-purple-color {
  color: #6f42c1;
}

.has-pink-color,
.has-pink-color:visited {
  color: #d63384;
}

.has-pink-background-color {
  background-color: #d63384;
  border-color: #d63384;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-pink-color {
  color: #d63384;
}

.has-red-color,
.has-red-color:visited {
  color: #dc3545;
}

.has-red-background-color {
  background-color: #dc3545;
  border-color: #dc3545;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-red-color {
  color: #dc3545;
}

.has-orange-color,
.has-orange-color:visited {
  color: #fd7e14;
}

.has-orange-background-color {
  background-color: #fd7e14;
  border-color: #fd7e14;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-orange-color {
  color: #fd7e14;
}

.has-yellow-color,
.has-yellow-color:visited {
  color: #FFC72C;
}

.has-yellow-background-color {
  background-color: #FFC72C;
  border-color: #FFC72C;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-yellow-color {
  color: #FFC72C;
}

.has-green-color,
.has-green-color:visited {
  color: #00152F;
}

.has-green-background-color {
  background-color: #00152F;
  border-color: #00152F;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-green-color {
  color: #00152F;
}

.has-teal-color,
.has-teal-color:visited {
  color: #20c997;
}

.has-teal-background-color {
  background-color: #20c997;
  border-color: #20c997;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-teal-color {
  color: #20c997;
}

.has-cyan-color,
.has-cyan-color:visited {
  color: #0dcaf0;
}

.has-cyan-background-color {
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-cyan-color {
  color: #0dcaf0;
}

.has-black-color,
.has-black-color:visited {
  color: #000;
}

.has-black-background-color {
  background-color: #000;
  border-color: #000;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-black-color {
  color: #000;
}

.has-white-color,
.has-white-color:visited {
  color: #fff;
}

.has-white-background-color {
  background-color: #fff;
  border-color: #fff;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-white-color {
  color: #fff;
}

.has-gray-color,
.has-gray-color:visited {
  color: #6c757d;
}

.has-gray-background-color {
  background-color: #6c757d;
  border-color: #6c757d;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-gray-color {
  color: #6c757d;
}

.has-gray-dark-color,
.has-gray-dark-color:visited {
  color: #343a40;
}

.has-gray-dark-background-color {
  background-color: #343a40;
  border-color: #343a40;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-gray-dark-color {
  color: #343a40;
}

.has-primary-color,
.has-primary-color:visited {
  color: #00152F;
}

.has-primary-background-color {
  background-color: #00152F;
  border-color: #00152F;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-primary-color {
  color: #00152F;
}

.has-secondary-color,
.has-secondary-color:visited {
  color: #EB6852;
}

.has-secondary-background-color {
  background-color: #EB6852;
  border-color: #EB6852;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-secondary-color {
  color: #EB6852;
}

.has-success-color,
.has-success-color:visited {
  color: #00152F;
}

.has-success-background-color {
  background-color: #00152F;
  border-color: #00152F;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-success-color {
  color: #00152F;
}

.has-info-color,
.has-info-color:visited {
  color: #0dcaf0;
}

.has-info-background-color {
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-info-color {
  color: #0dcaf0;
}

.has-warning-color,
.has-warning-color:visited {
  color: #FFC72C;
}

.has-warning-background-color {
  background-color: #FFC72C;
  border-color: #FFC72C;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-warning-color {
  color: #FFC72C;
}

.has-danger-color,
.has-danger-color:visited {
  color: #dc3545;
}

.has-danger-background-color {
  background-color: #dc3545;
  border-color: #dc3545;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-danger-color {
  color: #dc3545;
}

.has-light-color,
.has-light-color:visited {
  color: #f8f9fa;
}

.has-light-background-color {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-light-color {
  color: #f8f9fa;
}

.has-dark-color,
.has-dark-color:visited {
  color: #212529;
}

.has-dark-background-color {
  background-color: #212529;
  border-color: #212529;
}

.wp-block-button:hover .wp-block-button__link.has-white-background-color.has-dark-color {
  color: #212529;
}

figure.wp-block-image,
.wp-block-image > figure {
  display: block;
}
body.understrap-no-sidebar .alignwide,
body.understrap-no-sidebar .alignfull,
body.understrap-no-sidebar .wp-block-cover.alignwide,
body.understrap-no-sidebar .wp-block-cover.alignfull {
  margin: 0 calc(50% - 50vw);
  max-width: 100vw;
  width: 100vw;
}
body.understrap-no-sidebar .alignwide .wp-block-cover__inner-container,
body.understrap-no-sidebar .alignfull .wp-block-cover__inner-container,
body.understrap-no-sidebar .wp-block-cover.alignwide .wp-block-cover__inner-container,
body.understrap-no-sidebar .wp-block-cover.alignfull .wp-block-cover__inner-container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  body.understrap-no-sidebar .alignwide .wp-block-cover__inner-container,
  body.understrap-no-sidebar .alignfull .wp-block-cover__inner-container,
  body.understrap-no-sidebar .wp-block-cover.alignwide .wp-block-cover__inner-container,
  body.understrap-no-sidebar .wp-block-cover.alignfull .wp-block-cover__inner-container {
    max-width: 540px;
  }
}
@media (min-width: 767px) {
  body.understrap-no-sidebar .alignwide .wp-block-cover__inner-container,
  body.understrap-no-sidebar .alignfull .wp-block-cover__inner-container,
  body.understrap-no-sidebar .wp-block-cover.alignwide .wp-block-cover__inner-container,
  body.understrap-no-sidebar .wp-block-cover.alignfull .wp-block-cover__inner-container {
    max-width: 720px;
  }
}
@media (min-width: 991px) {
  body.understrap-no-sidebar .alignwide .wp-block-cover__inner-container,
  body.understrap-no-sidebar .alignfull .wp-block-cover__inner-container,
  body.understrap-no-sidebar .wp-block-cover.alignwide .wp-block-cover__inner-container,
  body.understrap-no-sidebar .wp-block-cover.alignfull .wp-block-cover__inner-container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  body.understrap-no-sidebar .alignwide .wp-block-cover__inner-container,
  body.understrap-no-sidebar .alignfull .wp-block-cover__inner-container,
  body.understrap-no-sidebar .wp-block-cover.alignwide .wp-block-cover__inner-container,
  body.understrap-no-sidebar .wp-block-cover.alignfull .wp-block-cover__inner-container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  body.understrap-no-sidebar .alignwide .wp-block-cover__inner-container,
  body.understrap-no-sidebar .alignfull .wp-block-cover__inner-container,
  body.understrap-no-sidebar .wp-block-cover.alignwide .wp-block-cover__inner-container,
  body.understrap-no-sidebar .wp-block-cover.alignfull .wp-block-cover__inner-container {
    max-width: 1320px;
  }
}
body.understrap-no-sidebar .alignwide,
body.understrap-no-sidebar .wp-block-cover.alignwide {
  margin: 0 calc(50% - 45vw);
  max-width: 90vw;
  width: 100vw;
}
@media (max-width: 920px) {
  body.understrap-no-sidebar .alignwide,
  body.understrap-no-sidebar .wp-block-cover.alignwide {
    margin: 0 calc(50% - 48vw);
    max-width: 96vw;
    width: 100vw;
  }
}

.wp-block-buttons .wp-block-button .wp-block-button__link {
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-border-radius: 0.375rem;
}
.wp-block-buttons .wp-block-button:not(.is-style-outline) .wp-block-button__link {
  border: 1px solid transparent;
}

/*
* Use existing Bootstrap 4/5 classes and
* variables to extend - override CF7 style
*
* Useful CF7 classes:
* .wpcf7 the wrapper element
* .wpcf7-form
* .wpcf7-form-control
* .wpcf7-text
* .wpcf7-email
* .wpcf7-textarea
* .wpcf7-submit
*/
.wpcf7 {
  max-width: 600px;
  margin: 0 auto !important;
}
.wpcf7 .wpcf7-form p {
  margin-bottom: 1rem;
}
.wpcf7 .wpcf7-form label {
  width: 100%;
}
.wpcf7 .wpcf7-not-valid-tip {
  color: theme-color("danger");
}
.wpcf7 .wpcf7-validation-errors {
  color: theme-color("danger");
  border: 1px solid #e9ecef;
}

.alert-black {
  background-color: #00152F;
  color: #fff;
  border-radius: 0;
  padding-top: 0.75rem;
  padding-bottom: 0.78rem;
}
.alert-black .fix-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.alert-black .fix-left .alert-left p {
  margin-bottom: 0;
  color: #fff;
}
@media (max-width: 540px) {
  .alert-black .fix-left .alert-left {
    max-width: 164px;
  }
}
.alert-black .fix-left .alert-right {
  margin-right: 46px;
}
.alert-black .fix-left .alert-right a {
  margin-bottom: 0;
  color: #fff;
  text-underline-offset: 8px;
  text-decoration-thickness: 1px;
  text-wrap: nowrap;
  font-size: 16px;
  font-weight: 600;
}
.alert-black .fix-left .alert-right a:hover {
  color: rgba(44, 176, 75, 0.75);
}
@media (max-width: 540px) {
  .alert-black .fix-left .alert-right {
    margin-right: 0;
    padding-left: 27px;
  }
}
@media (max-width: 540px) {
  .alert-black .fix-left {
    justify-content: flex-start;
  }
}
@media (max-width: 400px) {
  .alert-black .btn-link {
    max-width: 221px;
  }
}
.alert-black.alert-dismissible .btn-close {
  position: absolute;
  top: 10px;
  right: 27px;
  z-index: 2;
  box-sizing: content-box;
  width: 15px;
  height: 15px;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url(../assets/img/x-bottom.svg) center/35px auto no-repeat;
  border: 0;
  border-radius: 0;
  opacity: 1;
  padding: 0;
  transition: all 0.3s ease;
}
.alert-black.alert-dismissible .btn-close:hover {
  transform: scale(1.1);
}
@media (max-width: 540px) {
  .alert-black.alert-dismissible .btn-close {
    top: 17px;
    right: 14px;
  }
}
.alert-black .container {
  position: relative;
}

.logged-in.admin-bar #wrapper-navbar {
  top: 32px;
}
@media (max-width: 1100px) {
  .logged-in.admin-bar {
    top: 46px;
  }
}

#wrapper-navbar {
  position: sticky;
  width: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.01);
  margin-bottom: -132px;
}
@media (max-width: 1148px) {
  #wrapper-navbar {
    margin-bottom: -90px;
  }
}
#wrapper-navbar .mega-menu-link,
#wrapper-navbar .mega-menu-link:hover {
  font-weight: 600 !important;
}
#wrapper-navbar .mega-menu-link span {
  transition: all ease-in 0.3s;
}
#wrapper-navbar .mega-menu-link:hover span {
  transform: rotate(180deg) !important;
}
@media (max-width: 1148px) {
  #wrapper-navbar .mega-toggle-on .mega-menu-link:focus span:after,
  #wrapper-navbar .mega-toggle-on .mega-menu-link:active span:after {
    transform: rotate(180deg) !important;
  }
}
#wrapper-navbar #main-nav {
  padding: 0;
}
#wrapper-navbar .container-logo {
  max-width: 298px;
}
#wrapper-navbar .container-logo img {
  transition: all 0.3s ease;
}
#wrapper-navbar #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-flyout ul.mega-sub-menu {
  /*     box-shadow: 0 3px 3px rgb(0 0 0 / 3%); */
}
#wrapper-navbar .navbar-toggler {
  display: none !important;
}
#wrapper-navbar .btn-green-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center !important;
  margin-left: 30px !important;
}
#wrapper-navbar .btn-green-menu > a {
  text-decoration: none !important;
  height: auto !important;
  line-height: normal !important;
  min-width: 213px !important;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease !important;
  padding: 20px 39px !important;
  font-weight: 600;
  color: #00152F !important;
  border: 2px solid #00152F !important;
  border-radius: 100px !important;
  background-color: transparent !important;
  width: auto !important;
  display: inline-block;
}
#wrapper-navbar .btn-green-menu > a:hover {
  color: #fff !important;
  background-color: #00152F !important;
}
@media (max-width: 1150px) {
  #wrapper-navbar .space-link.mega-toggle-on .mega-sub-menu {
    margin-bottom: 25px !important;
  }
  #wrapper-navbar .container {
    flex-wrap: nowrap;
  }
  #wrapper-navbar .container-logo {
    padding: 10px 0;
    max-width: 220px;
  }
  #wrapper-navbar .btn-green-menu {
    text-align: left !important;
    margin-top: 15px !important;
    margin-left: 10px !important;
  }
  #wrapper-navbar .btn-green-menu > a {
    background-color: transparent !important;
    border-color: #00152F !important;
    color: #00152F !important;
    display: inline-block !important;
    padding: 16px 36px !important;
    letter-spacing: 0;
    text-align: center !important;
  }
  #wrapper-navbar .btn-green-menu > a:hover {
    background-color: #00152F !important;
    border-color: #00152F !important;
    color: #fff !important;
  }
}
@media (max-width: 768px) {
  #wrapper-navbar .btn-green-menu {
    text-align: center !important;
  }
}

.scrolled {
  background-color: #F9FAFE !important;
}
.scrolled .container-logo img {
  max-width: 65%;
}
@media (max-width: 1148px) {
  .scrolled .container-logo img {
    max-width: 90%;
  }
}
.scrolled #mega-menu-wrap-primary {
  background-color: #F9FAFE;
  height: 90px;
  display: flex;
  align-items: center;
}
.scrolled #mega-menu-wrap-primary #mega-menu-primary li.mega-menu-item > ul.mega-sub-menu {
  top: 110px;
}
@media (max-width: 1241px) {
  .scrolled #mega-menu-wrap-primary {
    height: auto;
    display: unset;
  }
  .scrolled #mega-menu-wrap-primary .mega-menu-toggle {
    background: #F9FAFE;
  }
}

#wrapper-footer {
  background-color: #00152F;
  padding: 100px 0 81px;
}
#wrapper-footer .logo-row-cta {
  margin-bottom: 37px;
}
#wrapper-footer .logo-row-cta .logo-footer {
  margin: 0;
}
#wrapper-footer .logo-row-cta .logo-inner-left {
  max-width: 298px;
}
#wrapper-footer .logo-row-cta .logo-inner-left img {
  width: 100%;
}
#wrapper-footer .logo-row-cta .content-buttons {
  justify-content: flex-end;
}
@media (min-width: 768px) {
  #wrapper-footer .logo-row-cta .content-buttons .btn, #wrapper-footer .logo-row-cta .content-buttons .woocommerce-notices-wrapper .woocommerce-message a.button, .woocommerce-notices-wrapper .woocommerce-message #wrapper-footer .logo-row-cta .content-buttons a.button, #wrapper-footer .logo-row-cta .content-buttons .woocommerce-info a.button, .woocommerce-info #wrapper-footer .logo-row-cta .content-buttons a.button, #wrapper-footer .logo-row-cta .content-buttons .wpcf7 input[type=submit], .wpcf7 #wrapper-footer .logo-row-cta .content-buttons input[type=submit] {
    padding: 17.5px 37px;
    min-width: 213px;
  }
}
#wrapper-footer hr {
  border: 0;
  border-bottom: 2px solid #fff;
  margin: 0;
}
#wrapper-footer h6, #wrapper-footer .h6 {
  font-size: 18px;
  letter-spacing: 0.9px;
  color: #fff;
}
#wrapper-footer .logos-menu-list {
  margin-bottom: 55px;
}
#wrapper-footer .logos-menu-list .row-div {
  display: flex;
  margin: 34px -25px 0;
  flex-wrap: nowrap;
}
#wrapper-footer .logos-menu-list .figure-img-box {
  flex: 1;
  width: 20%;
  padding: 0 25px;
  margin-bottom: 20px;
}
#wrapper-footer .logos-menu-list .figure-img-box img {
  border-radius: 10px;
  max-width: 200px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
#wrapper-footer .copyright_footer {
  text-align: right;
  font-family: "Poppins", sans-serif;
}
#wrapper-footer .copyright_footer .row {
  align-items: center;
}
#wrapper-footer .copyright_footer .inner-detail-copyright > *:last-child {
  margin-bottom: 0;
}
#wrapper-footer .copyright_footer p {
  font-size: 14px;
  color: #fff;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
}
#wrapper-footer .copyright_footer a:hover {
  color: #61c478;
}
@media (max-width: 991px) {
  #wrapper-footer {
    padding: 50px 0;
  }
  #wrapper-footer .logos-menu-list {
    margin-bottom: 25px;
  }
  #wrapper-footer .logos-menu-list .row-div {
    display: flex;
    margin: 34px -20px 0;
    flex-wrap: wrap;
    justify-content: center;
  }
  #wrapper-footer .logos-menu-list .figure-img-box {
    flex: initial;
    width: 16.666%;
    padding: 0 20px;
  }
  #wrapper-footer .logos-menu-list .figure-img-box img {
    max-width: 260px;
  }
}
@media (max-width: 767px) {
  #wrapper-footer .logo-row-cta {
    max-width: 238px;
    margin: 0 auto 25px;
  }
  #wrapper-footer .logo-row-cta .logo-inner-left {
    margin-bottom: 20px;
  }
  #wrapper-footer .copyright_footer p {
    text-align: center;
  }
  #wrapper-footer .logos-menu-list .figure-img-box {
    width: 20%;
  }
}
@media (max-width: 575px) {
  #wrapper-footer .logos-menu-list {
    margin-bottom: 25px;
  }
  #wrapper-footer .logos-menu-list .row-div {
    margin: 34px -15px 0;
  }
  #wrapper-footer .logos-menu-list .figure-img-box {
    width: 33.333%;
    padding: 0 15px;
  }
}
@media (max-width: 575px) {
  #wrapper-footer .logos-menu-list {
    margin-bottom: 25px;
  }
  #wrapper-footer .logos-menu-list .figure-img-box {
    width: 33.333%;
  }
}
@media (max-width: 350px) {
  #wrapper-footer .logos-menu-list .figure-img-box {
    width: 50%;
  }
}

.footer-menu-list {
  margin-top: 27px;
  margin-bottom: 38px;
}
.footer-menu-list .col-menus {
  width: 100%;
  display: flex;
  -moz-column-gap: 44px;
  column-gap: 44px;
  justify-content: space-between;
}
.footer-menu-list .col-menu .menu {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-menu-list .col-menu .menu li {
  font-size: 14px;
  margin-bottom: 16px;
  text-align: left;
}
.footer-menu-list .col-menu .menu li a {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.2;
  text-decoration: none;
}
.footer-menu-list .col-menu .menu li a:hover {
  color: #61c478;
  text-decoration: underline;
}
.footer-menu-list .col-menu .menu li:first-child {
  margin-bottom: 20px;
}
.footer-menu-list .col-menu .menu li:first-child a {
  font-size: 20px;
  letter-spacing: 2px;
  line-height: 1.5;
  text-transform: uppercase;
}
@media (min-width: 1225px) {
  .footer-menu-list .col-menu.col-item-footer-1 {
    min-width: 199px;
  }
}
@media (min-width: 1225px) {
  .footer-menu-list .col-menu.col-item-footer-2 {
    min-width: 195px;
  }
}
@media (max-width: 991px) {
  .footer-menu-list > .row > div {
    flex: 0 0 auto;
    width: 100%;
  }
  .footer-menu-list > .row .col-md-4 {
    order: 0;
  }
  .footer-menu-list > .row .col-md-8 {
    order: 1;
  }
}
@media (max-width: 768px) {
  .footer-menu-list .col-menus {
    flex-wrap: wrap;
    -moz-column-gap: 15px;
    column-gap: 15px;
  }
  .footer-menu-list .col-menu {
    width: calc(50% - 15px);
    margin-bottom: 25px;
  }
  .footer-menu-list .col-menu .menu li {
    margin-bottom: 15px;
  }
}
@media (max-width: 500px) {
  .footer-menu-list .col-menu {
    width: 100%;
    margin-bottom: 19px;
  }
  .footer-menu-list .col-menu > div > div {
    margin-bottom: 25px;
  }
  .footer-menu-list .col-menu > div > div:last-child {
    margin-bottom: 0;
  }
  .footer-menu-list .col-menu .menu li {
    margin-bottom: 10px;
  }
}

.list-social-link ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0 -10px;
}
.list-social-link ul li {
  padding: 0 10px;
  margin: 0 0 10px;
  min-width: 49.57px;
  transition: all ease 0.3s;
}
.list-social-link ul li a {
  width: 49.57px;
  height: 49.57px;
  border: 2px solid #fff;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-sizing: border-box;
  transition: all ease 0.3s;
}
.list-social-link ul li a img,
.list-social-link ul li a svg {
  max-width: 18px;
  width: 100%;
}
.list-social-link ul li.facebook-icon img,
.list-social-link ul li.facebook-icon svg {
  max-width: 12px;
}
.list-social-link ul li.linkedin-icon img,
.list-social-link ul li.linkedin-icon svg {
  max-width: 18px;
}
.list-social-link ul li.youtube-icon img,
.list-social-link ul li.youtube-icon svg {
  max-width: 23px;
}
.list-social-link ul li.crunchbase-icon img,
.list-social-link ul li.crunchbase-icon svg {
  max-width: 21px;
}
.list-social-link ul li.x-twiter-icon img,
.list-social-link ul li.x-twiter-icon svg {
  max-width: 18.99px;
}
.list-social-link ul li:hover a {
  opacity: 1;
  border-color: #61c478;
  transition: all ease 0.3s;
}
.list-social-link ul li:hover a svg path {
  fill: #61c478;
}
@media (max-width: 991px) {
  .list-social-link ul {
    margin: 0 -8px;
  }
  .list-social-link ul li {
    padding: 0 8px;
    margin: 0 0 8px;
    min-width: 40px;
  }
  .list-social-link ul li a {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 767px) {
  .list-social-link ul {
    justify-content: center;
    margin-bottom: 10px;
  }
  .list-social-link ul li a {
    padding: 10px;
  }
}

.block-page-404 {
  /****
   *** start background
   *****/
  /****
   *** end background
   *****/
}
.block-page-404 .content-text {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.block-page-404 .content-text p {
  font-size: 22px;
  width: 100%;
  max-width: 540px;
}
.block-page-404 .content-text .content-buttons {
  margin-top: 15px;
}
.block-page-404 .content-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.block-page-404 .content-404 .box {
  max-width: 650px;
  display: flex;
  align-items: center;
  height: 100%;
}
.block-page-404 .content-404 .box img {
  width: 100%;
  min-height: auto;
  -o-object-fit: contain;
  object-fit: contain;
}
.block-page-404 .video-container {
  opacity: 0.2;
}
.block-page-404 .overlay-content {
  background: url("../assets/img/overlay-404.png") !important;
  background-size: cover !important;
  background-position: 20% 100% !important;
}
@media (max-width: 990.98px) {
  .block-page-404 {
    margin-top: 90px !important;
  }
  .block-page-404 .content-text {
    text-align: center;
    row-gap: 0;
    align-items: center;
  }
  .block-page-404 .content-text p {
    font-size: 20px;
  }
}

.single {
  background-color: #fdfaf3;
}
.single .post-reading {
  font-size: 18px;
  line-height: 1.4444em;
  color: #00152F;
}
.single .main-title-content {
  margin-bottom: 18px;
}
@media (max-width: 1440px) {
  .single .main-title-content {
    max-width: 1050px;
  }
}
@media (max-width: 1180px) {
  .single .main-title-content {
    max-width: none;
  }
}
.single .figure-content-hero .figure-single {
  margin: 0 auto;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  background: transparent linear-gradient(218deg, #00152F 0, #0167A6 100%) 0 0 no-repeat padding-box;
  /*  &:after{
      content: "";
      width: 100%;
      height: 100%;
     opacity: 0;
  } */
}
.single .figure-content-hero .figure-single img {
  display: block;
  margin: 0 auto;
  width: 100%;
  mix-blend-mode: soft-light;
  max-height: 400px;
  -o-object-fit: cover;
  object-fit: cover;
}
.single .hero-single-bg {
  padding: 40px 0 0;
  margin-bottom: 44px;
  overflow: initial;
  position: relative;
}
.single .hero-single-bg::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 84px;
  z-index: 2;
  background: #fdfaf3;
  opacity: 1;
}
.single .hero-single-bg::after {
  content: "";
  position: absolute;
  background: url(../assets/img/bg-blogs.png);
  background-size: cover;
  background-position: 50% 0;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 10%;
}
.single .hero-single-bg .overlay-content {
  background-image: url("../assets/img/overlay-hero-home.png") !important;
  background-size: cover !important;
  background-position: 50% 100% !important;
}
.single .hero-single-bg .video-container {
  opacity: 0.2;
  z-index: 1;
}
.single .content-single-data > .container > *:last-child {
  margin-bottom: 0;
}
.single .content-single-data {
  color: #191919;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 1.454545em;
  padding-bottom: 139px;
}
.single .content-single-data a:not(.btn):not(.page-a-lk) {
  font-weight: normal;
  text-decoration: none;
  color: #00152F;
  text-decoration-line: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 7px;
}
.single .content-single-data p {
  margin-bottom: 20px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.454545em;
}
.single .content-single-data strong {
  font-weight: 700;
}
.single .content-single-data h2, .single .content-single-data .h2 {
  margin-bottom: 10px;
  letter-spacing: -1.35px;
}
.single .content-single-data h3, .single .content-single-data .h3 {
  margin-bottom: 18px;
  color: #000000;
  font-weight: 500;
  letter-spacing: -0.68px;
  line-height: 1.17647em;
}
.single .content-single-data h4, .single .content-single-data .h4 {
  margin-bottom: 37px;
  letter-spacing: 0;
  color: #000000;
  line-height: 1.0909em;
}
.single .content-single-data h5, .single .content-single-data .h5 {
  margin-bottom: 25px;
  line-height: 1.5em;
  color: #191919;
  font-weight: 600;
}
.single .content-single-data h6, .single .content-single-data .h6 {
  font-size: 16px;
  letter-spacing: 0;
  margin-bottom: 25px;
  line-height: 1.11111em;
  font-weight: 500;
  color: #181336;
}
.single .content-single-data ul {
  margin-bottom: 40px;
  padding: 0;
  list-style: none;
  font-size: 16px;
}
.single .content-single-data ul li {
  position: relative;
  padding: 0 0 0 21px;
  margin-bottom: 0;
}
.single .content-single-data ul li:before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #5B6670;
  position: absolute;
  border-radius: 100px;
  left: 0;
  top: 13px;
}
.single .content-single-data ul > li:last-child {
  margin-bottom: 0;
}
.single .content-single-data ul > li ul {
  margin-bottom: 0;
  margin-top: 21px;
}
.single .content-single-data ul > li ul li:before {
  content: "";
  background-color: #5B6670;
}
.single .content-single-data ol {
  margin-bottom: 40px;
  padding: 0;
  list-style: none;
  counter-reset: list;
  font-size: 16px;
}
.single .content-single-data ol li {
  position: relative;
  padding: 0 0 0 27px;
  margin-bottom: 0;
}
.single .content-single-data ol li:before {
  position: absolute;
  left: 0;
  top: 0;
  font-weight: 700;
  counter-increment: list;
  content: counter(list) ".";
  position: absolute;
  color: #000;
}
.single .content-single-data ol > li:last-child {
  margin-bottom: 0;
}
.single .content-single-data ol > li ol {
  margin-bottom: 0;
  margin-top: 34px;
}
.single .content-single-data ol > li ol li:before {
  content: "";
  background-color: #000;
}
.single .content-single-data blockquote {
  background-color: #fff;
  border-radius: 5px;
  padding: 85px 44px;
  margin-bottom: 40px;
}
.single .content-single-data blockquote p {
  font-family: "Figtree", sans-serif;
  font-size: 30px;
  line-height: 1.33333em;
  letter-spacing: 0;
}
.single .content-single-data blockquote > *:last-child {
  margin-bottom: 0;
}
.single .content-single-data .wistia_embed {
  border-radius: 15px;
  overflow: hidden;
}
.single .content-single-data .wistia_embed .w-video-wrapper.w-css-reset {
  background-color: transparent !important;
}
.single .content-single-data .wistia_embed img.w-css-reset {
  background-color: transparent !important;
}
.single .content-single-data .wp-block-embed,
.single .content-single-data .wistia_embed {
  margin-bottom: 50px;
  border-radius: 15px;
  overflow: hidden;
}
.single .content-single-data .wp-block-image {
  margin-bottom: 50px;
}
.single .content-single-data .has-large-font-size {
  font-size: 26px !important;
  line-height: 1.38em;
  letter-spacing: -0.26px;
  letter-spacing: 0;
}
@media (max-width: 767px) {
  .single .content-single-data .has-large-font-size {
    font-size: 22px !important;
    line-height: 1.45em;
    letter-spacing: -0.22px;
  }
}
@media (max-width: 575px) {
  .single .content-single-data .has-large-font-size {
    font-size: 20px !important;
  }
}
.single .content-single-data .has-medium-font-size {
  font-size: 23px !important;
  line-height: 1.45em;
  letter-spacing: -0.22px;
  letter-spacing: 0;
}
@media (max-width: 991px) {
  .single .content-single-data .has-medium-font-size {
    font-size: 20px !important;
    line-height: 1.5em;
    letter-spacing: -0.2px;
  }
}
.single .content-single-data .has-small-font-size {
  font-size: 18px !important;
  line-height: 1.55em;
  letter-spacing: 0.18px;
  letter-spacing: 0;
}
@media (max-width: 767px) {
  .single .content-single-data .has-small-font-size {
    font-size: 16px !important;
    line-height: 1.62em;
    letter-spacing: 0.16px;
  }
}
@media (max-width: 991px) {
  .single .content-single-data {
    padding-bottom: 60px;
    font-size: 17px;
    line-height: 1.55555em;
  }
  .single .content-single-data p {
    font-size: 15px;
    line-height: 1.55555em;
  }
  .single .content-single-data blockquote {
    padding: 45px 30px;
  }
  .single .content-single-data blockquote p {
    font-size: 24px;
  }
  .single .content-single-data .wp-block-image {
    margin-bottom: 40px;
  }
  .single .content-single-data .wp-block-embed,
  .single .content-single-data .wistia_embed,
  .single .content-single-data .wp-block-image {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .single .content-single-data {
    font-size: 16px;
    padding-bottom: 40px;
  }
  .single .content-single-data p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .single .content-single-data ul,
  .single .content-single-data ol {
    margin-bottom: 30px;
  }
  .single .content-single-data ul li,
  .single .content-single-data ol li {
    margin-bottom: 20px;
  }
  .single .content-single-data ul li ul,
  .single .content-single-data ul li ol,
  .single .content-single-data ol li ul,
  .single .content-single-data ol li ol {
    margin-top: 20px;
  }
  .single .content-single-data h2, .single .content-single-data .h2,
  .single .content-single-data h3,
  .single .content-single-data .h3,
  .single .content-single-data h4,
  .single .content-single-data .h4,
  .single .content-single-data h5,
  .single .content-single-data .h5,
  .single .content-single-data h6,
  .single .content-single-data .h6 {
    text-align: center;
  }
  .single .content-single-data h4, .single .content-single-data .h4 {
    margin-bottom: 20px;
    margin-top: 25px;
  }
  .single .content-single-data h5, .single .content-single-data .h5 {
    margin-bottom: 20px;
  }
  .single .content-single-data .wp-block-image {
    margin-bottom: 30px;
  }
  .single .content-single-data blockquote {
    padding: 30px;
    margin-bottom: 20px;
  }
  .single .content-single-data blockquote p {
    font-size: 19px;
  }
  .single .content-single-data blockquote footer {
    font-size: 14px;
  }
  .single .content-single-data .wp-block-embed,
  .single .content-single-data .wistia_embed,
  .single .content-single-data .wp-block-image {
    margin-bottom: 30px;
  }
}
@media (max-width: 575px) {
  .single .post-reading {
    font-size: 16px;
  }
}

.nav-pagination-single-page {
  margin-top: 92px;
}
.nav-pagination-single-page .page-a-lk {
  transition: all ease 0.3s;
}
.nav-pagination-single-page .page-a-lk.prev, .nav-pagination-single-page .page-a-lk.next {
  text-decoration: none !important;
  color: #00152F;
  display: flex;
  align-items: center;
  position: relative;
  top: 0;
  transform: translate(0%, 0%);
  transition: all ease 0.3s;
}
.nav-pagination-single-page .page-a-lk.prev::after, .nav-pagination-single-page .page-a-lk.next::after {
  content: none !important;
}
.nav-pagination-single-page .page-a-lk.prev:not(.disabled) .sr-only-text, .nav-pagination-single-page .page-a-lk.next:not(.disabled) .sr-only-text {
  opacity: 1;
}
.nav-pagination-single-page .page-a-lk.prev:not(.disabled) .circle-arrow, .nav-pagination-single-page .page-a-lk.next:not(.disabled) .circle-arrow {
  background-color: #00152F;
}
.nav-pagination-single-page .page-a-lk.prev:not(.disabled) .circle-arrow svg path, .nav-pagination-single-page .page-a-lk.next:not(.disabled) .circle-arrow svg path {
  stroke: #fff;
}
.nav-pagination-single-page .page-a-lk.prev .sr-only-text, .nav-pagination-single-page .page-a-lk.next .sr-only-text {
  font-size: 18px;
  letter-spacing: 0.9px;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  display: inline-block;
  color: #00152F;
  text-decoration: none !important;
  font-weight: 600;
  margin: 0;
  padding: 0;
  opacity: 0;
}
.nav-pagination-single-page .page-a-lk.prev .sr-only-text:after, .nav-pagination-single-page .page-a-lk.next .sr-only-text:after {
  content: none !important;
}
.nav-pagination-single-page .page-a-lk.prev .circle-arrow, .nav-pagination-single-page .page-a-lk.next .circle-arrow {
  width: 50px;
  height: 50px;
  min-width: 50px;
  display: inline-block;
  text-decoration: none !important;
  border: 1px solid #2A5F5A;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  margin: 0;
}
.nav-pagination-single-page .page-a-lk.prev .circle-arrow:after, .nav-pagination-single-page .page-a-lk.next .circle-arrow:after {
  content: none !important;
}
.nav-pagination-single-page .page-a-lk.prev svg, .nav-pagination-single-page .page-a-lk.next svg {
  width: 24px;
}
.nav-pagination-single-page .page-a-lk.prev {
  left: 0;
  justify-content: flex-start;
}
.nav-pagination-single-page .page-a-lk.prev .sr-only-text {
  margin-left: 20px;
  stroke: #fff;
}
.nav-pagination-single-page .page-a-lk.next {
  right: 0;
  justify-content: flex-end;
}
.nav-pagination-single-page .page-a-lk.next .sr-only-text {
  margin-right: 20px;
}
@media (max-width: 767px) {
  .nav-pagination-single-page {
    margin-top: 35px;
  }
  .nav-pagination-single-page .prev.page-a-lk {
    order: 1;
    top: 0;
    transform: initial;
  }
  .nav-pagination-single-page .prev.page-a-lk .sr-only-text {
    opacity: 1;
    font-size: 16px;
  }
  .nav-pagination-single-page .next.page-a-lk {
    order: 2;
    top: 0;
    transform: initial;
  }
  .nav-pagination-single-page .next.page-a-lk .sr-only-text {
    opacity: 1;
    font-size: 16px;
  }
}
@media (max-width: 344px) {
  .nav-pagination-single-page .prev.page-a-lk .sr-only-text,
  .nav-pagination-single-page .next.page-a-lk .sr-only-text {
    display: none;
  }
}

.socials-share {
  position: fixed;
  top: 260px;
  left: 0;
  z-index: 7;
}
.socials-share .list-share a {
  display: block;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.socials-share .list-share a[title=facebook] {
  background-color: #415a94;
}
.socials-share .list-share a[title=twitter] {
  background-color: #4aa1ec;
}
.socials-share .list-share a[title=print] {
  background-color: #788a8d;
}
.socials-share .list-share a[title=email] {
  background-color: #848484;
}
.socials-share .list-share a[title=plus] {
  background-color: #ee6f59;
}
.socials-share .list-share a[title=linkedIn] {
  background-color: #0077b5;
}
.socials-share .list-share a img {
  height: 26px;
}
@media (max-width: 1180px) {
  .socials-share {
    position: relative;
    top: 0;
    padding-bottom: 20px;
    z-index: 1;
    margin-top: 50px;
  }
  .socials-share .list-share {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .socials-share {
    margin-top: 30px;
  }
}

.single-ebook.ebook-template-gated-content .content-right__image {
  display: none;
}

.template-gated-content {
  padding-top: 64px !important;
  padding-bottom: 100px !important;
  background: url("../assets/img/overlay-hero-home.png") !important;
  background-size: cover !important;
  background-position: 50% 100% !important;
  opacity: 1 !important;
}
.template-gated-content .main-content-site {
  margin-top: 64px;
}
.template-gated-content .site-main .row {
  justify-content: space-between;
}
.template-gated-content .content-left p {
  font-size: 22px;
  color: #191919;
}
.template-gated-content .content-left h2, .template-gated-content .content-left .h2 {
  padding: 27px 0 29px;
  margin-bottom: 0;
}
.template-gated-content .content-left ul {
  padding-left: 20px;
}
.template-gated-content .content-left ul li {
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 32px;
}
.template-gated-content .content-left ul li::marker {
  color: #5B6670;
}
@media (max-width: 990px) {
  .template-gated-content .content-left {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0px;
  }
  .template-gated-content .content-left ul {
    /*    max-width: 450px;
    margin: 0 auto;
    text-align: left; */
  }
}
@media (max-width: 768px) {
  .template-gated-content .content-left {
    padding-bottom: 0;
  }
  .template-gated-content .content-left p {
    font-size: 18px;
  }
  .template-gated-content .content-left ul li {
    font-size: 18px;
    margin-bottom: 8px;
  }
}
@media (max-width: 576px) {
  .template-gated-content .content-left {
    padding-bottom: 0;
  }
}
.template-gated-content .content-right {
  max-width: 560px;
  padding-top: 39px;
  /*style form*/
  /*end style form*/
}
.template-gated-content .content-right__image {
  background: transparent linear-gradient(245deg, #800048 0%, #8F4993 100%) 0 0 no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  max-height: 251px;
  margin-bottom: 15%;
  margin-top: 30px;
  padding: 0 66px 30px;
  height: auto;
}
.template-gated-content .content-right__image figure img {
  max-width: 409px;
  border-radius: 10px;
  margin-top: 68px;
  width: 100%;
}
@media (max-width: 575px) {
  .template-gated-content .content-right__image figure img {
    margin-top: 30px;
  }
}
@media (max-width: 990px) {
  .template-gated-content .content-right__image {
    margin-top: 100px !important;
  }
}
.template-gated-content .content-right__form .box-script-content {
  padding: 60px 50px 87px 50px;
  background-color: white;
  box-shadow: 5px 5px 25px rgba(47, 93, 205, 0.1490196078);
  border-radius: 20px;
}
.template-gated-content .content-right__form form[id*=gform_] {
  padding: 60px 50px 87px 50px;
  background-color: white;
  box-shadow: 5px 5px 25px rgba(47, 93, 205, 0.1490196078);
  border-radius: 20px;
}
.template-gated-content .content-right__form form[id*=gform_] .gform-body legend,
.template-gated-content .content-right__form form[id*=gform_] .gform-body label {
  margin-bottom: 0;
  line-height: normal;
  font-size: 18px;
}
.template-gated-content .content-right__form form[id*=gform_] .gform-body legend .gfield_required::after,
.template-gated-content .content-right__form form[id*=gform_] .gform-body label .gfield_required::after {
  content: "*";
  color: #191919;
  position: relative;
  top: -2px;
}
.template-gated-content .content-right__form form[id*=gform_] .gform-body legend .gfield_required,
.template-gated-content .content-right__form form[id*=gform_] .gform-body label .gfield_required {
  padding-left: 0;
}
.template-gated-content .content-right__form form[id*=gform_] .gform-body legend .gfield_required .gfield_required_text,
.template-gated-content .content-right__form form[id*=gform_] .gform-body label .gfield_required .gfield_required_text {
  display: none;
}
.template-gated-content .content-right__form form[id*=gform_] .gform-body span:focus-visible,
.template-gated-content .content-right__form form[id*=gform_] .gform-body .ginput_container:focus-visible {
  border: none !important;
  outline: none;
}
.template-gated-content .content-right__form form[id*=gform_] .gform-body input {
  border: none;
  border-bottom: 1px solid #00152F;
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 576px) {
  .template-gated-content .content-right__form form[id*=gform_] .gform-body .gchoice {
    /*  text-align: center; */
  }
}
.template-gated-content .content-right__form form[id*=gform_] .gform-body .gchoice input {
  width: 19px;
  height: 19px;
  margin-right: 11px;
}
.template-gated-content .content-right__form form[id*=gform_] .gform-body .gchoice label {
  font-size: 16px;
  max-width: calc(100% - 34px);
}
.template-gated-content .content-right__form form[id*=gform_] .gform-body .gchoice label a {
  font-size: 16px;
  color: #00152F !important;
}
@media (hover: hover) {
  .template-gated-content .content-right__form form[id*=gform_] .gform-body .gchoice label a:hover {
    font-weight: bold;
  }
}
@media (max-width: 991px) {
  .template-gated-content .content-right__form form[id*=gform_] .gform-body .gchoice label {
    max-width: calc(100% - 35px);
  }
}
.template-gated-content .content-right__form form[id*=gform_] .gform_footer {
  padding-bottom: 0;
}
.template-gated-content .content-right__form form[id*=gform_] .gform_footer .gform_button {
  background: #00152F 0 0 no-repeat padding-box;
  border: none;
  border-radius: 100px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  max-width: 173px;
  width: 100%;
  padding: 17px 39px;
  margin-bottom: 0;
}
.template-gated-content .content-right__form form[id*=gform_] .gform_footer input[type=submit] {
  outline-color: #00152F;
}
.template-gated-content .content-right__form .gform_wrapper.gravity-theme .gform_validation_errors,
.template-gated-content .content-right__form .gform_wrapper.gravity-theme .validation_message {
  background-color: #fff;
}
.template-gated-content .content-right__form .gform_wrapper.gravity-theme .gform_validation_errors h2, .template-gated-content .content-right__form .gform_wrapper.gravity-theme .gform_validation_errors .h2,
.template-gated-content .content-right__form .gform_wrapper.gravity-theme .validation_message h2,
.template-gated-content .content-right__form .gform_wrapper.gravity-theme .validation_message .h2 {
  font-size: 15px;
  color: #c02b0a !important;
  font-weight: initial;
  letter-spacing: normal;
  line-height: 25px;
}
.template-gated-content .content-right__form .gform_wrapper.gravity-theme .gform_validation_errors {
  -webkit-padding-end: 16px;
  padding-inline-end: 16px;
  -webkit-padding-start: 48px;
  padding-inline-start: 48px;
}
.template-gated-content .content-right__form .gform_wrapper.gravity-theme .instruction.validation_message {
  display: none;
}
.template-gated-content .content-right__form .gform_wrapper.gravity-theme .gfield_error label {
  color: #00152F;
}
.template-gated-content .content-right__form .gform_wrapper.gravity-theme .gform_validation_errors > h2 .gform-icon, .template-gated-content .content-right__form .gform_wrapper.gravity-theme .gform_validation_errors > .h2 .gform-icon {
  left: 12px;
}
@media (max-width: 990px) {
  .template-gated-content .content-right {
    max-width: 770px;
  }
  .template-gated-content .content-right__image {
    max-width: 536px;
    margin-top: 51px !important;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }
  .template-gated-content .content-right__image figure img {
    width: 100%;
  }
  .template-gated-content .content-right__form form[id*=gform_] {
    padding: 40px;
  }
}
@media (max-width: 767px) {
  .template-gated-content .content-right {
    padding-top: 25px;
  }
}
@media (max-width: 576px) {
  .template-gated-content .content-right__image {
    padding-bottom: 15px;
    /*   padding-left: 15px;
    padding-right: 15px; */
  }
  .template-gated-content .content-right__form form[id*=gform_] {
    padding: 25px;
  }
  .template-gated-content .content-right__form form[id*=gform_] .gform_footer .gform_button {
    padding: 8px 15px;
    margin: 0 auto;
  }
}
.template-gated-content .video-container {
  opacity: 0.3;
  position: relative;
  z-index: -1;
}
.template-gated-content .video-container .video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}
.template-gated-content .video-container .video-background video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .template-gated-content {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
}

.template-thank-you {
  padding-top: 59px !important;
  padding-bottom: 100px !important;
  background: url("../assets/img/overlay-thank-you.png") !important;
  background-size: cover !important;
  background-position: 50% 100% !important;
  opacity: 1 !important;
}
.template-thank-you .site-main .row {
  justify-content: space-between;
}
.template-thank-you .content-left p {
  font-size: 22px;
  color: #00152F;
}
.template-thank-you .content-left h2, .template-thank-you .content-left .h2 {
  padding: 27px 0 29px 0;
  margin-bottom: 0;
}
.template-thank-you .content-left ul {
  padding-left: 20px;
}
.template-thank-you .content-left ul li {
  margin-bottom: 20px;
}
.template-thank-you .content-left ul li::marker {
  color: #5B6670;
}
@media (max-width: 990px) {
  .template-thank-you .content-left {
    text-align: center;
    padding-bottom: 60px;
  }
  .template-thank-you .content-left ul {
    max-width: 450px;
    margin: 0 auto;
  }
}
@media (max-width: 576px) {
  .template-thank-you .content-left {
    padding-bottom: 0;
  }
}
.template-thank-you .content-right {
  max-width: 536px;
  padding-top: 39px;
}
.template-thank-you .content-right__image {
  background: transparent linear-gradient(293deg, #0167a6 0, #57d19e 100%) 0 0 no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  max-height: 251px;
  height: 100%;
}
.template-thank-you .content-right__image figure img {
  width: 100%;
  max-width: 409px;
  border-radius: 10px;
  margin-top: -68px;
}
@media (max-width: 990px) {
  .template-thank-you .content-right {
    margin: 0 auto;
    max-width: 770px;
  }
  .template-thank-you .content-right__image {
    max-width: 536px;
    margin: 0 auto;
  }
}
@media (max-width: 576px) {
  .template-thank-you .content-right {
    margin-top: 60px;
  }
  .template-thank-you .content-right__image {
    border-radius: 5px;
  }
  .template-thank-you .content-right__image figure {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.template-thank-you .video-container {
  opacity: 0.4;
  position: relative;
  z-index: -1;
}
.template-thank-you .video-container .video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}
.template-thank-you .video-container .video-background video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transform: translate(-50%, -50%);
}
@media (max-width: 576px) {
  .template-thank-you .breadcrumb-site .items-breadcrumb {
    line-height: 2.5em;
  }
}

.page .site-main > .page > .entry-content > .wp-block-cover.alignfull {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.page .site-main > .page > .entry-content > .wp-block-cover.alignfull > .wp-block-cover__inner-container {
  max-width: 1370px;
  padding: 0 25px;
}
@media (max-width: 767px) {
  .page .site-main > .page > .entry-content > .wp-block-cover.alignfull > .wp-block-cover__inner-container {
    padding: 0 30px;
  }
}

/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
 */
/* 
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap'); */
.content-buttons {
  display: flex;
  gap: 26px;
  flex-wrap: wrap;
}
.content-buttons.buttons-justification-star {
  justify-content: flex-start;
}
.content-buttons.buttons-justification-center {
  justify-content: center;
}
.content-buttons.buttons-justification-end {
  justify-content: flex-end;
}
.content-buttons.buttons-justification-between {
  justify-content: space-between;
}
@media (max-width: 767px) {
  .content-buttons {
    justify-content: center !important;
  }
}

.wp-block-buttons .wp-block-button:not(.is-style-outline) .wp-block-button__link {
  background-color: #00152F;
  color: #fff;
  border: 0px solid #00152F;
  font-weight: 700;
  border-width: 0px !important;
  border-color: transparent !important;
  position: relative;
}
.wp-block-buttons .wp-block-button:not(.is-style-outline) .wp-block-button__link:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: initial;
  border-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  border: 2px solid transparent;
}
.wp-block-buttons .wp-block-button:not(.is-style-outline) .wp-block-button__link:hover {
  color: #00152F;
  border-color: initial !important;
  background-color: transparent;
  border-width: 0px !important;
}
.wp-block-buttons .wp-block-button:not(.is-style-outline) .wp-block-button__link:hover:before {
  border-radius: inherit;
  border-color: initial;
}
.wp-block-buttons .wp-block-button.is-style-outline .wp-block-button__link {
  border-color: initial !important;
  border-width: 2px !important;
  font-weight: 600;
  color: #00152F;
  border-color: initial;
}
.wp-block-buttons .wp-block-button.is-style-outline .wp-block-button__link::before {
  content: none;
}
.wp-block-buttons .wp-block-button.is-style-outline .wp-block-button__link:hover {
  color: #fff;
  background-color: #00152F;
  border-color: initial;
}

.wp-block-button__link {
  background-color: #00152F;
  color: #fff;
  border: 2px solid #00152F;
  font-weight: 700;
  border-color: initial !important;
}
.wp-block-button__link:hover {
  color: #00152F;
  border-color: #00152F;
  background-color: transparent;
}

.btn, .woocommerce-notices-wrapper .woocommerce-message a.button, .woocommerce-info a.button, .wpcf7 input[type=submit] {
  font-size: 16px;
  letter-spacing: 0;
  padding: 17.5px 39px;
  font-weight: 700;
  border-radius: 100px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5em;
  border-width: 2px;
}
.btn.btn-primary, .woocommerce-notices-wrapper .woocommerce-message a.btn-primary.button, .woocommerce-info a.btn-primary.button, .wpcf7 input.btn-primary[type=submit] {
  background-color: #00152F;
  color: #fff;
  border: 2px solid #00152F;
  font-weight: 700;
}
.btn.btn-primary:hover, .woocommerce-notices-wrapper .woocommerce-message a.btn-primary.button:hover, .woocommerce-info a.btn-primary.button:hover, .wpcf7 input.btn-primary[type=submit]:hover {
  color: #00152F;
  border-color: #00152F;
  background-color: transparent;
}
.btn.btn-primary.btn-outline, .woocommerce-notices-wrapper .woocommerce-message a.btn-primary.btn-outline.button, .woocommerce-info a.btn-primary.btn-outline.button, .wpcf7 input.btn-primary.btn-outline[type=submit] {
  font-weight: 600;
  color: #00152F;
  border-color: #00152F;
  background-color: transparent;
}
.btn.btn-primary.btn-outline:hover, .woocommerce-notices-wrapper .woocommerce-message a.btn-primary.btn-outline.button:hover, .woocommerce-info a.btn-primary.btn-outline.button:hover, .wpcf7 input.btn-primary.btn-outline[type=submit]:hover {
  color: #fff;
  background-color: #00152F;
}
.btn.btn-outline-primary, .woocommerce-notices-wrapper .woocommerce-message a.btn-outline-primary.button, .woocommerce-info a.btn-outline-primary.button, .wpcf7 input[type=submit] {
  font-weight: 600;
  color: #00152F;
  border-color: #00152F;
  background-color: transparent;
}
.btn.btn-outline-primary:hover, .woocommerce-notices-wrapper .woocommerce-message a.btn-outline-primary.button:hover, .woocommerce-info a.btn-outline-primary.button:hover, .wpcf7 input[type=submit]:hover {
  color: #fff;
  background-color: #00152F;
}
.btn.btn-secondary, .woocommerce-notices-wrapper .woocommerce-message a.btn-secondary.button, .woocommerce-info a.btn-secondary.button, .wpcf7 input.btn-secondary[type=submit] {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}
.btn.btn-secondary:hover, .woocommerce-notices-wrapper .woocommerce-message a.btn-secondary.button:hover, .woocommerce-info a.btn-secondary.button:hover, .wpcf7 input.btn-secondary[type=submit]:hover {
  color: #fff;
  border-color: #fff;
  color: #fff;
}
.btn.btn-secondary.btn-outline, .woocommerce-notices-wrapper .woocommerce-message a.btn-secondary.btn-outline.button, .woocommerce-info a.btn-secondary.btn-outline.button, .wpcf7 input.btn-secondary.btn-outline[type=submit] {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
.btn.btn-secondary.btn-outline:hover, .woocommerce-notices-wrapper .woocommerce-message a.btn-secondary.btn-outline.button:hover, .woocommerce-info a.btn-secondary.btn-outline.button:hover, .wpcf7 input.btn-secondary.btn-outline[type=submit]:hover {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}
.btn.btn-outline-secondary, .woocommerce-notices-wrapper .woocommerce-message a.btn-outline-secondary.button, .woocommerce-info a.btn-outline-secondary.button, .wpcf7 input.btn-outline-secondary[type=submit] {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
.btn.btn-outline-secondary:hover, .woocommerce-notices-wrapper .woocommerce-message a.btn-outline-secondary.button:hover, .woocommerce-info a.btn-outline-secondary.button:hover, .wpcf7 input.btn-outline-secondary[type=submit]:hover {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}
.btn.btn-link, .woocommerce-notices-wrapper .woocommerce-message a.btn-link.button, .woocommerce-info a.btn-link.button, .wpcf7 input.btn-link[type=submit] {
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  color: #00152F;
  display: inline-block;
  padding: 0;
  text-decoration: none;
  font-weight: 600;
  border: 0;
}
.btn.btn-link:after, .woocommerce-notices-wrapper .woocommerce-message a.btn-link.button:after, .woocommerce-info a.btn-link.button:after, .wpcf7 input.btn-link[type=submit]:after {
  content: "";
  background-image: url(../img/arrow-right.svg);
  display: inline-block;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin-left: 3px;
}
.btn.btn-link:hover, .woocommerce-notices-wrapper .woocommerce-message a.btn-link.button:hover, .woocommerce-info a.btn-link.button:hover, .wpcf7 input.btn-link[type=submit]:hover {
  color: #00152F;
  text-decoration: underline;
}
.btn.btn-link-white, .woocommerce-notices-wrapper .woocommerce-message a.btn-link-white.button, .woocommerce-info a.btn-link-white.button, .wpcf7 input.btn-link-white[type=submit] {
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  color: #fff;
  display: inline-block;
  padding: 0;
  text-decoration: none;
  font-weight: 600;
  border: 0;
}
.btn.btn-link-white:after, .woocommerce-notices-wrapper .woocommerce-message a.btn-link-white.button:after, .woocommerce-info a.btn-link-white.button:after, .wpcf7 input.btn-link-white[type=submit]:after {
  content: "";
  background-image: url(../img/arrow-right-white.svg);
  display: inline-block;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin-left: 3px;
}
.btn.btn-link-white:hover, .woocommerce-notices-wrapper .woocommerce-message a.btn-link-white.button:hover, .woocommerce-info a.btn-link-white.button:hover, .wpcf7 input.btn-link-white[type=submit]:hover {
  color: #fff;
  text-decoration: underline;
}
@media (max-width: 767px) {
  .btn, .woocommerce-notices-wrapper .woocommerce-message a.button, .woocommerce-info a.button, .wpcf7 input[type=submit] {
    padding: 16px 36px;
    letter-spacing: 0;
  }
}

.btn-site {
  font-size: 16px;
  letter-spacing: 0;
  padding: 20px 39px;
  font-weight: 700;
  border-radius: 100px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5em;
  text-decoration: none;
  background-color: #00152F;
  color: #fff;
  border: 2px solid #00152F;
  font-weight: 700;
  /*  &:hover{
       background: transparent;
       color: $black;
   } */
}
.btn-site:hover {
  color: #00152F;
  border-color: #00152F;
  background-color: transparent;
}
.btn-site.btn-outline {
  font-weight: 600;
  color: #00152F;
  border-color: #00152F;
  background-color: transparent;
}
.btn-site.btn-outline:hover {
  color: #fff;
  background-color: #00152F;
}

/* .btn-link{
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    color:  $green;
    display: inline-block;
    padding: 0;
    text-decoration: none;
    &:after{
        content: '';
        background-image: url(../img/arrow-right.svg);
        display: inline-block;
        width: 24px;
        height: 24px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        vertical-align: middle;
        margin-left: 3px;
    }
    &:hover{
        color:  $green;
        text-decoration: underline;
    }

} */
.content-form-gravityform {
  position: relative;
}
.content-form-gravityform .gform_wrapper.gravity-theme input[type=color],
.content-form-gravityform .gform_wrapper.gravity-theme input[type=date],
.content-form-gravityform .gform_wrapper.gravity-theme input[type=datetime-local],
.content-form-gravityform .gform_wrapper.gravity-theme input[type=datetime],
.content-form-gravityform .gform_wrapper.gravity-theme input[type=email],
.content-form-gravityform .gform_wrapper.gravity-theme input[type=month],
.content-form-gravityform .gform_wrapper.gravity-theme input[type=number],
.content-form-gravityform .gform_wrapper.gravity-theme input[type=password],
.content-form-gravityform .gform_wrapper.gravity-theme input[type=search],
.content-form-gravityform .gform_wrapper.gravity-theme input[type=tel],
.content-form-gravityform .gform_wrapper.gravity-theme input[type=text],
.content-form-gravityform .gform_wrapper.gravity-theme input[type=time],
.content-form-gravityform .gform_wrapper.gravity-theme input[type=url],
.content-form-gravityform .gform_wrapper.gravity-theme input[type=week],
.content-form-gravityform .gform_wrapper.gravity-theme select {
  background: #fff 0 0 no-repeat padding-box;
  border-radius: 35px;
  opacity: 1;
  color: #00152F;
  border: 0;
  padding: 22px 280px 22px 39px;
  font-size: 18px;
  line-height: normal;
  font-weight: 500;
  height: 68.67px;
  min-height: 68.67px;
}
.content-form-gravityform .gform_wrapper.gravity-theme input[type=color]::-moz-placeholder, .content-form-gravityform .gform_wrapper.gravity-theme input[type=date]::-moz-placeholder, .content-form-gravityform .gform_wrapper.gravity-theme input[type=datetime-local]::-moz-placeholder, .content-form-gravityform .gform_wrapper.gravity-theme input[type=datetime]::-moz-placeholder, .content-form-gravityform .gform_wrapper.gravity-theme input[type=email]::-moz-placeholder, .content-form-gravityform .gform_wrapper.gravity-theme input[type=month]::-moz-placeholder, .content-form-gravityform .gform_wrapper.gravity-theme input[type=number]::-moz-placeholder, .content-form-gravityform .gform_wrapper.gravity-theme input[type=password]::-moz-placeholder, .content-form-gravityform .gform_wrapper.gravity-theme input[type=search]::-moz-placeholder, .content-form-gravityform .gform_wrapper.gravity-theme input[type=tel]::-moz-placeholder, .content-form-gravityform .gform_wrapper.gravity-theme input[type=text]::-moz-placeholder, .content-form-gravityform .gform_wrapper.gravity-theme input[type=time]::-moz-placeholder, .content-form-gravityform .gform_wrapper.gravity-theme input[type=url]::-moz-placeholder, .content-form-gravityform .gform_wrapper.gravity-theme input[type=week]::-moz-placeholder, .content-form-gravityform .gform_wrapper.gravity-theme select::-moz-placeholder {
  color: #00152F;
}
.content-form-gravityform .gform_wrapper.gravity-theme input[type=color]::placeholder,
.content-form-gravityform .gform_wrapper.gravity-theme input[type=date]::placeholder,
.content-form-gravityform .gform_wrapper.gravity-theme input[type=datetime-local]::placeholder,
.content-form-gravityform .gform_wrapper.gravity-theme input[type=datetime]::placeholder,
.content-form-gravityform .gform_wrapper.gravity-theme input[type=email]::placeholder,
.content-form-gravityform .gform_wrapper.gravity-theme input[type=month]::placeholder,
.content-form-gravityform .gform_wrapper.gravity-theme input[type=number]::placeholder,
.content-form-gravityform .gform_wrapper.gravity-theme input[type=password]::placeholder,
.content-form-gravityform .gform_wrapper.gravity-theme input[type=search]::placeholder,
.content-form-gravityform .gform_wrapper.gravity-theme input[type=tel]::placeholder,
.content-form-gravityform .gform_wrapper.gravity-theme input[type=text]::placeholder,
.content-form-gravityform .gform_wrapper.gravity-theme input[type=time]::placeholder,
.content-form-gravityform .gform_wrapper.gravity-theme input[type=url]::placeholder,
.content-form-gravityform .gform_wrapper.gravity-theme input[type=week]::placeholder,
.content-form-gravityform .gform_wrapper.gravity-theme select::placeholder {
  color: #00152F;
}
.content-form-gravityform .gform_validation_errors {
  display: none;
}
.content-form-gravityform .gform_wrapper.gravity-theme #field_submit input,
.content-form-gravityform .gform_wrapper.gravity-theme .gform_footer input {
  font-size: 16px;
  letter-spacing: 0;
  padding: 22px 39px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  line-height: 1.5em;
  border-width: 2px;
  background-color: #00152F;
  color: #fff;
  border: 1px solid #00152F;
  font-weight: 700;
  position: absolute;
  top: 0;
  right: -1px;
  min-width: 275px;
  border-radius: 35px;
  min-height: 68.67px;
}
.content-form-gravityform .gform_wrapper.gravity-theme #field_submit input:hover,
.content-form-gravityform .gform_wrapper.gravity-theme .gform_footer input:hover {
  color: #00152F;
  border-color: #00152F;
  background-color: transparent;
}
.content-form-gravityform .gform_wrapper.gravity-theme .gfield-choice-input {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  accent-color: #00152F;
  margin-right: 11px;
}
.content-form-gravityform .gform_wrapper.gravity-theme .ginput_container_checkbox label {
  font-size: 16px;
  font-weight: normal;
}
.content-form-gravityform .gform_wrapper.gravity-theme .ginput_container_checkbox label a {
  font-size: initial;
  text-underline-offset: 5px;
}
.content-form-gravityform .gform_submission_error {
  display: none;
}
.content-form-gravityform .gform_wrapper.gravity-theme .gform_footer, .content-form-gravityform .gform_wrapper.gravity-theme .gform_page_footer {
  margin: 0px 0 0;
  padding: 0px 0;
}
.content-form-gravityform .content-form-gravityform .gform_wrapper.gravity-theme .ginput_container_checkbox label {
  vertical-align: text-top;
}
.content-form-gravityform .gform_wrapper.gravity-theme .gfield-choice-input + label {
  max-width: calc(100% - 43px);
  vertical-align: text-top;
}
@media (max-width: 992px) {
  .content-form-gravityform .gform_wrapper.gravity-theme .gfield-choice-input + label {
    max-width: 100%;
  }
}
.content-form-gravityform .gfield_checkbox .gchoice {
  display: flex;
  align-items: flex-start;
}
.content-form-gravityform .gfield_checkbox .gchoice label {
  position: relative;
  top: -3px;
}
@media (max-width: 991px) {
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=color],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=date],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=datetime-local],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=datetime],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=email],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=month],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=number],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=password],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=search],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=tel],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=text],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=time],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=url],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=week],
  .content-form-gravityform .gform_wrapper.gravity-theme select {
    padding: 10px 219px 10px 39px;
    min-height: 60px;
    height: 60px;
  }
  .content-form-gravityform .gform_wrapper.gravity-theme #field_submit input,
  .content-form-gravityform .gform_wrapper.gravity-theme .gform_footer input {
    min-width: 30px;
    min-height: 60px;
    height: 60px;
    padding: 14px 39px;
  }
  .content-form-gravityform .gfield_checkbox .gchoice {
    display: flex;
    align-items: flex-start;
  }
  .content-form-gravityform .gfield_checkbox .gchoice label {
    position: relative;
    top: -3px;
  }
}
@media (max-width: 575px) {
  .content-form-gravityform .form-subscribe-footer .gform-body .gform_fields .gfield .ginput_container input {
    padding: 10px 20px;
  }
}
@media (max-width: 575px) {
  .content-form-gravityform .gform_wrapper.gravity-theme #field_submit input,
  .content-form-gravityform .gform_wrapper.gravity-theme .gform_footer input {
    min-width: 30px;
    position: relative;
  }
  .content-form-gravityform .gform_wrapper.gravity-theme .gform_footer {
    justify-content: center;
  }
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=color],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=date],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=datetime-local],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=datetime],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=email],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=month],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=number],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=password],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=search],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=tel],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=text],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=time],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=url],
  .content-form-gravityform .gform_wrapper.gravity-theme input[type=week],
  .content-form-gravityform .gform_wrapper.gravity-theme select {
    padding: 10px 20px;
    min-height: 60px;
    height: 60px;
  }
  .content-form-gravityform .gform_wrapper.gravity-theme .gform_footer,
  .content-form-gravityform .gform_wrapper.gravity-theme .gform_page_footer {
    margin: 6px 0 0;
    padding: 16px 0;
  }
}

button:focus-visible, button:focus {
  outline: -webkit-focus-ring-color auto 1px;
}

.slick-dots {
  position: relative !important;
  display: flex !important;
  width: 100% !important;
}
.slick-dots li {
  flex: auto;
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
}
.slick-dots li button {
  width: 100% !important;
  height: 3px !important;
  padding: 0 !important;
  position: relative;
}
.slick-dots li button:focus-visible, .slick-dots li button:focus {
  outline: -webkit-focus-ring-color auto 1px !important;
}
.slick-dots li button:before {
  content: "" !important;
  width: 100% !important;
  height: 100% !important;
  background: white;
}
.slick-dots li.slick-active button:before {
  opacity: 1 !important;
}

.slick-next:focus-visible,
.slick-prev:focus-visible {
  outline: -webkit-focus-ring-color auto 1px !important;
}

.badge {
  font-size: 16px;
  border-radius: 5px;
  line-height: 1.25em;
  padding: 2.5px 15px;
  font-weight: 500;
  letter-spacing: 0.8px;
  font-family: "Poppins", sans-serif;
  white-space: initial;
  text-align: left;
}
.badge.badge-primary {
  background-color: #00152F;
  color: #fff;
}

.breadcrumb-site {
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .breadcrumb-site {
    margin-bottom: 30px;
  }
}

.items-breadcrumb {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.625em;
}
.items-breadcrumb a {
  font-weight: normal;
  text-decoration: none;
  color: #00152F;
  text-decoration-line: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 7px;
}
.items-breadcrumb .current-item {
  font-weight: 400;
  border-bottom: none;
}
.items-breadcrumb a,
.items-breadcrumb .current-item {
  font-size: 16px;
  line-height: 1.625em;
  color: #00152F;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.items-breadcrumb > span {
  margin-right: 20px;
  display: inline-block;
  margin-bottom: 5px;
}
.items-breadcrumb .fa {
  margin-right: 20px;
}
@media (max-width: 991px) {
  .items-breadcrumb a {
    text-underline-offset: 3px;
  }
}
@media (max-width: 767px) {
  .items-breadcrumb {
    font-size: 14px;
    line-height: 1.225em;
  }
  .items-breadcrumb a,
  .items-breadcrumb .current-item {
    font-size: 14px;
    line-height: 1.225em;
  }
}

.hero-home {
  /*    &.before-element{
         &:before {
             content: '';
             position: absolute;
             bottom: 0;
             left: 0;
             width: 100%;
             height: 140px;
             z-index: 1;
             background: linear-gradient(0deg, #fdfaf3  0%, rgba(255, 255, 255, 0) 100%);
         }
     } */
  /*    .video-container{
         opacity: 0.2;
     } */
  /*    .overlay-content{
          opacity: 0 !important; 
         background: url('../assets/img/overlay-hero-home.png') !important;
         background-size: cover !important;
         background-position: 50% 100% !important;
     } */
}
.hero-home .content-hero {
  display: flex;
  min-height: 674px;
  align-items: center;
  flex-wrap: wrap;
}
.hero-home .content-text {
  max-width: 49.1%;
  padding: 140px 0;
}
.hero-home .content-text h1, .hero-home .content-text .h1 {
  margin-bottom: 20px;
  font-size: 60px;
  letter-spacing: -0.6px;
}
.hero-home .content-text p {
  font-size: 22px;
}
.hero-home .content-text .content-buttons {
  margin-top: 60px;
}
.hero-home .content-img-cards {
  width: 78.8%;
  position: absolute;
  top: 0;
  right: -31%;
  padding-top: 110px;
  padding-bottom: 50px;
  height: 675px;
  z-index: 5;
}
@media (min-width: 1520px) {
  .hero-home .content-img-cards {
    right: -35%;
  }
}
.hero-home .content-img-cards img {
  display: block;
  width: 85%;
  margin-left: auto;
  max-height: 515px;
  box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.1490196078);
  border-radius: 15px;
}
.hero-home .content-img-cards .card-stat {
  position: absolute;
  opacity: 0;
  animation: fadeIn_bx 2s linear 1 forwards;
}
.hero-home .content-img-cards .card-1 {
  top: 0;
  left: 30%;
  animation-delay: 0.5s;
}
.hero-home .content-img-cards .card-1.aos-animate h3, .hero-home .content-img-cards .card-1.aos-animate .h3 {
  opacity: 1;
  animation: fadeIn_bx 0.5s linear 1;
}
.hero-home .content-img-cards .card-1.aos-animate p {
  opacity: 1;
  animation: fadeIn_bx 0.5s linear 1;
}
.hero-home .content-img-cards .card-2 {
  top: 30%;
  animation-delay: 1s;
}
.hero-home .content-img-cards .card-2.aos-animate h3, .hero-home .content-img-cards .card-2.aos-animate .h3 {
  opacity: 1;
  animation: fadeIn_bx 0.5s linear 1;
}
.hero-home .content-img-cards .card-2.aos-animate p {
  opacity: 1;
  animation: fadeIn_bx 0.5s linear 1;
}
.hero-home .content-img-cards .card-3 {
  bottom: 0;
  left: 25%;
  animation-delay: 1.5s;
}
.hero-home .content-img-cards .card-3.aos-animate h3, .hero-home .content-img-cards .card-3.aos-animate .h3 {
  opacity: 1;
  animation: fadeIn_bx 0.5s linear 1;
}
.hero-home .content-img-cards .card-3.aos-animate p {
  opacity: 1;
  animation: fadeIn_bx 0.5s linear 1;
}
@media (max-width: 1024px) {
  .hero-home {
    padding: 90px 0 !important;
  }
  .hero-home .content-text {
    max-width: 100%;
    padding: 0px 0 60px;
  }
  .hero-home .content-img-cards {
    width: 100%;
    position: relative;
    top: 0;
    right: 0;
    padding-top: 120px;
    padding-bottom: 125px;
    height: auto;
    min-height: 525px;
  }
}
@media (max-width: 767px) {
  .hero-home .content-text h1, .hero-home .content-text .h1 {
    font-size: 40px;
  }
  .hero-home .content-text p {
    font-size: 17px;
  }
  .hero-home .content-text .content-buttons {
    margin-top: 40px;
  }
}
@media (min-width: 631px) {
  .hero-home .img-mobile-hero {
    display: none !important;
  }
}
@media (max-width: 630px) {
  .hero-home .img-desktop-hero {
    display: none !important;
  }
  .hero-home .content-img-cards {
    padding: 0;
    min-height: initial;
  }
  .hero-home .content-img-cards .card-stat {
    position: initial;
    margin-bottom: 25px;
  }
  .hero-home .content-img-cards .card-stat:last-child {
    margin: 0;
  }
}

.general-section .card-stat {
  background: transparent linear-gradient(302deg, #0167A6 0%, #57D19E 100%) 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.1490196078);
  border-radius: 15px;
  padding: 40px;
  display: inline-block;
}
.general-section .card-stat h3, .general-section .card-stat .h3 {
  color: #fff !important;
  font-size: 40px;
  letter-spacing: -1.2px;
  font-weight: 800;
}
.general-section .card-stat p {
  margin-bottom: 0;
  color: #fff !important;
  max-width: 238px;
  font-size: 16px;
}
.general-section .card-stat.card-wide {
  display: flex;
  -moz-column-gap: 20px;
  column-gap: 20px;
  max-width: 342px;
}
.general-section .card-stat.card-wide h3, .general-section .card-stat.card-wide .h3 {
  margin-bottom: 0;
}
.general-section .card-stat.card-wide p {
  max-width: 141px;
}
@media (max-width: 991px) {
  .general-section .card-stat {
    padding: 30px;
  }
  .general-section .card-stat h3, .general-section .card-stat .h3 {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .general-section .card-stat {
    padding: 25px;
  }
  .general-section .card-stat h3, .general-section .card-stat .h3 {
    font-size: 25px;
  }
}

@keyframes fadeIn_bx {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.video-block::before {
  content: "";
  position: absolute;
  background: url("../assets/img/bg-gradient.png");
  background-size: cover;
  background-position: 50% 0%;
  width: 100%;
  height: 75%;
  left: 0;
  bottom: 13%;
}
.video-block .content-title p {
  font-size: 22px;
}
.video-block .content-title .content-buttons {
  margin-top: clamp(15px, 3vw, 35px);
}
.video-block .content-video {
  max-width: 963px;
  margin: 70px auto 0;
}
.video-block .content-video video {
  width: 100%;
  box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.1490196078);
  border-radius: 20px;
  box-shadow: 0 40px 19px rgb(238, 239, 240);
}
@media (max-width: 991px) {
  .video-block .content-video video {
    box-shadow: none;
  }
}
.video-block .content-video iframe {
  width: 100%;
  min-height: 560px;
  box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.1490196078);
  border-radius: 20px;
  box-shadow: 0 40px 19px rgb(238, 239, 240);
}
@media (max-width: 991px) {
  .video-block .content-video iframe {
    box-shadow: none;
  }
}
@media (max-width: 767px) {
  .video-block .content-title p {
    font-size: 17px;
  }
  .video-block .content-video {
    margin-top: 40px;
  }
  .video-block .content-video iframe {
    min-height: initial;
  }
}

@media (min-width: 1200px) {
  .tabs-two-columns .row {
    --bs-gutter-x: 150px;
  }
}
.tabs-two-columns .container::before {
  content: "";
  position: absolute;
  width: 65%;
  height: 75%;
  bottom: 1%;
  left: 0;
  background: url(../assets/img/bg-tabs.png);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}
.tabs-two-columns .content-title {
  margin-bottom: 70px;
}
.tabs-two-columns .content-title p:last-child {
  margin-bottom: 0;
}
.tabs-two-columns .content-title-tabs {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 25px;
  column-gap: 25px;
  row-gap: 25px;
  margin-bottom: 40px;
}
.tabs-two-columns .content-title-tabs .tab-title {
  padding: 15px 50px;
  background: #00152F;
  border: 2px solid #00152F;
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.tabs-two-columns .content-title-tabs .tab-title:hover {
  background: transparent;
  color: #00152F;
}
.tabs-two-columns .content-title-tabs .tab-title.active {
  background: transparent;
  color: #00152F;
}
.tabs-two-columns .tab-content.active-efect-item .women-efect-items .content-line-row .line-row-ma {
  transform: translate(0, 0) rotate(0deg);
  opacity: 1;
}
.tabs-two-columns .tab-content.active-efect-item .women-efect-items .dot-icon-circle.dot-2-icon {
  opacity: 0;
}
.tabs-two-columns .tab-content.active-efect-item .women-efect-items .price-current.price-normal-1 {
  opacity: 0;
}
.tabs-two-columns .tab-content.active-efect-item .women-efect-items .price-current.price-hover-1 {
  opacity: 1;
}
.tabs-two-columns .tab-content .women-efect-items {
  position: relative;
  margin-left: 146px;
  transition: all ease 0.4s;
}
@media (max-width: 1199px) {
  .tabs-two-columns .tab-content .women-efect-items {
    top: 0;
    left: 0;
  }
}
.tabs-two-columns .tab-content .women-efect-items:after {
  content: "";
  background: transparent linear-gradient(0deg, #FFC72C 0, #fdfaf3 100%) 0 0 no-repeat padding-box;
  opacity: 0.15;
  filter: blur(50px);
  position: absolute;
  min-width: 696px;
  min-height: 536px;
  z-index: -1;
  top: -203px;
  left: -146px;
}
.tabs-two-columns .tab-content .women-efect-items .border-center-women {
  position: relative;
}
.tabs-two-columns .tab-content .women-efect-items .border-center-women .border-center-curve {
  max-width: 331px;
  min-width: 331px;
  position: relative;
  z-index: 1;
}
.tabs-two-columns .tab-content .women-efect-items .border-center-women .center-women {
  max-width: 395px;
  min-width: 395px;
  -o-object-fit: contain;
  object-fit: contain;
  position: absolute;
  left: -105px;
  bottom: 0;
  z-index: 1;
}
.tabs-two-columns .tab-content .women-efect-items .apple-women {
  min-width: 550px;
  max-width: 550px;
  position: absolute;
  left: -105px;
  z-index: 1;
  top: 18px;
  width: 100%;
}
@media (max-width: 1199px) {
  .tabs-two-columns .tab-content .women-efect-items .apple-women {
    min-width: 496px;
  }
}
@media (min-width: 992px) and (max-width: 1144px) {
  .tabs-two-columns .tab-content .women-efect-items .apple-women {
    min-width: 411px;
    top: 11px;
  }
}
.tabs-two-columns .tab-content .women-efect-items .price-current {
  color: #fff;
  font-size: 20px;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  position: absolute;
  left: 256px;
  top: 174px;
  z-index: 1;
  transition: all ease 0.4s;
}
@media (max-width: 1199px) {
  .tabs-two-columns .tab-content .women-efect-items .price-current {
    top: 138px;
    left: 232px;
  }
}
@media (min-width: 992px) and (max-width: 1144px) {
  .tabs-two-columns .tab-content .women-efect-items .price-current {
    display: none;
  }
}
.tabs-two-columns .tab-content .women-efect-items .price-current.price-normal-1 {
  opacity: 1;
}
.tabs-two-columns .tab-content .women-efect-items .price-current.price-hover-1 {
  opacity: 0;
}
.tabs-two-columns .tab-content .women-efect-items .content-line-row {
  max-width: 153px;
  min-width: 153px;
  position: absolute;
  left: 252px;
  top: 216px;
  z-index: 1;
}
@media (max-width: 1199px) {
  .tabs-two-columns .tab-content .women-efect-items .content-line-row {
    top: 188px;
    left: 232px;
  }
}
@media (max-width: 1144px) {
  .tabs-two-columns .tab-content .women-efect-items .content-line-row {
    top: 188px;
    left: 232px;
  }
}
@media (min-width: 992px) and (max-width: 1144px) {
  .tabs-two-columns .tab-content .women-efect-items .content-line-row {
    top: 155px;
    left: 194px;
  }
}
.tabs-two-columns .tab-content .women-efect-items .content-line-row .line-row-dots {
  width: 100%;
}
.tabs-two-columns .tab-content .women-efect-items .content-line-row .line-row-ma {
  min-width: 104px;
  max-width: 104px;
  -o-object-fit: contain;
  object-fit: contain;
  position: absolute;
  left: -25px;
  top: -11px;
  transform: translate(-54px, 0) rotate(0deg);
  opacity: 0;
  transition: all ease 0.4s;
  z-index: 1;
}
.tabs-two-columns .tab-content .women-efect-items .dot-icon-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 100px;
  border: 4px solid #000;
}
.tabs-two-columns .tab-content .women-efect-items .dot-icon-circle.dot-1-icon {
  position: absolute;
  top: -5px;
  left: 94px;
}
.tabs-two-columns .tab-content .women-efect-items .dot-icon-circle.dot-2-icon {
  position: absolute;
  bottom: -6px;
  left: 6px;
  opacity: 1;
  transition: all ease 0.2s;
}
.tabs-two-columns .tab-content .women-efect-items:hover .content-line-row .line-row-ma {
  transform: translate(0, 0) rotate(0deg);
  opacity: 1;
}
.tabs-two-columns .tab-content .women-efect-items:hover .dot-icon-circle.dot-2-icon {
  opacity: 0;
}
.tabs-two-columns .tab-content .women-efect-items:hover .price-current.price-normal-1 {
  opacity: 0;
}
.tabs-two-columns .tab-content .women-efect-items:hover .price-current.price-hover-1 {
  opacity: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .tabs-two-columns .tab-content .women-efect-items {
    position: relative;
    padding-bottom: 50%;
    margin-top: 0;
  }
  .tabs-two-columns .tab-content .women-efect-items .border-center-category-m {
    position: absolute;
    height: 100%;
  }
  .tabs-two-columns .tab-content .women-efect-items .border-center-women .border-center-curve {
    position: absolute;
    bottom: 0;
  }
  .tabs-two-columns .tab-content .women-efect-items .border-center-women {
    position: initial;
  }
  .tabs-two-columns .tab-content .women-efect-items .border-center-women .center-women {
    width: 90%;
    min-width: 10px;
  }
  .tabs-two-columns .tab-content .women-efect-items .border-center-women .border-center-curve {
    position: absolute;
    bottom: 0;
    min-width: 10px;
    width: 73%;
  }
  .tabs-two-columns .tab-content .women-efect-items .tabs-two-columns .women-efect-items .apple-women {
    position: absolute;
    left: -105px;
    z-index: 1;
    top: 18px;
    width: 100%;
    min-width: 100px;
    max-width: calc(100% + 77px);
    width: calc(100% + 77px);
  }
  .tabs-two-columns .tab-content .women-efect-items .tabs-two-columns .women-efect-items .content-line-row {
    max-width: 153px;
    min-width: 10px;
    position: absolute;
    left: auto;
    top: 216px;
    z-index: 1;
    right: 0;
    transform: scale(0.8);
  }
}
@media (max-width: 649px) {
  .tabs-two-columns .tab-content .women-efect-items {
    position: relative;
    padding-bottom: 50%;
    margin-top: 0;
  }
  .tabs-two-columns .tab-content .women-efect-items .border-center-category-m {
    position: absolute;
    height: 100%;
  }
  .tabs-two-columns .tab-content .women-efect-items .border-center-women {
    position: initial;
  }
  .tabs-two-columns .tab-content .women-efect-items .border-center-women .border-center-curve {
    position: absolute;
    bottom: 0;
    min-width: 10px;
    width: 73%;
  }
  .tabs-two-columns .tab-content .women-efect-items .apple-women {
    position: absolute;
    left: -120px;
    z-index: 1;
    top: 18px;
    width: 100%;
    min-width: 20px;
    max-width: calc(100% + 146px);
    width: calc(100% + 100px);
  }
  .tabs-two-columns .tab-content .women-efect-items .price-current {
    left: 55%;
  }
  .tabs-two-columns .tab-content .women-efect-items .content-line-row {
    max-width: 153px;
    min-width: 153px;
    position: absolute;
    left: 243px;
    top: 200px;
    z-index: 1;
    right: 0;
    bottom: 12px;
  }
  .tabs-two-columns .tab-content .women-efect-items .border-center-women .center-women {
    -o-object-fit: contain;
    object-fit: contain;
    position: absolute;
    left: -105px;
    bottom: 0;
    z-index: 1;
    min-width: 10px;
    max-width: 100%;
    height: 100%;
    -o-object-position: bottom;
    object-position: bottom;
    height: calc(100% + 36px);
  }
}
@media (max-width: 576px) {
  .tabs-two-columns .tab-content .women-efect-items .content-line-row {
    top: 60%;
    left: 55%;
  }
  .tabs-two-columns .tab-content .women-efect-items .apple-women {
    left: -70px;
    top: 12px;
    width: calc(100% + 24px);
  }
  .tabs-two-columns .tab-content .women-efect-items .price-current {
    top: 45%;
  }
}
@media (max-width: 496px) {
  .tabs-two-columns .tab-content .women-efect-items {
    margin-left: 70px;
  }
  .tabs-two-columns .tab-content .women-efect-items .content-line-row {
    transform: scale(0.7);
    top: 55%;
    left: 45%;
  }
  .tabs-two-columns .tab-content .women-efect-items .price-current {
    left: 50%;
    top: 45%;
    font-size: 16px;
  }
  .tabs-two-columns .tab-content .women-efect-items .women-efect-items {
    margin-left: 70px;
  }
  .tabs-two-columns .tab-content .women-efect-items .apple-women {
    max-width: calc(100% + 70px);
    width: calc(100% + 10px);
    left: -40px;
    top: 0;
  }
  .tabs-two-columns .tab-content .women-efect-items .border-center-women .border-center-curve {
    position: absolute;
    bottom: 0;
    min-width: 10px;
    width: 93%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }
}
@media (max-width: 370px) {
  .tabs-two-columns .tab-content .women-efect-items .content-line-row {
    transform: scale(0.5);
    top: 55%;
    left: 35%;
  }
  .tabs-two-columns .tab-content .women-efect-items .border-center-women .border-center-curve {
    width: 72%;
  }
  .tabs-two-columns .tab-content .women-efect-items .apple-women {
    width: calc(100% + 50px);
    left: -80px;
    top: -20px;
  }
}
.tabs-two-columns .tab-content.active-efect-item .merch-guy-efect-items .border-center-merch-guy .box-dot-efect .box-ixon-tb2 svg path {
  fill: #fff;
}
.tabs-two-columns .tab-content.active-efect-item .merch-guy-efect-items .border-center-merch-guy .box-dot-efect .box-ixon-tb2.box-icon-1 {
  background-color: #FFC72C;
  border-width: 0;
}
.tabs-two-columns .tab-content.active-efect-item .merch-guy-efect-items .border-center-merch-guy .box-dot-efect .box-ixon-tb2.box-icon-2 {
  bottom: 95px;
}
.tabs-two-columns .tab-content.active-efect-item .merch-guy-efect-items .border-center-merch-guy .box-dot-efect .hand-icons {
  height: 215px;
  bottom: -6px;
  transform: translate(0, 0) rotate(-20deg);
  left: 69px;
}
.tabs-two-columns .tab-content.active-efect-item .merch-guy-efect-items .border-center-merch-guy .box-dot-efect .hand-icons .hand-icon-hover {
  opacity: 1;
  visibility: visible;
  height: auto;
}
.tabs-two-columns .tab-content.active-efect-item .merch-guy-efect-items .border-center-merch-guy .box-dot-efect .hand-icons .hand-icon-normal {
  opacity: 1;
  visibility: visible;
}
.tabs-two-columns .tab-content .merch-guy-efect-items {
  position: relative;
  margin-left: 146px;
  transition: all ease 0.4s;
  margin-top: 121px;
}
.tabs-two-columns .tab-content .merch-guy-efect-items:after {
  content: "";
  background: transparent linear-gradient(0deg, #FFC72C 0, #FDFAF3 100%) 0 0 no-repeat padding-box;
  opacity: 0.15;
  filter: blur(50px);
  position: absolute;
  min-width: 696px;
  min-height: 536px;
  z-index: -1;
  top: -203px;
  left: -146px;
}
.tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy {
  position: relative;
  max-width: 331px;
  min-width: 331px;
}
.tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .border-center-curve {
  position: relative;
  z-index: 1;
}
.tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .center-merch-guy {
  max-width: 208px;
  min-width: 208px;
  -o-object-fit: contain;
  object-fit: contain;
  position: absolute;
  right: 50px;
  bottom: 0;
  z-index: 2;
  border-radius: 0 0 87px 0;
}
.tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .icon-merch-guy {
  max-width: 458px;
  min-width: 458px;
  -o-object-fit: contain;
  object-fit: contain;
  position: absolute;
  top: -31px;
  left: -68px;
  z-index: 1;
  mix-blend-mode: multiply;
}
.tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .box-dot-efect {
  position: absolute;
  left: -89px;
  bottom: 0;
}
@media (max-width: 580px) {
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .box-dot-efect {
    transform: scale(0.7);
    left: -65PX;
    z-index: 1;
  }
}
.tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .box-dot-efect .box-ixon-tb2 {
  display: inline-flex;
  width: 57px;
  height: 57px;
  border: 3.2px solid #000;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  z-index: 1;
  position: relative;
}
.tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .box-dot-efect .box-ixon-tb2 svg {
  width: 28px;
}
.tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .box-dot-efect .box-ixon-tb2.box-icon-1 {
  bottom: 34px;
  position: relative;
  z-index: 1;
  transition: all linear 0.3s;
}
.tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .box-dot-efect .box-ixon-tb2.box-icon-2 {
  bottom: 31px;
  position: absolute;
  left: 0;
  bottom: 34px;
  z-index: 0;
  background-color: #FE7045;
  border-color: #FE7045;
  transition: all ease 0.4s;
}
.tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .box-dot-efect .box-ixon-tb2.box-icon-2 svg path {
  fill: #fff;
}
.tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .box-dot-efect .hand-icons {
  position: absolute;
  left: 48px;
  bottom: -83px;
  z-index: 1;
  overflow: hidden;
  height: 129px;
  transition: all linear 0.3s;
  bottom: 0;
  height: 129px;
}
.tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .box-dot-efect .hand-icons img {
  width: 100%;
  display: block;
  transition: all linear 0.3s;
}
.tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .box-dot-efect .hand-icons .hand-icon-hover {
  min-width: 251px;
  max-width: 251px;
  top: 0;
  position: relative;
  left: 0;
  visibility: hidden;
  transition: all linear 1s;
}
.tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .box-dot-efect .hand-icons .hand-icon-normal {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
}
.tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy:hover .box-dot-efect .box-ixon-tb2 svg path {
  fill: #fff;
}
.tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy:hover .box-dot-efect .box-ixon-tb2.box-icon-1 {
  background-color: #FFC72C;
  border-width: 0;
}
.tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy:hover .box-dot-efect .box-ixon-tb2.box-icon-2 {
  bottom: 95px;
}
.tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy:hover .box-dot-efect .hand-icons {
  height: 215px;
  bottom: -6px;
  transform: translate(0, 0) rotate(-20deg);
  left: 69px;
}
.tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy:hover .box-dot-efect .hand-icons .hand-icon-hover {
  opacity: 1;
  visibility: visible;
  height: auto;
}
.tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy:hover .box-dot-efect .hand-icons .hand-icon-normal {
  opacity: 1;
  visibility: visible;
}
@media only screen and (min-width: 992px) and (max-width: 1145px) {
  .tabs-two-columns .tab-content .merch-guy-efect-items {
    position: relative;
    padding-bottom: 50%;
    margin-top: 0;
    margin-left: 106px;
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy {
    position: absolute;
    height: 100%;
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .border-center-curve {
    position: absolute;
    z-index: 1;
    bottom: 0;
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .icon-merch-guy {
    max-width: 458px;
    min-width: 458px;
    -o-object-fit: contain;
    object-fit: contain;
    position: absolute;
    top: -31px;
    left: -68px;
    z-index: 1;
    mix-blend-mode: multiply;
    width: calc(100% + 146px);
    max-width: calc(100% + 146px);
    min-width: 10px;
    bottom: -40px;
    top: auto;
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .border-center-curve {
    position: absolute;
    z-index: 1;
    bottom: 0;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: bottom;
    object-position: bottom;
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .center-merch-guy {
    -o-object-fit: contain;
    object-fit: contain;
    position: absolute;
    right: 50px;
    bottom: 0;
    height: calc(100% + 40px);
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .icon-merch-guy {
    -o-object-fit: contain;
    object-fit: contain;
    position: absolute;
    left: -17px;
    z-index: 1;
    mix-blend-mode: multiply;
    min-width: 10px;
    bottom: -50px;
    top: auto;
    height: calc(100% + 147px);
    width: calc(100% + 47px);
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .tabs-two-columns .merch-guy-efect-items .border-center-merch-guy:hover .box-dot-efect .hand-icons {
    height: 193px;
    bottom: -6px;
  }
}
@media (max-width: 649px) {
  .tabs-two-columns .tab-content .merch-guy-efect-items {
    margin-left: 90px;
    position: relative;
    padding-bottom: 50%;
    margin-top: 0;
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy:hover .box-dot-efect .hand-icons {
    height: 193px;
    bottom: -6px;
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy {
    position: initial;
    height: 100%;
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .border-center-curve {
    position: absolute;
    z-index: 1;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: left bottom;
    object-position: left bottom;
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .icon-merch-guy {
    max-width: 100%;
    min-width: auto;
    width: 88%;
    height: 100%;
    bottom: 0;
    top: auto;
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .center-merch-guy {
    height: 100%;
    right: 50%;
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .border-center-curve {
    height: 93%;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: left bottom;
    object-position: left bottom;
    bottom: 0;
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .box-dot-efect .hand-icons .hand-icon-hover {
    min-width: 225px;
    max-width: 230px;
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .box-dot-efect .hand-icons {
    height: 90px;
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .box-dot-efect .hand-icons .hand-icon-hover {
    min-width: 175px;
    max-width: 174px;
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy:hover .box-dot-efect .hand-icons {
    height: 90px;
    bottom: 0;
    transform: translate(0, 0) rotate(0deg);
    left: 69px;
  }
}
@media (max-width: 576px) {
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .center-merch-guy {
    height: 100%;
    right: 45%;
  }
}
@media (max-width: 449px) {
  .tabs-two-columns .tab-content .merch-guy-efect-items {
    padding-bottom: 60%;
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .center-merch-guy {
    height: 100%;
    right: 35%;
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .icon-merch-guy {
    left: -15%;
    width: calc(100% + 23px);
    max-width: calc(100% + 23px);
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .box-dot-efect {
    transform: scale(0.8);
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .center-merch-guy {
    height: 100%;
    right: 45px;
    bottom: 0;
    max-width: inherit;
    min-width: auto;
  }
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .box-dot-efect {
    transform: scale(0.7);
    z-index: 1;
    bottom: -4px;
  }
}
@media (max-width: 390px) {
  .tabs-two-columns .tab-content .merch-guy-efect-items .border-center-merch-guy .center-merch-guy {
    right: 30px;
  }
}
.tabs-two-columns .tab-content.active-efect-item .category-m-efect-items .border-center-category-m .icon-category-hand {
  opacity: 1;
  right: calc(100% - 94px);
}
@media (max-width: 600px) {
  .tabs-two-columns .tab-content.active-efect-item .category-m-efect-items .border-center-category-m .icon-category-hand {
    right: calc(100% - 62px);
  }
}
@media (max-width: 500px) {
  .tabs-two-columns .tab-content.active-efect-item .category-m-efect-items .border-center-category-m .icon-category-hand {
    right: calc(100% - 50px);
  }
}
@media (max-width: 450px) {
  .tabs-two-columns .tab-content.active-efect-item .category-m-efect-items .border-center-category-m .icon-category-hand {
    right: calc(100% - 70px);
  }
}
.tabs-two-columns .tab-content.active-efect-item .category-m-efect-items .border-center-category-m .chart-box {
  padding: 0;
}
.tabs-two-columns .tab-content.active-efect-item .category-m-efect-items .border-center-category-m .chart-box .inner-img svg .item-line {
  stroke: #fff;
}
.tabs-two-columns .tab-content.active-efect-item .category-m-efect-items .border-center-category-m .chart-box .border-item-1:after {
  transform: translate(50%, 0) rotate(90deg);
}
.tabs-two-columns .tab-content.active-efect-item .category-m-efect-items .border-center-category-m .chart-box .border-item-2 .after-div {
  transform: translate(0, 0) rotate(-47deg);
  opacity: 1;
}
.tabs-two-columns .tab-content.active-efect-item .category-m-efect-items .border-center-category-m .chart-box .border-item-2 .after-div:after {
  opacity: 1;
}
.tabs-two-columns .category-m-efect-items {
  position: relative;
  margin-left: 146px;
  transition: all ease 0.4s;
  margin-top: 96px;
}
.tabs-two-columns .category-m-efect-items:after {
  content: "";
  background: transparent linear-gradient(0deg, #FFC72C 0, #FDFAF3 100%) 0 0 no-repeat padding-box;
  opacity: 0.15;
  filter: blur(50px);
  position: absolute;
  min-width: 696px;
  min-height: 536px;
  z-index: -1;
  top: -203px;
  left: -146px;
}
.tabs-two-columns .category-m-efect-items .border-center-category-m {
  position: relative;
  max-width: 331px;
  min-width: 331px;
}
.tabs-two-columns .category-m-efect-items .border-center-category-m .border-center-curve {
  position: relative;
  z-index: 1;
}
.tabs-two-columns .category-m-efect-items .border-center-category-m .center-category-m {
  max-width: 150px;
  min-width: 150px;
  -o-object-fit: contain;
  object-fit: contain;
  position: absolute;
  right: 43px;
  top: -75px;
  z-index: 2;
}
.tabs-two-columns .category-m-efect-items .border-center-category-m .icon-category-m {
  position: absolute;
  min-width: 546px;
  left: -146px;
  top: -38px;
}
.tabs-two-columns .category-m-efect-items .border-center-category-m .icon-category-hand {
  width: 83px;
  position: absolute;
  top: 0;
  transition: all linear 0.3s;
  right: 60px;
  z-index: 1;
  opacity: 0;
}
@media (max-width: 450px) {
  .tabs-two-columns .category-m-efect-items .border-center-category-m .icon-category-hand {
    top: -10px;
  }
}
.tabs-two-columns .category-m-efect-items .border-center-category-m .chart-box {
  position: absolute;
  width: 142px;
  /*  height: 104px; */
  overflow: hidden;
  padding: 0 16px;
  transition: all linear 0.3s;
  bottom: 113px;
  left: -145px;
  z-index: 1;
}
@media (max-width: 500px) {
  .tabs-two-columns .category-m-efect-items .border-center-category-m .chart-box {
    bottom: 76px;
    left: -130px;
  }
}
.tabs-two-columns .category-m-efect-items .border-center-category-m .chart-box .inner-img {
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}
.tabs-two-columns .category-m-efect-items .border-center-category-m .chart-box .inner-img svg {
  min-width: 142px;
  max-width: 142px;
  position: relative;
  transform: translate(-50%, 0px);
  left: 50%;
  transition: all linear 0s;
}
.tabs-two-columns .category-m-efect-items .border-center-category-m .chart-box .inner-img svg .item-line {
  transition: all ease 0.3s;
  stroke: #000;
}
.tabs-two-columns .category-m-efect-items .border-center-category-m .chart-box .border-item-1 {
  background: #fff;
  position: absolute;
  width: 50px;
  height: 49px;
  bottom: 3px;
  left: calc(50% - 22px);
  transform: translate(-50%, 0);
  mix-blend-mode: multiply;
  border-radius: 0 0 0px 100px;
  overflow: hidden;
}
.tabs-two-columns .category-m-efect-items .border-center-category-m .chart-box .border-item-1:after {
  content: "";
  background: #FE7045;
  position: absolute;
  width: 100px;
  height: 51px;
  bottom: 0;
  left: 0;
  transform: translate(50%, 0%) rotate(0deg);
  mix-blend-mode: multiply;
  border-radius: 0;
  overflow: hidden;
  transform-origin: 0 0;
  transition: all linear 0.3s;
}
.tabs-two-columns .category-m-efect-items .border-center-category-m .chart-box .border-item-2 {
  background: transparent;
  position: absolute;
  width: 53px;
  height: 100px;
  top: 4px;
  left: 50%;
  transform: translate(0%, 0);
  mix-blend-mode: multiply;
  border-radius: 0 100px 100px 0;
  overflow: hidden;
}
.tabs-two-columns .category-m-efect-items .border-center-category-m .chart-box .border-item-2 .inner-0 {
  width: 50px;
  height: 50px;
  position: absolute;
  mix-blend-mode: multiply;
  left: 0;
  top: 0;
}
.tabs-two-columns .category-m-efect-items .border-center-category-m .chart-box .border-item-2 .item-inner {
  position: absolute;
  background: #fff;
  mix-blend-mode: multiply;
  width: 100px;
  height: 100px;
  display: inline-block;
  left: -49%;
  top: 0;
  mix-blend-mode: multiply;
  transform: translate(48%, 0px) rotate(45deg);
}
.tabs-two-columns .category-m-efect-items .border-center-category-m .chart-box .border-item-2 .after-div {
  background: #FFC72C;
  position: absolute;
  width: 100px;
  height: 51px;
  bottom: 0;
  left: 0;
  transform: translate(0, 0) rotate(-180deg);
  mix-blend-mode: multiply;
  border-radius: 0;
  transform-origin: 0 0;
  transition: all linear 0.3s;
}
.tabs-two-columns .category-m-efect-items .border-center-category-m .chart-box .border-item-2 .after-div:after {
  content: "";
  background: #ffc72c;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: -49px;
  transform: translate(0, 0) rotate(1deg);
  opacity: 1;
}
.tabs-two-columns .category-m-efect-items .border-center-category-m .chart-box .border-item-3 {
  position: absolute;
  top: 0;
  width: 50px;
  left: calc(50% + 27px);
  top: 3px;
  transform: translate(-50%, 0px);
  mix-blend-mode: multiply;
}
.tabs-two-columns .category-m-efect-items .border-center-category-m .chart-box .border-item-3:after {
  content: "";
}
.tabs-two-columns .category-m-efect-items .border-center-category-m:hover .icon-category-hand {
  opacity: 1;
  right: calc(100% - 94px);
}
.tabs-two-columns .category-m-efect-items .border-center-category-m:hover .chart-box {
  padding: 0;
}
.tabs-two-columns .category-m-efect-items .border-center-category-m:hover .chart-box .inner-img svg .item-line {
  stroke: #fff;
}
.tabs-two-columns .category-m-efect-items .border-center-category-m:hover .chart-box .border-item-1:after {
  transform: translate(50%, 0) rotate(90deg);
}
.tabs-two-columns .category-m-efect-items .border-center-category-m:hover .chart-box .border-item-2 .after-div {
  transform: translate(0, 0) rotate(-47deg);
  opacity: 1;
}
.tabs-two-columns .category-m-efect-items .border-center-category-m:hover .chart-box .border-item-2 .after-div:after {
  opacity: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1133px) {
  .tabs-two-columns .category-m-efect-items {
    position: relative;
    padding-bottom: 50%;
    margin-top: 0;
  }
  .tabs-two-columns .category-m-efect-items .border-center-category-m {
    position: absolute;
    height: 100%;
  }
  .tabs-two-columns .category-m-efect-items .border-center-category-m .border-center-curve {
    position: absolute;
    bottom: 0;
  }
  .tabs-two-columns .category-m-efect-items .border-center-category-m .icon-category-m {
    position: absolute;
    min-width: 546px;
    left: -146px;
    top: -38px;
    width: calc(100% + 146px);
    min-width: auto;
  }
  .tabs-two-columns .category-m-efect-items .border-center-category-m .center-category-m {
    right: 25%;
    top: auto;
    bottom: -40px;
    max-width: 39%;
    min-width: auto;
    height: calc(100% + 88px);
  }
  .tabs-two-columns .category-m-efect-items .border-center-category-m .border-center-curve {
    width: calc(100% - 50px);
    bottom: auto;
    top: 0;
  }
}
@media (max-width: 991px) {
  .tabs-two-columns .category-m-efect-items {
    margin-top: 81px;
  }
  .tabs-two-columns .category-m-efect-items .border-center-category-m .center-category-m {
    max-width: 130px;
    min-width: 130px;
    top: -55px;
  }
}
@media (max-width: 600px) {
  .tabs-two-columns .category-m-efect-items {
    position: relative;
    padding-bottom: 50%;
    margin-top: 0;
  }
  .tabs-two-columns .category-m-efect-items .border-center-category-m {
    position: initial;
    height: 100%;
  }
  .tabs-two-columns .category-m-efect-items .border-center-category-m .center-category-m {
    max-width: 130px;
    min-width: 130px;
    top: -55px;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    top: -5%;
    height: 100%;
    right: 45%;
  }
  .tabs-two-columns .category-m-efect-items .border-center-category-m .border-center-curve {
    position: absolute;
    z-index: 1;
    width: 67%;
    max-width: 100%;
    height: 90%;
    -o-object-fit: contain;
    object-fit: contain;
  }
  .tabs-two-columns .category-m-efect-items .border-center-category-m .icon-category-m {
    position: absolute;
    min-width: 100%;
    left: -146px;
    top: -40px;
    width: calc(100% + 65px);
  }
  .tabs-two-columns .category-m-efect-items .chart-box {
    transform: scale(0.9);
  }
  .tabs-two-columns .category-m-efect-items .border-center-category-m .icon-category-hand {
    width: 53px;
  }
}
@media (max-width: 500px) {
  .tabs-two-columns .category-m-efect-items .chart-box {
    transform: scale(0.7);
  }
  .tabs-two-columns .category-m-efect-items .border-center-category-m .icon-category-hand {
    transform: scale(0.7);
  }
}
@media (max-width: 450px) {
  .tabs-two-columns .category-m-efect-items {
    margin-left: 66px;
  }
  .tabs-two-columns .category-m-efect-items .border-center-category-m .border-center-curve {
    height: 100%;
    left: 5%;
  }
  .tabs-two-columns .category-m-efect-items .border-center-category-m .center-category-m {
    transform: scale(1.3);
  }
  .tabs-two-columns .category-m-efect-items .border-center-category-m .chart-box {
    position: absolute;
    width: 142px;
    overflow: hidden;
    padding: 0 16px;
    transition: all linear 0.3s;
    bottom: 73px;
    transform: scale(0.5);
  }
  .tabs-two-columns .category-m-efect-items .border-center-category-m .icon-category-m {
    position: absolute;
    min-width: 100%;
    left: -66px;
    top: -15px;
    width: calc(100% + 10px);
  }
  .tabs-two-columns .category-m-efect-items .border-center-category-m .chart-box {
    position: absolute;
    width: 142px;
    overflow: hidden;
    padding: 0 16px;
    transition: all linear 0.3s;
    bottom: 73px;
    left: -96px;
    z-index: 1;
  }
}
@media (max-width: 400px) {
  .tabs-two-columns .category-m-efect-items .border-center-category-m .center-category-m {
    right: 30%;
  }
  .tabs-two-columns .category-m-efect-items .border-center-category-m .chart-box {
    bottom: 50px;
  }
}
.tabs-two-columns .tab-content.active-efect-item .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .finger-analytics {
  left: -136px;
}
@media (max-width: 576px) {
  .tabs-two-columns .tab-content.active-efect-item .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .finger-analytics {
    left: -85px;
  }
}
.tabs-two-columns .tab-content.active-efect-item .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .bar-col.bar-col-1 {
  background-color: #FFC72C;
  border: 0;
  height: 113px;
}
@media (max-width: 576px) {
  .tabs-two-columns .tab-content.active-efect-item .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .bar-col.bar-col-1 {
    height: 78px;
  }
}
.tabs-two-columns .tab-content.active-efect-item .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .bar-col.bar-col-3 {
  background-color: #FE7045;
  border: 0;
  height: 78px;
}
@media (max-width: 576px) {
  .tabs-two-columns .tab-content.active-efect-item .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .bar-col.bar-col-3 {
    height: 52px;
  }
}
.tabs-two-columns .tab-content.active-efect-item .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .bar-col.bar-col-5 {
  border: 0;
  height: 17px;
}
.tabs-two-columns .tab-content.active-efect-item .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .bar-col.bar-col-5 .border-inner-5-0 {
  background-color: #00152F;
}
.tabs-two-columns .tab-content.active-efect-item .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .bar-col.bar-col-5 .border-inner-5 {
  border: 0;
}
.tabs-two-columns .tab-content .analytics-efect-items {
  position: relative;
  margin-left: 146px;
  transition: all ease 0.4s;
  margin-top: 121px;
}
.tabs-two-columns .tab-content .analytics-efect-items:after {
  content: "";
  background: transparent linear-gradient(0deg, #FFC72C 0, #FDFAF3 100%) 0 0 no-repeat padding-box;
  opacity: 0.15;
  filter: blur(50px);
  position: absolute;
  min-width: 696px;
  min-height: 536px;
  z-index: -1;
  top: -203px;
  left: -146px;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics {
  position: relative;
  max-width: 331px;
  min-width: 331px;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .border-center-curve {
  position: relative;
  z-index: 0;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .center-analytics {
  width: 254px;
  position: absolute;
  left: -21px;
  bottom: 0;
  z-index: 4;
  border-radius: 0;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .center-product {
  position: absolute;
  z-index: 1;
  min-width: 650px;
  left: -164px;
  bottom: 2px;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item {
  position: absolute;
  right: -151px;
  bottom: -9px;
  width: 188px;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row {
  display: flex;
  align-items: flex-end;
  position: relative;
  padding-left: 4px;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .finger-analytics {
  position: absolute;
  left: -260px;
  bottom: 5px;
  width: 100%;
  max-width: 150px;
  z-index: 1;
  transition: all linear 0.3s;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .finger-analytics.finger-analytics-2 {
  z-index: 3;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .bar-col {
  min-width: 22px;
  min-height: 12px;
  display: inline-block;
  height: 22px;
  margin-right: 9px;
  transition: all linear 0.3s;
  z-index: 2;
  position: relative;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .bar-col.bar-col-1 {
  height: 21px;
  background-color: #000;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .bar-col.bar-col-2 {
  height: 47px;
  background-color: #fff;
  border: 2.5px solid #000;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .bar-col.bar-col-3 {
  height: 12px;
  background-color: #000;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .bar-col.bar-col-4 {
  height: 24px;
  background-color: #fff;
  border: 2.5px solid #000;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .bar-col.bar-col-5 {
  height: 17px;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .bar-col.bar-col-5 .border-inner-5-0 {
  background-color: #000;
  position: absolute;
  height: 100%;
  width: 100%;
  transition: all ease 0.3s;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .bar-col.bar-col-5 .border-inner-5 {
  border: 3px solid #000;
  transition: all ease 1s;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 3;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .bar-col.bar-col-6 {
  height: 24px;
  background-color: #fff;
  border: 2.5px solid #000;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  border-radius: 20px;
  background: #000;
  z-index: 2;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics:hover .efect-bar-item .bar-col-row .finger-analytics {
  left: -136px;
}
@media (max-width: 576px) {
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics:hover .efect-bar-item .bar-col-row .finger-analytics {
    left: -85px;
  }
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics:hover .efect-bar-item .bar-col-row .bar-col.bar-col-1 {
  background-color: #FFC72C;
  border: 0;
  height: 113px;
}
@media (max-width: 576px) {
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics:hover .efect-bar-item .bar-col-row .bar-col.bar-col-1 {
    height: 78px;
  }
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics:hover .efect-bar-item .bar-col-row .bar-col.bar-col-3 {
  background-color: #FE7045;
  border: 0;
  height: 78px;
}
@media (max-width: 576px) {
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics:hover .efect-bar-item .bar-col-row .bar-col.bar-col-3 {
    height: 52px;
  }
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics:hover .efect-bar-item .bar-col-row .bar-col.bar-col-5 {
  border: 0;
  height: 17px;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics:hover .efect-bar-item .bar-col-row .bar-col.bar-col-5 .border-inner-5-0 {
  background-color: #00152F;
}
.tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics:hover .efect-bar-item .bar-col-row .bar-col.bar-col-5 .border-inner-5 {
  border: 0;
}
@media (max-width: 1400px) {
  .tabs-two-columns .tab-content .analytics-efect-items {
    transform: scale(0.9);
    position: relative;
    left: -19px;
    top: -24px;
  }
}
@media (max-width: 1200px) {
  .tabs-two-columns .tab-content .analytics-efect-items {
    transform: scale(0.8);
    left: -55px;
  }
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .center-product {
    min-width: 620px;
    left: -141px;
  }
}
@media (max-width: 1000px) {
  .tabs-two-columns .tab-content .analytics-efect-items {
    transform: scale(0.7);
    left: -85px;
  }
}
@media (max-width: 991px) {
  .tabs-two-columns .tab-content .analytics-efect-items {
    transform: scale(1);
    left: 0;
    top: 0;
  }
}
@media (max-width: 768px) {
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .border-center-curve {
    max-width: 289px;
    min-width: 280px;
  }
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .center-analytics {
    width: 200px;
  }
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .center-product {
    min-width: 570px;
  }
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item {
    right: -120px;
  }
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .finger-analytics {
    position: absolute;
    left: -196px;
    width: 100%;
    bottom: 15px;
  }
}
@media (max-width: 662px) {
  .tabs-two-columns .tab-content .analytics-efect-items {
    margin-top: 0;
    padding-bottom: 50%;
    margin-left: 86px;
  }
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics {
    position: initial;
    padding-top: 0;
  }
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .border-center-curve {
    min-width: auto;
    max-width: initial;
    width: calc(100% - 126px);
    position: absolute;
    bottom: 0;
    left: 20px;
    max-height: 290px;
    -o-object-fit: contain;
    object-fit: contain;
  }
}
@media (max-width: 662px) and (max-width: 576px) {
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .border-center-curve {
    width: calc(100% - 200px);
  }
}
@media (max-width: 662px) {
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .center-analytics {
    left: 6%;
    width: 40%;
  }
}
@media (max-width: 662px) {
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .center-product {
    min-width: 100%;
    left: -70px;
    width: calc(100% + 70px);
  }
}
@media (max-width: 662px) and (max-width: 576px) {
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .center-product {
    width: calc(100% + 14px);
  }
}
@media (max-width: 662px) {
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item {
    right: 0;
  }
}
@media (max-width: 662px) {
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .finger-analytics {
    opacity: 1;
  }
}
@media (max-width: 576px) {
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item .bar-col-row .finger-analytics {
    width: 50%;
    bottom: 10px;
  }
}
@media (max-width: 476px) {
  .tabs-two-columns .tab-content .analytics-efect-items {
    margin-top: 0;
    margin-left: 26px;
  }
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics {
    position: initial;
    padding-top: 0;
  }
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .border-center-curve {
    width: calc(100% - 96px);
    max-height: 210px;
  }
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .center-analytics {
    width: 40%;
    left: 13%;
  }
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .center-product {
    min-width: 100%;
    left: -40px;
    width: calc(100% + 0px);
  }
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item {
    right: 0;
    transform: translate(0px, 0px) scale(0.6);
  }
}
@media (max-width: 414px) {
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .border-center-curve {
    width: calc(100% - 90px);
    max-height: 190px;
  }
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .center-analytics {
    left: 9%;
  }
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item {
    right: -15px;
  }
}
@media (max-width: 390px) {
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .border-center-curve {
    width: calc(100% - 105px);
    max-height: 155px;
  }
  .tabs-two-columns .tab-content .analytics-efect-items .border-center-analytics .efect-bar-item {
    bottom: -15px;
    right: -30px;
  }
}
.tabs-two-columns .default-box-img {
  position: relative;
}
.tabs-two-columns .default-box-img:after {
  content: "";
  background: transparent linear-gradient(0deg, #F750A8 0%, #FCF59D 100%) 0 0 no-repeat padding-box;
  opacity: 0.15;
  filter: blur(50px);
  position: absolute;
  min-width: 696px;
  min-height: 536px;
  z-index: -1;
  top: -203px;
  left: -146px;
}
.tabs-two-columns .default-box-img img {
  position: relative;
  z-index: 1;
}
@keyframes move-elment {
  0% {
    display: block;
    opacity: 0;
  }
  50% {
    display: block;
    opacity: 1;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
.tabs-two-columns .content-tabs .tab-content {
  display: none;
}
.tabs-two-columns .content-tabs .tab-content.active {
  display: block;
  animation: move-elment 1s linear 1;
}
.tabs-two-columns .content-tabs .col-list {
  margin-top: 50px;
}
.tabs-two-columns .content-tabs .thead-tabs {
  margin-bottom: 70px;
}
.tabs-two-columns .content-tabs .thead-tabs p {
  font-size: 22px;
}
.tabs-two-columns .content-tabs .thead-tabs p:last-child {
  margin-bottom: 0;
}
.tabs-two-columns .content-tabs img {
  max-width: 550px;
  width: 100%;
}
.tabs-two-columns .content-tabs .content-list {
  padding: 20px 0;
  border-bottom: 1px solid #00152F;
}
.tabs-two-columns .content-tabs .content-list p {
  margin-bottom: 0;
  font-size: 18px;
}
.tabs-two-columns .content-tabs .content-list:first-child {
  border-top: 1px solid #00152F;
}
.tabs-two-columns .content-tabs .content-buttons {
  margin-top: 90px;
}
.tabs-two-columns .container:before {
  opacity: 1 !important;
}
@media (max-width: 991px) {
  .tabs-two-columns .content-title {
    margin-bottom: 40px;
  }
  .tabs-two-columns .content-title-tabs .tab-title {
    padding: 15px 35px;
  }
  .tabs-two-columns .content-tabs .col-list {
    margin-top: 40px;
  }
  .tabs-two-columns .content-tabs .thead-tabs {
    margin-bottom: 40px;
  }
  .tabs-two-columns .content-tabs .content-buttons {
    margin-top: 40px;
  }
}
@media (max-width: 990.98px) {
  .tabs-two-columns .default-box-img {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .tabs-two-columns .content-title-tabs {
    -moz-column-gap: 9px;
    column-gap: 9px;
    row-gap: 15px;
    margin-bottom: 30px;
  }
  .tabs-two-columns .content-title-tabs .tab-title {
    padding: 6px 18px;
  }
}
@media (max-width: 575.98px) {
  .tabs-two-columns .content-title-tabs {
    justify-content: center;
  }
}

.bg-glow-orange .container::before {
  content: "";
  position: absolute;
  width: 65%;
  height: 80%;
  bottom: -110px;
  left: -104px;
  background: url(../assets/img/bg-media-object.png);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
  rotate: 180deg;
  filter: blur(50px);
}

.tabs-two-columns-benefits .content-tabs .col-lg-6:first-child {
  padding-right: 15px;
}
.tabs-two-columns-benefits .content-tabs .col-lg-6:first-child img {
  margin-left: 35px;
}
@media (max-width: 1200px) {
  .tabs-two-columns-benefits .content-tabs .col-lg-6:first-child img {
    margin-left: 0;
  }
}

.slider-stats .eyebrow {
  margin-bottom: 80px;
}
.slider-stats .content-text {
  max-width: 536px;
}
.slider-stats .content-text .eyebrow {
  margin-bottom: 20px;
}
.slider-stats .content-text p {
  font-size: 22px;
}
.slider-stats .content-text .content-buttons {
  margin-top: 0px;
}
.slider-stats .slick-list {
  margin: 0 -20px;
}
.slider-stats .slick-list .slick-slide {
  margin: 0 20px;
}
.slider-stats .progress {
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 0;
  overflow: hidden;
  background-color: hsla(0deg, 0%, 100%, 0.393);
  background-image: linear-gradient(to right, #fff, #fff);
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size 0.4s ease-in-out;
}
.slider-stats .content-slider .slick-dots {
  bottom: -33px;
  opacity: 0;
}
.slider-stats .content-slider .slick-dots li button:before {
  transition: all linear 0.3s;
  width: 100% !important;
}
.slider-stats .content-slider .slick-dots li button:after {
  content: "" !important;
  width: 100% !important;
  height: 100% !important;
  background: white;
  position: absolute;
  left: 0;
  top: 0;
  transition: all linear 0s;
}
.slider-stats .content-slider .slick-dots li.slick-active button:before {
  width: 100% !important;
}
.slider-stats .content-slider .slick-dots li.slick-active button:after {
  width: 100% !important;
  transition: all linear 0.3s;
}
.slider-stats .content-slider .slick-dots li.slick-active ~ li button:before {
  opacity: 0.25 !important;
}
.slider-stats .content-slider .slick-dots li.slick-active ~ li button:after {
  width: 0% !important;
  transition: all linear 0s !important;
  height: 0;
}
.slider-stats .content-slider.active-items-efect .slick-dots li button {
  height: 1px !important;
}
.slider-stats .progress-content {
  padding: 0 90px;
}
.slider-stats .content-slider {
  padding: 0 90px;
}
.slider-stats .content-slider .row {
  --bs-gutter-x: 80px;
}
@media (max-width: 1200px) {
  .slider-stats .content-slider .row {
    --bs-gutter-x: 45px;
  }
}
.slider-stats .content-slider .slick-arrow {
  width: 50px;
  height: 50px;
  z-index: 5;
  transform: translateY(-70%);
}
.slider-stats .content-slider .slick-arrow:before {
  content: "";
  background-size: cover !important;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}
.slider-stats .content-slider .slick-arrow.slick-prev {
  left: 0;
}
.slider-stats .content-slider .slick-arrow.slick-prev:before {
  background: url("../assets/img/slider-arrow-withe-left.svg");
}
.slider-stats .content-slider .slick-arrow.slick-next {
  right: 0;
}
.slider-stats .content-slider .slick-arrow.slick-next:before {
  background: url("../assets/img/slider-arrow-white-right.svg");
}
.slider-stats .content-slider .content-slider-box {
  padding-bottom: 140px;
}
.slider-stats .content-slider .content-stats .row {
  --bs-gutter-x: 25px;
  --bs-gutter-y: 60px;
  row-gap: 80px;
}
.slider-stats .content-slider .content-stats .stat-item {
  max-width: 200px;
  width: 100%;
}
.slider-stats .content-slider .content-stats .stat-item .stat-item--number {
  font-size: 40px;
  font-family: "Figtree", sans-serif;
  letter-spacing: -1.2px;
}
.slider-stats .content-slider .content-stats .stat-item p {
  margin-bottom: 0;
  font-size: 16px;
}
.slider-stats .content-slider .row-custom {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  grid-gap: 30px;
  -moz-column-gap: 20px;
  column-gap: 20px;
  align-items: center;
}
.slider-stats .content-slider .row-custom figure {
  margin: 0;
  max-width: 210px;
}
.slider-stats .content-slider .row-custom figure img {
  max-width: 100%;
}
@media (max-width: 991px) {
  .slider-stats .eyebrow {
    margin-bottom: 40px;
  }
  .slider-stats .content-text {
    max-width: 100%;
    margin-bottom: 40px;
  }
  .slider-stats .content-text p {
    font-size: 18px;
  }
  .slider-stats .content-text .content-buttons {
    margin-top: 0px;
  }
  .slider-stats .progress-content {
    padding: 0 45px;
  }
  .slider-stats .content-slider {
    padding: 0 45px;
  }
  .slider-stats .content-slider .content-slider-box {
    padding-bottom: 50px;
  }
  .slider-stats .content-slider .row {
    --bs-gutter-y: 30px!important;
  }
  .slider-stats .content-slider .slick-arrow {
    width: 30px;
    height: 30px;
  }
  .slider-stats .content-slider .slick-arrow:before {
    width: 30px;
    height: 30px;
  }
  .slider-stats .content-slider .slick-arrow.slick-prev {
    left: -10px;
  }
  .slider-stats .content-slider .slick-arrow.slick-next {
    right: -10px;
  }
}
@media (max-width: 768px) {
  .slider-stats .content-slider .row-custom {
    margin-top: 30px;
    justify-content: center;
    grid-gap: 20px;
  }
  .slider-stats .content-slider .row-custom .content-buttons {
    justify-content: center;
  }
}
@media (max-width: 575px) {
  .slider-stats .eyebrow {
    margin-bottom: 30px;
  }
  .slider-stats .content-text {
    margin-bottom: 30px;
  }
  .slider-stats .content-text p {
    font-size: 16px;
  }
  .slider-stats .content-text .content-buttons {
    margin-top: 0px;
  }
  .slider-stats .progress-content {
    padding: 0 25px;
  }
  .slider-stats .content-slider {
    padding: 0 25px;
  }
  .slider-stats .content-slider .content-slider-box {
    padding-bottom: 30px;
  }
  .slider-stats .content-slider .row {
    --bs-gutter-y: 20px!important;
  }
  .slider-stats .content-slider .content-stats .stat-item {
    margin: 0 auto;
    text-align: center;
  }
  .slider-stats .content-slider .content-stats .stat-item .stat-item--number {
    font-size: 27px;
  }
}

.block-content .large-text {
  font-size: 22px !important;
  letter-spacing: 0;
}
.block-content img {
  margin-top: 65px;
}
@media (max-width: 767px) {
  .block-content .large-text {
    font-size: 18px !important;
    letter-spacing: 0;
  }
}
.block-content.buttons-style1 .content-buttons {
  margin-top: clamp(20px, 4vw, 85px) !important;
}
.block-content.buttons-style1 .content-buttons .btn, .block-content.buttons-style1 .content-buttons .woocommerce-notices-wrapper .woocommerce-message a.button, .woocommerce-notices-wrapper .woocommerce-message .block-content.buttons-style1 .content-buttons a.button, .block-content.buttons-style1 .content-buttons .woocommerce-info a.button, .woocommerce-info .block-content.buttons-style1 .content-buttons a.button, .block-content.buttons-style1 .content-buttons .wpcf7 input[type=submit], .wpcf7 .block-content.buttons-style1 .content-buttons input[type=submit] {
  padding: 16.5px 62px;
}

.bg-degraded::before {
  content: "";
  position: absolute;
  background: url("../assets/img/bg-tabs.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 750px;
  height: 750px;
  left: calc((100vw - 1450px) / 2);
  bottom: -10%;
}
@media (max-width: 767px) {
  .bg-degraded::before {
    width: 380px;
    height: 380px;
    left: -50px;
    bottom: 0;
    top: initial;
  }
}

.section-image img {
  margin-top: 0;
  width: 100%;
}
@media (max-width: 767px) {
  .section-image .image-desktop {
    display: none;
  }
}
.section-image .image-mobile {
  display: none;
}
@media (max-width: 767px) {
  .section-image .image-mobile {
    display: unset;
  }
}

.accordion-interactive .content-title {
  margin-bottom: 30px;
}
.accordion-interactive .content-title h2, .accordion-interactive .content-title .h2, .accordion-interactive .content-title h3, .accordion-interactive .content-title .h3, .accordion-interactive .content-title h4, .accordion-interactive .content-title .h4, .accordion-interactive .content-title h5, .accordion-interactive .content-title .h5, .accordion-interactive .content-title h6, .accordion-interactive .content-title .h6 {
  max-width: 1120px;
}
.accordion-interactive .content-title h2, .accordion-interactive .content-title .h2 {
  font-size: 50px;
  letter-spacing: -1.5px;
}
.accordion-interactive .content-title p {
  font-size: 22px;
  max-width: 1120px;
}
.accordion-interactive .content-title p:last-child {
  margin-bottom: 0;
}
.accordion-interactive .row {
  --bs-gutter-x: 70px;
}
.accordion-interactive .content-accordion-text {
  max-width: 648px;
  padding: 20px 0;
  border-bottom: 1px solid #000;
}
.accordion-interactive .content-accordion-text .tab-title {
  color: #000000;
  text-transform: uppercase;
  font-size: 22px;
  position: relative;
  padding-right: 55px;
  transition: all 0.4s ease-in;
  cursor: pointer;
  font-family: "Figtree", sans-serif;
  font-weight: 700;
}
.accordion-interactive .content-accordion-text .tab-title::before {
  content: "";
  position: absolute;
  background: url("../assets/img/arrow-accordion.svg");
  background-size: contain;
  width: 23px;
  height: 13px;
  right: 25px;
  top: 8px;
}
.accordion-interactive .content-accordion-text .tab-title.active {
  margin-bottom: 20px;
}
.accordion-interactive .content-accordion-text .tab-title.active::before {
  transform: rotate(180deg);
}
.accordion-interactive .content-accordion-text .c-text p {
  max-width: 536px;
  font-size: 18px;
}
.accordion-interactive .content-accordion-text .c-text p:last-child {
  margin-bottom: 0;
}
.accordion-interactive .content-accordion-text .btn, .accordion-interactive .content-accordion-text .woocommerce-notices-wrapper .woocommerce-message a.button, .woocommerce-notices-wrapper .woocommerce-message .accordion-interactive .content-accordion-text a.button, .accordion-interactive .content-accordion-text .woocommerce-info a.button, .woocommerce-info .accordion-interactive .content-accordion-text a.button, .accordion-interactive .content-accordion-text .wpcf7 input[type=submit], .wpcf7 .accordion-interactive .content-accordion-text input[type=submit] {
  margin-top: 20px;
}
.accordion-interactive .content-accordion-text .tab-content {
  display: none;
}
.accordion-interactive .content-accordion-text .tab-content.active {
  display: block;
}
.accordion-interactive .content-accordion-img .tab-content {
  display: none;
}
.accordion-interactive .content-accordion-img .tab-content.active {
  display: block;
}
.accordion-interactive .content-accordion-img .content-img-accordion {
  position: relative;
  max-width: 550px;
  width: 100%;
  height: 550px;
  display: flex;
  align-items: center;
  background: #f9f3f3;
  justify-content: center;
  padding: 25px;
  margin: 0 auto;
}
.accordion-interactive .content-accordion-img .content-img-accordion.external > img {
  position: absolute;
  max-width: 118%;
}
.accordion-interactive .content-accordion-img .content-img-accordion > img {
  min-width: 380px;
  max-width: 460px;
  position: relative;
  z-index: 5;
}
.accordion-interactive .content-accordion-img .content-img-accordion .gallery-bg {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  -moz-column-gap: 10px;
  column-gap: 10px;
  row-gap: 10px;
  z-index: 0;
}
.accordion-interactive .content-accordion-img .content-img-accordion .gallery-bg > div {
  width: calc(50% - 5px);
  height: calc(33% - 5px);
}
.accordion-interactive .content-accordion-img .content-img-accordion .gallery-bg > div img {
  max-width: 100% !important;
  width: 100%;
  height: 100% !important;
  min-height: initial !important;
  min-width: initial !important;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 15px;
}
.accordion-interactive .content-accordion-img .content-buttons {
  margin-top: 40px;
}
@media (max-width: 991px) {
  .accordion-interactive .content-title h2, .accordion-interactive .content-title .h2 {
    font-size: 40px;
  }
  .accordion-interactive .content-accordion-text {
    max-width: 100%;
  }
  .accordion-interactive .content-accordion-img {
    margin-top: 30px;
  }
  .accordion-interactive .content-accordion-img .content-img-accordion {
    margin: 0 auto;
  }
  .accordion-interactive .content-accordion-img .content-buttons {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .accordion-interactive .content-title h2, .accordion-interactive .content-title .h2 {
    font-size: 30px;
    letter-spacing: -1.5px;
  }
  .accordion-interactive .content-accordion-img .content-img-accordion {
    height: auto;
    padding: 70px 25px;
  }
  .accordion-interactive .content-accordion-img .content-img-accordion img {
    min-width: initial;
    width: 100%;
  }
  .accordion-interactive .content-accordion-img .content-img-accordion.external img {
    position: relative;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .content-tablet {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .content-desktop-tablet {
    display: none !important;
  }
}
.side-by-side-stats .container::before {
  content: "";
  position: absolute;
  background: url("../assets/img/cleardemand-glow-bg.png");
  background-size: cover;
  width: 700px;
  height: 700px;
  right: 0;
  top: -13%;
}
.side-by-side-stats .content-title h2, .side-by-side-stats .content-title .h2 {
  font-size: 50px;
}
.side-by-side-stats .content-title p {
  font-size: 22px;
}
.side-by-side-stats .content-title .content-buttons {
  margin-top: 40px;
}
.side-by-side-stats .content-image {
  position: relative;
  padding: 100px 25px;
  margin-top: 55px;
  max-width: 610px;
  margin-left: auto;
}
.side-by-side-stats .content-image img {
  border-radius: 25px;
  min-height: 250px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.side-by-side-stats .content-image .card-stat {
  max-width: 305px;
  -moz-column-gap: 15px;
  column-gap: 15px;
  box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.1490196078);
  position: absolute;
  transition: all ease 0.3s;
  width: 100%;
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .side-by-side-stats .content-image .card-stat {
    padding: 30px;
  }
}
.side-by-side-stats .content-image .card-stat h3, .side-by-side-stats .content-image .card-stat .h3 {
  font-weight: 600;
}
.side-by-side-stats .content-image .card-stat p {
  max-width: 120px;
}
.side-by-side-stats .content-image .card-stat.card-1 {
  top: 0;
  left: 0;
  opacity: 0.25;
  transform: translate(0px, 0px);
}
.side-by-side-stats .content-image .card-stat.card-2 {
  /*  top: 0;
  left: 0; */
  top: 0;
  left: 100%;
  transform: translate(-100%, 0px);
  max-width: 274px;
}
.side-by-side-stats .content-image .card-stat.card-3 {
  top: 100%;
  left: 0;
  opacity: 0.6;
  transform: translate(0px, -100%);
}
.side-by-side-stats .content-image .card-stat.card-4 {
  left: 100%;
  top: 100%;
  opacity: 0;
  transform: translate(-100%, -100%);
}
@media (max-width: 560px) {
  .side-by-side-stats .content-image .card-stat {
    flex-wrap: wrap;
    padding: 15px;
    max-width: 150px !important;
  }
  .side-by-side-stats .content-image .card-stat h3, .side-by-side-stats .content-image .card-stat .h3 {
    width: 100%;
  }
  .side-by-side-stats .content-image .card-stat p {
    max-width: 100%;
  }
}
.side-by-side-stats .content-image.active-items .card-stat.card-1 {
  opacity: 0.8;
}
.side-by-side-stats .content-image.active-items .card-stat.card-2 {
  opacity: 0;
}
.side-by-side-stats .content-image.active-items .card-stat.card-3 {
  opacity: 1;
}
.side-by-side-stats .content-image.active-items .card-stat.card-4 {
  opacity: 0.8;
}
.side-by-side-stats .content-image.animation-1.animation-2 {
  opacity: 1;
}
.side-by-side-stats .content-image.animation-1.animation-2 .card-stat.card-1 {
  top: 0;
  left: 100%;
  transform: translate(-100%, 0px);
  max-width: 274px;
  width: 100%;
  opacity: 1;
  z-index: 2;
}
.side-by-side-stats .content-image.animation-1.animation-2 .card-stat.card-2 {
  left: 100%;
  top: 100%;
  opacity: 0;
  transform: translate(-100%, -100%);
  z-index: 0;
}
.side-by-side-stats .content-image.animation-1.animation-2 .card-stat.card-3 {
  top: 0;
  left: 0;
  opacity: 0.25;
  transform: translate(0px, 0px);
  opacity: 0.25;
  z-index: 0;
}
.side-by-side-stats .content-image.animation-1.animation-2 .card-stat.card-4 {
  top: 100%;
  left: 0;
  opacity: 0.6;
  transform: translate(0px, -100%);
  z-index: 0;
}
.side-by-side-stats .content-image.animation-1.animation-2.animation-3 .card-stat.card-1 {
  left: 100%;
  top: 100%;
  opacity: 0;
  transform: translate(-100%, -100%);
  z-index: 0;
}
.side-by-side-stats .content-image.animation-1.animation-2.animation-3 .card-stat.card-2 {
  top: 100%;
  left: 0;
  opacity: 0.6;
  transform: translate(0, -100%);
  z-index: 0;
}
.side-by-side-stats .content-image.animation-1.animation-2.animation-3 .card-stat.card-3 {
  top: 0;
  left: 100%;
  transform: translate(-100%, 0);
  max-width: 274px;
  width: 100%;
  opacity: 1;
  z-index: 2;
}
.side-by-side-stats .content-image.animation-1.animation-2.animation-3 .card-stat.card-4 {
  top: 0;
  left: 0;
  opacity: 0.25;
  transform: translate(0px, 0px);
  opacity: 0.25;
  z-index: 0;
}
.side-by-side-stats .content-image.animation-1.animation-2.animation-4 .card-stat.card-1 {
  top: 100%;
  left: 0;
  opacity: 0.6;
  transform: translate(0, -100%);
  z-index: 0;
}
.side-by-side-stats .content-image.animation-1.animation-2.animation-4 .card-stat.card-2 {
  top: 0;
  left: 0;
  opacity: 0.25;
  transform: translate(0, 0);
  opacity: 0.25;
  z-index: 0;
}
.side-by-side-stats .content-image.animation-1.animation-2.animation-4 .card-stat.card-3 {
  left: 100%;
  top: 100%;
  opacity: 0;
  transform: translate(-100%, -100%);
  z-index: 0;
}
.side-by-side-stats .content-image.animation-1.animation-2.animation-4 .card-stat.card-4 {
  top: 0;
  left: 100%;
  transform: translate(-100%, 0);
  max-width: 274px;
  width: 100%;
  opacity: 1;
  z-index: 2;
}
@media (max-width: 1200px) {
  .side-by-side-stats .content-image {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 767px) {
  .side-by-side-stats .container::before {
    right: initial;
    left: 50%;
    transform: translate(-50%, 0%);
    top: 33%;
  }
  .side-by-side-stats .content-title h2, .side-by-side-stats .content-title .h2 {
    font-size: 30px;
  }
  .side-by-side-stats .content-title p {
    font-size: 18px;
  }
  .side-by-side-stats .content-title .content-buttons {
    margin-top: 30px;
  }
}

.latest-resources .content-title {
  margin-bottom: 70px;
}
.latest-resources .content-title p:last-child {
  margin-bottom: 0;
}
.latest-resources .content-buttons {
  margin-top: 20px;
}
.latest-resources .row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
}
.latest-resources .row .mb-post {
  margin-bottom: 30px;
}
.latest-resources .row .mb-rf {
  margin-bottom: 80px !important;
}
.latest-resources .row .mt-r {
  margin-top: -50px;
}
@media (max-width: 991px) {
  .latest-resources .content-title {
    margin-bottom: 40px;
  }
  .latest-resources .content-buttons {
    margin-top: 10px;
  }
  .latest-resources .firts {
    order: 1;
  }
  .latest-resources .second {
    order: 0;
  }
  .latest-resources .row .mb-rf {
    margin-bottom: 30px !important;
  }
  .latest-resources .row .mt-r {
    margin-top: 0;
  }
}

.item-resources {
  background: linear-gradient(227deg, #57D18B 0, #0167A6 100%);
  mix-blend-mode: overlay;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
}
.item-resources.item-resouces-no-img {
  background: transparent linear-gradient(71deg, #0167A6 0%, #569EF0 100%) 0% 0% no-repeat padding-box;
}
.item-resources.item-resouces-no-img .item-resources--img {
  display: none;
}
.item-resources.item-resouces-no-img .item-resources--text p {
  display: block;
}
.item-resources.item-featured-resouces {
  padding: 30px;
  background: transparent linear-gradient(71deg, #0167A6 0%, #569EF0 100%) 0% 0% no-repeat padding-box;
  display: flex;
  -moz-column-gap: 30px;
  column-gap: 30px;
  height: 100%;
  flex-direction: row-reverse;
  align-items: center;
}
.item-resources.item-featured-resouces .item-resources--img {
  width: 48%;
  height: 100%;
  max-height: 200px;
}
.item-resources.item-featured-resouces .item-resources--img img {
  mix-blend-mode: initial;
}
.item-resources.item-featured-resouces .item-resources--text {
  width: 52%;
  padding: 0;
}
.item-resources.item-featured-resouces .item-resources--text h4, .item-resources.item-featured-resouces .item-resources--text .h4 {
  margin-bottom: 10px;
}
.item-resources.item-featured-resouces .item-resources--text p {
  display: block;
}
@media (max-width: 690px) {
  .item-resources.item-featured-resouces {
    flex-wrap: wrap;
    height: auto !important;
  }
  .item-resources.item-featured-resouces .item-resources--img {
    width: 100%;
    margin-bottom: 25px;
  }
  .item-resources.item-featured-resouces .item-resources--text {
    width: 100%;
  }
}
.item-resources .item-resources--img {
  position: relative;
  height: 195px;
}
.item-resources .item-resources--img img {
  width: 100%;
  height: 100% !important;
  -o-object-fit: cover;
  object-fit: cover;
  mix-blend-mode: soft-light;
}
.item-resources .item-resources--text {
  padding: 30px;
}
.item-resources .item-resources--text span {
  background: #00152F;
  border-radius: 5px;
  padding: 3px 15px;
  color: white !important;
  display: inline-block;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.item-resources .item-resources--text h4, .item-resources .item-resources--text .h4 {
  color: white !important;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.item-resources .item-resources--text p {
  color: white !important;
  margin-bottom: 20px;
  font-size: 18px;
  display: none;
}
@media (max-width: 767px) {
  .item-resources .item-resources--text h4, .item-resources .item-resources--text .h4 {
    font-size: 20px !important;
  }
}

.cta-side-form .content-title {
  margin-bottom: 100px;
  max-width: 1100px;
}
.cta-side-form .content-title h2, .cta-side-form .content-title .h2 {
  font-size: 60px;
  letter-spacing: -1.8px;
}
.cta-side-form .content-title p {
  font-size: 22px;
}
.cta-side-form .content-title p:last-child {
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .cta-side-form .content-title h2, .cta-side-form .content-title .h2 {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .cta-side-form .content-title {
    margin-bottom: 40px;
  }
  .cta-side-form .content-title h2, .cta-side-form .content-title .h2 {
    font-size: 28px;
  }
  .cta-side-form .content-title p {
    font-size: 17px;
  }
}
.cta-side-form .content-img {
  max-width: 570px;
  margin-bottom: 30px;
  position: relative;
  height: 446px;
}
.cta-side-form .content-img::before {
  content: "";
  position: absolute;
  width: 125%;
  height: 165%;
  background: url(../assets/img/bg-tabs.png);
  background-size: contain;
  background-repeat: no-repeat;
  right: -28%;
  bottom: -40%;
  z-index: -1;
}
.cta-side-form .content-img img {
  position: relative;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
}
.cta-side-form .content-img .item-stat {
  background: transparent linear-gradient(302deg, #0167A6 0%, #57D19E 100%) 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.1490196078);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  -moz-column-gap: 15px;
  column-gap: 15px;
  row-gap: 10px;
  position: absolute;
  transition: all 0.8s ease;
  opacity: 0;
}
.cta-side-form .content-img .item-stat .top-stat {
  font: normal normal 800 40px/95px Figtree;
  letter-spacing: -1.2px;
  color: #fff;
  text-transform: capitalize;
  display: flex;
  gap: 2px;
}
.cta-side-form .content-img .item-stat .top-stat span {
  line-height: 1;
}
.cta-side-form .content-img .item-stat p {
  text-align: left;
  font: normal normal normal 16px/20px Figtree;
  letter-spacing: 0;
  color: #fff !important;
  margin-bottom: 0;
}
.cta-side-form .content-img .item-stat.position-0 {
  max-width: 342px;
  height: 130px;
  top: 0;
  left: 0;
}
.cta-side-form .content-img .item-stat.position-1 {
  width: 213px;
  height: 196px;
  flex-wrap: wrap;
  top: 0;
  right: 0;
}
.cta-side-form .content-img .item-stat.position-2 {
  width: 320px;
  height: 176px;
  flex-wrap: wrap;
  bottom: 60px;
  right: 0;
}
.cta-side-form .content-img .item-stat.position-3 {
  width: 333px;
  height: 130px;
  bottom: 0;
  left: 0;
}
.cta-side-form .content-img .item-stat.position-4 {
  width: 257px;
  height: 156px;
  flex-wrap: wrap;
  left: 0;
  top: calc(50% - 78px);
}
.cta-side-form .content-img.transition-1 .item-stat.position-0, .cta-side-form .content-img.transition-1 .item-stat.position-2 {
  opacity: 1;
}
.cta-side-form .content-img.transition-2 .item-stat.position-1, .cta-side-form .content-img.transition-2 .item-stat.position-3 {
  opacity: 1;
}
.cta-side-form .content-img.transition-3 .item-stat.position-2 {
  opacity: 1;
}
.cta-side-form .content-img.transition-4 .item-stat.position-1, .cta-side-form .content-img.transition-4 .item-stat.position-4 {
  opacity: 1;
}
@media (max-width: 767px) {
  .cta-side-form .content-img {
    height: 390px;
  }
  .cta-side-form .content-img .item-stat {
    padding: 25px;
    -moz-column-gap: 8px;
    column-gap: 8px;
    row-gap: 5px;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .cta-side-form .content-img .item-stat .top-stat {
    font-size: 27px;
  }
  .cta-side-form .content-img .item-stat p {
    font-size: 14px;
    line-height: 17px;
  }
  .cta-side-form .content-img .item-stat.position-0 {
    max-width: 260px;
  }
  .cta-side-form .content-img .item-stat.position-1 {
    width: 170px;
  }
  .cta-side-form .content-img .item-stat.position-2 {
    width: 280px;
    bottom: 30px;
  }
  .cta-side-form .content-img .item-stat.position-3 {
    width: 250px;
  }
  .cta-side-form .content-img .item-stat.position-4 {
    width: 210px;
    top: calc(50% - 50px);
    height: 100px;
  }
  .cta-side-form .content-img.transition-1 .item-stat.position-0, .cta-side-form .content-img.transition-1 .item-stat.position-2 {
    opacity: 1;
  }
  .cta-side-form .content-img.transition-2 .item-stat.position-1, .cta-side-form .content-img.transition-2 .item-stat.position-3 {
    opacity: 1;
  }
  .cta-side-form .content-img.transition-3 .item-stat.position-2 {
    opacity: 1;
  }
  .cta-side-form .content-img.transition-4 .item-stat.position-1, .cta-side-form .content-img.transition-4 .item-stat.position-4 {
    opacity: 1;
  }
}
.cta-side-form .content-form .gform_heading {
  display: none;
}
.cta-side-form .content-form form .gfield_label {
  font-size: 18px !important;
  margin-bottom: 0;
}
.cta-side-form .content-form form textarea,
.cta-side-form .content-form form input,
.cta-side-form .content-form form select {
  border: 1px solid #00152F;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 0 0 10px !important;
  height: 30px;
  color: #2A5F5A;
  font-size: 16px !important;
  background: transparent;
}
.cta-side-form .content-form form input[type=checkbox] {
  width: 19px;
  height: 19px;
  border: 1px solid #00152F;
  border-radius: 2px;
}
.cta-side-form .content-form form input[type=submit] {
  outline-color: #00152F;
}
.cta-side-form .content-form form .ginput_container_consent {
  display: flex;
  align-items: center;
  gap: 15px;
}
.cta-side-form .content-form form .ginput_container_consent .gform-field-label.gfield_consent_label {
  font-size: 16px;
  color: #00152F;
}
.cta-side-form .content-form form .ginput_container_consent .gform-field-label.gfield_consent_label a {
  font-size: 16px;
  color: #00152F !important;
}
@media (hover: hover) {
  .cta-side-form .content-form form .ginput_container_consent .gform-field-label.gfield_consent_label a:hover {
    font-weight: bolder;
  }
}
.cta-side-form .content-form form .gfield textarea.large {
  height: 35px;
}
.cta-side-form .content-form form .gfield textarea.large::-moz-placeholder {
  text-align: left;
  font: normal normal normal 16px/32px Figtree;
  letter-spacing: 0;
  color: #2A5F5A;
}
.cta-side-form .content-form form .gfield textarea.large::placeholder {
  text-align: left;
  font: normal normal normal 16px/32px Figtree;
  letter-spacing: 0;
  color: #2A5F5A;
}
.cta-side-form .content-form form .gfield--type-choice .ginput_container {
  display: flex;
  -moz-column-gap: 15px;
  column-gap: 15px;
}
.cta-side-form .content-form form .gfield--type-choice .ginput_container .gform-field-label {
  font-size: 16px !important;
  color: #191919;
}
.cta-side-form .content-form form .gfield--type-choice .ginput_container .gform-field-label a {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}
.cta-side-form .content-form form .validation_message {
  background: #fff;
}
.cta-side-form .content-form form .gform_footer {
  margin-top: 40px !important;
  padding: 0 !important;
}
.cta-side-form .content-form form .gform_footer .gform_button {
  border: 1px solid #00152F !important;
  background: #00152F;
  color: #fff !important;
  font-size: 16px !important;
  padding: 16px 43px !important;
  height: auto;
  border-radius: 100px;
}
.cta-side-form .content-form form .gform_footer .gform_button:hover {
  color: #00152F !important;
  background: transparent;
}
.cta-side-form .content-form .gform_wrapper.gravity-theme .gform_validation_errors {
  background-color: #fff;
}
.cta-side-form .content-form .gform_wrapper.gravity-theme .gform_validation_errors h2, .cta-side-form .content-form .gform_wrapper.gravity-theme .gform_validation_errors .h2 {
  font-size: 15px;
  color: #c02b0a !important;
  font-weight: initial;
  letter-spacing: normal;
  line-height: 25px;
}
.cta-side-form .content-form .gform_wrapper.gravity-theme .instruction.validation_message {
  display: none;
}
.cta-side-form .content-form .gform_wrapper.gravity-theme .gfield_error label {
  color: #00152F;
}
@media (max-width: 990.98px) {
  .cta-side-form .content-img {
    margin: 0 auto 50px;
  }
}

.hero-banner-common .content-text-hero .breadcrumb-site {
  margin-bottom: 34px;
}
.hero-banner-common .content-text-hero p {
  font-size: 22px;
}
.hero-banner-common .content-text-hero .content-buttons {
  margin-top: 40px;
}
.hero-banner-common .conten-media {
  max-width: 527px;
  margin-left: auto;
  margin-bottom: 15px;
  border-radius: 15px;
}
.hero-banner-common .conten-media > * {
  border-radius: 15px;
}
@media (max-width: 991px) {
  .hero-banner-common .content-text-hero .content-buttons {
    margin-top: 30px;
    justify-content: center;
  }
  .hero-banner-common .conten-media {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    margin-top: 30px;
  }
}
@media (max-width: 991px) {
  .hero-banner-common {
    padding: 60px 0 !important;
  }
  .hero-banner-common .content-text-hero p {
    font-size: 17px;
  }
}

@media (min-width: 992px) {
  .content-tablet-desktop {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .content-desktop {
    display: none !important;
  }
}
.full-image-fluid .container .row {
  align-items: start !important;
}
.full-image-fluid .container .row .conten-media {
  margin-left: 0;
  margin-bottom: 0;
  max-width: 100%;
  position: relative;
  left: 100px;
}
.full-image-fluid .container .row .conten-media img {
  max-width: 971px;
  box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.1490196078);
}

.process-order .content-title {
  margin-bottom: 50px;
}
.process-order .content-title p:last-child {
  margin-bottom: 0;
}
.process-order .col-step:last-child .step-item::before {
  content: none;
}
.process-order .step-item {
  position: relative;
}
.process-order .step-item::before {
  content: "";
  position: absolute;
  background: url("../assets/img/arrow-step.svg");
  background-size: cover;
  width: 50px;
  height: 27px;
  right: 0;
  top: 25px;
}
.process-order .step-item .step-item--title {
  position: relative;
  max-width: 280px;
  border: 3px solid #000;
  border-radius: 100px;
  padding: 17px 17px 17px 90px;
  font-size: 18px;
  line-height: 1.2em;
  font-weight: 700;
  letter-spacing: 0.9px;
  font-family: "Poppins", sans-serif;
  margin-right: 40px;
  background: transparent;
}
.process-order .step-item .step-item--title::before {
  content: "";
  position: absolute;
  background: url("../assets/img/checkmark-step.svg");
  background-size: cover;
  width: 50px;
  height: 50px;
  left: 17px;
  top: 50%;
  transform: translate(0px, -50%);
}
@media (min-width: 991px) and (max-width: 1200px) {
  .process-order .step-item .step-item--title {
    padding: 17px 15px 17px 55px;
    font-size: 17px;
  }
  .process-order .step-item .step-item--title::before {
    width: 30px;
    height: 30px;
  }
}
.process-order .step-item .step-item--list {
  padding-top: 30px;
  border-top: 1px solid #00152F;
  margin: 45px 25px 0 0;
}
.process-order .step-item .step-item--list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.process-order .step-item .step-item--list ul li {
  position: relative;
  padding-left: 40px;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  line-height: 1.5em;
}
.process-order .step-item .step-item--list ul li:last-child {
  margin-bottom: 0;
}
.process-order .step-item .step-item--list ul li::before {
  content: "";
  background: url("../assets/img/checkmark-list.svg");
  position: absolute;
  background-size: contain;
  width: 19px;
  height: 14px;
  left: 0;
  top: 8px;
}
@media (min-width: 991px) and (max-width: 1200px) {
  .process-order .step-item .step-item--list ul li {
    font-size: 17px;
  }
}
@media (max-width: 991px) {
  .process-order .step-item {
    margin-bottom: 35px;
  }
  .process-order .step-item::before {
    content: none;
  }
}
@media (max-width: 767px) {
  .process-order .step-item {
    max-width: 350px;
    margin: 0 auto 45px;
  }
  .process-order .step-item .step-item--title {
    padding: 17px 17px 17px 75px;
    font-size: 17px;
  }
  .process-order .step-item .step-item--title::before {
    width: 36px;
    height: 36px;
  }
}

@media (min-width: 1400px) {
  .side-by-side .row {
    --bs-gutter-x: 90px;
  }
}
.side-by-side .content-text .content-buttons {
  margin-top: 40px;
}
.side-by-side .content-text .eyebrow {
  font-weight: 700;
  font-size: 22px;
}
.side-by-side .content-text ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.side-by-side .content-text ul li {
  position: relative;
  padding-left: 37px;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 1.5em;
}
.side-by-side .content-text ul li:last-child {
  margin-bottom: 0;
}
.side-by-side .content-text ul li::before {
  content: "";
  background: url("../assets/img/checkmark-list.svg");
  position: absolute;
  background-size: contain;
  width: 19px;
  height: 14px;
  left: 0;
  top: 8px;
}
.side-by-side .content-media img {
  display: block;
  margin: auto;
  max-width: 370px;
  width: 100%;
}

.privacy-policy {
  background: #FDFAF3 0 0 no-repeat padding-box;
  opacity: 1;
  overflow: visible;
}
.privacy-policy .container .wrapper {
  background: #fff 0 0 no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 100px 112px;
  max-width: 1096px;
  margin: 0 auto;
  margin-top: -226px;
}
.privacy-policy .container .wrapper h1, .privacy-policy .container .wrapper .h1, .privacy-policy .container .wrapper h2, .privacy-policy .container .wrapper .h2, .privacy-policy .container .wrapper h3, .privacy-policy .container .wrapper .h3, .privacy-policy .container .wrapper h4, .privacy-policy .container .wrapper .h4, .privacy-policy .container .wrapper h5, .privacy-policy .container .wrapper .h5, .privacy-policy .container .wrapper h6, .privacy-policy .container .wrapper .h6 {
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  .privacy-policy .container .wrapper {
    max-width: 600px;
    padding: 50px;
  }
}
@media (max-width: 576px) {
  .privacy-policy .container .wrapper {
    padding: 20px;
  }
}
@media (max-width: 768px) {
  .privacy-policy .container .wrapper h5, .privacy-policy .container .wrapper .h5 {
    font-size: 18px;
  }
}
@media (max-width: 576px) {
  .privacy-policy .container .wrapper h4, .privacy-policy .container .wrapper .h4 {
    font-size: 16px;
  }
}
.privacy-policy .container .wrapper p {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
}
.privacy-policy .container .wrapper p a:not(.btn-site):not(.btn-link):not(.btn) {
  font-weight: 600 !important;
  color: #00152F !important;
  font-family: Poppins, sans-serif !important;
}
@media (max-width: 576px) {
  .privacy-policy .container .wrapper p {
    font-size: 18px;
    margin-bottom: 6px;
  }
  .privacy-policy .container .wrapper p a:not(.btn-site):not(.btn-link):not(.btn) {
    font-size: 18px !important;
  }
}
.privacy-policy .container .wrapper hr {
  border: 1px solid #00152F;
  opacity: 1;
  margin-bottom: 40px;
}
.privacy-policy .container .wrapper a {
  font-weight: 600;
}
.privacy-policy .container .wrapper .iub_footer p {
  font-size: 11px !important;
  font-family: Poppins, sans-serif;
}
.privacy-policy .container .wrapper .iub_footer p a:not(.btn-site):not(.btn-link):not(.btn) {
  font-size: 11px !important;
}
.privacy-policy .container .wrapper .iub_footer a:not(.btn-site):not(.btn-link):not(.btn) {
  font-family: Poppins, sans-serif !important;
}
.privacy-policy .container .wrapper ul {
  padding-left: 20px;
}
.privacy-policy .container .wrapper ul li {
  margin-bottom: 21px;
  font-size: 22px;
  line-height: 32px;
}
.privacy-policy .container .wrapper ul li::marker {
  color: #5B6670;
}
@media (max-width: 576px) {
  .privacy-policy .container .wrapper ul li {
    font-size: 18px;
  }
}

.header-privacy-policy .container {
  display: flex;
  flex-direction: column-reverse;
}
.header-privacy-policy .container h1, .header-privacy-policy .container .h1 {
  margin-bottom: 0;
}
.header-privacy-policy .container h6, .header-privacy-policy .container .h6 {
  padding-bottom: 34px;
  margin-bottom: 0;
  font-size: 16px;
  color: #00152F !important;
}
@media (max-width: 768px) {
  .header-privacy-policy .container h6, .header-privacy-policy .container .h6 {
    padding-bottom: 17px;
  }
}
@media (max-width: 991px) {
  .header-privacy-policy:not(.hero-pages):not(.cta-promo-bar):not(.promo-card):not(.hero-single-bg):not(.hero-home):not(.hero-with-form):not(.hero-banner-common) {
    padding: 40px 0px 200px 0px !important;
  }
}

.cta-gated-content .wrapper .row {
  justify-content: space-between;
}
.cta-gated-content__left p {
  font-size: 22px;
}
.cta-gated-content__left h2, .cta-gated-content__left .h2 {
  padding: 27px 0 29px;
  margin-bottom: 0;
}
.cta-gated-content__left ul {
  padding-left: 20px;
}
.cta-gated-content__left ul li {
  margin-bottom: 20px;
}
.cta-gated-content__left ul li::marker {
  color: #5B6670;
}
@media (max-width: 990px) {
  .cta-gated-content__left {
    text-align: center;
    padding-bottom: 60px;
  }
  .cta-gated-content__left ul {
    max-width: 450px;
    margin: 0 auto;
  }
}
@media (max-width: 576px) {
  .cta-gated-content__left {
    padding-bottom: 0;
  }
}
.cta-gated-content__right {
  max-width: 536px;
  padding-top: 39px;
  /*style form*/
  /*end style form*/
}
.cta-gated-content__right .content-img {
  background: transparent linear-gradient(245deg, #800048 0%, #8F4993 100%) 0 0 no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  max-height: 251px;
  height: 100%;
}
.cta-gated-content__right .content-img figure img {
  max-width: 409px;
  border-radius: 10px;
  margin-top: -68px;
}
.cta-gated-content__right .content-form form[id*=gform_] {
  padding: 60px 50px 87px 50px;
  background-color: white;
  box-shadow: 5px 5px 25px rgba(47, 93, 205, 0.1490196078);
  border-radius: 20px;
}
.cta-gated-content__right .content-form form[id*=gform_] .gform-body legend,
.cta-gated-content__right .content-form form[id*=gform_] .gform-body label {
  margin-bottom: 0;
  line-height: normal;
  font-size: 18px;
}
.cta-gated-content__right .content-form form[id*=gform_] .gform-body legend .gfield_required::after,
.cta-gated-content__right .content-form form[id*=gform_] .gform-body label .gfield_required::after {
  content: "*";
  color: #00152F;
  position: relative;
  top: -2px;
}
.cta-gated-content__right .content-form form[id*=gform_] .gform-body legend .gfield_required,
.cta-gated-content__right .content-form form[id*=gform_] .gform-body label .gfield_required {
  padding-left: 0;
}
.cta-gated-content__right .content-form form[id*=gform_] .gform-body legend .gfield_required .gfield_required_text,
.cta-gated-content__right .content-form form[id*=gform_] .gform-body label .gfield_required .gfield_required_text {
  display: none;
}
.cta-gated-content__right .content-form form[id*=gform_] .gform-body span:focus-visible,
.cta-gated-content__right .content-form form[id*=gform_] .gform-body .ginput_container:focus-visible {
  border: none !important;
  outline: none;
}
.cta-gated-content__right .content-form form[id*=gform_] .gform-body input {
  border: none;
  border-bottom: 1px solid #00152F;
  padding-top: 0;
  padding-bottom: 0;
}
.cta-gated-content__right .content-form form[id*=gform_] .gform-body input:focus-visible {
  border-bottom: 3px solid #00152F !important;
  outline: none;
}
@media (max-width: 576px) {
  .cta-gated-content__right .content-form form[id*=gform_] .gform-body .gchoice {
    text-align: center;
  }
}
.cta-gated-content__right .content-form form[id*=gform_] .gform-body .gchoice input {
  width: 19px;
  height: 19px;
}
.cta-gated-content__right .content-form form[id*=gform_] .gform-body .gchoice label {
  font-size: 16px;
}
.cta-gated-content__right .content-form form[id*=gform_] .gform-body .gchoice label a {
  font-size: 16px;
  color: #00152F !important;
}
.cta-gated-content__right .content-form form[id*=gform_] .gform_footer {
  padding-bottom: 0;
}
.cta-gated-content__right .content-form form[id*=gform_] .gform_footer .gform_button {
  background: #00152F 0 0 no-repeat padding-box;
  border: none;
  border-radius: 100px;
  color: white;
  outline: none;
  padding: 20px 40px;
  font-size: 16px;
  font-weight: 700;
}
@media (max-width: 990px) {
  .cta-gated-content__right {
    margin: 0 auto;
    max-width: 770px;
  }
  .cta-gated-content__right .content-img {
    max-width: 536px;
    margin: 0 auto;
  }
  .cta-gated-content__right .content-form form[id*=gform_] {
    padding: 40px;
  }
}
@media (max-width: 576px) {
  .cta-gated-content__right .content-img {
    height: auto;
    max-height: none;
    max-width: 340px;
  }
  .cta-gated-content__right .content-img figure {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .cta-gated-content__right .content-img figure img {
    max-width: 80%;
    margin-top: 0;
  }
  .cta-gated-content__right .content-form form[id*=gform_] {
    padding: 25px;
  }
  .cta-gated-content__right .content-form form[id*=gform_] .gform_footer .gform_button {
    padding: 8px 15px;
    margin: 0 auto;
  }
}
.cta-gated-content .video-container {
  opacity: 0.3;
}
.cta-gated-content .overlay-content {
  background: url("../assets/img/overlay-gated.png") !important;
  background-size: cover !important;
  background-position: 50% 100% !important;
  opacity: 1 !important;
}

.hero-banner-featured-resource {
  overflow: initial;
  /*   .overlay-content{
        background-image: url('../assets/img/overlay-hero-home.png') !important;
        background-size: cover !important;
        background-position: 50% 100% !important;
    } */
}
.hero-banner-featured-resource::before {
  content: "";
  /*   position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 140px;
    z-index: 2;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); */
}
.hero-banner-featured-resource .video-container {
  z-index: 1;
}
.hero-banner-featured-resource h1, .hero-banner-featured-resource .h1 {
  margin-bottom: 27px;
}
@media (min-width: 991px) {
  .hero-banner-featured-resource p {
    font-size: 22px;
  }
}
.hero-banner-featured-resource .content-buttons {
  margin-top: 39px;
}
.hero-banner-featured-resource .featured-resource {
  background: transparent linear-gradient(293deg, #0167A6 0%, #57D19E 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  position: relative;
  max-width: 536px;
  margin: 66px 0 0 auto;
  padding: 40px 63px 47px;
}
.hero-banner-featured-resource .featured-resource .badge {
  margin-bottom: 15px;
}
.hero-banner-featured-resource .featured-resource h3, .hero-banner-featured-resource .featured-resource .h3 {
  font-size: 24px;
  color: #fff !important;
  margin-bottom: 10px;
  font-size: 22px;
  line-height: 1.0909em;
}
.hero-banner-featured-resource .featured-resource p {
  color: #fff !important;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
}
.hero-banner-featured-resource .featured-resource .featured-resources--img {
  width: 100%;
  margin-bottom: 43px;
  max-width: 409px;
  margin: -116px auto 30px;
  overflow: hidden;
  border-radius: 10px;
  padding-bottom: 69.9266%;
  position: relative;
  transform: translateY(146px);
}
.hero-banner-featured-resource .featured-resource .featured-resources--img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.hero-banner-featured-resource .featured-resource .featured-resources--content {
  width: 100%;
}
@media (max-width: 767px) {
  .hero-banner-featured-resource .featured-resource {
    margin: 10px auto 50px auto;
    padding: 30px;
  }
}
@media (max-width: 575px) {
  .hero-banner-featured-resource .featured-resource {
    padding-bottom: 140px;
  }
}

.search-filter-shortcodes {
  position: relative;
  overflow: visible;
}
.search-filter-shortcodes .content-title {
  margin-bottom: 70px;
}
.search-filter-shortcodes .filter-menu-row {
  margin-bottom: 40px;
}
.search-filter-shortcodes .filter-menu-row ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  list-style: none;
  padding: 0;
}
.search-filter-shortcodes .filter-menu-row ul li {
  padding: 0 12px;
  margin: 0 0 12px;
  width: 33.333%;
}
.search-filter-shortcodes .filter-menu-row label {
  width: 100%;
}
.search-filter-shortcodes .filter-menu-row select,
.search-filter-shortcodes .filter-menu-row input {
  background: #fff;
  border-radius: 30px;
  opacity: 1;
  height: 59px;
  border: 1px solid #fff;
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #191919;
  padding: 12px 34px;
  width: 100%;
}
.search-filter-shortcodes .filter-menu-row select::-moz-placeholder, .search-filter-shortcodes .filter-menu-row input::-moz-placeholder {
  color: #2A5F5A;
}
.search-filter-shortcodes .filter-menu-row select::placeholder,
.search-filter-shortcodes .filter-menu-row input::placeholder {
  color: #2A5F5A;
}
.search-filter-shortcodes .filter-menu-row select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23707070'%3E%3Cpath d='M24 24H0V0h24v24z' fill='none' opacity='1'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z'/%3E%3C/svg%3E");
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-position: calc(100% - 28px) 50%;
  background-repeat: no-repeat;
  background-size: 22px;
}
@media (max-width: 991px) {
  .search-filter-shortcodes .filter-menu-row ul li {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .search-filter-shortcodes .filter-menu-row ul li {
    width: 100%;
  }
}
.search-filter-shortcodes .filter-results-data > .row {
  margin: 0 -12px;
}
.search-filter-shortcodes .filter-results-data > .row > div {
  padding: 0 12px;
  margin-bottom: 40px;
}
.search-filter-shortcodes .post-item-box {
  background: transparent linear-gradient(302deg, #0167A6 0%, #57D18B 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  height: 100%;
}
.search-filter-shortcodes .post-item-box:hover .btn, .search-filter-shortcodes .post-item-box:hover .woocommerce-notices-wrapper .woocommerce-message a.button, .woocommerce-notices-wrapper .woocommerce-message .search-filter-shortcodes .post-item-box:hover a.button, .search-filter-shortcodes .post-item-box:hover .woocommerce-info a.button, .woocommerce-info .search-filter-shortcodes .post-item-box:hover a.button, .search-filter-shortcodes .post-item-box:hover .wpcf7 input[type=submit], .wpcf7 .search-filter-shortcodes .post-item-box:hover input[type=submit] {
  text-decoration: underline;
}
.search-filter-shortcodes .post-item-box .link-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.search-filter-shortcodes .post-item-box .post-item--img {
  mix-blend-mode: soft-light;
  overflow: hidden;
  padding-bottom: 45.99%;
  position: relative;
}
.search-filter-shortcodes .post-item-box .post-item--img img {
  position: absolute;
  width: calc(100% + 50px);
  height: calc(100% + 50px);
  -o-object-fit: cover;
  object-fit: cover;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  max-width: 101%;
}
.search-filter-shortcodes .post-item-box .post-item--content {
  text-align: left;
  padding: 30px 36px 44px;
}
.search-filter-shortcodes .post-item-box .post-item--content .badge {
  margin-bottom: 21px;
}
.search-filter-shortcodes .post-item-box .post-item--content .post-reading-time {
  margin-bottom: 10px;
}
.search-filter-shortcodes .post-item-box .post-item--content .post-reading-time span {
  font-size: 14px;
  letter-spacing: 0;
  display: block;
  color: #fff !important;
}
.search-filter-shortcodes .post-item-box .post-item--content h4, .search-filter-shortcodes .post-item-box .post-item--content .h4 {
  color: #fff !important;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: uppercase;
}
.search-filter-shortcodes .post-item-box .post-item--content p {
  color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 18px;
  letter-spacing: 0;
  margin-bottom: 39px;
}
.search-filter-shortcodes .post-item-box .post-item--content .btn, .search-filter-shortcodes .post-item-box .post-item--content .woocommerce-notices-wrapper .woocommerce-message a.button, .woocommerce-notices-wrapper .woocommerce-message .search-filter-shortcodes .post-item-box .post-item--content a.button, .search-filter-shortcodes .post-item-box .post-item--content .woocommerce-info a.button, .woocommerce-info .search-filter-shortcodes .post-item-box .post-item--content a.button, .search-filter-shortcodes .post-item-box .post-item--content .wpcf7 input[type=submit], .wpcf7 .search-filter-shortcodes .post-item-box .post-item--content input[type=submit] {
  position: absolute;
  bottom: 22px;
  left: 36px;
}
@media (max-width: 767px) {
  .search-filter-shortcodes .post-item-box .post-item--content {
    padding: 25px;
  }
}
.search-filter-shortcodes .pagination-results {
  margin-top: 24px;
}
.search-filter-shortcodes .pagination-results .pagination-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk {
  transition: all ease 0.3s;
}
.search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.prev, .search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.next {
  text-decoration: none !important;
  color: #00152F;
  display: flex;
  align-items: center;
  position: relative;
  top: 50%;
  transform: translate(0%, -50%);
  transition: all ease 0.3s;
}
.search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.prev::after, .search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.next::after {
  content: none !important;
}
.search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.prev:not(.disabled) .sr-only-text, .search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.next:not(.disabled) .sr-only-text {
  opacity: 1;
}
.search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.prev:not(.disabled) .circle-arrow, .search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.next:not(.disabled) .circle-arrow {
  background-color: #00152F;
}
.search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.prev:not(.disabled) .circle-arrow svg path, .search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.next:not(.disabled) .circle-arrow svg path {
  stroke: #fff;
}
.search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.prev .sr-only-text, .search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.next .sr-only-text {
  font-size: 18px;
  letter-spacing: 0.9px;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  display: inline-block;
  color: #00152F;
  text-decoration: none !important;
  font-weight: 600;
  margin: 0;
  padding: 0;
  opacity: 0;
}
.search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.prev .sr-only-text:after, .search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.next .sr-only-text:after {
  content: none !important;
}
.search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.prev .circle-arrow, .search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.next .circle-arrow {
  width: 50px;
  height: 50px;
  min-width: 50px;
  display: inline-block;
  text-decoration: none !important;
  border: 1px solid #2A5F5A;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  margin: 0;
}
.search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.prev .circle-arrow:after, .search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.next .circle-arrow:after {
  content: none !important;
}
.search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.prev svg, .search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.next svg {
  width: 24px;
}
.search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.prev {
  left: 0;
}
.search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.prev .sr-only-text {
  margin-left: 20px;
  stroke: #fff;
}
.search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.next {
  right: 0;
}
.search-filter-shortcodes .pagination-results .pagination-nav .page-a-lk.next .sr-only-text {
  margin-right: 20px;
}
.search-filter-shortcodes .pagination-results .page-pagination-content span, .search-filter-shortcodes .pagination-results .page-pagination-content a {
  display: inline-block;
  font-size: 16px;
  color: #00152F;
  padding: 10px;
  text-decoration: none;
  text-align: center;
  /*   text-decoration-line: underline; */
  /*  text-decoration-thickness: 2px; */
  /*       text-underline-offset: 3px; */
  text-transform: uppercase;
  line-height: normal;
  /* text-decoration-color: $green; */
  font-family: "Poppins", sans-serif;
  position: relative;
  padding: 0 5.5px 4px;
  margin: 18px 18px;
}
.search-filter-shortcodes .pagination-results .page-pagination-content span:after, .search-filter-shortcodes .pagination-results .page-pagination-content a:after {
  content: "";
  background-color: #00152F;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 100%;
}
.search-filter-shortcodes .pagination-results .page-pagination-content span.current, .search-filter-shortcodes .pagination-results .page-pagination-content a.current {
  font-weight: 700;
  /*    text-decoration-line: underline;
     text-decoration-thickness: 2px;
     text-underline-offset: 3px;
     text-transform: uppercase;
     font-weight: 700; */
  /*    text-decoration-color: transparent; */
  text-decoration: none;
}
.search-filter-shortcodes .pagination-results .page-pagination-content span.current::after, .search-filter-shortcodes .pagination-results .page-pagination-content a.current::after {
  background-color: transparent;
}
.search-filter-shortcodes .pagination-results .page-pagination-content .pagenumbers {
  display: flex;
  justify-content: center;
}
.search-filter-shortcodes .pagination-results .page-pagination-content .pagenumbers .page-numbers.dots:after {
  content: none;
}
@media (max-width: 991px) {
  .search-filter-shortcodes .content-title {
    margin-bottom: 40px;
  }
  .search-filter-shortcodes .filter-results-data > .row {
    margin: 0 -12px;
  }
  .search-filter-shortcodes .filter-results-data > .row > div {
    padding: 0 12px;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .search-filter-shortcodes .pagination-results {
    margin-top: 10px;
  }
  .search-filter-shortcodes .pagination-results .pagination-nav {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    width: 100%;
    justify-content: space-between;
  }
  .search-filter-shortcodes .pagination-results .pagination-nav .prev.page-a-lk {
    order: 1;
    top: 0;
    transform: initial;
    padding: 0 15px 0 0;
  }
  .search-filter-shortcodes .pagination-results .pagination-nav .prev.page-a-lk .sr-only-text {
    opacity: 1;
    font-size: 16px;
  }
  .search-filter-shortcodes .pagination-results .pagination-nav .next.page-a-lk {
    order: 2;
    top: 0;
    transform: initial;
    padding: 0 0 0 15px;
  }
  .search-filter-shortcodes .pagination-results .pagination-nav .next.page-a-lk .sr-only-text {
    opacity: 1;
    font-size: 16px;
  }
  .search-filter-shortcodes .pagination-results .pagination-nav .page-pagination-content {
    order: 0;
    width: 100%;
    padding: 0 15px;
  }
}
@media (max-width: 360px) {
  .search-filter-shortcodes .pagination-results .pagination-nav .prev.page-a-lk .sr-only-text {
    display: none;
  }
  .search-filter-shortcodes .pagination-results .pagination-nav .next.page-a-lk .sr-only-text {
    display: none;
  }
}

.block-hero-banner-form {
  overflow: initial;
  position: relative;
  /****
   *** start background
   ****/
  /****
   *** end background
   ****/
}
.block-hero-banner-form .content-text {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: 100%;
  padding-bottom: 350px;
}
.block-hero-banner-form .content-text h1, .block-hero-banner-form .content-text .h1,
.block-hero-banner-form .content-text h2,
.block-hero-banner-form .content-text .h2,
.block-hero-banner-form .content-text h3,
.block-hero-banner-form .content-text .h3,
.block-hero-banner-form .content-text h4,
.block-hero-banner-form .content-text .h4,
.block-hero-banner-form .content-text h5,
.block-hero-banner-form .content-text .h5,
.block-hero-banner-form .content-text h6,
.block-hero-banner-form .content-text .h6 {
  letter-spacing: -0.9px;
}
.block-hero-banner-form .content-text img {
  margin-bottom: 10px;
}
.block-hero-banner-form .content-text ul {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-left: 19px;
}
.block-hero-banner-form .content-text ul li {
  padding-left: 10px;
  font-size: 18px;
}
.block-hero-banner-form .content-text ul li strong {
  font-size: 20px;
}
.block-hero-banner-form .content-text ul li::marker {
  content: url(../assets/img/chek-contact.svg);
}
.block-hero-banner-form .content-text ul li ul {
  padding-left: 0;
}
.block-hero-banner-form .content-text ul li ul li {
  padding-left: 0;
}
.block-hero-banner-form .content-text ul li ul li::marker {
  content: none;
}
.block-hero-banner-form .content-text p {
  font-size: 22px;
}
.block-hero-banner-form .content-form {
  height: 100%;
}
.block-hero-banner-form .content-form .content-wrapper {
  position: sticky;
  display: flex;
  justify-content: center;
}
.block-hero-banner-form .content-form .content-wrapper .box {
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 5px 5px 25px rgba(47, 93, 205, 0.1490196078);
  border: 8px solid #fff;
  border-radius: 20px;
  padding: 60px 50px 50px 51px;
  width: 100%;
  max-width: 536px;
  margin: 0 auto;
}
.block-hero-banner-form .content-form .content-wrapper .box .gform_title {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.9px;
  color: #000;
  font-weight: 600;
  margin-bottom: 25px;
}
.block-hero-banner-form .content-form .content-wrapper .box .gform_required_legend {
  display: none;
}
.block-hero-banner-form .content-form .content-wrapper .box input[type=text],
.block-hero-banner-form .content-form .content-wrapper .box input[type=email],
.block-hero-banner-form .content-form .content-wrapper .box input[type=tel],
.block-hero-banner-form .content-form .content-wrapper .box textarea,
.block-hero-banner-form .content-form .content-wrapper .box select {
  border: initial;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.block-hero-banner-form .content-form .content-wrapper .box input[type=checkbox] {
  width: 19px;
  height: 19px;
  border: 1px solid #00152F;
  border-radius: 2px;
}
.block-hero-banner-form .content-form .content-wrapper .box textarea::-moz-placeholder {
  color: #2A5F5A;
}
.block-hero-banner-form .content-form .content-wrapper .box textarea::placeholder {
  color: #2A5F5A;
}
.block-hero-banner-form .content-form .content-wrapper .box legend.gfield_label,
.block-hero-banner-form .content-form .content-wrapper .box label.gfield_label {
  font-weight: 600;
  font-size: 18px;
  color: #191919;
  margin-bottom: 0;
}
.block-hero-banner-form .content-form .content-wrapper .box legend.gfield_label .gfield_required_asterisk,
.block-hero-banner-form .content-form .content-wrapper .box label.gfield_label .gfield_required_asterisk {
  color: #191919;
}
.block-hero-banner-form .content-form .content-wrapper .box .gform_wrapper.gravity-theme .gfield textarea.large {
  height: 35px;
  margin-top: 20px;
  padding-left: 0;
}
.block-hero-banner-form .content-form .content-wrapper .box .ginput_container_consent {
  display: flex;
  align-items: start;
  gap: 15px;
}
.block-hero-banner-form .content-form .content-wrapper .box .ginput_container_consent input[type=checkbox] {
  margin-top: 6px;
}
.block-hero-banner-form .content-form .content-wrapper .box .ginput_container_consent .gfield_consent_label {
  font-size: 16px;
  color: #00152F;
}
.block-hero-banner-form .content-form .content-wrapper .box .ginput_container_consent .gfield_consent_label a {
  font-size: 16px;
  color: #00152F;
  text-decoration: none;
  transition: border-bottom-width 0.2s;
}
.block-hero-banner-form .content-form .content-wrapper .box .ginput_container_consent .gfield_consent_label a:hover {
  border-bottom: 1.5px solid #00152F;
}
@media (hover: hover) {
  .block-hero-banner-form .content-form .content-wrapper .box .ginput_container_consent .gfield_consent_label a:hover:hover {
    font-weight: bold;
  }
}
.block-hero-banner-form .content-form .content-wrapper .box input[type=submit] {
  background-color: #00152F;
  color: #fff;
  border: 1px solid #00152F;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0;
  padding: 16px 39px;
  font-weight: 700;
  border-radius: 100px;
  margin-top: 10px;
  outline-color: #00152F;
}
@media (hover: hover) {
  .block-hero-banner-form .content-form .content-wrapper .box input[type=submit]:hover {
    color: #00152F;
    border-color: #00152F;
    background-color: transparent;
  }
}
.block-hero-banner-form .content-form .content-wrapper .gform_wrapper.gravity-theme .gform_validation_errors,
.block-hero-banner-form .content-form .content-wrapper .gform_wrapper.gravity-theme .validation_message {
  background-color: #fff !important;
}
.block-hero-banner-form .content-form .content-wrapper .gform_wrapper.gravity-theme .gform_validation_errors h2, .block-hero-banner-form .content-form .content-wrapper .gform_wrapper.gravity-theme .gform_validation_errors .h2,
.block-hero-banner-form .content-form .content-wrapper .gform_wrapper.gravity-theme .validation_message h2,
.block-hero-banner-form .content-form .content-wrapper .gform_wrapper.gravity-theme .validation_message .h2 {
  font-size: 15px;
  color: #c02b0a !important;
  font-weight: initial;
  letter-spacing: normal;
  line-height: 25px;
}
.block-hero-banner-form .content-form .content-wrapper .gform_wrapper.gravity-theme .instruction.validation_message {
  display: none;
}
.block-hero-banner-form .content-form .content-wrapper .gform_wrapper.gravity-theme .gfield_error label {
  color: #00152F;
}
.block-hero-banner-form::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(0deg, rgb(253, 250, 243) 45%, rgba(253, 250, 243, 0.5) 105%);
}
.block-hero-banner-form .video-container {
  opacity: 0.5;
}
.block-hero-banner-form .overlay-content {
  background: url("../assets/img/overlay-hero-form.png") !important;
  background-size: cover !important;
  background-position: 50% 100% !important;
  z-index: 1;
}
@media (max-width: 982px) {
  .block-hero-banner-form .content-form .content-wrapper .box {
    padding: 50px 35px;
  }
  .block-hero-banner-form .content-form .content-wrapper .box .ginput_container_consent input[type=checkbox] {
    margin-top: 4px;
  }
}
@media (max-width: 990.98px) {
  .block-hero-banner-form .content-text {
    margin-bottom: initial;
    text-align: center;
    align-items: center;
    padding-bottom: initial;
  }
  .block-hero-banner-form .content-text ul {
    text-align: left;
  }
  .block-hero-banner-form .content-form .content-wrapper .box {
    padding: 40px 30px;
  }
  .block-hero-banner-form .content-form .content-wrapper .box .gform_title {
    text-align: center;
  }
  .block-hero-banner-form .content-form .content-wrapper .box .ginput_container_consent input[type=checkbox] {
    margin-top: 2px;
  }
}
@media (max-width: 575.98px) {
  .block-hero-banner-form .content-form .content-wrapper .box {
    padding: 20px 10px;
  }
  .block-hero-banner-form .content-form .content-wrapper .box .gfield--type-captcha.gfield--input-type-captcha {
    transform: scale(0.6);
    transform-origin: 0 0;
  }
}

.block-accordion-settings .content-accordion {
  display: flex;
  justify-content: center;
}
.block-accordion-settings .content-accordion .accordion {
  width: 100%;
  max-width: 873px;
  --bs-accordion-border-color: initial;
  --bs-accordion-active-color: initial;
  --bs-accordion-active-bg: initial;
  --bs-accordion-btn-focus-border-color: initial;
}
.block-accordion-settings .content-accordion .accordion .accordion-item {
  border-bottom: 1px solid #000;
}
.block-accordion-settings .content-accordion .accordion .accordion-item .accordion-header .accordion-button {
  color: #000;
  font-size: 22px;
  line-height: 32px;
  font-weight: bold;
}
.block-accordion-settings .content-accordion .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background-color: initial;
  background-image: initial;
}
.block-accordion-settings .content-accordion .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}
.block-accordion-settings .content-accordion .accordion .accordion-item .accordion-header .accordion-button.collapsed {
  padding-top: 40px;
  padding-bottom: 40px;
}
.block-accordion-settings .content-accordion .accordion .accordion-item .accordion-header .accordion-button:focus {
  border-color: initial;
  box-shadow: initial;
}
.block-accordion-settings .content-accordion .accordion .accordion-item .accordion-collapse .accordion-body {
  padding: 5px 20px 20px 20px;
}
.block-accordion-settings .content-accordion .accordion .accordion-item .accordion-collapse .accordion-body p {
  color: #00152F !important;
}

.cta-subscribe .content-title {
  max-width: 536px;
}
.cta-subscribe .content-title h2, .cta-subscribe .content-title .h2, .cta-subscribe .content-title h3, .cta-subscribe .content-title .h3 {
  margin-bottom: 11px;
}
.cta-subscribe .content-title p {
  letter-spacing: 0;
}
@media (min-width: 992px) {
  .cta-subscribe .content-title p {
    font-size: 18px;
    letter-spacing: 0;
  }
}
@media (max-width: 992px) {
  .cta-subscribe .content-title {
    max-width: 100% !important;
    text-align: center;
  }
}
@media (max-width: 992px) {
  .cta-subscribe .content-form-gravityform form .gfield--type-checkbox, .cta-subscribe .content-form-gravityform form .gfield--type-captcha {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }
}
.cta-subscribe .content-form-gravityform .gform_wrapper.gravity-theme .gform_validation_errors,
.cta-subscribe .content-form-gravityform .gform_wrapper.gravity-theme .validation_message {
  background-color: #fff !important;
  padding: 10px 24px;
}
.cta-subscribe .content-form-gravityform .gform_wrapper.gravity-theme .gform_validation_errors h2, .cta-subscribe .content-form-gravityform .gform_wrapper.gravity-theme .gform_validation_errors .h2,
.cta-subscribe .content-form-gravityform .gform_wrapper.gravity-theme .validation_message h2,
.cta-subscribe .content-form-gravityform .gform_wrapper.gravity-theme .validation_message .h2 {
  font-size: 15px;
  color: #c02b0a !important;
  font-weight: initial;
  letter-spacing: normal;
  line-height: 25px;
}
.cta-subscribe .content-form-gravityform .gform_wrapper.gravity-theme .instruction.validation_message {
  display: none;
}
.cta-subscribe .content-form-gravityform .gform_wrapper.gravity-theme .gfield_error label {
  color: #fff !important;
}

.cta-promo-bar {
  border-radius: 15px;
  opacity: 1;
}
.cta-promo-bar h1, .cta-promo-bar .h1,
.cta-promo-bar h2,
.cta-promo-bar .h2,
.cta-promo-bar h3,
.cta-promo-bar .h3,
.cta-promo-bar h4,
.cta-promo-bar .h4,
.cta-promo-bar h5,
.cta-promo-bar .h5,
.cta-promo-bar h6,
.cta-promo-bar .h6 {
  margin-bottom: 0 !important;
  letter-spacing: 0;
  font-weight: 700;
}
.cta-promo-bar h4, .cta-promo-bar .h4 {
  font-size: 24px;
  line-height: 1.125em;
}
@media (min-width: 768px) {
  .cta-promo-bar .btn, .cta-promo-bar .woocommerce-notices-wrapper .woocommerce-message a.button, .woocommerce-notices-wrapper .woocommerce-message .cta-promo-bar a.button, .cta-promo-bar .woocommerce-info a.button, .woocommerce-info .cta-promo-bar a.button, .cta-promo-bar .wpcf7 input[type=submit], .wpcf7 .cta-promo-bar input[type=submit] {
    min-width: 209px;
    padding: 19px 36px;
  }
}
.cta-promo-bar .row-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0px 0 27px;
}
.cta-promo-bar .row-banner .col-banner-detail {
  text-align: left;
  padding-right: 15px;
}
.cta-promo-bar .row-banner .col-banner-cta {
  text-align: right;
}
@media (max-width: 991px) {
  .cta-promo-bar {
    margin-bottom: 40px !important;
  }
}
@media (max-width: 767px) {
  .cta-promo-bar .row-banner {
    flex-wrap: wrap;
    padding: 0;
  }
  .cta-promo-bar .row-banner .col-banner-detail {
    padding-right: 0;
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }
  .cta-promo-bar .row-banner .col-banner-cta {
    text-align: center;
    width: 100%;
  }
}

.block-slider-testimonials .content-eyebrow span {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.9px;
}
.block-slider-testimonials .content-eyebrow hr {
  margin: initial;
  margin-top: 5px;
  border-top: 2px solid #000;
  opacity: 0.4;
}
.block-slider-testimonials .progress {
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.25);
  background-image: linear-gradient(to right, #000, #000);
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size 0.4s ease-in-out;
  margin-top: -8px;
}
.block-slider-testimonials .progress-content {
  padding: 0 clamp(60px, 5vw, 100px) 0;
}
.block-slider-testimonials .content-slider .slider {
  padding: 0 clamp(60px, 5vw, 100px) 0;
}
.block-slider-testimonials .content-slider .slider .items-slider .box {
  width: 100%;
  max-width: 848px;
  margin: 0 auto;
  margin-top: clamp(40px, 5vw, 100px);
  margin-bottom: clamp(50px, 5vw, 110px);
}
.block-slider-testimonials .content-slider .slider .items-slider .box p {
  font-size: 30px;
  margin-bottom: 19px;
}
.block-slider-testimonials .content-slider .slider .items-slider .box .figure-logo {
  margin: 0 0 21px;
  max-width: 210px;
}
.block-slider-testimonials .content-slider .slider .items-slider .box .figure-logo img {
  max-width: 100%;
}
.block-slider-testimonials .content-slider .slider .items-slider .box h6, .block-slider-testimonials .content-slider .slider .items-slider .box .h6, .block-slider-testimonials .content-slider .slider .items-slider .box span {
  font-size: 22px;
  padding-left: 20px;
}
.block-slider-testimonials .content-slider .slider .items-slider .box h6, .block-slider-testimonials .content-slider .slider .items-slider .box .h6 {
  position: relative;
  font-weight: initial;
}
.block-slider-testimonials .content-slider .slider .items-slider .box h6::before, .block-slider-testimonials .content-slider .slider .items-slider .box .h6::before {
  content: "-";
  position: absolute;
  left: 0;
}
.block-slider-testimonials .content-slider .slick-custom-arrow {
  width: clamp(30px, 5vw, 50px);
  height: clamp(30px, 5vw, 50px);
}
.block-slider-testimonials .content-slider .slick-custom-arrow::before {
  content: none;
}
.block-slider-testimonials .content-slider .slick-next {
  right: 0;
}
.block-slider-testimonials .content-slider .slick-prev {
  left: 0;
}
.block-slider-testimonials .content-slider .slick-dots {
  opacity: 0;
}
.block-slider-testimonials .content-slider .slick-dots li button:before {
  background-color: #000;
}
@media (max-width: 767px) {
  .block-slider-testimonials .content-slider .slider .items-slider .box p {
    font-size: 20px;
  }
  .block-slider-testimonials .content-slider .slider .items-slider .box h6, .block-slider-testimonials .content-slider .slider .items-slider .box .h6, .block-slider-testimonials .content-slider .slider .items-slider .box span {
    font-size: 18px;
    padding-left: 20px;
  }
}
@media (max-width: 400px) {
  .block-slider-testimonials .progress-content {
    padding: 0 40px;
  }
  .block-slider-testimonials .content-slider .slider {
    padding: 0 40px;
  }
  .block-slider-testimonials .content-slider .slider .items-slider .box p {
    font-size: 18px;
  }
  .block-slider-testimonials .content-slider .slider .items-slider .box h6, .block-slider-testimonials .content-slider .slider .items-slider .box .h6, .block-slider-testimonials .content-slider .slider .items-slider .box span {
    font-size: 14px;
  }
}

.promo-card {
  border-radius: 15px;
  padding: 18px 0 18px !important;
}
.promo-card.alignfull .box-left-pr-bar {
  max-width: 100%;
}
.promo-card.alignfull .figure-content-pr {
  max-width: 100%;
}
.promo-card .box-left-pr-bar {
  max-width: 392px;
}
.promo-card .box-left-pr-bar .badge {
  margin-bottom: 15px;
}
.promo-card .box-left-pr-bar h1, .promo-card .box-left-pr-bar .h1,
.promo-card .box-left-pr-bar h2,
.promo-card .box-left-pr-bar .h2,
.promo-card .box-left-pr-bar h3,
.promo-card .box-left-pr-bar .h3,
.promo-card .box-left-pr-bar h4,
.promo-card .box-left-pr-bar .h4,
.promo-card .box-left-pr-bar h5,
.promo-card .box-left-pr-bar .h5,
.promo-card .box-left-pr-bar h6,
.promo-card .box-left-pr-bar .h6 {
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.promo-card .box-left-pr-bar p {
  font-size: 18px;
  line-height: 1.555555em;
  font-weight: 400;
  letter-spacing: 0;
  margin-bottom: 20px;
}
.promo-card .box-left-pr-bar .btn, .promo-card .box-left-pr-bar .woocommerce-notices-wrapper .woocommerce-message a.button, .woocommerce-notices-wrapper .woocommerce-message .promo-card .box-left-pr-bar a.button, .promo-card .box-left-pr-bar .woocommerce-info a.button, .woocommerce-info .promo-card .box-left-pr-bar a.button, .promo-card .box-left-pr-bar .wpcf7 input[type=submit], .wpcf7 .promo-card .box-left-pr-bar input[type=submit] {
  text-transform: uppercase;
  font-weight: 600;
}
.promo-card .box-left-pr-bar .btn:after, .promo-card .box-left-pr-bar .woocommerce-notices-wrapper .woocommerce-message a.button:after, .woocommerce-notices-wrapper .woocommerce-message .promo-card .box-left-pr-bar a.button:after, .promo-card .box-left-pr-bar .woocommerce-info a.button:after, .woocommerce-info .promo-card .box-left-pr-bar a.button:after, .promo-card .box-left-pr-bar .wpcf7 input[type=submit]:after, .wpcf7 .promo-card .box-left-pr-bar input[type=submit]:after {
  margin-left: 5px;
}
.promo-card .figure-content-pr {
  max-width: 409px;
  position: relative;
}
@media (min-width: 991px) {
  .promo-card .figure-content-pr {
    right: -6px;
  }
}
.promo-card .figure-content-pr img {
  width: 100%;
  display: block;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .promo-card .row {
    flex-direction: column-reverse;
  }
  .promo-card .box-left-pr-bar {
    max-width: 100%;
  }
  .promo-card .figure-content-pr {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

.relared-post {
  background-color: #fff;
  padding: 107px 0 175px;
}
.relared-post .content-title {
  margin-bottom: 69px;
}
.relared-post .content-title.row-content-element .eyebrow {
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.relared-post .content-title.row-content-element .eyebrow .title-related {
  margin-bottom: 15px;
}
.relared-post .content-title.row-content-element .eyebrow .arrow-nav-content {
  margin-bottom: 24px;
}
.relared-post .post-item-box {
  background: transparent linear-gradient(302deg, #0167A6 0%, #57D18B 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  height: 100%;
}
.relared-post .post-item-box:hover .btn, .relared-post .post-item-box:hover .woocommerce-notices-wrapper .woocommerce-message a.button, .woocommerce-notices-wrapper .woocommerce-message .relared-post .post-item-box:hover a.button, .relared-post .post-item-box:hover .woocommerce-info a.button, .woocommerce-info .relared-post .post-item-box:hover a.button, .relared-post .post-item-box:hover .wpcf7 input[type=submit], .wpcf7 .relared-post .post-item-box:hover input[type=submit] {
  text-decoration: underline;
}
.relared-post .post-item-box .link-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.relared-post .post-item-box .post-item--img {
  mix-blend-mode: soft-light;
  overflow: hidden;
  padding-bottom: 45.99%;
  position: relative;
}
.relared-post .post-item-box .post-item--img img {
  position: absolute;
  width: calc(100% + 50px);
  height: calc(100% + 50px);
  -o-object-fit: cover;
  object-fit: cover;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  max-width: 101%;
}
.relared-post .post-item-box .post-item--content {
  text-align: left;
  padding: 30px 36px 44px;
}
.relared-post .post-item-box .post-item--content .badge {
  margin-bottom: 21px;
}
.relared-post .post-item-box .post-item--content .post-reading-time {
  margin-bottom: 10px;
}
.relared-post .post-item-box .post-item--content .post-reading-time span {
  font-size: 14px;
  letter-spacing: 0;
  display: block;
  color: #fff !important;
}
.relared-post .post-item-box .post-item--content h4, .relared-post .post-item-box .post-item--content .h4 {
  color: #fff !important;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: uppercase;
}
.relared-post .post-item-box .post-item--content p {
  color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 18px;
  letter-spacing: 0;
  margin-bottom: 39px;
}
.relared-post .post-item-box .post-item--content .btn, .relared-post .post-item-box .post-item--content .woocommerce-notices-wrapper .woocommerce-message a.button, .woocommerce-notices-wrapper .woocommerce-message .relared-post .post-item-box .post-item--content a.button, .relared-post .post-item-box .post-item--content .woocommerce-info a.button, .woocommerce-info .relared-post .post-item-box .post-item--content a.button, .relared-post .post-item-box .post-item--content .wpcf7 input[type=submit], .wpcf7 .relared-post .post-item-box .post-item--content input[type=submit] {
  position: absolute;
  bottom: 22px;
  left: 36px;
}
@media (max-width: 767px) {
  .relared-post .post-item-box .post-item--content {
    padding: 25px;
  }
}
.relared-post .post-slider-list {
  margin: 0 -12px;
}
.relared-post .post-slider-list .slider-list-space {
  margin-bottom: 0;
}
.relared-post .post-slider-list .slider-list-space .slick-track {
  display: flex;
}
.relared-post .post-slider-list .slider-list-space .slick-track .slick-slide {
  margin: 0 12px;
  height: auto;
}
.relared-post .post-slider-list .slider-list-space .slick-track .slick-slide > div {
  height: 100%;
}
.relared-post .post-slider-list .slider-list-space .slick-track .slick-slide > div > div {
  height: 100%;
}
@media (max-width: 767px) {
  .relared-post .content-title {
    margin-bottom: 40px;
  }
}

.arrow-nav-content .arrow-icon {
  width: 50px;
  height: 50px;
  min-width: 50px;
  display: inline-block;
  text-decoration: none !important;
  border: 1px solid #2A5F5A;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  margin: 0;
  cursor: pointer;
  transition: all ease 0.3s;
}
.arrow-nav-content .arrow-icon svg {
  width: 24px;
}
.arrow-nav-content .arrow-icon.prev-div {
  margin-right: 10px;
}
.arrow-nav-content .arrow-icon:hover {
  background-color: #00152F;
}
.arrow-nav-content .arrow-icon:hover svg path {
  stroke: #fff;
}

.hero_banner-starts .content-title h2, .hero_banner-starts .content-title .h2 {
  font-size: 50px;
}
.hero_banner-starts .content-title p {
  font-size: 22px;
}
.hero_banner-starts .content-title .content-buttons {
  margin-top: 40px;
}
.hero_banner-starts::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 140px;
  z-index: 2;
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
}
.hero_banner-starts .overlay-content {
  background-image: url("../assets/img/overlay-hero-home.png") !important;
  background-size: cover !important;
  background-position: 50% 100% !important;
}
.hero_banner-starts .video-container {
  opacity: 0.2;
  z-index: 1;
}
.hero_banner-starts .content-image-bx-list {
  margin-top: 18px;
}
.hero_banner-starts .content-image-bx-list.active-items .card-stat {
  /*   &.card-1{
        opacity: .25;
    }
    &.card-2{
        opacity: 1;
        z-index: 1;

    }
    &.card-3{
        opacity: 1;
        z-index: 1;
    }
    &.card-4{
        opacity: .25;

    } */
}
.hero_banner-starts .content-image-bx-list .card-stat.card-1 {
  opacity: 0.25;
  /*  h3{
       width: 100%;
   }
   p{
       width: 100%;
       max-width: 167px;
   } */
}
.hero_banner-starts .content-image-bx-list .card-stat.card-2 {
  opacity: 1;
}
.hero_banner-starts .content-image-bx-list .card-stat.card-3 {
  max-width: 320px;
  opacity: 0.6;
}
.hero_banner-starts .content-image-bx-list .card-stat.card-4 {
  /*   flex-wrap: wrap; */
  opacity: 0;
  /*  h3{
       width: 100%;
   }
   p{
       width: 100%;
   } */
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2 {
  opacity: 1;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2 .card-stat.card-1 {
  top: 0;
  left: 100%;
  transform: translate(-100%, 0px);
  z-index: 1;
  opacity: 1;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2 .card-stat.card-1 h3, .hero_banner-starts .content-image-bx-list.animation-1.animation-2 .card-stat.card-1 .h3 {
  width: auto;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2 .card-stat.card-1 p {
  max-width: 100%;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2 .card-stat.card-2 {
  left: 100%;
  top: 100%;
  transform: translate(-100%, -100%);
  z-index: 2;
  opacity: 0;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2 .card-stat.card-2 p {
  max-width: 100%;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2 .card-stat.card-3 {
  top: 0;
  left: 0;
  transform: translate(0px, 0px);
  z-index: 0;
  opacity: 0.25;
  z-index: 2;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2 .card-stat.card-3 p {
  max-width: 100%;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2 .card-stat.card-4 {
  top: 100%;
  left: 0;
  transform: translate(0px, -100%);
  z-index: 0;
  opacity: 0.6;
  z-index: 1;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2 .card-stat.card-4 h3, .hero_banner-starts .content-image-bx-list.animation-1.animation-2 .card-stat.card-4 .h3 {
  width: auto;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2.animation-3 .card-stat.card-1 {
  left: 100%;
  top: 100%;
  transform: translate(-100%, -100%);
  z-index: 2;
  opacity: 0;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2.animation-3 .card-stat.card-1 p {
  max-width: 100%;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2.animation-3 .card-stat.card-2 {
  top: 100%;
  left: 0;
  transform: translate(0, -100%);
  z-index: 0;
  opacity: 0.6;
  z-index: 1;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2.animation-3 .card-stat.card-2 p {
  max-width: 100%;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2.animation-3 .card-stat.card-3 {
  top: 0;
  left: 100%;
  transform: translate(-100%, 0);
  z-index: 2;
  opacity: 1;
  z-index: 1;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2.animation-3 .card-stat.card-3 p {
  max-width: 100%;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2.animation-3 .card-stat.card-4 {
  top: 0;
  left: 0;
  transform: translate(0px, 0px);
  z-index: 0;
  opacity: 0.25;
  z-index: 2;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2.animation-3 .card-stat.card-4 p {
  max-width: 100%;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2.animation-4 .card-stat.card-1 {
  top: 100%;
  left: 0;
  opacity: 0.6;
  transform: translate(0, -100%);
  z-index: 0;
  z-index: 1;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2.animation-4 .card-stat.card-1 p {
  max-width: 100%;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2.animation-4 .card-stat.card-2 {
  top: 0;
  left: 0;
  transform: translate(0, 0);
  opacity: 0.25;
  z-index: 2;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2.animation-4 .card-stat.card-2 p {
  max-width: 100%;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2.animation-4 .card-stat.card-3 {
  left: 100%;
  top: 100%;
  transform: translate(-100%, -100%);
  z-index: 2;
  opacity: 0;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2.animation-4 .card-stat.card-3 p {
  max-width: 100%;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2.animation-4 .card-stat.card-4 {
  top: 0;
  left: 100%;
  transform: translate(-100%, 0);
  z-index: 0;
  opacity: 1;
}
.hero_banner-starts .content-image-bx-list.animation-1.animation-2.animation-4 .card-stat.card-4 p {
  max-width: 100%;
}
@media (max-width: 767px) {
  .hero_banner-starts .content-title h2, .hero_banner-starts .content-title .h2 {
    font-size: 30px;
  }
  .hero_banner-starts .content-title p {
    font-size: 18px;
  }
  .hero_banner-starts .content-title .content-buttons {
    margin-top: 30px;
  }
}
@media (max-width: 558px) {
  .hero_banner-starts .content-image-bx-list.active-items .card-stat.card-1 {
    opacity: 0.25;
    z-index: 0;
  }
  .hero_banner-starts .content-image-bx-list.active-items .card-stat.card-2 {
    opacity: 1;
    z-index: 1;
  }
  .hero_banner-starts .content-image-bx-list.active-items .card-stat.card-3 {
    opacity: 1;
    z-index: 1;
  }
  .hero_banner-starts .content-image-bx-list.active-items .card-stat.card-4 {
    opacity: 0.25;
    z-index: 0;
  }
  .hero_banner-starts .content-image-bx-list .card-stat.card-1 {
    max-width: 165px;
    z-index: 1;
  }
  .hero_banner-starts .content-image-bx-list .card-stat.card-2 {
    max-width: 165px;
  }
  .hero_banner-starts .content-image-bx-list .card-stat.card-3 {
    max-width: 165px;
  }
  .hero_banner-starts .content-image-bx-list .card-stat.card-4 {
    max-width: 165px;
    z-index: 1;
  }
}

.content-image-bx-list {
  position: relative;
  padding: 100px 25px;
  margin-top: 55px;
  max-width: 610px;
  margin-left: auto;
}
.content-image-bx-list img {
  border-radius: 25px;
  min-height: 250px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.content-image-bx-list .card-stat {
  max-width: 305px;
  -moz-column-gap: 15px;
  column-gap: 15px;
  box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.1490196078);
  position: absolute;
  transition: all ease 0.8s;
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .content-image-bx-list .card-stat {
    padding: 30px;
  }
}
.content-image-bx-list .card-stat h3, .content-image-bx-list .card-stat .h3 {
  font-weight: 600;
  width: auto;
}
.content-image-bx-list .card-stat p {
  max-width: 120px;
}
.content-image-bx-list .card-stat.card-1 {
  top: 0;
  left: 0;
  opacity: 1;
  transform: translate(0px, 0px);
  max-width: 249px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.content-image-bx-list .card-stat.card-1 h3, .content-image-bx-list .card-stat.card-1 .h3 {
  width: 100%;
}
.content-image-bx-list .card-stat.card-1 p {
  width: 100%;
}
.content-image-bx-list .card-stat.card-2 {
  top: 0;
  left: 100%;
  transform: translate(-100%, 0px);
  max-width: 319px;
  width: 100%;
  opacity: 0.6;
  display: flex;
  flex-wrap: nowrap;
}
.content-image-bx-list .card-stat.card-2 h3, .content-image-bx-list .card-stat.card-2 .h3 {
  width: auto;
}
.content-image-bx-list .card-stat.card-2 p {
  width: 100%;
}
.content-image-bx-list .card-stat.card-3 {
  top: 100%;
  left: 0;
  opacity: 0.6;
  transform: translate(0px, -100%);
  max-width: 320px;
  width: 100%;
  display: flex;
}
.content-image-bx-list .card-stat.card-3 h3, .content-image-bx-list .card-stat.card-3 .h3 {
  width: auto;
}
.content-image-bx-list .card-stat.card-3 p {
  width: 100%;
}
.content-image-bx-list .card-stat.card-4 {
  left: 100%;
  top: 100%;
  opacity: 1;
  transform: translate(-100%, -100%);
  max-width: 212px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.content-image-bx-list .card-stat.card-4 h3, .content-image-bx-list .card-stat.card-4 .h3 {
  width: 100%;
}
.content-image-bx-list .card-stat.card-4 p {
  width: 100%;
}
@media (max-width: 700px) {
  .content-image-bx-list .card-stat {
    flex-wrap: wrap !important;
    padding: 15px;
    max-width: 150px !important;
  }
  .content-image-bx-list .card-stat h3, .content-image-bx-list .card-stat .h3 {
    width: 100% !important;
  }
  .content-image-bx-list .card-stat p {
    max-width: 100% !important;
  }
}
@media (max-width: 1200px) {
  .content-image-bx-list {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }
}
.slider-logos .content-title {
  margin: 0 0 91px;
}
.slider-logos .slider-logo-img {
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1019607843);
  border-radius: 5px;
  opacity: 1;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 0 59%;
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  min-height: 120px;
  max-height: 120px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slider-logos .slider-logo-img img {
  max-width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
}
.slider-logos .list-img-gallery {
  margin: 0 -12px;
}
.slider-logos .progress-content {
  padding: 0 90px;
}
.slider-logos .progress {
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 0;
  overflow: hidden;
  background-color: hsla(0deg, 0%, 100%, 0.393);
  background-image: linear-gradient(to right, #fff, #fff);
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size 0.4s ease-in-out;
}
.slider-logos .gallery-istems-js {
  padding: 0 90px;
}
.slider-logos .gallery-istems-js .slick-slide {
  margin: 0 12px;
}
.slider-logos .gallery-istems-js .slick-dots {
  margin: 0 12px;
}
.slider-logos .gallery-istems-js .slick-arrow {
  width: 50px;
  height: 50px;
  z-index: 5;
}
.slider-logos .gallery-istems-js .slick-arrow:before {
  content: "";
  background-size: cover !important;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}
.slider-logos .gallery-istems-js .slick-arrow.slick-prev {
  left: 0;
}
.slider-logos .gallery-istems-js .slick-arrow.slick-prev:before {
  background: url("../assets/img/slider-arrow-withe-left.svg");
}
.slider-logos .gallery-istems-js .slick-arrow.slick-next {
  right: 0;
}
.slider-logos .gallery-istems-js .slick-arrow.slick-next:before {
  background: url("../assets/img/slider-arrow-white-right.svg");
}
.slider-logos .slider-nav-dots {
  margin-top: 118px;
  padding: 0 90px;
  margin-bottom: -3px !important;
  opacity: 0;
}
.slider-logos .slider-nav-dots .slick-slide {
  display: none !important;
}
.slider-logos .slider-nav-dots .slick-dots {
  position: relative !important;
  bottom: 0 !important;
}
@media (max-width: 991px) {
  .slider-logos .slider-nav-dots {
    margin-top: 48px;
  }
}
@media (max-width: 575px) {
  .slider-logos .gallery-istems-js,
  .slider-logos .slider-nav-dots,
  .slider-logos .progress-content {
    padding: 0 25px;
  }
  .slider-logos .gallery-istems-js .slick-arrow:before {
    width: 30px;
    height: 30px;
  }
  .slider-logos .gallery-istems-js .slick-arrow {
    width: 25px;
    height: 25px;
  }
}

.meet-the-team .content-title {
  margin-bottom: 46px;
}
.meet-the-team .list-team-items {
  margin-bottom: -49px;
}
.meet-the-team .list-team-items .row > div {
  margin-bottom: 49px;
}
@media (max-width: 768px) {
  .meet-the-team .list-team-items .row > div {
    width: 33.333%;
  }
}
@media (max-width: 450px) {
  .meet-the-team .list-team-items .row > div {
    width: 50%;
    padding-left: 10%;
  }
}
@media (max-width: 325px) {
  .meet-the-team .list-team-items .row {
    margin: 0 -5px;
  }
  .meet-the-team .list-team-items .row > div {
    padding: 0 5px;
  }
}

.post-team-bx .figure-team {
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 39px;
}
.post-team-bx .figure-team:after {
  content: "";
  opacity: 0.25;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: linear-gradient(0deg, #00152F 0, #0167A6 100%, #57D18B);
}
.post-team-bx .figure-team img {
  width: 100%;
  height: 100%;
  position: relative;
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top;
  object-position: top;
  z-index: 1;
  filter: saturate(0);
}
.post-team-bx h5, .post-team-bx .h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
}
.post-team-bx h6, .post-team-bx .h6 {
  font-family: "Figtree", sans-serif;
  margin-bottom: 0;
  font-weight: 400;
}
.post-team-bx > *:last-child {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .post-team-bx .figure-team {
    margin-bottom: 20px;
  }
}
@media (max-width: 450px) {
  .post-team-bx .figure-team {
    width: 100px;
    height: 100px;
  }
}

.block-cards-stats .content-title {
  margin-bottom: clamp(30px, 5vw, 50px);
}
.block-cards-stats .content-repeater {
  /* display: flex;
   flex-flow: row wrap;
   justify-content: space-between;
   gap: 30px;*/
}
.block-cards-stats .content-repeater .box {
  /*width: min(100%, 320px);*/
  background: transparent linear-gradient(298deg, #0167A6 0%, #57D19E 100%) 0 0 no-repeat padding-box;
  box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.1490196078);
  border-radius: 15px;
  opacity: 1;
  padding: 48px 35px 60px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  max-width: 320px;
  min-height: 176px;
  width: 100%;
  height: 100%;
}
.block-cards-stats .content-repeater .box__title {
  display: flex;
  font-size: 40px;
  letter-spacing: -1.2px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 800;
  text-align: left;
}
.block-cards-stats .content-repeater .box__text {
  letter-spacing: 0;
  color: #fff !important;
  opacity: 1;
  line-height: 20px;
  text-align: left;
  font-size: 16px;
  text-align: left;
  margin-bottom: 0;
}
.block-cards-stats .content-repeater .box.margin-right-auto {
  margin-right: auto;
}
.block-cards-stats .content-repeater .box.margin-left-auto {
  margin-left: auto;
}
.block-cards-stats .content-repeater .box.margin-left-right-auto {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 991px) {
  .block-cards-stats .content-repeater .box {
    padding: 35px;
    min-height: 160px;
    max-width: 100%;
  }
  .block-cards-stats .content-repeater .box__title {
    font-size: 30px;
    line-height: 34px;
  }
  .block-cards-stats .content-repeater .box__text {
    font-size: 14px;
    line-height: 16px;
  }
}
@media (max-width: 576px) {
  .block-cards-stats .content-repeater .box {
    min-height: auto;
  }
  .block-cards-stats .content-repeater .box__title {
    justify-content: center;
  }
  .block-cards-stats .content-repeater .box__text {
    text-align: center;
  }
}

.block-columns-with-list .content-title {
  margin-bottom: clamp(40px, 5vw, 100px);
}
.block-columns-with-list .content-columns .box {
  display: flex;
  flex-direction: column;
  row-gap: clamp(10px, 5vw, 15px);
  width: min(100%, 423px);
}
.block-columns-with-list .content-columns .box hr {
  margin: 20px 0 16px;
  opacity: 1;
}
.block-columns-with-list .content-columns .box h5, .block-columns-with-list .content-columns .box .h5 {
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 0;
  color: #000000;
  text-transform: uppercase;
  text-align: left;
}
.block-columns-with-list .content-columns .box ul {
  display: flex;
  flex-direction: column;
  row-gap: clamp(8px, 5vw, 15px);
  padding-left: 27px;
}
.block-columns-with-list .content-columns .box ul li {
  list-style-image: url(../assets/img/chek-contact.svg);
  padding-left: 10px;
  text-align: left;
  letter-spacing: 0;
  color: #00152F;
  text-transform: capitalize;
  font-weight: 600;
  width: min(100%, 367px);
}
@media (max-width: 766px) {
  .block-columns-with-list .content-columns .box {
    margin: 0 auto;
  }
  .block-columns-with-list .content-columns .box h5, .block-columns-with-list .content-columns .box .h5 {
    font-size: 20px;
  }
}

.columns-with-top-line .content-text h2, .columns-with-top-line .content-text .h2, .columns-with-top-line .content-text h3, .columns-with-top-line .content-text .h3, .columns-with-top-line .content-text h4, .columns-with-top-line .content-text .h4, .columns-with-top-line .content-text h5, .columns-with-top-line .content-text .h5, .columns-with-top-line .content-text h6, .columns-with-top-line .content-text .h6 {
  max-width: 1120px;
}
.columns-with-top-line .content-text h2, .columns-with-top-line .content-text .h2 {
  font-size: 50px;
  letter-spacing: -1.5px;
  margin-bottom: 30px;
}
.columns-with-top-line .content-text p {
  font-size: 22px;
  max-width: 1096px;
}
.columns-with-top-line .content-text p:last-child {
  margin-bottom: 50px;
}
.columns-with-top-line .content-columns {
  margin-bottom: 40px;
}
.columns-with-top-line .content-columns__item {
  border-top: 1px solid #00152F;
  padding-top: 20px;
  margin-bottom: 50px;
}
.columns-with-top-line .content-columns__item p {
  font-size: 18px;
}
@media (max-width: 991px) {
  .columns-with-top-line .content-text h2, .columns-with-top-line .content-text .h2 {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .columns-with-top-line .content-columns {
    margin-bottom: 0;
  }
  .columns-with-top-line .content-text h2, .columns-with-top-line .content-text .h2 {
    font-size: 30px;
  }
}

.columns-with-checklist .content-title {
  margin-bottom: 42px;
}
.columns-with-checklist .content-title > h1, .columns-with-checklist .content-title > .h1, .columns-with-checklist .content-title > h2, .columns-with-checklist .content-title > .h2, .columns-with-checklist .content-title > h3, .columns-with-checklist .content-title > .h3, .columns-with-checklist .content-title > h4, .columns-with-checklist .content-title > .h4, .columns-with-checklist .content-title > h5, .columns-with-checklist .content-title > .h5, .columns-with-checklist .content-title > h6, .columns-with-checklist .content-title > .h6 {
  margin-bottom: 32px;
}
.columns-with-checklist .box-checklist {
  padding: 0 0 0 39px;
  position: relative;
  max-width: 560px;
}
.columns-with-checklist .box-checklist:before {
  content: "";
  background-image: url(../img/checkmark.svg);
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 8px;
}
.columns-with-checklist .box-checklist h3, .columns-with-checklist .box-checklist .h3 {
  letter-spacing: -0.68px;
  font-weight: 500;
  margin-bottom: 15px;
}
.columns-with-checklist .box-checklist p {
  color: #00152F !important;
}
@media (min-width: 991px) {
  .columns-with-checklist .box-checklist p {
    font-size: 22px;
    letter-spacing: 0;
    line-height: 1.454545em;
  }
}
.columns-with-checklist .box-checklist > *:last-child {
  margin-bottom: 0;
}
.columns-with-checklist .list-columns-checklist {
  margin-bottom: -50px;
}
.columns-with-checklist .list-columns-checklist > .row > div {
  margin-bottom: 50px;
}
@media (max-width: 766.98px) {
  .columns-with-checklist .content-title > h1, .columns-with-checklist .content-title > .h1, .columns-with-checklist .content-title > h2, .columns-with-checklist .content-title > .h2, .columns-with-checklist .content-title > h3, .columns-with-checklist .content-title > .h3, .columns-with-checklist .content-title > h4, .columns-with-checklist .content-title > .h4, .columns-with-checklist .content-title > h5, .columns-with-checklist .content-title > .h5, .columns-with-checklist .content-title > h6, .columns-with-checklist .content-title > .h6 {
    margin-bottom: 20px;
  }
}

.columns-with-cards .content-text {
  padding-top: 20px;
}
.columns-with-cards .content-columns {
  padding-top: 73px;
  padding-bottom: 124px;
}
.columns-with-cards .content-columns .content-columns__item .content-img {
  max-width: 260px;
  padding-bottom: 58px;
}
.columns-with-cards .content-columns .content-columns__item h5, .columns-with-cards .content-columns .content-columns__item .h5 {
  border-bottom: 1px solid #00152F;
  padding-bottom: 20px;
  font-weight: 700;
  font-size: 22px;
}
.columns-with-cards .content-columns .content-columns__item p {
  font-size: 18px;
  padding-top: 34px;
  margin-bottom: 0;
  line-height: 28px;
}
@media (max-width: 1199px) {
  .columns-with-cards .content-columns .row {
    row-gap: 80px;
  }
}

.hero-image {
  background-size: cover !important;
  background-position: center !important;
}
.hero-image .row {
  flex-direction: column;
  align-items: normal !important;
}
.hero-image .row .col-lg-6 {
  width: 100%;
}
.hero-image .row .content-text-hero {
  padding-bottom: 113px;
}
.hero-image .row .content-desktop {
  margin: 0 auto;
}
.hero-image .row .content-desktop .conten-media {
  margin: 0 auto;
  max-width: 100%;
  text-align: center;
  box-shadow: none !important;
  border-radius: 0px !important;
}
.hero-image .row .content-desktop .conten-media img {
  max-width: 1096px;
  width: 100%;
  border-radius: 0;
  box-shadow: none !important;
}
@media (max-width: 1151px) {
  .hero-image .row .content-desktop .conten-media img {
    max-width: 100%;
  }
}
@media (max-width: 991px) {
  .hero-image {
    padding: 30px 0 0 0px !important;
  }
  .hero-image .content-text-hero {
    padding-bottom: 50px !important;
    text-align: center;
  }
  .hero-image .content-text-hero .breadcrumb-site {
    text-align: left !important;
  }
  .hero-image .content-text-hero .conten-media {
    margin-top: 50px;
    box-shadow: none !important;
  }
  .hero-image .content-text-hero .conten-media img {
    box-shadow: none;
  }
}
@media (max-width: 576px) {
  .hero-image {
    background-size: auto !important;
  }
  .hero-image .content-text-hero .conten-media {
    margin-top: 20px;
  }
}
@media (max-width: 400px) {
  .hero-image {
    background-size: auto !important;
  }
}

.block-accordion-button .content-text p {
  font-size: 22px;
  max-width: 1094px;
}
.block-accordion-button .content-text .content-buttons a {
  margin-top: 25px;
}
.block-accordion-button .midle-before {
  position: absolute;
  background-position: 50% 0;
  width: 100%;
  left: 0;
  bottom: 22%;
  opacity: 0.7;
  height: 314px;
  background: linear-gradient(0deg, rgba(87, 209, 139, 0.5) 0, transparent 40%);
  z-index: 2;
  -webkit-mask-image: url(../img/bg-img-center.png);
  mask-image: url(../img/bg-img-center.png);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}
.block-accordion-button .midle-before:after {
  content: "";
  max-width: 839px;
  width: 100%;
  height: 619px;
  position: absolute;
  transform: translate(0, 0) rotate(178deg);
  background: radial-gradient(ellipse at center, #ffbd07 0, #FDFAF3 37%, transparent 64%);
  mix-blend-mode: color;
  opacity: 0.19;
  filter: blur(50px);
  left: -95px;
}
.block-accordion-button .accordion-column {
  margin-top: 98px;
}
.block-accordion-button .accordion-column .content-accordion {
  display: flex;
  justify-content: center;
  max-width: 1096px;
  padding: 77px 82px;
  background-color: #fff;
  margin: 0 auto;
}
.block-accordion-button .accordion-column .content-accordion .accordion {
  width: 100%;
  --bs-accordion-border-color: initial;
  --bs-accordion-active-color: initial;
  --bs-accordion-active-bg: initial;
  --bs-accordion-btn-focus-border-color: initial;
}
.block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header {
  margin-bottom: 15px;
}
.block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .accordion-button {
  border: 2px solid #00152F;
  border-radius: 50px;
  padding: 12px;
  position: relative;
}
.block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .accordion-button h3, .block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .accordion-button .h3 {
  font-size: 22px;
  line-height: 32px;
  font-weight: bold;
  color: #00152F !important;
  padding: 0 30px;
  max-width: 360px;
}
.block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .accordion-button p {
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #00152F;
  margin-right: 50px;
}
.block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .accordion-button h3, .block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .accordion-button .h3,
.block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .accordion-button p {
  margin-bottom: 0;
}
.block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .accordion-button:focus {
  border-color: initial;
  box-shadow: initial;
}
.block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .accordion-button:after {
  content: "";
  margin-left: 0;
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  color: #00152F;
  display: inline-block;
  transition: transform 0.3s;
  background-image: var(--bs-accordion-btn-icon);
}
.block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .accordion-button.open:after {
  transform: translateY(-50%) rotate(180deg);
}
.block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .no-subheading h3, .block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .no-subheading .h3 {
  max-width: 100%;
}
.block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-collapse .accordion-body {
  padding: 38px 20px 37px 20px;
}
.block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-collapse .accordion-body h6 strong, .block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-collapse .accordion-body .h6 strong {
  font-weight: 700;
}
.block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-collapse .accordion-body hr {
  border-top: 2px solid;
}
.block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-collapse .accordion-body p {
  color: #00152F !important;
  font-size: 22px;
  margin-top: 40px;
}
@media (max-width: 1110px) {
  .block-accordion-button .accordion-column .content-accordion {
    padding: 50px 40px;
  }
}
@media (max-width: 991px) {
  .block-accordion-button .accordion-column {
    margin-top: 45px;
  }
}
@media (max-width: 768px) {
  .block-accordion-button .accordion-column .content-accordion {
    padding: 20px;
  }
  .block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .accordion-button {
    border-radius: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
  .block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .accordion-button h3, .block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .accordion-button .h3 {
    font-size: 18px;
    line-height: 28px;
  }
  .block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .accordion-button p {
    font-size: 14px;
    padding: 0 30px;
    margin-right: 0;
  }
  .block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .accordion-button:after {
    right: 31px;
  }
  .block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-collapse .accordion-body {
    padding: 5px 20px 20px 20px;
  }
  .block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-collapse .accordion-body p {
    font-size: 16px;
    margin-top: 20px;
  }
}
@media (max-width: 576px) {
  .block-accordion-button .content-text p {
    font-size: 17px;
  }
  .block-accordion-button .accordion-column {
    padding-right: 0;
    padding-left: 0;
  }
  .block-accordion-button .accordion-column .content-accordion {
    padding: 20px 10px;
  }
  .block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .accordion-button h3, .block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .accordion-button .h3 {
    font-size: 16px;
    line-height: 26px;
    padding: 0 12px;
  }
  .block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .accordion-button p {
    font-size: 12px;
    padding: 0 12px;
  }
  .block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-header .accordion-button:after {
    right: 15px;
  }
  .block-accordion-button .accordion-column .content-accordion .accordion .accordion-item .accordion-collapse .accordion-body {
    padding: 5px 20px 20px 20px;
  }
}

.media-object {
  padding: 135px 0 50px !important;
}
.media-object .row {
  row-gap: 60px;
}
.media-object.media-object-section-center .content-img {
  min-height: initial;
}
.media-object.media-object-section-center .row {
  align-items: center;
}
.media-object #cont-img {
  position: relative;
  margin-top: 15px;
  min-height: 370px;
  display: flex;
  align-items: start;
}
.media-object #cont-img figure {
  margin: 0 auto;
  max-width: 530px;
}
.media-object #cont-img figure img {
  border-radius: 15px;
}
.media-object #cont-img::before {
  content: "";
  position: absolute;
  background: url(../assets/img/bg-media-object.png);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
  width: 696px;
  height: 696px;
  top: -208px;
  left: -140px;
}
@media (max-width: 991px) {
  .media-object #cont-img::before {
    height: clamp(200px, 100vw, 723px);
    transform: scale(0.9);
    top: -170px;
    left: 80px;
  }
}
@media (max-width: 767px) {
  .media-object #cont-img::before {
    top: -160px;
    left: 10px;
    transform: scale(1.2, 1);
  }
}
@media (max-width: 576px) {
  .media-object #cont-img::before {
    top: -44px;
    transform: scale(1.5, 1.3);
  }
}
.media-object .content-text {
  padding-right: 25px;
}
.media-object .content-text h2, .media-object .content-text .h2 {
  font-size: 50px;
  letter-spacing: -1.5px;
  margin-bottom: 30px;
}
.media-object .content-text p {
  font-size: 22px;
}
.media-object .content-text .content-buttons {
  margin-top: 40px;
}
@media (max-width: 991px) {
  .media-object .content-text {
    padding-right: 0;
  }
}
@media (max-width: 991px) {
  .media-object #cont-img {
    margin-top: 40px;
    min-height: auto;
  }
  .media-object .content-text p {
    font-size: 17px;
  }
  .media-object .content-text .content-buttons {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .media-object .content-text h2, .media-object .content-text .h2 {
    font-size: 28px;
    line-height: 1.3em;
    letter-spacing: -0.5px;
    margin-bottom: 20px;
  }
}
.media-object .flex-row-reverse .content-text {
  padding-left: 25px;
  padding-right: 0;
}
@media (max-width: 991px) {
  .media-object .flex-row-reverse .content-text {
    padding-left: 0px !important;
  }
}

.block-side-by-side-list .content-title {
  margin-bottom: 80px;
}
.block-side-by-side-list .content-title > h1, .block-side-by-side-list .content-title > .h1, .block-side-by-side-list .content-title > h2, .block-side-by-side-list .content-title > .h2, .block-side-by-side-list .content-title > h3, .block-side-by-side-list .content-title > .h3, .block-side-by-side-list .content-title > h4, .block-side-by-side-list .content-title > .h4, .block-side-by-side-list .content-title > h5, .block-side-by-side-list .content-title > .h5, .block-side-by-side-list .content-title > h6, .block-side-by-side-list .content-title > .h6 {
  margin-bottom: 40px;
}
.block-side-by-side-list .content-group {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
.block-side-by-side-list .content-group .box-title {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
}
.block-side-by-side-list .content-group .box-repeater {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  list-style-type: none;
}
.block-side-by-side-list .content-group .box-repeater li {
  padding: 20px 0 30px 35px;
  border-bottom: 2px solid rgba(25, 25, 25, 0.5);
  /*&::marker {
      //content: none;
      display: none!important;
  }*/
}
.block-side-by-side-list .content-group .box-repeater li:first-child {
  border-top: 2px solid rgba(25, 25, 25, 0.5);
}
.block-side-by-side-list .content-group .box-repeater li h5, .block-side-by-side-list .content-group .box-repeater li .h5 {
  letter-spacing: 0;
  color: #00152F;
  text-transform: capitalize;
  width: min(100%, 461px);
  position: relative;
  font-weight: 600;
  text-align: left;
}
.block-side-by-side-list .content-group .box-repeater li h5::before, .block-side-by-side-list .content-group .box-repeater li .h5::before {
  content: "";
  position: absolute;
  left: -35px;
  top: 6px;
  background-image: url(../assets/img/chek-contact.svg);
  width: 17px;
  height: 13px;
  background-repeat: no-repeat;
  background-size: 100%;
}
.block-side-by-side-list .content-group .box-repeater li p {
  font-size: 18px;
  width: min(100%, 461px);
  letter-spacing: 0;
  color: #00152F;
  text-align: left;
}
.block-side-by-side-list .content-group .box-repeater li .btn, .block-side-by-side-list .content-group .box-repeater li .woocommerce-notices-wrapper .woocommerce-message a.button, .woocommerce-notices-wrapper .woocommerce-message .block-side-by-side-list .content-group .box-repeater li a.button, .block-side-by-side-list .content-group .box-repeater li .woocommerce-info a.button, .woocommerce-info .block-side-by-side-list .content-group .box-repeater li a.button, .block-side-by-side-list .content-group .box-repeater li .wpcf7 input[type=submit], .wpcf7 .block-side-by-side-list .content-group .box-repeater li input[type=submit] {
  padding: 5px 25px;
}
.block-side-by-side-list .content-media {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.block-side-by-side-list .content-media .box {
  position: relative;
}
.block-side-by-side-list .content-media .box::before {
  content: "";
  position: absolute;
  left: -15%;
  bottom: -33%;
  width: 800px;
  height: 800px;
  background-image: url(../assets/img/ellipse_hot_it_works.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.block-columns-with-top-line-tabs .content-title {
  margin-bottom: clamp(30px, 5vw, 65px);
}
.block-columns-with-top-line-tabs .content-tabs .box {
  display: flex;
  flex-direction: column;
  row-gap: 23px;
}
.block-columns-with-top-line-tabs .content-tabs .box > ul {
  gap: clamp(10px, 5vw, 20px);
}
.block-columns-with-top-line-tabs .content-tabs .box > ul .nav-link {
  letter-spacing: 0;
  color: #2A5F5A;
  text-transform: uppercase;
  border: 2px solid #2A5F5A;
  border-radius: 100px;
  font-size: 22px;
  font-weight: 700;
  font-family: Poppins, sans-serif;
  padding: 16px 40px;
}
.block-columns-with-top-line-tabs .content-tabs .box > ul .nav-link.active {
  color: #fff;
  font-family: Poppins, sans-serif;
  background: #00152F 0 0 no-repeat padding-box;
  border: 2px solid #00152F;
  border-radius: 100px;
  padding: 16px 40px;
}
.block-columns-with-top-line-tabs .content-tabs .box .wrapper-tab .tab-pane > h3, .block-columns-with-top-line-tabs .content-tabs .box .wrapper-tab .tab-pane > .h3 {
  margin-bottom: 18px;
  letter-spacing: -0.68px;
  color: #000000 !important;
  text-align: left;
}
.block-columns-with-top-line-tabs .content-tabs .box .wrapper-tab .tab-pane > p {
  letter-spacing: 0;
  color: #00152F;
  font-size: 22px !important;
  text-align: left;
}
.block-columns-with-top-line-tabs .content-tabs .box .wrapper-tab .tab-pane .content-lists {
  margin-top: clamp(20px, 5vw, 35px);
}
.block-columns-with-top-line-tabs .content-tabs .box .wrapper-tab .tab-pane .content-lists .content-repeater {
  display: flex;
  flex-direction: column;
}
.block-columns-with-top-line-tabs .content-tabs .box .wrapper-tab .tab-pane .content-lists .content-repeater > hr {
  border-top: 2px solid rgba(0, 0, 0, 0.4);
  opacity: 1;
}
.block-columns-with-top-line-tabs .content-tabs .box .wrapper-tab .tab-pane .content-lists .content-repeater p {
  font-size: 18px;
  color: #00152F !important;
  flex-grow: 1;
}
.block-columns-with-top-line-tabs .content-tabs .box .wrapper-tab .tab-pane .content-lists .content-repeater a {
  text-align: start;
}

/*# sourceMappingURL=child-theme.css.map */
.cta-gated-content{
    // background-color: #f1e4e9 !important;
    .wrapper{
        .row{
            justify-content: space-between;
        }
    }
    &__left{
        p{
            font-size: 22px;
        }
        h2{
            padding: 27px 0 29px;
            margin-bottom: 0;
        }
        ul{
            padding-left: 20px;
            li{
                margin-bottom: 20px;
            }
            li::marker{
                color: #5B6670;
            }
        }
        @media (max-width: 990px) {
            text-align: center;
            padding-bottom: 60px;
            ul{
                max-width: 450px;
                margin: 0 auto;
            }
        }
        @media (max-width: 576px){
            padding-bottom: 0;
        }
    }
    &__right{
        max-width: 536px;
        padding-top: 39px;
        .content-img{
            background: transparent linear-gradient(245deg, #800048 0%, #8F4993 100%) 0 0 no-repeat padding-box;
            border-radius: 10px;
            opacity: 1;
            text-align: center;
            max-height: 251px;
            height: 100%;
            figure{
                img{
                    max-width: 409px;
                    border-radius: 10px;
                    margin-top: -68px;
                }
            }
        }

        /*style form*/
        .content-form{
                form[id*="gform_"]{
                    padding: 60px 50px 87px 50px;
                    background-color: white;
                    box-shadow: 5px 5px 25px #2F5DCD26;
                    border-radius: 20px;
                    .gform-body{
                        legend ,
                        label{
                            margin-bottom: 0;
                            line-height: normal;
                            font-size: 18px;
                            .gfield_required::after{
                                content: "*";
                                color: $black_2;
                                position: relative;
                                top: -2px;
                            }
                            .gfield_required{
                                padding-left: 0;
                                .gfield_required_text{
                                    display: none;
                                }
                            }
                        }
                        span:focus-visible ,
                        .ginput_container:focus-visible{
                            border: none !important;
                            outline: none;
                        }
                        input{
                            border: none;
                            border-bottom: 1px solid $green;
                            padding-top: 0;
                            padding-bottom: 0;
                        }
                        input:focus-visible{
                            border-bottom: 3px solid $green !important;
                            outline: none;
                        }
                        .gchoice{
                            @media (max-width: 576px) {
                                text-align: center;
                            }
                            input{
                                width: 19px;
                                height: 19px;
                            }
                            label{
                                font-size: 16px;
                                a{
                                    font-size: 16px;
                                    color: $green !important;
                                }
                            }

                        }
                    }
                    .gform_footer{
                        padding-bottom: 0;
                        .gform_button{
                            background: $green 0 0 no-repeat padding-box;
                            border: none;
                            border-radius: 100px;
                            color: white;
                            outline: none;
                            padding: 20px 40px;
                            font-size: 16px;
                            font-weight: 700;
                        }
                    }
                }
        }
        @media (max-width: 990px) {
            margin: 0 auto;
            max-width: 770px;
            .content-img{
                max-width: 536px;
                margin: 0 auto;
            }
            .content-form{
                form[id*="gform_"]{
                    padding: 40px;
                }
            }
        }
        @media (max-width: 576px) {
            .content-img{
                height: auto;
                max-height: none;
                max-width: 340px;
                figure{
                    padding-top: 40px;
                    padding-bottom: 40px;
                    img{
                        max-width: 80%;
                        margin-top: 0;
                    }
                }
            }
            .content-form{
                form[id*="gform_"]{
                    padding: 25px;
                    .gform_footer{
                        .gform_button{
                            padding: 8px 15px;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
        /*end style form*/
    }
    .video-container{
        opacity: 0.3;
    }
    .overlay-content{
        background: url('../assets/img/overlay-gated.png') !important;
        background-size: cover !important;
        background-position: 50% 100% !important;
        opacity: 1 !important;
    }
}
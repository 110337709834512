.breadcrumb-site {
    margin-bottom: 40px;

    @media(max-width: $breakpoint-down-md) {
        margin-bottom: 30px;
    }
}

.items-breadcrumb {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 1.625em;
    a {
        font-weight: normal;
        text-decoration: none;
        color: $green;
        text-decoration-line: underline;
        text-decoration-thickness: 3px;
        text-underline-offset: 7px;
    }

    .current-item {
        font-weight: 400;
        border-bottom: none;
    }

    a,
    .current-item {
        font-size: 16px;
        line-height: 1.625em;
        color: $green;
        font-weight: 400;
        font-family: $font-title;
    }

    >span {
        margin-right: 20px;
        display: inline-block;
        margin-bottom: 5px;
    }
    .fa{
        margin-right: 20px;
    }
    @media(max-width: 991px){
        a{
            text-underline-offset: 3px;
        }
    }
    @media(max-width: $breakpoint-down-md) {
        font-size: 14px;
        line-height: 1.225em;

        a,
        .current-item {
            font-size: 14px;
            line-height: 1.225em;
        }
    }
}
.block-side-by-side-list {
    .content-title {
        margin-bottom: 80px;

        &>h1,
        &>h2,
        &>h3,
        &>h4,
        &>h5,
        &>h6 {
            margin-bottom: 40px;
        }
    }

    .content-group {
        display: flex;
        flex-direction: column;
        row-gap: 30px;

        .box-title {
            display: flex;
            flex-direction: column;
            row-gap: 3px;
        }

        .box-repeater {
            display: flex;
            flex-direction: column;
            padding-left: 0;
            list-style-type: none;

            li {
                padding: 20px 0 30px 35px;
                border-bottom: 2px solid rgba(25, 25, 25, 0.5);

                /*&::marker {
                    //content: none;
                    display: none!important;
                }*/

                &:first-child {
                    border-top: 2px solid rgba(25, 25, 25, 0.5);
                }

                h5 {
                    letter-spacing: 0;
                    color: $black_2;
                    text-transform: capitalize;
                    width: min(100%, 461px);
                    position: relative;
                    font-weight: 600;
                    text-align: left;

                    &::before {
                        content: "";
                        position: absolute;
                        left: -35px;
                        top: 6px;
                        background-image: url(../assets/img/chek-contact.svg);
                        width: 17px;
                        height: 13px;
                        background-repeat: no-repeat;
                        background-size: 100%;
                    }
                }

                p {
                    font-size: 18px;
                    width: min(100%, 461px);
                    letter-spacing: 0;
                    color: $black_2;
                    text-align: left;
                }

                .btn {
                    padding: 5px 25px;
                }
            }

        }
    }

    .content-media {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .box {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                left: -15%;
                bottom: -33%;
                width: 800px;
                height: 800px;
                background-image: url(../assets/img/ellipse_hot_it_works.png);
                background-repeat: no-repeat;
                background-size: 100%;
            }
        }
    }
}
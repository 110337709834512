.media-object{
    padding: 135px 0 50px !important;
    .row{
        row-gap: 60px;
    }
    &.media-object-section-center{
        .content-img {
            min-height: initial;
        }
        .row {
            align-items: center;
        }
        .content-img::before {
           // bottom: -65%;
        }
    }
    &.image-large-media-object{
        .content-img::before {
            //bottom: -120%;
        }
    }

    #cont-img {
        position: relative;
        margin-top: 15px;
        min-height: 370px;
        display: flex;
        align-items: start;
        figure{
            margin: 0 auto;
            max-width: 530px;
            img {
                //display: block;
               //object-fit: contain;
               // width: 100%;
               border-radius: 15px;
            }
        }
    }
    #cont-img::before {
        content: "";
        position: absolute;
        background: url(../assets/img/bg-media-object.png);
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
        width: 696px;
        // width: 140%;
        height: 696px;
        top: -208px;
        left: -140px;
        @media (max-width: 991px) {
            height: clamp(200px, 100vw, 723px);
            transform: scale(0.9);
            top: -170px;
            left: 80px;
        }
        @media (max-width: 767px) {
            top: -160px;
            left: 10px;
            transform: scale(1.2, 1);
        }
        @media (max-width: 576px) {
            top: -44px;
            transform: scale(1.5, 1.3);
        }
    }

    .content-text{
        padding-right: 25px;
        h2{
            font-size: 50px;
            letter-spacing: -1.5px;
            margin-bottom: 30px;
        }
        p{
            font-size: 22px;

        }
        .content-buttons {
            margin-top: 40px;
        }
        @media (max-width: 991px) {
            padding-right: 0;
        }
    }
    @media (max-width: 991px) {
        #cont-img {
            margin-top: 40px;
            min-height: auto;
        }
        .content-text{
            p{
                font-size: 17px;
            }
            .content-buttons {
                justify-content: center;
            }
        }
    }
    @media (max-width: 767px) {
        .content-text{
            h2{
                font-size: 28px;
                line-height: 1.3em;
                letter-spacing: -0.5px;
                margin-bottom: 20px;
            }
        }
    }

    .flex-row-reverse {
        .content-text {
            padding-left: 25px;
            padding-right: 0;
            @media (max-width: 991px) {
                padding-left: 0px!important;
            }
        }
    }
}
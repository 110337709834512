.slider-logos{
    .content-title{
        margin: 0 0 91px;
    }
    .slider-logo-img{
        background: #fff 0 0 no-repeat padding-box;
        box-shadow: 0 0 10px #0000001A;
        border-radius: 5px;
        opacity: 1;
        position: relative;
        display: flex;
        justify-content: center;
        padding: 0 0 59%;
        margin: 0 auto;
        overflow: hidden;
        width: 100%;
        min-height: 120px;
        max-height: 120px;
        padding: 12px;

        display: flex;
        justify-content: center;
        align-items: center;
        img{
            max-width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            /* position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%); */
        }
    }
    .list-img-gallery{
      margin: 0 -12px;
    }

    .list-img-gallery{

    }
    .progress-content{
        padding: 0 90px;
    }

    .progress{
        display: block;
        width: 100%;
        height: 3px;
        border-radius: 0;
        overflow: hidden;
        background-color: hsla(0, 0%, 100%, 0.393);
        background-image: linear-gradient(to right, #fff, #fff);
        background-repeat: no-repeat;
        background-size: 0 100%;
        transition: background-size .4s ease-in-out;
    }


    .gallery-istems-js{
        padding: 0 90px;
        .slick-slide{
            margin: 0 12px;
        }
        .slick-dots{
            margin: 0 12px;
            li{

            }
        }

        .slick-arrow {
            width: 50px;
            height: 50px;
            z-index: 5;
            &:before {
                content: '';
                background-size: cover!important;
                width: 50px;
                height: 50px;
                position: absolute;
                top: 0;
                left: 0;
            }
            &.slick-prev{
                left: 0;
                &:before{
                    background: url('../assets/img/slider-arrow-withe-left.svg');
                }
            }
            &.slick-next{
                right: 0;
                &:before{
                    background: url('../assets/img/slider-arrow-white-right.svg');
                }
            }
        }
    }
    .slider-nav-dots{
        margin-top: 118px;
        padding: 0 90px;
        margin-bottom: -3px !important;
        opacity: 0;
        .slick-slide{
            display: none!important;
        }
        .slick-dots{
            position: relative !important;
            bottom: 0 !important;
        }
    }
    @media (max-width: 991px) {
        .slider-nav-dots{
            margin-top: 48px;
        }
    }
    @media (max-width: 575px) {

        .gallery-istems-js,
        .slider-nav-dots,
        .progress-content{
            padding: 0 25px;
        }
        .gallery-istems-js {
            .slick-arrow:before{
                width: 30px;
                height: 30px;
            }
            .slick-arrow {
                width: 25px;
                height: 25px;
            }
        }
    }
}
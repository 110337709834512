.block-content{
    .large-text {
        font-size: 22px !important;
        letter-spacing: 0;
    }
    img{
        margin-top: 65px;
    }
    @media (max-width: 767px) {
        .large-text {
            font-size: 18px !important;
            letter-spacing: 0;
        }
    }
    &.buttons-style1{
        .content-buttons{
            margin-top: clamp(20px, 4vw, 85px) !important;
            .btn{
                padding: 16.5px 62px;
            }
        }

    }
}

// .none-content{
//     .content-buttons{
//         padding-top: 55px;
//         .btn{
//             padding: 15px 50px;
//             font-size: 22px;
//         }
//     }
// }

.bg-degraded{
    &::before{
        content: '';
        position: absolute;
        background: url('../assets/img/bg-tabs.png');
        background-size: contain;
        background-repeat: no-repeat;
        width: 750px;
        height: 750px;
        left: calc((100vw - 1450px) / 2);
        bottom: -10%;
    }
    @media (max-width: 767px) {
        &::before{
            width: 380px;
            height: 380px;
            left: -50px;
            bottom: 0;
            top: initial;
        }
    }
}

.section-image{
    img{
        margin-top: 0;
        width: 100%;
    }
    .image-desktop{
        @media (max-width: 767px) {
            display: none;
         }
    }
    .image-mobile{
        display: none;
        @media (max-width: 767px) {
           display: unset;
        }
    }
}
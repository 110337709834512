.cta-subscribe{
    .content-title{
        max-width: 536px;
        h2,h3{
            margin-bottom: 11px;
        }
        p{
            letter-spacing: 0;
            @media(min-width: 992px){
                font-size: 18px;
                letter-spacing: 0;
            }
        }
        @media(max-width: 992px){
            max-width: 100%!important; 
            text-align: center;
        }
    }
    .content-form-gravityform{
        @media(max-width: 992px){
            form{
                .gfield--type-checkbox, .gfield--type-captcha{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    max-width: 100%;
                }

            }
        }
        
        //custom css validation errors
        .gform_wrapper.gravity-theme .gform_validation_errors,
        .gform_wrapper.gravity-theme .validation_message {
            background-color: $white !important;
            padding: 10px 24px;
            h2 {
                font-size: 15px;
                color: #c02b0a !important;
                font-weight: initial;
                letter-spacing: normal;
                line-height: 25px;
            }
        }

        .gform_wrapper.gravity-theme .instruction.validation_message {
            display: none;
        }

        .gform_wrapper.gravity-theme .gfield_error label {
            color: $white !important;
        }

        //end--> custom css validation errors
    }
    
}
.block-accordion-settings {
    .content-text {}

    .content-accordion {

        display: flex;
        justify-content: center;

        .accordion {
            width: 100%;
            max-width: 873px;
            --bs-accordion-border-color: initial;
            --bs-accordion-active-color: initial;
            --bs-accordion-active-bg: initial;
            --bs-accordion-btn-focus-border-color: initial;

            .accordion-item {
                border-bottom: 1px solid $black;

                .accordion-header {
                    .accordion-button {
                        color: $black;
                        font-size: 22px;
                        line-height: 32px;
                        font-weight: bold;

                        &:not(.collapsed) {
                            // color: $black;
                            // padding-top: 200px !important;
                            background-color: initial;
                            background-image: initial;

                            &::after {
                                background-image: var(--bs-accordion-btn-icon);
                            }
                        }

                        &.collapsed {
                            padding-top: 40px;
                            padding-bottom: 40px;
                        }

                        &:focus {
                            border-color: initial;
                            box-shadow: initial;
                        }
                    }
                }

                .accordion-collapse {
                    .accordion-body {
                        padding: 5px 20px 20px 20px;
                        p {
                            color: $black_2 !important;
                        }
                    }
                }


            }
        }

    }
}
@mixin box-feactured-resource{
    .featured-resource{
        background: transparent linear-gradient(293deg, $sky-blue 0%, $sky-green 100%) 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        position: relative;
        max-width: 536px;
        margin: 66px 0 0 auto;
        padding: 40px 63px 47px;
        .badge{
            margin-bottom: 15px;
        }
        h3{
            font-size: 24px;
            color: $white!important;
            margin-bottom: 10px;
            font-size: 22px;
            line-height: 1.0909em;
        }
        p{
            color: $white!important;
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 20px;
        }
        .featured-resources--img{
            width: 100%;
            margin-bottom: 43px;
            max-width: 409px;
            margin: -116px auto 30px;
            overflow: hidden;
            border-radius: 10px;
            padding-bottom: 69.9266%;
            position: relative;
            transform: translateY(146px);
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .featured-resources--content{
            width: 100%;
        }
        @media(max-width: $breakpoint-down-md){
            margin: 10px auto 50px auto;
            padding: 30px;
        }
        @media(max-width: $breakpoint-down-sm){
            padding-bottom: 140px;
        }
    }

}

@mixin nav-filter_input{
    .filter-menu-row{
        margin-bottom: 40px;
        ul{
            display: flex;
            flex-wrap: wrap;
            margin: 0 -12px;
            list-style: none;
            padding: 0;
            li{
                padding: 0 12px;
                margin: 0 0 12px;
                width: 33.333%;
            }
        }
        label{
            width: 100%;
        }
        select,
        input{
            background: #fff;
            border-radius: 30px;
            opacity: 1;
            height: 59px;
            border: 1px solid #fff;
            font-size: 16px;
            letter-spacing: 0;
            font-weight: 600;
            font-family: $font-title;
            color: #191919;
            padding: 12px 34px;
            width: 100%;
            &::placeholder{
                color: #2A5F5A;
            }
        }
        select{
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23707070'%3E%3Cpath d='M24 24H0V0h24v24z' fill='none' opacity='1'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z'/%3E%3C/svg%3E");
            appearance: none;
            background-position: calc(100% - 28px) 50%;
            background-repeat: no-repeat;
            background-size: 22px;
        }
        input{

        }
        @media(max-width: $breakpoint-down-lg){
            ul{
                li{
                    width: 50%;
                }
            }
        }
        @media(max-width: $breakpoint-down-md){
            ul{
                li{
                    width: 100%;
                }
            }
        }
    }
}
@mixin get_filter_input{


}

@mixin post-item-box{
    .post-item-box{
        background: transparent linear-gradient(302deg, #0167A6 0%, #57D18B 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
        height: 100%;
        &:hover{
            .btn{
                text-decoration: underline;

            }
        }
        .link-block{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .post-item--img{
            mix-blend-mode: soft-light;
            overflow: hidden;
            padding-bottom: 45.99%;
            position: relative;
            img{
                position: absolute;
                width: calc(100% + 50px);
                height: calc(100% + 50px);
                object-fit: cover;
                left: 50%;
                transform: translate(-50%, -50%);
                top: 50%;
                max-width: 101%;
            }
        }
        .post-item--content{
            text-align: left;
            padding: 30px 36px 44px;
            .badge{
                margin-bottom: 21px;
            }
            .post-reading-time{
                margin-bottom: 10px;
                span{
                    font-size: 14px;
                    letter-spacing: 0;
                    display: block;
                    color: $white!important;
                }
            }
            h4{
                color: $white!important;
                font-weight: 700;
                letter-spacing: 0;
                text-transform: uppercase;
            }
            p{
                color: $white!important;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                font-size: 18px;
                letter-spacing: 0;
                margin-bottom: 39px;
            }
            .btn{
                position: absolute;
                bottom: 22px;
                left: 36px;
            }
        }
        @media(max-width: $breakpoint-down-md){
            .post-item--content{
                padding: 25px;
            }
        }
    }
}


@mixin tab-1-women{
    .tab-content {
        &.active-efect-item{
            .women-efect-items{
                .content-line-row .line-row-ma{
                    transform: translate(0,0) rotate(0deg);
                    opacity: 1;
                }
                .dot-icon-circle.dot-2-icon{
                    opacity: 0;
                }
                .price-current.price-normal-1{
                    opacity: 0;
                }
                .price-current.price-hover-1{
                    opacity: 1;
                }
            }
        }
        .women-efect-items{
            position: relative;
            margin-left: 146px;
            transition: all ease 0.4s;
            @media (max-width: 1199px) {
                top: 0;
                left: 0;
            }

            &:after{
                content: "";
               // background: transparent linear-gradient(0deg, #F750A8 0%, #FCF59D 100%) 0 0 no-repeat padding-box;
                background: transparent linear-gradient(0deg, $yellow 0, $linen 100%) 0 0 no-repeat padding-box;
                opacity: 0.15;
                filter: blur(50px);
                position: absolute;
                min-width: 696px;
                min-height: 536px;
                z-index: -1;
                top: -203px;
                left: -146px;
            }
            .border-center-women{
                position: relative;
                .border-center-curve{
                    max-width: 331px;
                    min-width: 331px;
                    position: relative;
                    z-index: 1;
                }
                .center-women{
                    max-width: 395px;
                    min-width: 395px;
                    object-fit: contain;
                    position: absolute;
                    left: -105px;
                    bottom: 0;
                    z-index: 1;
                }
            }
            .apple-women{
                min-width: 550px;
                max-width: 550px;
                position: absolute;
                left: -105px;
                z-index: 1;
                top: 18px;
                width: 100%;
                @media (max-width: 1199px) {
                    min-width: 496px;
                }
                @media (min-width: 992px) and (max-width: 1144px) {
                    min-width: 411px;
                    top: 11px;
                }
            }
            .price-current{
                color: #fff;
                font-size: 20px;
                display: inline-block;
                font-family: "Poppins", sans-serif;
                font-weight: 600;
                letter-spacing: 0;
                position: absolute;
                left: 256px;
                top: 174px;
                z-index: 1;
                transition: all ease 0.4s;
                @media (max-width: 1199px) {
                    top: 138px;
                    left: 232px;
                }
                @media (min-width: 992px) and (max-width: 1144px) {
                    display: none;
                }
                &.price-normal-1{
                    opacity: 1;
                }
                &.price-hover-1{
                    opacity: 0;
                }
            }


            .content-line-row{
                max-width: 153px;
                min-width: 153px;
                position: absolute;
                left: 252px;
                top: 216px;
                z-index: 1;
                @media (max-width: 1199px) {
                    top: 188px;
                    left: 232px;
                }
                @media (max-width: 1144px) {
                    top: 188px;
                    left: 232px;
                }
                @media (min-width: 992px) and (max-width: 1144px) {
                    top: 155px;
                    left: 194px;
                }
            }
            .content-line-row{
                .line-row-dots{
                    width: 100%;
                }
                .line-row-ma{
                    min-width: 104px;
                    max-width: 104px;
                    object-fit: contain;
                    position: absolute;
                    left: -25px;
                    top: -11px;
                    transform: translate(-54px, 0) rotate(0deg);
                    opacity: 0;
                    transition: all ease 0.4s;
                    z-index: 1;
                }
            }
            .dot-icon-circle{
                display: inline-block;
                width: 20px;
                height: 20px;
                background-color: #fff;
                border-radius: 100px;
                border: 4px solid #000;

                &.dot-1-icon{
                    position: absolute;
                    top: -5px;
                    left: 94px;
                }
                &.dot-2-icon{
                    position: absolute;
                    bottom: -6px;
                    left: 6px;
                    opacity: 1;
                    transition: all ease 0.2s;
                }
            }
            &:hover{
                .content-line-row .line-row-ma{
                    transform: translate(0,0) rotate(0deg);
                    opacity: 1;
                }
                .dot-icon-circle.dot-2-icon{
                    opacity: 0;
                }
                .price-current.price-normal-1{
                    opacity: 0;
                }
                .price-current.price-hover-1{
                    opacity: 1;
                }
            }


            @media only screen and (min-width: 992px) and (max-width: 1200px) {
                position: relative;
                padding-bottom: 50%;
                margin-top: 0;
                .border-center-category-m{
                    position: absolute;
                    height: 100%;
                }
                .border-center-women .border-center-curve{
                    position: absolute;
                    bottom: 0;
                }
                 .border-center-women {
                    position: initial;
                }
                .border-center-women .center-women{
                    width: 90%;
                    min-width: 10px;
                }
                .border-center-women .border-center-curve {
                    position: absolute;
                    bottom: 0;
                    min-width: 10px;
                    width: 73%;
                }
                .tabs-two-columns .women-efect-items .apple-women {
                    position: absolute;
                    left: -105px;
                    z-index: 1;
                    top: 18px;
                    width: 100%;
                    min-width: 100px;
                    max-width: calc(100% + 77px);
                    width: calc(100% + 77px);
                }
                .tabs-two-columns .women-efect-items .content-line-row {
                    max-width: 153px;
                    min-width: 10px;
                    position: absolute;
                    left: auto;
                    top: 216px;
                    z-index: 1;
                    right: 0;
                    transform: scale(0.8);
                }
            }
            @media(max-width: 649px){
                position: relative;
                padding-bottom: 50%;
                margin-top: 0;
                .border-center-category-m{
                    position: absolute;
                    height: 100%;
                }
                .border-center-women {
                    position: initial;
                }
                .border-center-women .border-center-curve{
                    position: absolute;
                    bottom: 0;
                    min-width: 10px;
                    width: 73%;
                }
                .apple-women{
                    position: absolute;
                    left: -120px;
                    z-index: 1;
                    top: 18px;
                    width: 100%;
                    min-width: 20px;
                    max-width: calc(100% + 146px);
                    width: calc(100% + 100px);
                }
                .line-row-ma{
                    // display: none;
                }
                .price-current{
                    left: 55%;
                }
                .content-line-row {
                    max-width: 153px;
                    min-width: 153px;
                    position: absolute;
                    left: 243px;
                    top: 200px;
                    z-index: 1;
                    right: 0;
                    bottom: 12px;
                }
                .border-center-women .center-women {
                    object-fit: contain;
                    position: absolute;
                    left: -105px;
                    bottom: 0;
                    z-index: 1;
                    min-width: 10px;
                    max-width: 100%;
                    height: 100%;
                    object-position: bottom;
                    height: calc(100% + 36px);
                }

            }
            @media (max-width: 576px) {
                .content-line-row {
                    top: 60%;
                    left: 55%;
                }
                .apple-women{
                    left: -70px;
                    top: 12px;
                    width: calc(100% + 24px);
                }
                .price-current{
                    top: 45%;
                }

            }
            @media(max-width: 496px){
                margin-left: 70px;
                .content-line-row{
                    transform: scale(0.7);
                    top: 55%;
                    left: 45%;
                }
                .price-current {
                    left: 50%;
                    top: 45%;
                    font-size: 16px;
                }
                .women-efect-items {
                    margin-left: 70px;
                }
                .apple-women{
                    max-width: calc(100% + 70px);
                    width: calc(100% + 10px);
                    left: -40px;
                    top: 0;
                }
                .border-center-women .border-center-curve {
                    position: absolute;
                    bottom: 0;
                    min-width: 10px;
                    width: 93%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            @media(max-width: 370px){
                .content-line-row{
                    transform: scale(0.5);
                    top: 55%;
                    left: 35%;
                }
                .border-center-women .border-center-curve{
                    width: 72%;
                }
                .apple-women{
                    width: calc(100% + 50px);
                    left: -80px;
                    top: -20px;
                }
            }
        }
    }

}


@mixin tab-2-merch-guy{
    .tab-content {
        &.active-efect-item{

            .merch-guy-efect-items{
                .border-center-merch-guy{
                        .box-dot-efect{
                            .box-ixon-tb2{
                                svg{
                                    path{
                                        fill: #fff;
                                    }
                                }
                                &.box-icon-1{
                                    background-color: #FFC72C;
                                    border-width: 0;
                                }
                                &.box-icon-2{
                                    bottom: 95px;
                                }
                            }
                            .hand-icons{
                            height: 215px;
                            bottom: -6px;
                            transform: translate(0, 0) rotate(-20deg);
                            left: 69px;

                                .hand-icon-hover{
                                    opacity: 1;
                                    visibility: visible;
                                    height: auto;
                                }
                                .hand-icon-normal{
                                    opacity: 1;
                                    visibility: visible;

                                }
                            }
                        }
                }

            }

        }
        .merch-guy-efect-items{
            position: relative;
            margin-left: 146px;
            transition: all ease 0.4s;
            margin-top: 121px;
            &:after{
                content: "";
           //     background: transparent linear-gradient(0deg, #F750A8 0%, #FCF59D 100%) 0 0 no-repeat padding-box;
                background: transparent linear-gradient(0deg, #FFC72C 0, #FDFAF3 100%) 0 0 no-repeat padding-box;
                opacity: 0.15;
                filter: blur(50px);
                position: absolute;
                min-width: 696px;
                min-height: 536px;
                z-index: -1;
                top: -203px;
                left: -146px;
            }
            .border-center-merch-guy{
                position: relative;
                max-width: 331px;
                min-width: 331px;
                .border-center-curve{

                    position: relative;
                    z-index: 1;
                }
                .center-merch-guy{
                    max-width: 208px;
                    min-width: 208px;
                    object-fit: contain;
                    position: absolute;
                    right: 50px;
                    bottom: 0;
                    z-index: 2;
                    border-radius: 0 0 87px 0;
                }
                .icon-merch-guy{
                    max-width: 458px;
                    min-width: 458px;
                    object-fit: contain;
                    position: absolute;
                    top: -31px;
                    left: -68px;
                    z-index: 1;
                    mix-blend-mode: multiply;
                }
                .box-dot-efect{
                    position: absolute;
                    left: -89px;
                    bottom: 0;
                    @media (max-width: 580px) {
                        transform: scale(0.7);
                        left: -65PX;
                        z-index: 1;
                    }

                    .box-ixon-tb2{
                        display: inline-flex;
                        width: 57px;
                        height: 57px;
                        border: 3.2px solid #000;
                        background-color: #fff;
                        justify-content: center;
                        align-items: center;
                        border-radius: 7px;
                        z-index: 1;
                        position: relative;
                        svg{
                            width: 28px;
                        }
                        &.box-icon-1{
                            bottom: 34px;
                            position: relative;
                            z-index: 1;
                            transition: all linear .3s
                        }
                        &.box-icon-2{
                            bottom: 31px;
                            position: absolute;
                            left: 0;
                            bottom: 34px;
                            z-index: 0;
                            background-color: #FE7045;
                            border-color: #FE7045;
                            transition: all ease .4s;
                            svg{
                                path{
                                    fill: #fff;
                                }
                            }
                        }

                    }

                    .hand-icons{

                        position: absolute;
                        left: 48px;
                        bottom: -83px;
                        z-index: 1;
                        overflow: hidden;
                        height: 129px;
                        transition: all linear .3s;
                        bottom: 0;
                        height: 129px;
                        img{
                            width: 100%;
                            display: block;
                            transition: all linear .3s
                        }
                        .hand-icon-it{

                        }
                        .hand-icon-hover{
                            min-width: 251px;
                            max-width: 251px;
                            top: 0;
                            position: relative;
                            left: 0;
                            visibility: hidden;
                            transition: all linear 1s
                        }
                        .hand-icon-normal{
                            position: absolute;
                            left: 0;
                            top: 0;
                            opacity: 1;
                        }
                    }
                }



            }

                .border-center-merch-guy{
                    &:hover{
                        .box-dot-efect{
                            .box-ixon-tb2{
                                svg{
                                    path{
                                        fill: #fff;
                                    }
                                }
                                &.box-icon-1{
                                    background-color: #FFC72C;
                                    border-width: 0;
                                }
                                &.box-icon-2{
                                    bottom: 95px;
                                }
                            }
                            .hand-icons{
                            height: 215px;
                            bottom: -6px;
                            transform: translate(0, 0) rotate(-20deg);
                            left: 69px;

                                .hand-icon-hover{
                                    opacity: 1;
                                    visibility: visible;
                                    height: auto;
                                }
                                .hand-icon-normal{
                                    opacity: 1;
                                    visibility: visible;

                                }
                            }
                        }
                    }
                }



            @media only screen and (min-width: 992px) and (max-width: 1145px) {
                position: relative;
                padding-bottom: 50%;
                margin-top: 0;
                margin-left: 106px;
                .border-center-merch-guy{
                    position: absolute;
                    height: 100%;
                }

                .border-center-merch-guy .border-center-curve {
                    position: absolute;
                    z-index: 1;
                    bottom: 0;
                }

                .border-center-merch-guy .icon-merch-guy {
                    max-width: 458px;
                    min-width: 458px;
                    -o-object-fit: contain;
                    object-fit: contain;
                    position: absolute;
                    top: -31px;
                    left: -68px;
                    z-index: 1;
                    mix-blend-mode: multiply;
                    width: calc(100% + 146px);
                    max-width: calc(100% + 146px);
                    min-width: 10px;
                    bottom: -40px;
                    top: auto;
                }
                 .border-center-merch-guy .border-center-curve {
                    position: absolute;
                    z-index: 1;
                    bottom: 0;
                    height: 100%;
                    object-fit: contain;
                    object-position: bottom;
                }
                .border-center-merch-guy .center-merch-guy {
                    object-fit: contain;
                    position: absolute;
                    right: 50px;
                    bottom: 0;
                    height: calc(100% + 40px);
                }
              .border-center-merch-guy .icon-merch-guy {
                    object-fit: contain;
                    position: absolute;
                    left: -17px;
                    z-index: 1;
                    mix-blend-mode: multiply;
                    min-width: 10px;
                    bottom: -50px;
                    top: auto;
                    height: calc(100% + 147px);
                    width: calc(100% + 47px);
                }
                .tabs-two-columns .merch-guy-efect-items .border-center-merch-guy:hover .box-dot-efect .hand-icons {
                    height: 193px;
                    bottom: -6px;
                }
            }
            @media(max-width: 649px){
                margin-left: 90px;
                position: relative;
                padding-bottom: 50%;
                margin-top: 0;

                .border-center-merch-guy:hover .box-dot-efect .hand-icons {
                    height: 193px;
                    bottom: -6px;
                }
                .border-center-merch-guy{
                    position: initial;
                    height: 100%;
                }
                .border-center-merch-guy .border-center-curve {
                    position: absolute;
                    z-index: 1;
                    height: 100%;
                    object-fit: contain;
                    object-position: left bottom;
                }
                .border-center-merch-guy .icon-merch-guy {
                    max-width: 100%;
                    min-width: auto;
                    width: 88%;
                    height: 100%;
                    bottom: 0;
                    top: auto;
                }
                .border-center-merch-guy .center-merch-guy {
                    height: 100%;
                    right: 50%;
                }
                 .border-center-merch-guy .border-center-curve {
                    height: 93%;
                    object-fit: contain;
                    object-position: left bottom;
                    bottom: 0;
                }
               .border-center-merch-guy .box-dot-efect .hand-icons .hand-icon-hover {
                    min-width: 225px;
                    max-width: 230px;
                }
               .border-center-merch-guy .box-dot-efect .hand-icons{
                 height: 90px;
                }
                .border-center-merch-guy .box-dot-efect .hand-icons .hand-icon-hover {
                    min-width: 175px;
                    max-width: 174px;
                }
                .border-center-merch-guy:hover .box-dot-efect .hand-icons {
                    height: 90px;
                    bottom: 0;
                    transform: translate(0, 0) rotate(0deg);
                    left: 69px;
                }
                .border-center-merch-guy .box-dot-efect .hand-icons .hand-icon-hover{
                    // opacity: 0!important;
                }
                .border-center-merch-guy .box-dot-efect .hand-icons .hand-icon-normal{
                    // opacity: 0!important;
                }
            }

            @media (max-width: 576px) {
                .border-center-merch-guy .center-merch-guy {
                    height: 100%;
                    right: 45%;
                }
            }

            @media(max-width: 449px){
                padding-bottom: 60%;
                .border-center-merch-guy .center-merch-guy {
                    height: 100%;
                    right: 35%;
                }
                .border-center-merch-guy .icon-merch-guy{
                    left: -15%;
                    width: calc(100% + 23px);
                    max-width: calc(100% + 23px);
                }
                .border-center-merch-guy .box-dot-efect {
                    transform: scale(0.8);
                }

                .border-center-merch-guy .center-merch-guy {
                    height: 100%;
                    right: 45px;
                    bottom: 0;
                    max-width: inherit;
                    min-width: auto;
                }
                .border-center-merch-guy .box-dot-efect {
                    transform: scale(0.7);
                    z-index: 1;
                    bottom: -4px;
                }
            }
            @media(max-width: 390px){
                .border-center-merch-guy .center-merch-guy {
                    right: 30px;
                }
            }
        }
    }

}


@mixin tab-3--category-m{

    .tab-content {
        &.active-efect-item{
            .category-m-efect-items{
                .border-center-category-m{
                  //  &:hover{
                        .icon-category-hand{
                            opacity: 1;
                            right: calc(100% - 94px);
                            @media(max-width: 600px){
                                right: calc(100% - 62px);
                            }
                            @media(max-width: 500px){
                                right: calc(100% - 50px);
                            }
                            @media(max-width: 450px){
                                right: calc(100% - 70px);
                            }
                        }
                        .chart-box{
                            padding: 0;
                            .inner-img{

                                 svg{
                                   .item-line{
                                    stroke: #fff;
                                   }
                                }
                            }
                            .border-item-1{
                                   &:after{
                                    transform: translate(50%, 0) rotate(90deg);
                                   }
                            }
                            .border-item-2{
                                .after-div{
                                    transform: translate(0, 0) rotate(-47deg);
                                    opacity: 1;
                                    &:after{
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                 //   }
                }
            }
        }


    }
    .category-m-efect-items{
        position: relative;
        margin-left: 146px;
        transition: all ease 0.4s;
        margin-top: 96px;
        &:after{
            content: "";
            //background: transparent linear-gradient(0deg, #F750A8 0%, #FCF59D 100%) 0 0 no-repeat padding-box;
            background: transparent linear-gradient(0deg, #FFC72C 0, #FDFAF3 100%) 0 0 no-repeat padding-box;
            opacity: 0.15;
            filter: blur(50px);
            position: absolute;
            min-width: 696px;
            min-height: 536px;
            z-index: -1;
            top: -203px;
            left: -146px;
        }
        .border-center-category-m{
            position: relative;
            max-width: 331px;
            min-width: 331px;
            .border-center-curve{
                position: relative;
                z-index: 1;
            }
            .center-category-m{
                max-width: 150px;
                min-width: 150px;
                object-fit: contain;
                position: absolute;
                right: 43px;
                top: -75px;
                z-index: 2;
            }
            .icon-category-m{
                position: absolute;
                min-width: 546px;
                left: -146px;
                top: -38px;
            }
            .icon-category-hand{
                width: 83px;
                position: absolute;
                top: 0;
                transition: all linear .3s;
                right: 60px;
                z-index: 1;
                opacity: 0;
                @media(max-width: 450px){
                    top: -10px
                }
            }
            .chart-box{
                position: absolute;
                width: 142px;
               /*  height: 104px; */
                overflow: hidden;
                padding: 0 16px;
                transition: all linear 0.3s;
                bottom: 113px;
                left: -145px;
                z-index: 1;
                @media(max-width: 500px){
                        bottom: 76px;
                        left: -130px;
                }
                .inner-img{
                    overflow: hidden;
                    margin: 0 auto;
                    position: relative;
                     svg{
                        min-width: 142px;
                        max-width: 142px;
                        position: relative;
                        transform: translate(-50%, 0px);
                        left: 50%;
                        transition: all linear 0s;
                        .item-line{
                            transition: all ease 0.3s;
                            stroke: #000;
                        }
                    }
                }

                .border-item-1{
                    background: #fff;
                    position: absolute;
                    width: 50px;
                    height: 49px;
                    bottom: 3px;
                    left: calc(50% - 22px);
                    transform: translate(-50%, 0);
                    mix-blend-mode: multiply;
                    border-radius: 0 0 0px 100px;
                    overflow: hidden;
                    &:after{
                        content: "";
                        background: #FE7045;
                        position: absolute;
                        width: 100px;
                        height: 51px;
                        bottom: 0;
                        left: 0;
                        transform: translate(50%, 0%) rotate(0deg);
                        mix-blend-mode: multiply;
                        border-radius: 0;
                        overflow: hidden;
                        transform-origin: 0 0;
                        transition: all linear 0.3s;
                    }
                }

                .border-item-2{
                    background: transparent;
                    position: absolute;
                    width: 53px;
                    height: 100px;
                    top: 4px;
                    left: 50%;
                    transform: translate(0%, 0);
                    mix-blend-mode: multiply;
                    border-radius: 0 100px 100px 0;
                    overflow: hidden;
                    .inner-0{
                        width: 50px;
                        height: 50px;
                        position: absolute;
                        mix-blend-mode: multiply;
                        left: 0;
                        top: 0;
                    }
                    .item-inner{
                        position: absolute;
                        background: #fff;
                        mix-blend-mode: multiply;
                        width: 100px;
                        height: 100px;
                        display: inline-block;
                        left: -49%;
                        top: 0;
                        mix-blend-mode: multiply;
                        transform: translate(48%, 0px) rotate(45deg);
                    }
                    .after-div{

                        background: #FFC72C;
                        position: absolute;
                        width: 100px;
                        height: 51px;
                        bottom: 0;
                        left: 0;
                        transform: translate(0, 0) rotate(-180deg);
                        mix-blend-mode: multiply;
                        border-radius: 0;
                        transform-origin: 0 0;
                        transition: all linear .3s;
                        &:after{
                            content: "";
                            background: #ffc72c;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            top: -49px;
                            transform: translate(0, 0) rotate(1deg);
                            opacity: 1;
                        }
                    }
                }

                .border-item-3{
                    position: absolute;
                    top: 0;
                    width: 50px;
                    left: calc(50% + 27px);
                    top: 3px;
                    transform: translate(-50%, 0px);
                    mix-blend-mode: multiply;
                    &:after{
                        content: "";
                    }
                }
            }
            &:hover{
                .icon-category-hand{
                    opacity: 1;
                    right: calc(100% - 94px);
                }
                .chart-box{
                    padding: 0;
                    .inner-img{

                         svg{
                           .item-line{
                            stroke: #fff;
                           }
                        }
                    }
                    .border-item-1{
                           &:after{
                            transform: translate(50%, 0) rotate(90deg);
                           }
                    }
                    .border-item-2{
                        .after-div{
                            transform: translate(0, 0) rotate(-47deg);
                            opacity: 1;
                            &:after{
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        @media only screen and (min-width: 992px) and (max-width: 1133px) {
            position: relative;
            padding-bottom: 50%;
            margin-top: 0;
            .border-center-category-m{
                position: absolute;
                height: 100%;
            }
            .border-center-category-m .border-center-curve{
                position: absolute;
                bottom: 0;
            }
            .border-center-category-m .icon-category-m {
                position: absolute;
                min-width: 546px;
                left: -146px;
                top: -38px;
                width: calc(100% + 146px);
                min-width: auto;
            }
            .border-center-category-m .center-category-m{
                right: 25%;
                top: auto;
                bottom: -40px;
                max-width: 39%;
                min-width: auto;
                height: calc(100% + 88px);
            }
           .border-center-category-m .border-center-curve {
                width: calc(100% - 50px);
                bottom: auto;
                top: 0;
            }

        }
        @media(max-width: 991px){
            margin-top: 81px;
           .border-center-category-m .center-category-m{
                max-width: 130px;
                min-width: 130px;
                top: -55px;
            }

        }
        @media(max-width: 649px){
            .border-center-category-m .icon-category-hand{
                // opacity: 0!important;
            }
        }
        @media(max-width: 600px){
            position: relative;
            padding-bottom: 50%;
            margin-top: 0;
            .border-center-category-m{
                position: initial;
                height: 100%;
            }

           .border-center-category-m .center-category-m {
                max-width: 130px;
                min-width: 130px;
                top: -55px;
                height: 100%;
                object-fit: contain;
                top: -5%;
                height: 100%;
                right: 45%;
            }
             .border-center-category-m .border-center-curve {
                position: absolute;
                z-index: 1;
                width: 67%;
                max-width: 100%;
                height: 90%;
                object-fit: contain;

            }
            .border-center-category-m .icon-category-m {
                position: absolute;
                min-width: 100%;
                left: -146px;
                top: -40px;
                width: calc(100% + 65px);
            }
            .chart-box{
                transform: scale(0.9);
            }
            .border-center-category-m .icon-category-hand {
                width: 53px;
            }
        }

        @media(max-width: 500px){
            .chart-box{
                transform: scale(0.7);
            }
            .border-center-category-m .icon-category-hand {
                transform: scale(0.7);
            }

        }

        @media(max-width: 450px){
            margin-left: 66px;
            .border-center-category-m .border-center-curve{
                height: 100%;
                left: 5%;
            }
            .border-center-category-m .center-category-m{
                transform: scale(1.3);
            }
            .border-center-category-m .chart-box {
                position: absolute;
                width: 142px;
                overflow: hidden;
                padding: 0 16px;
                transition: all linear .3s;
                bottom: 73px;
                transform: scale(.5);
            }
            .border-center-category-m .icon-category-m {
                position: absolute;
                min-width: 100%;
                left: -66px;
                top: -15px;
                width: calc(100% + 10px);
            }
            .border-center-category-m .chart-box {
                position: absolute;
                width: 142px;
                overflow: hidden;
                padding: 0 16px;
                transition: all linear .3s;
                bottom: 73px;
                left: -96px;
                z-index: 1;
            }
        }
        @media(max-width: 400px){
            .border-center-category-m .center-category-m{
                right: 30%;
            }
            .border-center-category-m .chart-box{
                bottom: 50px;
            }
        }
    }
}

@mixin tab-4-analytics{
    .tab-content {
        &.active-efect-item{
            .analytics-efect-items{
                .border-center-analytics{
                //    &:hover{
                        .efect-bar-item{
                            .bar-col-row{
                                .finger-analytics{
                                    left: -136px;
                                    @media (max-width: 576px) {
                                        left: -85px;
                                    }
                                }
                                .bar-col{
                                    &.bar-col-1{
                                        background-color: #FFC72C;
                                        border: 0;
                                        height: 113px;
                                        @media (max-width: 576px) {
                                            height: 78px;
                                        }
                                    }
                                    &.bar-col-2{
                                    }
                                    &.bar-col-3{
                                        background-color: #FE7045;
                                        border: 0;
                                        height: 78px;
                                        @media (max-width: 576px) {
                                            height: 52px;
                                        }
                                    }
                                    &.bar-col-4{
                                    }
                                    &.bar-col-5{
                                        border: 0;
                                        height: 17px;
                                        .border-inner-5-0{
                                            background-color: $primary;
                                        }
                                        .border-inner-5{
                                            border: 0;
                                        }
                                    }
                                    &.bar-col-6{
                                    }
                                }

                            }
                        }
                 //   }
                }
            }
        }
        .analytics-efect-items{
            position: relative;
            margin-left: 146px;
            transition: all ease 0.4s;
            margin-top: 121px;
            &:after{
                content: "";
               // background: transparent linear-gradient(0deg, #F750A8 0%, #FCF59D 100%) 0 0 no-repeat padding-box;
                background: transparent linear-gradient(0deg, #FFC72C 0, #FDFAF3 100%) 0 0 no-repeat padding-box;
                opacity: 0.15;
                filter: blur(50px);
                position: absolute;
                min-width: 696px;
                min-height: 536px;
                z-index: -1;
                top: -203px;
                left: -146px;
            }
            .border-center-analytics{
                position: relative;
                max-width: 331px;
                min-width: 331px;
                .border-center-curve{
                    position: relative;
                    z-index: 0;
                }
                .center-analytics{
                    width: 254px;
                    position: absolute;
                    left: -21px;
                    bottom: 0;
                    z-index: 4;
                    border-radius: 0;
                }
                .center-product{
                    position: absolute;
                    z-index: 1;
                    min-width: 650px;
                    left: -164px;
                    bottom: 2px;
                }
                .efect-bar-item{
                    position: absolute;
                    right: -151px;
                    bottom: -9px;
                    width: 188px;

                    .bar-col-row{
                        display: flex;
                        align-items: flex-end;
                        position: relative;
                        padding-left: 4px;
                        .finger-analytics{
                            position: absolute;
                            left: -260px;
                            bottom: 5px;
                            width: 100%;
                            max-width: 150px;
                            z-index: 1;
                            transition: all linear 0.3s;
                            &.finger-analytics-2{
                                z-index: 3;
                            }
                        }
                        .bar-col{
                            min-width: 22px;
                            min-height: 12px;
                            display: inline-block;
                            height: 22px;
                            margin-right: 9px;
                            transition: all linear 0.3s;
                            z-index: 2;
                            position: relative;
                            &.bar-col-1{
                                height: 21px;
                                background-color: #000;
                            }
                            &.bar-col-2{
                                height: 47px;
                                background-color: #fff;
                                border: 2.5px solid #000;
                            }
                            &.bar-col-3{
                                height: 12px;
                                background-color: #000;

                            }
                            &.bar-col-4{
                                height: 24px;
                                background-color: #fff;
                                border: 2.5px solid #000;
                            }
                            &.bar-col-5{
                                height: 17px;
                                .border-inner-5-0{
                                    background-color: #000;
                                    position: absolute;
                                    height: 100%;
                                    width: 100%;
                                    transition: all ease  0.3s;
                                }
                                .border-inner-5{
                                    border: 3px solid #000;
                                    transition: all ease  1s;
                                    position: absolute;
                                    height: 100%;
                                    width: 100%;
                                    z-index: 3;
                                }


                            }
                            &.bar-col-6{
                                height: 24px;
                                background-color: #fff;
                                border: 2.5px solid #000;
                            }
                        }
                        &:after{
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 3px;
                            border-radius: 20px;
                            background: #000;
                            z-index: 2;
                        }
                    }
                }
                &:hover{
                    .efect-bar-item{
                        .bar-col-row{
                            .finger-analytics{

                                left: -136px;
                                @media (max-width: 576px) {
                                    left: -85px;
                                }



                            }
                            .bar-col{
                                &.bar-col-1{
                                    background-color: #FFC72C;
                                    border: 0;
                                    height: 113px;
                                    @media (max-width: 576px) {
                                        height: 78px;
                                    }
                                }
                                &.bar-col-2{
                                }
                                &.bar-col-3{
                                    background-color: #FE7045;
                                    border: 0;
                                    height: 78px;
                                    @media (max-width: 576px) {
                                        height: 52px;
                                    }
                                }
                                &.bar-col-4{
                                }
                                &.bar-col-5{

                                    border: 0;
                                    height: 17px;
                                    .border-inner-5-0{
                                        background-color: $primary;
                                    }
                                    .border-inner-5{
                                        border: 0;
                                    }
                                }
                                &.bar-col-6{
                                }
                            }

                        }
                    }
                }
            }
            @media(max-width: 1400px){
                transform: scale(0.9);
                position: relative;
                left: -19px;
                top: -24px;
            }
            @media(max-width: 1200px){
                transform: scale(.8);
                left: -55px;
                .border-center-analytics .center-product{
                    min-width: 620px;
                    left: -141px;
                }
            }
            @media(max-width: 1000px){
                transform: scale(.7);
                left: -85px;
            }
            @media(max-width: 991px){
                transform: scale(1);
                left: 0;
                top: 0;
            }
            @media(max-width: 768px){
                .border-center-analytics{
                    .border-center-curve{
                        max-width: 289px;
                        min-width: 280px;
                    }
                    .center-analytics{
                        width: 200px;
                    }
                    .center-product{
                        min-width: 570px;
                    }
                    .efect-bar-item{
                        right: -120px;
                        .bar-col-row{
                            .finger-analytics{
                                position: absolute;
                                left: -196px;
                                width: 100%;
                                // max-width: 85px;
                                bottom: 15px;
                                &.finger-analytics-2{
                                }
                            }

                        }
                    }

                }
            }

            @media(max-width: 662px){
                margin-top: 0;
                padding-bottom: 50%;
                margin-left: 86px;
                .border-center-analytics{
                    position: initial;
                    padding-top: 0;
                }
                .border-center-analytics .border-center-curve {
                    min-width: auto;
                    max-width: initial;
                    width: calc(100% - 126px);
                    position: absolute;
                    bottom: 0;
                    left: 20px;
                    max-height: 290px;
                    object-fit: contain;
                    @media (max-width: 576px) {
                        width: calc(100% - 200px);
                    }
                }
                 .border-center-analytics .center-analytics {
                    left: 6%;
                    width: 40%;
                }
                .border-center-analytics .center-product {
                    min-width: 100%;
                    left: -70px;
                    width: calc(100% + 70px);
                    @media (max-width: 576px) {
                        width: calc(100% + 14px);
                    }
                }
                .border-center-analytics .efect-bar-item {
                    right: 0;
                }
                .border-center-analytics .efect-bar-item .bar-col-row .finger-analytics{
                    opacity: 1;
                }
            }

            @media(max-width: 576px){
                .border-center-analytics .efect-bar-item .bar-col-row .finger-analytics{
                    width: 50%;
                    // left: -84px;
                    bottom: 10px;
                }
            }

            @media(max-width: 476px){
                margin-top: 0;
                margin-left: 26px;
                .border-center-analytics{
                    position: initial;
                    padding-top: 0;
                }
                .border-center-analytics .border-center-curve {
                    width: calc(100% - 96px);
                    max-height: 210px;
                }
                .border-center-analytics .center-analytics {

                    width: 40%;
                    left: 13%;
                }
                .border-center-analytics .center-product {
                    min-width: 100%;
                    left: -40px;
                    width: calc(100% + 0px);
                }
                .border-center-analytics .efect-bar-item {
                    right: 0;
                    transform: translate(0px, 0px) scale(0.6);
                }
            }
            @media(max-width: 414px){
                .border-center-analytics .border-center-curve{
                    width: calc(100% - 90px);
                    max-height: 190px;
                }
                .border-center-analytics .center-analytics{
                    left: 9%;
                }
                .border-center-analytics .efect-bar-item{
                    right: -15px;
                }
            }
            @media(max-width: 390px){
                .border-center-analytics .border-center-curve{
                    width: calc(100% - 105px);
                    max-height: 155px;
                }
                .border-center-analytics .efect-bar-item{
                    bottom: -15px;
                    right: -30px;
                }

            }
        }
    }

}

@mixin tab-default{
    .default-box-img{
        position: relative;
        &:after{
            content: "";
            background: transparent linear-gradient(0deg, #F750A8 0%, #FCF59D 100%) 0 0 no-repeat padding-box;
            opacity: 0.15;
            filter: blur(50px);
            position: absolute;
            min-width: 696px;
            min-height: 536px;
            z-index: -1;
            top: -203px;
            left: -146px;
        }
        img{
            position: relative;
            z-index: 1;
        }
    }
}
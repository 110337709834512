.relared-post{
    background-color: #fff;
    padding: 107px 0 175px;
    .content-title{
        margin-bottom: 69px;
        &.row-content-element{
           
            .eyebrow{
                padding-bottom: 0;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                .title-related{
                    margin-bottom: 15px;
                }
                .arrow-nav-content{
                    margin-bottom: 24px;
                }
            }
        }
    }

    @include post-item-box;

    .post-slider-list{
        margin: 0 -12px;
        .slider-list-space{
            margin-bottom: 0;
            .slick-track{
                display: flex;
                .slick-slide{
                    margin: 0 12px;
                    height: auto;
                    >div{
                        height: 100%;
                        >div{
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
    @media(max-width: $breakpoint-down-md){
        .content-title{
            margin-bottom: 40px;
        }
    }
}

.arrow-nav-content{

    .arrow-icon{
        width: 50px;
        height: 50px;
        min-width: 50px;
        display: inline-block;
        text-decoration: none!important;
        border: 1px solid #2A5F5A;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        margin: 0;
        cursor: pointer;
        transition: all ease 0.3s;
        svg{
            width: 24px;
        }
        &.prev-div{
            margin-right: 10px;
        }
        &.next-div{

        }
        &:hover{
            background-color: $green;
            svg{
                path{
                    stroke: #fff;
                }
            }
        }
    }
}
.columns-with-checklist{
    .content-title{
        margin-bottom: 42px;
        &>h1,&>h2,&>h3,&>h4,&>h5,&>h6{
            margin-bottom: 32px;
        }
    }
    .box-checklist{
        padding:  0 0 0 39px;
        position: relative;
        max-width: 560px;
        &:before{
            content: "";
            background-image: url(../img/checkmark.svg);
            width: 18px;
            height: 18px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            position: absolute;
            left: 0;
            top: 8px;
        }
        h3{
            letter-spacing: -0.68px;
            font-weight: 500;
            margin-bottom: 15px;
        }
        p{
            color: $black_2 !important;
            @media(min-width: 991px){
                font-size: 22px;
                letter-spacing: 0;
                line-height: 1.454545em;
            }
        }
        >*:last-child{
            margin-bottom: 0;
        }
    }
    .list-columns-checklist{
        margin-bottom: -50px;
        >.row{
            >div{
                margin-bottom: 50px;
            }
        }
    }
    @include media-breakpoint-down(md) {
        .content-title{
            &>h1,&>h2,&>h3,&>h4,&>h5,&>h6{
                margin-bottom: 20px;
            }
        }
     }
}
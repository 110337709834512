.badge{
    font-size: 16px;
    border-radius: 5px;
    line-height: 1.25em;
    padding: 2.5px 15px;
    font-weight: 500;
    letter-spacing: 0.8px;
    font-family: $font-title;
    white-space: initial;
    text-align: left;
    &.badge-primary{
        background-color: $primary;
        color: #fff;
    }
}
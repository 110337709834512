.block-hero-banner-form {
    overflow: initial;
    position: relative;
    .content-text {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        height: 100%;
        padding-bottom: 350px;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            letter-spacing: -0.9px;
        }

        img {
            margin-bottom: 10px;
        }

        ul {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            padding-left: 19px;

            li {
                padding-left: 10px;
                font-size: 18px;

                strong {
                    font-size: 20px;
                }

                &::marker {
                    content: url(../assets/img/chek-contact.svg);
                }

                ul {
                    padding-left: 0;

                    li {
                        padding-left: 0;

                        &::marker {
                            content: none;
                        }
                    }
                }
            }
        }

        p {
            font-size: 22px;
        }
    }

    .content-form {
        height: 100%;

        .content-wrapper {
            position: sticky;
            // top:auto;
            display: flex;
            justify-content: center;

            .box {
                background: $white 0 0 no-repeat padding-box;
                box-shadow: 5px 5px 25px #2F5DCD26;
                border: 8px solid $white;
                border-radius: 20px;
                padding: 60px 50px 50px 51px;
                width: 100%;
                max-width: 536px;
                margin: 0 auto;
                // position: initial;

                .gform_title {
                    font-size: 18px;
                    text-transform: uppercase;
                    letter-spacing: 0.9px;
                    color: $black;
                    font-weight: 600;
                    margin-bottom: 25px;
                }

                .gform_required_legend {
                    display: none;
                }

                input[type="text"],
                input[type="email"],
                input[type="tel"],
                textarea,
                select {
                    border: initial;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
                    padding-bottom: 0 !important;
                    padding-top: 0 !important;
                }

                input[type="checkbox"] {
                    width: 19px;
                    height: 19px;
                    border: 1px solid $green;
                    border-radius: 2px;
                }

                textarea::placeholder {
                    color: #2A5F5A;
                }

                legend.gfield_label,
                label.gfield_label {
                    font-weight: 600;
                    font-size: 18px;
                    color: #191919;
                    margin-bottom: 0;

                    .gfield_required_asterisk {
                        color: #191919;
                    }
                }

                .gform_wrapper.gravity-theme .gfield textarea.large {
                    height: 35px;
                    margin-top: 20px;
                    padding-left: 0;
                }

                .ginput_container_consent {
                    display: flex;
                    align-items: start;
                    gap: 15px;

                    input[type="checkbox"] {
                        margin-top: 6px;
                    }

                    .gfield_consent_label {
                        font-size: 16px;
                        color: $black_2;

                        a {
                            font-size: 16px;
                            color: $green;
                            text-decoration: none;
                            transition: border-bottom-width 0.2s;
                        }

                        a:hover {
                            border-bottom: 1.5px solid $green;

                            @media(hover : hover) {
                                &:hover {
                                    font-weight: bold;
                                }
                            }

                        }
                    }
                }



                input[type="submit"] {
                    background-color: $green;
                    color: #fff;
                    border: 1px solid $green;
                    font-weight: 700;
                    font-size: 16px;
                    letter-spacing: 0;
                    padding: 16px 39px;
                    font-weight: 700;
                    border-radius: 100px;
                    margin-top: 10px;
                    outline-color: $primary;

                    @media(hover : hover) {
                        &:hover {
                            color: $green;
                            border-color: $green;
                            background-color: transparent;
                        }
                    }

                }
            }

            //custom css validation errors
            .gform_wrapper.gravity-theme .gform_validation_errors,
            .gform_wrapper.gravity-theme .validation_message {
                background-color: $white !important;

                h2 {
                    font-size: 15px;
                    color: #c02b0a !important;
                    font-weight: initial;
                    letter-spacing: normal;
                    line-height: 25px;
                }
            }

            .gform_wrapper.gravity-theme .instruction.validation_message {
                display: none;
            }

            .gform_wrapper.gravity-theme .gfield_error label {
                color: $black_2;
            }

            //end--> custom css validation errors
        }
    }

    /****
     *** start background
     ****/
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: linear-gradient(0deg, rgba(253, 250, 243, 1) 45%, rgba(253, 250, 243, 0.5) 105%);
    }

    .video-container {
        opacity: 0.5;
    }

    .overlay-content {
        background: url('../assets/img/overlay-hero-form.png') !important;
        background-size: cover !important;
        background-position: 50% 100% !important;
        z-index: 1;
    }

    /****
     *** end background
     ****/

    @media (max-width: 982px) {
        .content-form {
            .content-wrapper {
                .box {
                    padding: 50px 35px;
                    .ginput_container_consent {
                        input[type="checkbox"] {
                            margin-top: 4px;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .content-text {
            margin-bottom: initial;
            text-align: center;
            align-items: center;
            padding-bottom: initial;
            ul {
                text-align: left;
            }
        }
        .content-form {
            .content-wrapper {
                .box {
                    padding: 40px 30px;

                    .gform_title {
                        text-align: center;
                    }

                    .ginput_container_consent {
                        input[type="checkbox"] {
                            margin-top: 2px;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .content-form {
            .content-wrapper {
                .box {
                    padding: 20px 10px;
                    .gfield--type-captcha.gfield--input-type-captcha{
                        transform:scale(0.6);
                        transform-origin:0 0;
                    }
                }
            }
        }
    }
}
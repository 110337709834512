body {
    font-size: $font-base;
    line-height: 1.454545em;
    font-weight: 400;
    background-color: #fff;
    font-family: $font-text;
    color: $black_2;
    letter-spacing: 0;
    visibility: hidden;
    &.font-loaded {
        visibility: visible;
      }

    @media (max-width: $breakpoint-down-lg) {
        font-size: 17px;
        line-height: 1.55555em;
    }

    @media (max-width: $breakpoint-down-md) {
        font-size: 16px;
    }
}

a:focus-visible {
    outline: 1px solid $primary !important;
}

a:hover {
    text-decoration-thickness: 1px;
}

#page-wrapper,
#content {
    padding: 0;
}

.content-area {
    max-width: 1370px;
    margin: 0 auto;
    padding: 0 25px;

    @media (max-width: $breakpoint-down-md) {
        padding: 0 30px;
    }
}


.container {
    max-width: 1370px;
    padding: 0 25px;
    @media (max-width: $breakpoint-down-md) {
        padding: 0 30px;
    }
}

.main-content-site {
    margin-top: 132px;

    @media(max-width: 1148px) {
        margin-top: 90px;
    }
}

.eyebrow {
    display: block;
    letter-spacing: 0.9px;
    color: #0B0B0B;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid $black_2;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-family: $font-title;
    font-size: 18px;
}

.heading-white .eyebrow {
    color: white;
    border-bottom: 1px solid white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $black_2;
    font-family: $font-title;
    margin: 0 0 10px;
}

@mixin reset-list-h1 {
    font-size: 60px;
    line-height: 1.16667em;
    margin: 0 0 20px;
    letter-spacing: -0.6px;
    font-weight: 300;

    @media (max-width: 991px) {
        font-size: 40px;
    }

    @media (max-width: $breakpoint-down-md) {
        font-size: 32px;
        line-height: 1.2941em;
    }
}

h1 {
    @include reset-list-h1();
}

@mixin reset-list-h2 {
    font-size: 45px;
    line-height: 1.333333em;
    letter-spacing: -1.35px;
    font-weight: 300;

    @media (max-width: $breakpoint-down-lg) {
        font-size: 30px;
    }

    @media (max-width: $breakpoint-down-md) {
        font-size: 24px;
        line-height: 1.3125em;
    }
}

h2 {
    @include reset-list-h2;
}

@mixin reset-list-h3 {
    font-size: 34px;
    line-height: 1.294117em;
    letter-spacing: -0.68px;
    font-weight: 500;

    @media (max-width: $breakpoint-down-lg) {
        font-size: 27px;
    }

    @media (max-width: $breakpoint-down-md) {
        font-size: 22px;
        line-height: 1.28em;
        letter-spacing: -0.22px;
    }
}

h3 {
    @include reset-list-h3;
}

@mixin reset-list-h4 {
    font-size: 22px;
    line-height: 1.03846em;
    letter-spacing: 0;

    @media (max-width: $breakpoint-down-md) {
        font-size: 25px;
        line-height: 1.2em;
    }
}

h4 {
    @include reset-list-h4;

}

@mixin reset-list-h5 {
    font-size: 20px;
    line-height: 1.22727em;
    letter-spacing: 0;
    font-weight: 600;

    @media (max-width: $breakpoint-down-md) {
        font-size: 18px;
        line-height: 1.3em;
    }
}

h5 {
    @include reset-list-h5;
}

@mixin reset-list-h6 {
    font-size: 18px;
    line-height: 1.4em;
    letter-spacing: 0;
    font-weight: 600;

    @media (max-width: $breakpoint-down-md) {
        font-size: 16px;
        line-height: 1.62em;
    }
}

h6 {
    @include reset-list-h6;
}

a {
    font-size: $font-base;
    line-height: 1.4444em;
    font-weight: 400;
    font-family: $font-text;
    color: $primary;
    outline: none !important;
}

li a {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
}

p {
    font-size: $font-base;
    line-height: 1.4444em;
    font-weight: 400;
    font-family: $font-text;
    color: $black;

    &.large-text {
        font-size: $font-large;
    }

    &.small-text {
        font-size: $font-small;
    }

    a {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        font-family: inherit;
        font-weight: inherit;
    }

    @media (max-width: $breakpoint-down-lg) {
        font-size: 17px;
        line-height: 1.55555em;
    }

    @media (max-width: $breakpoint-down-md) {
        font-size: 16px;
    }
}

@mixin has-large-font-size-txt {
    font-size: $font-large !important;
    line-height: 1.38em;
    letter-spacing: -0.26px;

    @media (max-width: $breakpoint-down-md) {
        font-size: 22px !important;
        line-height: 1.45em;
        letter-spacing: -0.22px;
    }

    @media (max-width: $breakpoint-down-sm) {
        font-size: 20px !important;
    }
}

@mixin has-medium-font-size-txt {
    font-size: $font-medium !important;
    line-height: 1.45em;
    letter-spacing: -0.22px;

    @media (max-width: $breakpoint-down-lg) {
        font-size: 20px !important;
        line-height: 1.5em;
        letter-spacing: -0.2px;
    }
}

@mixin has-small-font-size-txt {
    font-size: $font-small !important;
    line-height: 1.55em;
    letter-spacing: 0.18px;

    @media (max-width: $breakpoint-down-md) {
        font-size: 16px !important;
        line-height: 1.62em;
        letter-spacing: 0.16px;
    }
}


.large-text {

    p,
    a {
        font-size: inherit !important;
        line-height: inherit !important;
        font-family: inherit !important;
    }

    @include has-large-font-size-txt;
}

.medium-text {

    p,
    a {
        font-size: inherit !important;
        line-height: inherit !important;
        font-family: inherit !important;
    }

    @include has-medium-font-size-txt;

}

.small-text {

    p,
    a {
        font-size: inherit !important;
        line-height: inherit !important;
        font-family: inherit !important;
    }

    @include has-small-font-size-txt;

}

@mixin single-content {
    //max-width: 922px;
}

.content-video-media-module{
    video,iframe{
        width: 100%;
    }
}

.general-section {
    position: relative;
    background-size: cover !important;
    background-position: 50% 50% !important;
    z-index: 2;
    overflow: hidden;

    .container {
        position: relative;
        z-index: 5;
    }

    .container-fluid {
        position: relative;
        z-index: 5;
    }
    .bg-over-content-img{
        opacity: 1; 
        background: url('../assets/img/hero-img-y.png') !important;
        background-size: cover !important;
        background-position: 50% 100% !important;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        transition: opacity 0.5s ease-in-out
    }
    .overlay-content {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;

        &.overlay-bg-bt {
            bottom: 0;
            background-position: bottom !important;
            background-repeat: no-repeat !important;
        }

        .overlay-bg-bt-2 {
            background-color: $linen;
            bottom: 0;
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: 100% 314px;
        }

        &.bg-style-2 {
            &:after {
                content: "";
                background-image: url(../assets/img/overlay-hero-home.png);
                background-size: cover;
                background-repeat: no-repeat;
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
            }

            &:before {}
        }
    }
    &.before-element{
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 140px;
            z-index: 2;
            background: linear-gradient(0deg, #fdfaf3  0%, rgba(255, 255, 255, 0) 100%);
        }
    }
    .bg-after-element-color {
        content: "";
        background: linear-gradient(180deg, rgb(255 255 255 / 0%) 0%, #fdfaf3 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 84px;
        z-index: 2;
        top: auto;
    }

    &.parallax-effect {
        background-attachment: fixed !important;
    }

    //Size container
    &.size-default {
        .container {
            max-width: 1370px;
        }
    }

    &.size-small {
        .container {
            max-width: 922px;
        }
    }

    &.size-medium {
        .container {
            max-width: 1146px;
        }
    }

    &.size-large {
        .container {
            max-width: 1260px;
        }
    }

    &.size-big {
        .container {
            max-width: 1530px;
        }
    }

    &.size-full {
        .container {
            max-width: 100%;
            padding: 0;
        }

        &.full-width-with-padding {
            .container {
                padding: 0 25px;
            }
        }
    }

    //color Text / heading



    &.text-dark {
        color: $black !important;

        p {
            color: $black !important;
        }

        ul {
            li {
                &::marker {
                    color: $black;
                }
            }
        }
    }

    &.heading-dark {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $black !important;
        }
    }

    &.text-white {
        color: $white !important;

        a:not(.btn-site):not(.btn-link):not(.btn),
        p {
            color: $white !important;
        }

        ul {
            li {
                &::marker {
                    color: $white;
                }
            }
        }
    }

    &.heading-white {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $white !important;
        }
    }

    &.text-dark_2 {
        color: $black_2 !important;

        a:not(.btn-site):not(.btn-link):not(.btn),
        p {
            color: $black_2 !important;
        }

        ul {
            li {
                &::marker {
                    color: $black_2;
                }
            }
        }
    }

    &.heading-dark_2 {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $black_2 !important;
        }
    }

    &.text-green {
        color: $green !important;

        a:not(.btn-site):not(.btn-link):not(.btn),
        p {
            color: $green !important;
        }

        ul {
            li {
                &::marker {
                    color: $green;
                }
            }
        }
    }

    &.heading-green {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $green !important;
        }
    }


    .video-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -2;
        overflow: hidden;
        opacity: 0.8;
        video {
            width: 100vw;
            height: 100%;
            object-fit: cover;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: -1;
            &::-webkit-media-controls {
                display: none !important;
            }
            
            &::-moz-media-controls {
                display: none !important;
            }
            
            &::-ms-media-controls {
                display: none !important;
            }
            
            &::-o-media-controls {
                display: none !important;
            }
        }
    }

    @media (max-width: 991px) {
        &:not(.hero-pages):not(.cta-promo-bar):not(.promo-card):not(.hero-single-bg):not(.hero-home):not(.hero-with-form):not(.hero-banner-common) {
            padding: 60px 0 !important;
        }
    }

    @media (max-width: 767px) {
        &:not(.hero-pages):not(.cta-promo-bar):not(.promo-card):not(.hero-single-bg):not(.hero-home):not(.hero-with-form):not(.hero-banner-common) {
            padding: 40px 0 !important;
        }
    }
}



/* .breadcrumb-site{
    text-transform: uppercase;
    font-family: $font-text;

    a {
        text-decoration: none !important;
    }

    span{
        font-weight: 500;
        letter-spacing: 0.9px;
        color: $black;
        a{
            font-weight: inherit;
        }
    }

    .current-item{
        font-size: 20px;
        padding-bottom: 4px;
    }  
 
    .sep-breadcrumb {
        display: inline-block;
        width: 10px;
        height: 12px;
        margin: 0 10px;
        background-image: url("../assets/img/sep-breadcrumb.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    @include media-breakpoint-down(lg) {
        margin-bottom: 10px;
    }
} */
/* .breadcrumb-white .breadcrumb-site{
    span{
        color: $white;
        a{
            color: $white;
        }
    }
} */


.main-title-content {

    margin: 0 auto 53px;

    h6 {
        color: #F15C49;
        font-size: $font-small !important;
        line-height: 1.55em;
        letter-spacing: 2px !important;
        margin-bottom: 23.7px;

        @media (max-width: 767px) {
            font-size: 14px !important;
            letter-spacing: 1.56px !important;
            margin-bottom: 10px;
        }
    }

    >*:last-child {
        margin-bottom: 0;
    }

    @media (max-width: $breakpoint-down-md) {
        margin: 0 auto 40px;
    }
}

.wp-block-cover{
    .wp-block-columns{
        .wp-block-column{
            display: flex;
            flex-direction: column;
            height: auto;
            .wp-block-buttons{
                margin-top: auto;
            }
        }
    }
}

.wp-block-list-pad{
    @media (max-width: 991px) {
        padding-top: 20px !important;
    }
    @media (max-width: 991px) {
        padding-top: 52px !important;
    }
}

.style-box-shadow-none {
    box-shadow: none !important;
}
.cta-side-form {
    .content-title {
        margin-bottom: 100px;
        max-width: 1100px;

        h2 {
            font-size: 60px;
            letter-spacing: -1.8px;
        }

        p {
            font-size: 22px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        @media (max-width: 991px) {
            h2 {
                font-size: 40px;
            }
        }

        @media (max-width: 767px) {
            margin-bottom: 40px;

            h2 {
                font-size: 28px;
            }

            p {
                font-size: 17px;
            }
        }
    }

    .content-img {
        max-width: 570px;
        margin-bottom: 30px;
        position: relative;
        height: 446px;

        &::before {
            content: "";
            position: absolute;
            width: 125%;
            height: 165%;
            background: url(../assets/img/bg-tabs.png);
            background-size: contain;
            background-repeat: no-repeat;
            right: -28%;
            bottom: -40%;
            z-index: -1;
        }

        img {
            position: relative;
            height: 100%;
            object-fit: contain;
            width: 100%;
        }

        .item-stat {
           // background: transparent linear-gradient(291deg, $primary 0%, #FFC72C 100%) 0 0 no-repeat padding-box;
            background: transparent linear-gradient(302deg, #0167A6 0%, #57D19E 100%) 0% 0% no-repeat padding-box;
            box-shadow: 5px 5px 50px #00000026;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 40px;
            column-gap: 15px;
            row-gap: 10px;
            position: absolute;
            transition: all 0.8s ease;
            opacity: 0;

            .top-stat {
                font: normal normal 800 40px / 95px Figtree;
                letter-spacing: -1.2px;
                color: #fff;
                text-transform: capitalize;
                display: flex;
                gap: 2px;

                span {
                    line-height: 1;
                }
            }

            p {
                text-align: left;
                font: normal normal normal 16px / 20px Figtree;
                letter-spacing: 0;
                color: #fff !important;
                margin-bottom: 0;
            }

            &.position-0 {
                max-width: 342px;
                height: 130px;
                top: 0;
                left: 0;
            }

            &.position-1 {
                width: 213px;
                height: 196px;
                flex-wrap: wrap;
                top: 0;
                right: 0;
            }

            &.position-2 {
                width: 320px;
                height: 176px;
                flex-wrap: wrap;
                bottom: 60px;
                right: 0;
            }

            &.position-3 {
                width: 333px;
                height: 130px;
                bottom: 0;
                left: 0;
            }

            &.position-4 {
                width: 257px;
                height: 156px;
                flex-wrap: wrap;
                left: 0;
                top: calc(50% - 78px);
            }

        }

        &.transition-1 {
            .item-stat {

                &.position-0,
                &.position-2 {
                    opacity: 1;
                }
            }
        }

        &.transition-2 {
            .item-stat {

                &.position-1,
                &.position-3 {
                    opacity: 1;
                }
            }
        }

        &.transition-3 {
            .item-stat {
                &.position-2 {
                    opacity: 1;
                }
            }
        }

        &.transition-4 {
            .item-stat {

                &.position-1,
                &.position-4 {
                    opacity: 1;
                }
            }
        }

        @media(max-width:767px) {
            height: 390px;

            .item-stat {
                padding: 25px;
                column-gap: 8px;
                row-gap: 5px;
                height: fit-content !important;

                .top-stat {
                    font-size: 27px;
                }

                p {
                    font-size: 14px;
                    line-height: 17px;
                }

                &.position-0 {
                    max-width: 260px;
                }

                &.position-1 {
                    width: 170px;
                }

                &.position-2 {
                    width: 280px;
                    bottom: 30px;
                }

                &.position-3 {
                    width: 250px;
                }

                &.position-4 {
                    width: 210px;
                    top: calc(50% - 50px);
                    height: 100px;
                }

            }

            &.transition-1 {
                .item-stat {

                    &.position-0,
                    &.position-2 {
                        opacity: 1;
                    }
                }
            }

            &.transition-2 {
                .item-stat {

                    &.position-1,
                    &.position-3 {
                        opacity: 1;
                    }
                }
            }

            &.transition-3 {
                .item-stat {
                    &.position-2 {
                        opacity: 1;
                    }
                }
            }

            &.transition-4 {
                .item-stat {

                    &.position-1,
                    &.position-4 {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .content-form {
        .gform_heading {
            display: none;
        }

        form {
            .gfield_label {
                font-size: 18px !important;
                margin-bottom: 0;
            }

            textarea,
            input,
            select {
                border: 1px solid $green;
                border-top: none;
                border-left: none;
                border-right: none;
                // outline: none;
                padding: 0 0 10px !important;
                height: 30px;
                color: #2A5F5A;
                font-size: 16px !important;
                background: transparent;
            }

            input[type="checkbox"] {
                width: 19px;
                height: 19px;
                border: 1px solid $green;
                border-radius: 2px;
            }

            input[type="submit"] {
                outline-color: $primary;
            }

            .ginput_container_consent {
                display: flex;
                align-items: center;
                gap: 15px;

                .gform-field-label.gfield_consent_label {
                    font-size: 16px;
                    color: $black_2;

                    a {
                        font-size: 16px;
                        color: $green !important;

                        @media(hover : hover) {
                            &:hover {
                                font-weight: bolder;
                            }
                        }
                    }
                }
            }

            .gfield textarea.large {
                height: 35px;

                &::placeholder {
                    text-align: left;
                    font: normal normal normal 16px/32px Figtree;
                    letter-spacing: 0;
                    color: #2A5F5A;
                }
            }

            .gfield--type-choice .ginput_container {
                display: flex;
                column-gap: 15px;

                .gform-field-label {
                    font-size: 16px !important;
                    color: #191919;

                    a {
                        color: inherit;
                        font-size: inherit;
                        line-height: inherit;
                    }
                }
            }

            .validation_message {
                background: $white;
            }

            .gform_footer {
                margin-top: 40px !important;
                padding: 0 !important;

                .gform_button {
                    border: 1px solid $green !important;
                    background: $green;
                    color: #fff !important;
                    font-size: 16px !important;
                    padding: 16px 43px !important;
                    height: auto;
                    border-radius: 100px;

                    &:hover {
                        color: $green !important;
                        background: transparent;
                    }
                }
            }
        }

        //start---> custom css validation errors
        .gform_wrapper.gravity-theme .gform_validation_errors {
            background-color: $white;

            h2 {
                font-size: 15px;
                color: #c02b0a !important;
                font-weight: initial;
                letter-spacing: normal;
                line-height: 25px;
            }
        }

        .gform_wrapper.gravity-theme .instruction.validation_message {
            display: none;
        }

        .gform_wrapper.gravity-theme .gfield_error label {
            color: $black_2;
        }

        //end----> custom css validation errors
    }

    @include media-breakpoint-down(lg) {
        .content-img {
            margin: 0 auto 50px;
        }
    }
}
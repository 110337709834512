.single {
    background-color: $linen;

    .content-single-post {}

    .custom-container-post {
        @include single-content;
    }

    .post-reading {
        font-size: 18px;
        line-height: 1.4444em;
        color: $black_2;
    }

    .main-title-content {
        margin-bottom: 18px;
        @media (max-width: 1440px) {
            max-width: 1050px;
        }
        @media (max-width: 1180px) {
            max-width: none;
        }
    }

    .space-hero {}

    .figure-content-hero {
        .figure-single {
            margin: 0 auto;
            position: relative;
            border-radius: 15px;
            overflow: hidden;
            background: transparent linear-gradient(218deg, $primary 0, $sky-blue 100%) 0 0 no-repeat padding-box;

            /*  &:after{
                content: "";
                width: 100%;
                height: 100%;
               opacity: 0;
            } */
            img {
                display: block;
                margin: 0 auto;
                width: 100%;
                mix-blend-mode: soft-light;
                max-height: 400px;
                object-fit: cover;
            }
        }
    }

    .hero-single-bg {
        padding: 40px 0 0;
        margin-bottom: 44px;
        overflow: initial;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 84px;
            z-index: 2;
            background: $linen;
            opacity: 1;
        }

        &::after {

            content: "";
            position: absolute;
            background: url(../assets/img/bg-blogs.png);
            background-size: cover;
            background-position: 50% 0;
            width: 100%;
            height: 100%;
            left: 0;
            bottom: 10%;



        }




        .overlay-content {
            background-image: url('../assets/img/overlay-hero-home.png') !important;
            background-size: cover !important;
            background-position: 50% 100% !important;
        }

        .video-container {
            opacity: 0.2;
            z-index: 1;
        }
    }

    .content-single-data {
        >.container {
            >*:last-child {
                margin-bottom: 0;
            }
        }
    }

    .content-single-data {
        color: #191919;
        font-size: 22px;
        letter-spacing: 0;
        line-height: 1.454545em;
        padding-bottom: 139px;

        a:not(.btn):not(.page-a-lk) {
            font-weight: normal;
            text-decoration: none;
            color: $green;
            text-decoration-line: underline;
            text-decoration-thickness: 3px;
            text-underline-offset: 7px;
        }

        p {
            margin-bottom: 20px;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 1.454545em;
        }

        strong {
            font-weight: 700;
        }

        h2 {
            margin-bottom: 10px;
            letter-spacing: -1.35px;
        }

        h3 {
            margin-bottom: 18px;
            color: #000000;
            font-weight: 500;
            letter-spacing: -0.68px;
            line-height: 1.17647em;
        }

        h4 {
            margin-bottom: 37px;
            letter-spacing: 0;
            color: #000000;
            line-height: 1.0909em;
        }

        h5 {
            margin-bottom: 25px;
            line-height: 1.5em;
            color: #191919;
            font-weight: 600;
        }

        h6 {
            font-size: 16px;
            letter-spacing: 0;
            margin-bottom: 25px;
            line-height: 1.11111em;
            font-weight: 500;
            color: #181336;
        }

        ul {
            margin-bottom: 40px;
            padding: 0;
            list-style: none;
            font-size: 16px;
            li {
                position: relative;
                padding: 0 0 0 21px;
                margin-bottom: 0;

                &:before {
                    content: "";
                    width: 5px;
                    height: 5px;
                    background-color: #5B6670;
                    position: absolute;
                    border-radius: 100px;
                    left: 0;
                    top: 13px;
                }
            }

            >li {
                &:last-child {
                    margin-bottom: 0;
                }

                ul {
                    margin-bottom: 0;
                    margin-top: 21px;

                    li {
                        &:before {
                            content: "";
                            background-color: #5B6670;
                        }
                    }
                }
            }
        }

        ol {
            margin-bottom: 40px;
            padding: 0;
            list-style: none;
            counter-reset: list;
            font-size: 16px;
            li {
                position: relative;
                padding: 0 0 0 27px;
                margin-bottom: 0;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-weight: 700;
                    counter-increment: list;
                    content: counter(list) '.';
                    position: absolute;
                    color: $black;

                }
            }

            >li {
                &:last-child {
                    margin-bottom: 0;
                }

                ol {
                    margin-bottom: 0;
                    margin-top: 34px;

                    li {
                        &:before {
                            content: "";
                            background-color: $black;
                        }
                    }
                }
            }

        }

        blockquote {
            background-color: $white;
            border-radius: 5px;
            padding: 85px 44px;
            margin-bottom: 40px;

            p {
                font-family: $font-text;
                font-size: 30px;
                line-height: 1.33333em;
                letter-spacing: 0;
            }

            >*:last-child {
                margin-bottom: 0;
            }

        }

        .wistia_embed {
            border-radius: 15px;
            overflow: hidden;

            .w-video-wrapper.w-css-reset {
                background-color: transparent !important;
            }

            img.w-css-reset {
                background-color: transparent !important;
            }
        }

        .wp-block-embed,
        .wistia_embed {
            margin-bottom: 50px;
            border-radius: 15px;
            overflow: hidden;
        }

        .wp-block-image {
            margin-bottom: 50px;
        }

        .has-large-font-size {
            @include has-large-font-size-txt;
            letter-spacing: 0;
        }

        .has-medium-font-size {
            @include has-medium-font-size-txt;
            letter-spacing: 0;
        }

        .has-small-font-size {
            @include has-small-font-size-txt;
            letter-spacing: 0;
        }
    }

    @media(max-width: $breakpoint-down-lg) {

        .content-single-data {
            padding-bottom: 60px;
            font-size: 17px;
            line-height: 1.55555em;

            p {
                font-size: 15px;
                line-height: 1.55555em;
            }

            h2 {}

            h3 {}

            h4 {}

            h5 {}

            h6 {}

            blockquote {
                padding: 45px 30px;

                p {
                    font-size: 24px;
                }


            }

            .wp-block-image {
                margin-bottom: 40px;
            }

            .wp-block-embed,
            .wistia_embed,
            .wp-block-image {
                margin-bottom: 40px;
            }
        }


    }

    @media (max-width: $breakpoint-down-md) {

        .content-single-data {
            font-size: 16px;
            padding-bottom: 40px;

            p {
                font-size: 14px;
                margin-bottom: 20px;
            }

            ul,
            ol {
                margin-bottom: 30px;

                li {
                    margin-bottom: 20px;

                    ul,
                    ol {
                        margin-top: 20px
                    }
                }
            }

            h2,
            h3,
            h4,
            h5,
            h6 {
                text-align: center;
            }

            h4 {
                margin-bottom: 20px;
                margin-top: 25px;

            }

            h5 {
                margin-bottom: 20px;

            }

            .wp-block-image {
                margin-bottom: 30px;
            }

            blockquote {
                padding: 30px;
                margin-bottom: 20px;

                p {
                    font-size: 19px;
                }

                footer {
                    font-size: 14px;
                }
            }

            .wp-block-embed,
            .wistia_embed,
            .wp-block-image {
                margin-bottom: 30px;
            }
        }
    }

    @media (max-width: $breakpoint-down-sm) {
        .post-reading {
            font-size: 16px;
        }
    }
}


.nav-pagination-single-page {
    margin-top: 92px;

    .page-a-lk {
        transition: all ease 0.3s;

        &.prev,
        &.next {
            text-decoration: none !important;
            color: $green;
            display: flex;
            align-items: center;
            position: relative;
            top: 0;
            transform: translate(0%, 0%);
            transition: all ease 0.3s;

            &::after {
                content: none !important;
            }

            &:not(.disabled) {
                .sr-only-text {
                    opacity: 1;
                }

                .circle-arrow {
                    background-color: $green;

                    svg {
                        path {
                            stroke: #fff;
                        }
                    }
                }
            }

            .sr-only-text {
                font-size: 18px;
                letter-spacing: 0.9px;
                font-family: $font-title;
                text-transform: uppercase;
                display: inline-block;
                color: $green;
                text-decoration: none !important;
                font-weight: 600;
                margin: 0;
                padding: 0;
                opacity: 0;

                &:after {
                    content: none !important;
                }
            }

            .circle-arrow {
                width: 50px;
                height: 50px;
                min-width: 50px;
                display: inline-block;
                text-decoration: none !important;
                border: 1px solid #2A5F5A;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border-radius: 100px;
                margin: 0;

                &:after {
                    content: none !important;
                }
            }

            svg {
                width: 24px;
            }
        }

        &.prev {
            left: 0;
            justify-content: flex-start;

            .sr-only-text {
                margin-left: 20px;
                stroke: #fff;
            }
        }

        &.next {
            right: 0;
            justify-content: flex-end;

            .sr-only-text {
                margin-right: 20px;

            }

        }
    }

    @media(max-width: $breakpoint-down-md) {
        margin-top: 35px;

        .prev.page-a-lk {
            order: 1;
            top: 0;
            transform: initial;

            .sr-only-text {
                opacity: 1;
                font-size: 16px;
            }
        }

        .next.page-a-lk {
            order: 2;
            top: 0;
            transform: initial;

            .sr-only-text {
                opacity: 1;
                font-size: 16px;
            }
        }
    }

    @media(max-width: 344px) {

        .prev.page-a-lk,
        .next.page-a-lk {
            .sr-only-text {
                display: none;
            }
        }

    }
}



.socials-share {
    position: fixed;
    top: 260px;
    left: 0;
    z-index: 7;

    .list-share {
        a {
            display: block;
            width: 55px;
            height: 55px;
            display: flex;
            justify-content: center;
            align-items: center;

            &[title="facebook"] {
                background-color: #415a94;
            }

            &[title="twitter"] {
                background-color: #4aa1ec;
            }

            &[title="print"] {
                background-color: #788a8d;
            }

            &[title="email"] {
                background-color: #848484;
            }

            &[title="plus"] {
                background-color: #ee6f59;
            }

            &[title="linkedIn"] {
                background-color: #0077b5;
            }

            img {
                height: 26px;
            }
        }
    }

    @media (max-width: 1180px) {
        position: relative;
        top: 0;
        padding-bottom: 20px;
        z-index: 1;
        margin-top: 50px;

        .list-share {
            display: flex;
            justify-content: center;
        }
    }

    @media(max-width: 768px) {
        margin-top: 30px;
    }
}
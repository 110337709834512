.video-block{
    &::before{
        content: '';
        position: absolute;
        background: url('../assets/img/bg-gradient.png');
        background-size: cover;
        background-position: 50% 0%;
        width: 100%;
        height: 75%;
        left: 0;
        bottom: 13%;
    }
    .content-title{
        p{
            font-size: 22px;
        }
        .content-buttons{
            margin-top: clamp(15px,3vw,35px);
        }
    }
    .content-video{
        max-width: 963px;
        margin: 70px auto 0;
        video {
            width: 100%;
            box-shadow: 5px 5px 50px #00000026;
            border-radius: 20px;
            box-shadow: 0 40px 19px rgba(238, 239, 240,1);
            @media (max-width: 991px) {
                box-shadow: none;
            }
        }
        iframe {
            width: 100%;
            min-height: 560px;
            box-shadow: 5px 5px 50px #00000026;
            border-radius: 20px;
            box-shadow: 0 40px 19px rgba(238, 239, 240,1);
            @media (max-width: 991px) {
                box-shadow: none;
            }

        }
    }
    @media (max-width: 767px) {
        .content-title{
            p{
                font-size: 17px;
            }
        }
        .content-video{
            margin-top: 40px;
           
            iframe {
                min-height: initial;
            }
        }
    }
}
.hero-home{
 /*    &.before-element{
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 140px;
            z-index: 1;
            background: linear-gradient(0deg, #fdfaf3  0%, rgba(255, 255, 255, 0) 100%);
        }
    } */
  
 /*    .video-container{
        opacity: 0.2;
    } */
 /*    .overlay-content{
         opacity: 0 !important; 
        background: url('../assets/img/overlay-hero-home.png') !important;
        background-size: cover !important;
        background-position: 50% 100% !important;
    } */
    .content-hero {
        display: flex;
        min-height: 674px;
        align-items: center;
        flex-wrap: wrap;
    }
    .content-text {
        max-width: 49.1%;
        padding: 140px 0;
        h1{
            margin-bottom: 20px;
            font-size: 60px;
            letter-spacing: -0.6px;
        }
        p{
            font-size: 22px;
        }
        .content-buttons{
            margin-top: 60px;
        }
    }
    .content-img-cards {
        width: 78.8%;
        position: absolute;
        top: 0;
        right: -31%;
        padding-top: 110px;
        padding-bottom: 50px;
        height: 675px;
        z-index: 5;
        @media (min-width: 1520px) {
            right: -35%;
        }
        img {
            display: block;
            width: 85%;
            margin-left: auto;
            max-height: 515px;
            box-shadow: 5px 5px 50px rgba(0, 0, 0, .1490196078);
            border-radius: 15px;
        }
        .card-stat {
            position: absolute;
            opacity: 0; 
            animation: fadeIn_bx 2s  linear 1 forwards; 
        }
       
        .card-1{
            top: 0;
            left: 30%;
            animation-delay: 0.5s;
            &.aos-animate{
                h3{
                    opacity: 1; 
                    animation: fadeIn_bx 0.5s linear 1; 
                }
                p{
                    opacity: 1; 
                    animation: fadeIn_bx 0.5s linear 1; 
 
                }
            }
        }
        .card-2{
            top: 30%;
            animation-delay: 1s;
            &.aos-animate{
                h3{
                    opacity: 1; 
                    animation: fadeIn_bx 0.5s linear 1; 
                }
                p{
                    opacity: 1; 
                    animation: fadeIn_bx 0.5s linear 1; 
 
                }
            }
        }
        .card-3{
            bottom: 0;
            left: 25%;
            animation-delay: 1.5s;
            &.aos-animate{
                h3{
                    opacity: 1; 
                    animation: fadeIn_bx 0.5s linear 1; 
                }
                p{
                    opacity: 1; 
                    animation: fadeIn_bx 0.5s linear 1; 
 
                }
            } 
        }
    }
    @media (max-width: 1024px) {
        padding: 90px 0 !important;
        .content-text {
            max-width: 100%;
            padding: 0px 0 60px;
        }
        .content-img-cards {
            width: 100%;
            position: relative;
            top: 0;
            right: 0;
            padding-top: 120px;
            padding-bottom: 125px;
            height: auto;
            min-height: 525px;
        }
    }
    @media (max-width: 767px) {
        .content-text {
            h1{
                font-size: 40px;
            }
            p{
                font-size: 17px;
            }
            .content-buttons{
                margin-top: 40px;
            }
        }
    }
    @media (min-width: 631px) {
        .img-mobile-hero{
            display: none!important;
        }
    }
    @media (max-width: 630px) {
        .img-desktop-hero{
            display: none!important;
        }
        .content-img-cards {
            padding: 0;
            min-height: initial;
            .card-stat {
                position: initial;
                margin-bottom: 25px;
                &:last-child{
                    margin: 0;
                }
            }
        }
    }
}

.general-section .card-stat {
  //  background: linear-gradient(302deg, $primary 0%, #FFC72C 100%);
    background: transparent linear-gradient(302deg, #0167A6 0%, #57D19E 100%) 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 50px #00000026;
    border-radius: 15px;
    padding: 40px;
    display: inline-block;
    h3{
        color: $white!important;
        font-size: 40px;
        letter-spacing: -1.2px;
        font-weight: 800;
    }
    p{
        margin-bottom: 0;
        color: $white!important;
        max-width: 238px;
        font-size: 16px;
    }
    &.card-wide{
        display: flex;
        column-gap: 20px;
        max-width: 342px;
        h3{
            margin-bottom: 0;
        }
        p{
            max-width: 141px;
        }
    }
    @media (max-width: 991px) {
        padding: 30px;
        h3{
            font-size: 30px;
        }
    }
    @media (max-width: 767px) {
        padding: 25px;
        h3{
            font-size: 25px;
        }
    }
}


@keyframes fadeIn_bx {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
     // background-color: red!important;
    }
  }
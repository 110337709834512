.slick-arrow{
    
}
.slick-dots{
    position: relative !important;
    display: flex!important;
    width: 100%!important;
    li {
        flex: auto;
        width: auto !important;
        height: auto !important;
        margin: 0 !important;
        button {
            width: 100%!important;
            height: 3px!important;
            padding: 0!important;
            position: relative;
            &:focus-visible,
            &:focus{
                outline: -webkit-focus-ring-color auto 1px!important;
            }
            &:before {
                content: ''!important;
                width: 100%!important;
                height: 100%!important;
                background: white;
            }
            
        }
        &.slick-active button:before{
            opacity: 1!important;
        }
    }
}

.slick-next:focus-visible,
 .slick-prev:focus-visible {
    outline: -webkit-focus-ring-color auto 1px!important;
}
.tabs-two-columns {
    @media (min-width: 1200px) {
        .row {
            --bs-gutter-x: 150px;
        }
    }

    .container::before {
        content: "";
        position: absolute;
        width: 65%;
        height: 75%;
        bottom: 1%;
        left: 0;
        background: url(../assets/img/bg-tabs.png);
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
    }

    .content-title {
        margin-bottom: 70px;

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .content-title-tabs {
        display: flex;
        flex-wrap: wrap;
        column-gap: 25px;
        row-gap: 25px;
        margin-bottom: 40px;

        .tab-title {
            padding: 15px 50px;
            background: $green;
            border: 2px solid $green;
            color: #fff;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            display: flex;
            align-items: center;
            font-family: $font-title;
            font-weight: 700;

            &:hover {
                background: transparent;
                color: $green;
            }

            &.active {
                background: transparent;
                color: $green;
            }
        }
    }

    @include tab-1-women;
    @include tab-2-merch-guy;
    @include tab-3--category-m;
    @include tab-4-analytics;
    @include tab-default;

    @keyframes move-elment {
        0% {
            display: block;
            opacity: 0;
        }
        50%{
            display: block;
            opacity: 1;
        }
        100% {
            display: block;
            opacity: 1;
        }
    }

    
    .content-tabs {
        .tab-content {
            display: none;

            &.active {
                display: block;
                animation:  move-elment 1s linear 1;
            }
            &.active-efect-item{
                

            }
            
        }

        .col-list {
            margin-top: 50px;
        }

        .thead-tabs {
            margin-bottom: 70px;

            p {
                font-size: 22px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        img {
            max-width: 550px;
            width: 100%;
        }

        .content-list {
            padding: 20px 0;
            border-bottom: 1px solid $black_2;

            p {
                margin-bottom: 0;
                font-size: 18px;
            }


            &:first-child {
                border-top: 1px solid $black_2;
            }
        }

        .content-buttons {
            margin-top: 90px;
        }
    }

    .container:before{
        opacity: 1 !important;
    }
    

    @media (max-width: 991px) {
        .content-title {
            margin-bottom: 40px;
        }

        .content-title-tabs {
            .tab-title {
                padding: 15px 35px;
            }
        }

        .content-tabs {
            .col-list {
                margin-top: 40px;
            }

            .thead-tabs {
                margin-bottom: 40px;
            }

            .content-buttons {
                margin-top: 40px;
            }
        }
    }
    @include media-breakpoint-down(lg) {
        .default-box-img {
            display: flex;
            justify-content: center;
        }
    }

    @media (max-width: 768px) {
        .tab-item-content{
             
        }
        .content-title-tabs {
            column-gap: 9px;
            row-gap: 15px;
            margin-bottom: 30px;

            .tab-title {
                padding: 6px 18px;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .content-title-tabs {
            justify-content: center;
        }
    }
}


.bg-glow-orange {
    .container::before {
        content: "";
        position: absolute;
        width: 65%;
        height: 80%;
        bottom: -110px;
        left: -104px;
        background: url(../assets/img/bg-media-object.png);
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
        rotate: 180deg;
        filter: blur(50px);
    }
}

.tabs-two-columns-benefits {

    .content-tabs {
        .col-lg-6:first-child {
            padding-right: 15px;

            img {
                margin-left: 35px;
            }
        }
    }

    @media (max-width: 1200px) {
        .content-tabs {
            .col-lg-6:first-child {
                img {
                    margin-left: 0;
                }
            }
        }
    }

}


.content-tabs {

}
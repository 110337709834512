.columns-with-cards{
    .content-text{
        padding-top: 20px;
    }
    .content-columns{
        padding-top: 73px;
        padding-bottom: 124px;
        .content-columns__item{
            .content-img{
                max-width: 260px;
                padding-bottom: 58px;
            }
            h5{
                border-bottom: 1px solid $black_2;
                padding-bottom: 20px;
                font-weight: 700;
                font-size: 22px;
            }
            p{
                font-size: 18px;
                padding-top: 34px;
                margin-bottom: 0;
                line-height: 28px;
            }
        }
    }
    @media (max-width: 1199px) {
        .content-columns{
            .row{
                row-gap: 80px;
            }
        }
    }
}
.hero-image{
    background-size: cover !important ;
    background-position: center !important;
    // background-image: url(../assets/img/bg-competive2.png) !important;
    .row{
        flex-direction: column;
        align-items: normal !important;
        .col-lg-6{
            width: 100%;
        }
        .content-text-hero{
            padding-bottom: 113px;
        }
        .content-desktop{
            margin: 0 auto;
            .conten-media{
                margin: 0 auto;
                max-width: 100%;
                text-align: center;
                box-shadow: none !important;
                border-radius: 0px !important;
                img{
                    max-width: 1096px;
                    width: 100%;
                    // border-left: 50px solid white;
                    // border-right: 50px solid white;
                    border-radius: 0;
                    box-shadow: none !important;
                    @media (max-width: 1151px) {
                        max-width: 100%;
                    }
                }
            }
        }
    }
    @media (max-width: 991px) {
        padding: 30px 0 0 0px !important;
        .content-text-hero{
            padding-bottom: 50px !important;
            text-align: center;
            .breadcrumb-site{
                text-align: left !important;
            }
            .conten-media{
                margin-top: 50px;
                box-shadow: none !important;
                img{
                    box-shadow: none;
                }
            }
        }
    }
    @media (max-width: 576px) {
        background-size: auto !important ;
        .content-text-hero{
            .conten-media{
                margin-top: 20px;
            }
        }
    }
    @media (max-width: 400px){
        background-size: auto !important ;
    }
}
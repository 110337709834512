.block-columns-with-list {
    .content-title {
        margin-bottom: clamp(40px, 5vw, 100px);
    }

    .content-columns {

        .box {
            display: flex;
            flex-direction: column;
            row-gap: clamp(10px, 5vw, 15px);
            width: min(100%, 423px);

            hr {
                margin: 20px 0 16px;
                opacity: 1;
            }

            h5 {
                font-weight: bold;
                font-size: 22px;
                letter-spacing: 0;
                color: #000000;
                text-transform: uppercase;
                text-align: left;
            }

            ul {
                display: flex;
                flex-direction: column;
                row-gap: clamp(8px, 5vw, 15px);
                padding-left: 27px;

                li {
                    list-style-image: url(../assets/img/chek-contact.svg);
                    padding-left: 10px;
                    text-align: left;
                    letter-spacing: 0;
                    color: $black_2;
                    text-transform: capitalize;
                    font-weight: 600;
                    width: min(100%, 367px);
                }
            }
        }
    }

    @media (max-width: 766px) {
        .content-columns {
            .box {
                margin: 0 auto;

                h5 {
                    font-size: $font-base;
                }
            }
        }
    }
}
.slider-stats{
    .eyebrow {
        margin-bottom: 80px;
    }
    .content-text{
        max-width: 536px;
        .eyebrow {
            margin-bottom: 20px;
        }
        p{
            font-size: 22px;
        }
        .content-buttons {
            margin-top: 00px;
        }
    }

    .slick-list {
        margin: 0 -20px;
        .slick-slide {
            margin: 0 20px;
        }
    }
    .progress{
        display: block;
        width: 100%;
        height: 3px;
        border-radius: 0;
        overflow: hidden;
        background-color: hsla(0, 0%, 100%, 0.393);
        background-image: linear-gradient(to right, #fff, #fff);
        background-repeat: no-repeat;
        background-size: 0 100%;
        transition: background-size .4s ease-in-out;
    }
    .content-slider{
        .slick-dots{
            bottom: -33px;
            opacity: 0;
            li{
                button{
                    &:before{
                        transition: all linear 0.3s;
                        width: 100%!important;
                    }
                    &:after{
                        content: ''!important;
                        width: 100%!important;
                        height: 100%!important;
                        background: white;
                        position: absolute;
                        left: 0;
                        top: 0;
                        transition: all linear 0s;
                    }
                }
                &.slick-active{
                    button{
                        &:before{
                            width: 100%!important;
                        }
                        &:after{
                            width: 100%!important;
                            transition: all linear 0.3s;
                        }
                    }
                    & ~ li button{
                        &:before{
                            opacity: .25!important;
                        }
                        &:after{
                            width: 0%!important;
                            transition: all linear 0s!important;
                             height: 0;;
                        }
                    }
                }

            }
        }
        &.active-items-efect{
            .slick-dots{
                li{
                    button{
                        height: 1px!important;
                    }

                }
            }
        }
    }


    //add line over dot
    .progress-content{
        padding: 0 90px;
    }
    .content-slider{
        padding: 0 90px;
        .row{
            --bs-gutter-x: 80px;
        }
        @media (max-width: 1200px) {
            .row{
                --bs-gutter-x: 45px;
            }
        }
        .slick-arrow {
            width: 50px;
            height: 50px;
            z-index: 5;
            transform: translateY(-70%);
            &:before {
                content: '';
                background-size: cover!important;
                width: 50px;
                height: 50px;
                position: absolute;
                top: 0;
                left: 0;
            }
            &.slick-prev{
                left: 0;
                &:before{
                    background: url('../assets/img/slider-arrow-withe-left.svg');
                }
            }
            &.slick-next{
                right: 0;
                &:before{
                    background: url('../assets/img/slider-arrow-white-right.svg');
                }
            }
        }
        .content-slider-box{
            padding-bottom: 140px;
        }
        .content-stats{
            .row{
                --bs-gutter-x: 25px;
                --bs-gutter-y: 60px;
                row-gap: 80px;
            }
            .stat-item{
                max-width: 200px;
                width: 100%;
                .stat-item--number{
                    font-size: 40px;
                    font-family: $font-text;
                    letter-spacing: -1.2px;
                }
                p{
                    margin-bottom: 0;
                    font-size: 16px;
                }
                // @media (max-width: 990px) {
                //     margin: 0 auto;
                // }
            }

        }
        .row-custom{
            display: flex;
            flex-wrap: wrap;
            margin-top: 50px;
            grid-gap: 30px;
            column-gap: 20px;
            align-items: center;
            figure{
                margin: 0;
                max-width: 210px;
                img{
                    max-width: 100%;
                }
            }
        }
    }
    @media (max-width: 991px) {
        .eyebrow {
            margin-bottom: 40px;
        }
        .content-text{
            max-width: 100%;
            margin-bottom: 40px;
            p{
                font-size: 18px;
            }
            .content-buttons {
                margin-top: 0px;
            }
        }
        .progress-content{
            padding: 0 45px;
        }
        .content-slider{
            padding: 0 45px;
            .content-slider-box{
                padding-bottom: 50px;
            }
            .row{
                --bs-gutter-y: 30px!important;
            }
            .slick-arrow {
                width: 30px;
                height: 30px;
                &:before {
                    width: 30px;
                    height: 30px;
                }
                &.slick-prev{
                    left: -10px;
                }
                &.slick-next{
                    right: -10px;
                }
            }
        }
    }
    @media (max-width: 768px) {
        .content-slider{
            .row-custom{
                margin-top: 30px;
                justify-content: center;
                grid-gap: 20px;
                .content-buttons{
                    justify-content: center;
                }
            }
        }
        
    }
    @media (max-width: 575px) {
        .eyebrow {
            margin-bottom: 30px;
        }
        .content-text{
            margin-bottom: 30px;
            p{
                font-size: 16px;
            }
            .content-buttons {
                margin-top: 0px;
            }
        }
       
        .progress-content{
            padding: 0 25px;
        }
        .content-slider{
            padding: 0 25px;
            .content-slider-box{
                padding-bottom: 30px;
            }
            .row{
                --bs-gutter-y: 20px!important;
            }
            .content-stats{
                .stat-item{
                    margin: 0 auto;
                    text-align: center;
                    .stat-item--number{
                        font-size: 27px;

                    }
                }

            }
        }
    }
}
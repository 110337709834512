.content-form-gravityform{
    position: relative;
    .gform_wrapper.gravity-theme input[type=color],
    .gform_wrapper.gravity-theme input[type=date],
    .gform_wrapper.gravity-theme input[type=datetime-local],
    .gform_wrapper.gravity-theme input[type=datetime],
    .gform_wrapper.gravity-theme input[type=email],
    .gform_wrapper.gravity-theme input[type=month],
    .gform_wrapper.gravity-theme input[type=number],
    .gform_wrapper.gravity-theme input[type=password],
    .gform_wrapper.gravity-theme input[type=search],
    .gform_wrapper.gravity-theme input[type=tel],
    .gform_wrapper.gravity-theme input[type=text],
    .gform_wrapper.gravity-theme input[type=time],
    .gform_wrapper.gravity-theme input[type=url],
    .gform_wrapper.gravity-theme input[type=week],
    .gform_wrapper.gravity-theme select{
        background: #fff 0 0 no-repeat padding-box;
        border-radius: 35px;
        opacity: 1;
        color: $black_2;
        border: 0;
        padding: 22px 280px 22px 39px;
        font-size: 18px;
        line-height: normal;
        font-weight: 500;
        height: 68.67px;
        min-height: 68.67px;
        &::placeholder{
            color: $black_2;
        }
    }
    .gform_validation_errors{
        display: none;
    }
    .gform_wrapper.gravity-theme #field_submit input,
    .gform_wrapper.gravity-theme .gform_footer input{
        font-size: 16px;
        letter-spacing: 0;
        padding: 22px 39px;
        font-weight: 700;
        font-family: $font-title;
        line-height: 1.5em;
        border-width: 2px;
        background-color: $green;
        color: $white;
        border: 1px solid $green;
        font-weight: 700;
        position: absolute;
        top: 0;
        right: -1px;
        min-width: 275px;
        border-radius: 35px;
        min-height: 68.67px;
        &:hover{
            color: $green;
            border-color: $green;
            background-color: transparent;

        }
    }
    .gform_wrapper.gravity-theme .gfield-choice-input{
        width: 18px;
        height: 18px;
        border-radius: 2px;
        accent-color: $green;
        margin-right: 11px;

    }
    .gform_wrapper.gravity-theme  .ginput_container_checkbox{
        label{
            font-size: 16px;
            font-weight: normal;
            a{
                font-size: initial;
                text-underline-offset: 5px;
            }
        }
    }
    .gform_submission_error{
        display: none;
    }
    .gform_wrapper.gravity-theme .gform_footer, .gform_wrapper.gravity-theme .gform_page_footer {
        margin: 0px 0 0;
        padding: 0px 0;
    }
    .content-form-gravityform .gform_wrapper.gravity-theme .ginput_container_checkbox label{
        vertical-align: text-top;
    }
    .gform_wrapper.gravity-theme .gfield-choice-input+label {
        max-width: calc(100% - 43px);
        vertical-align: text-top;
        @media(max-width: 992px){
            max-width: 100%;
        }
    }
    .gfield_checkbox{
        .gchoice{
            display: flex;
            align-items: flex-start;
            label{
                position: relative;
                top: -3px;
            }
        }
    }
    @media(max-width: 991px){
    .gform_wrapper.gravity-theme input[type=color],
    .gform_wrapper.gravity-theme input[type=date],
    .gform_wrapper.gravity-theme input[type=datetime-local],
    .gform_wrapper.gravity-theme input[type=datetime],
    .gform_wrapper.gravity-theme input[type=email],
    .gform_wrapper.gravity-theme input[type=month],
    .gform_wrapper.gravity-theme input[type=number],
    .gform_wrapper.gravity-theme input[type=password],
    .gform_wrapper.gravity-theme input[type=search],
    .gform_wrapper.gravity-theme input[type=tel],
    .gform_wrapper.gravity-theme input[type=text],
    .gform_wrapper.gravity-theme input[type=time],
    .gform_wrapper.gravity-theme input[type=url],
    .gform_wrapper.gravity-theme input[type=week],
    .gform_wrapper.gravity-theme select{
        padding: 10px 219px 10px 39px;
        min-height: 60px;
        height: 60px;
    }
        .gform_wrapper.gravity-theme #field_submit input,
        .gform_wrapper.gravity-theme .gform_footer input{
            min-width: 30px;
            min-height: 60px;
            height: 60px;
            padding: 14px 39px;
        }
        .gfield_checkbox{
            .gchoice{
                display: flex;
                align-items: flex-start;
                label{
                    position: relative;
                    top: -3px;
                }
            }
        }
    }


    @media (max-width: $breakpoint-down-sm){
        .form-subscribe-footer{
            .gform-body{
                .gform_fields{
                    .gfield{
                        .ginput_container{
                            input{
                                padding: 10px 20px;
                            }
                        }
                    }
                }
            }
        }
    }


    @media(max-width: $breakpoint-down-sm){
        .gform_wrapper.gravity-theme #field_submit input,
        .gform_wrapper.gravity-theme .gform_footer input {
            min-width: 30px;
            position: relative;
        }
        .gform_wrapper.gravity-theme .gform_footer {
            justify-content: center;
        }
        .gform_wrapper.gravity-theme input[type=color],
        .gform_wrapper.gravity-theme input[type=date],
        .gform_wrapper.gravity-theme input[type=datetime-local],
        .gform_wrapper.gravity-theme input[type=datetime],
        .gform_wrapper.gravity-theme input[type=email],
        .gform_wrapper.gravity-theme input[type=month],
        .gform_wrapper.gravity-theme input[type=number],
        .gform_wrapper.gravity-theme input[type=password],
        .gform_wrapper.gravity-theme input[type=search],
        .gform_wrapper.gravity-theme input[type=tel],
        .gform_wrapper.gravity-theme input[type=text],
        .gform_wrapper.gravity-theme input[type=time],
        .gform_wrapper.gravity-theme input[type=url],
        .gform_wrapper.gravity-theme input[type=week],
        .gform_wrapper.gravity-theme select{
            padding: 10px 20px;
            min-height: 60px;
            height: 60px;
        }
        .gform_wrapper.gravity-theme
         .gform_footer,
         .gform_wrapper.gravity-theme .gform_page_footer {
            margin: 6px 0 0;
            padding: 16px 0;
        }
    }
}
button{
    &:focus-visible,
    &:focus{
        outline: -webkit-focus-ring-color auto 1px;
    }
}
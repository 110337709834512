.privacy-policy{
    background: #FDFAF3 0 0 no-repeat padding-box;
    opacity: 1;
    overflow: visible;
    .container{
        .wrapper{
            background: #fff 0 0 no-repeat padding-box;
            border-radius: 5px;
            opacity: 1;
            padding: 100px 112px;
            max-width: 1096px;
            margin: 0 auto;
            margin-top: -226px;
            h1, h2, h3, h4, h5, h6 {
                letter-spacing: 1px;
            }
            @media (max-width: 768px) {
                max-width: 600px;
                padding: 50px;
            }
            @media (max-width: 576px) {
                padding: 20px;
            }
            h5{
                @media (max-width: 768px) {
                    font-size: 18px;
                }
            }
            h4{
                @media (max-width: 576px) {
                    font-size: 16px;
                }
            }
            p{
                font-size: 22px;
                font-family: $font-title;
                a:not(.btn-site):not(.btn-link):not(.btn){
                    font-weight: 600 !important;
                    color: $green !important;
                    font-family: Poppins, sans-serif !important;

                }
                @media (max-width: 576px) {
                    font-size: 18px;
                    margin-bottom: 6px;
                    a:not(.btn-site):not(.btn-link):not(.btn){
                        font-size: 18px !important;
                    }
                }
            }
            hr{
                border: 1px solid $black_2;
                opacity: 1;
                margin-bottom: 40px;
            }
            a{
                font-weight: 600;
            }
            .iub_footer{
                p{
                    font-size: 11px !important;
                    font-family: Poppins, sans-serif;
                    a:not(.btn-site):not(.btn-link):not(.btn){
                        font-size: 11px !important;

                    }
                }
                a:not(.btn-site):not(.btn-link):not(.btn){
                    font-family: Poppins, sans-serif!important;
                }
            }
            ul{
                padding-left: 20px;
                li{
                    margin-bottom: 21px;
                    font-size: 22px;
                    line-height: 32px;
                }
                li::marker{
                    color: #5B6670;
                }
                @media (max-width: 576px) {
                    li{
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

.header-privacy-policy{
    // background-color: aqua !important;
    .container{
        display: flex;
        flex-direction: column-reverse;
        h1{
            margin-bottom: 0;
        }
        h6{
            padding-bottom: 34px;
            margin-bottom: 0;
            font-size: 16px;
            color: $green !important;
            @media (max-width: 768px) {
                padding-bottom: 17px;
            }
        }
    }
    @media (max-width: 991px) {
		&:not(.hero-pages):not(.cta-promo-bar):not(.promo-card):not(.hero-single-bg):not(.hero-home):not(.hero-with-form):not(.hero-banner-common) {
			padding: 40px 0px 200px 0px !important;
		}
	}
}
.template-thank-you{
    padding-top: 59px !important;
    padding-bottom: 100px !important;

    background: url('../assets/img/overlay-thank-you.png') !important;
            background-size: cover !important;
            background-position: 50% 100% !important;
            opacity: 1 !important;

    .site-main{
        .row{
            justify-content: space-between;
        }
    }
    .content-left{
        p{
            font-size: 22px;
            color: $black_2;
        }
        h2{
            padding: 27px 0 29px 0;
            margin-bottom: 0;
        }
        ul{
            padding-left: 20px;
            li{
                margin-bottom: 20px;
            }
            li::marker{
                color: #5B6670;
            }
        }
        @media (max-width: 990px) {
            text-align: center;
            padding-bottom: 60px;
            ul{
                max-width: 450px;
                margin: 0 auto;
            }
        }
        @media (max-width: 576px){
            padding-bottom: 0;
        }
    }

    .content-right{
        max-width: 536px;
        padding-top: 39px;
        &__image{
           // background: transparent linear-gradient(245deg, #800048 0%, #8F4993 100%) 0 0 no-repeat padding-box;
            background: transparent linear-gradient(293deg, #0167a6 0, #57d19e 100%) 0 0 no-repeat padding-box;
            border-radius: 10px;
            opacity: 1;
            text-align: center;
            max-height: 251px;
            height: 100%;
            figure{
                img{
                    width: 100%;
                    max-width: 409px;
                    border-radius: 10px;
                    margin-top: -68px;
                }
            }
        }

        @media (max-width: 990px) {
            margin: 0 auto;
            max-width: 770px;
            &__image{
                max-width: 536px;
                margin: 0 auto;
            }
        }
        @media (max-width: 576px) {
            margin-top: 60px;
            &__image{
                border-radius: 5px;
                figure{
                    padding-left: 25px;
                    padding-right: 25px;
                }
            }
        }
    }

    .video-container{
        opacity: 0.4;
        position: relative;
        z-index: -1;
        .video-background {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            overflow: hidden;
            video {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: translate(-50%, -50%);
            }
        }
    }
    @media (max-width: 576px){
        .breadcrumb-site{
            .items-breadcrumb{
                line-height: 2.5em;
            }
        }
    }
}
.accordion-interactive{
    .content-title{
        margin-bottom: 30px;
        h2,h3,h4,h5,h6{
            max-width: 1120px;
        }
        h2{
            font-size: 50px;
            letter-spacing: -1.5px;
        }
        p{
            font-size: 22px;
            max-width: 1120px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .row{
        --bs-gutter-x: 70px;
    }
    .content-accordion-text {
        max-width: 648px;
        padding: 20px 0;
        border-bottom: 1px solid #000;
        .tab-title{
            color: #000000;
            text-transform: uppercase;
            font-size: 22px;
            position: relative;
            padding-right: 55px;
            transition: all 0.4s ease-in;
            cursor: pointer;
            font-family: $font-text;
            font-weight: 700;
            &::before{
                content: '';
                position: absolute;
                background: url('../assets/img/arrow-accordion.svg');
                background-size: contain;
                width: 23px;
                height: 13px;
                right: 25px;
                top: 8px;
            }
            &.active{
                margin-bottom: 20px;
                &::before{
                    transform: rotate(180deg);
                }
            }
        }
        .c-text{
            p{
                max-width: 536px;
                font-size: 18px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .btn{
            margin-top: 20px;
        }
        .tab-content{ 
            display: none;
            &.active{
                display: block;
            }
        }
    }
    .content-accordion-img{
        .tab-content{ 
            display: none;
            &.active{
                display: block;
            }
        }
        .content-img-accordion{
            position: relative;
            max-width: 550px;
            width: 100%;
            height: 550px;
            display: flex;
            align-items: center;
            background: #f9f3f3;
            justify-content: center;
            padding: 25px;
            margin: 0 auto;
            &.external{
                > img{
                    position: absolute;
                    max-width: 118%;
                }
            }
            > img{
                min-width: 380px;
                max-width: 460px;
                position: relative;
                z-index: 5;
            }
            .gallery-bg {
                position: absolute;
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                height: 100%;
                column-gap: 10px;
                row-gap: 10px;
                z-index: 0;
                > div {
                    width: calc(50% - 5px);
                    height: calc(33% - 5px);
                    img {
                        max-width: 100% !important;
                        width: 100%;
                        height: 100% !important;
                        min-height: initial !important;
                        min-width: initial !important;
                        object-fit: cover;
                        border-radius: 15px;
                    }
                }
            }
        }
        .content-buttons {
            margin-top: 40px;
        }
    }

    @media (max-width: 991px) {
        .content-title{
            h2{
                font-size: 40px;
            }
        }
        .content-accordion-text {
            max-width: 100%;
        }
        .content-accordion-img{
            margin-top: 30px;
            .content-img-accordion{
                margin: 0 auto;
            }
            .content-buttons {
                margin-top: 30px;
            }
        }
    }
    @media (max-width: 767px) {
        .content-title{
            h2{
                font-size: 30px;
                letter-spacing: -1.5px;
            }
        }
        .content-accordion-img{
            .content-img-accordion{
                height: auto;
                padding: 70px 25px;
                img{
                    min-width: initial;
                    width: 100%;
                }
                &.external{
                    img{
                        position: relative;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .content-tablet{
        display: none!important;
    }
}
@media (max-width: 991px) {
    .content-desktop-tablet{
        display: none!important;
    }
}
.hero-banner-common{
    .content-text-hero{
        .breadcrumb-site {
            margin-bottom: 34px;
        }
        p{
            font-size: 22px;
        }
        .content-buttons {
            margin-top: 40px;
        }
    }

    .conten-media{
        max-width: 527px;
        margin-left: auto;
        margin-bottom: 15px;
      //  box-shadow: 5px 5px 50px rgba(0, 0, 0, .1490196078);
        border-radius: 15px;
        & > *{
            border-radius: 15px;
        }
    }
    @media (max-width: 991px) {
        .content-text-hero{
            .content-buttons {
                margin-top: 30px;
                justify-content: center;
            }
        }
        .conten-media{
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0;
            margin-top: 30px;
        }
    }
    @media (max-width: 991px) {
        padding: 60px 0 !important;
        .content-text-hero{
            p{
                font-size: 17px;
            }
        }
    }
}

@media (min-width: 992px) {
    .content-tablet-desktop{
        display: none!important;
    }
}
@media (max-width: 991px) {
    .content-desktop{
        display: none!important;
    }
}

.full-image-fluid{
    .container{
        .row{
            align-items: start !important;
            .conten-media{
                margin-left: 0;
                margin-bottom: 0;
                max-width: 100%;
                position: relative;
                left: 100px;
                img{
                    max-width: 971px;
                    box-shadow: 5px 5px 50px rgba(0, 0, 0, .1490196078);
                }
            }
        }
    }
    // @media (max-width: 991px) {
    //     .container{
    //         .row{
    //             .content-tablet-desktop{
    //                 display: none;
    //             }
    //             .content-desktop{
    //                 display: block !important;
    //             }
    //         }
    //     }
    // }
}
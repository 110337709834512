.single-ebook{
    &.ebook-template-gated-content{
        .content-right__image{
            display: none;
        }
    }
}

.template-gated-content {
    padding-top: 64px !important;
    padding-bottom: 100px !important;

    background: url("../assets/img/overlay-hero-home.png")!important;
    background-size: cover !important;
    background-position: 50% 100% !important;
    opacity: 1 !important;

    .main-content-site {
        margin-top: 64px;
    }

    .site-main {
        .row {
            justify-content: space-between;
        }
    }

    .content-left {
        p {
            font-size: 22px;
            color: #191919;
        }

        h2 {
            padding: 27px 0 29px;
            margin-bottom: 0;
        }

        ul {
            padding-left: 20px;

            li {
                margin-bottom: 20px;
                font-size: 22px;
                line-height: 32px;
            }

            li::marker {
                color: #5B6670;
            }
        }

        @media (max-width: 990px) {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 00px;

            ul {
                /*    max-width: 450px;
                margin: 0 auto;
                text-align: left; */
            }
        }

        @media (max-width: 768px) {

            padding-bottom: 0;

            p {
                font-size: 18px;
            }

            ul {
                li {
                    font-size: 18px;
                    margin-bottom: 8px;
                }
            }
        }

        @media (max-width: 576px) {
            padding-bottom: 0;
        }
    }

    .content-right {
        max-width: 560px;
        padding-top: 39px;

        &__image {
            background: transparent linear-gradient(245deg, #800048 0%, #8F4993 100%) 0 0 no-repeat padding-box;
            border-radius: 10px;
            opacity: 1;
            text-align: center;
            max-height: 251px;
            margin-bottom: 15%;
            margin-top: 30px;
            padding: 0 66px 30px;
            height: auto;

            figure {
                img {
                    max-width: 409px;
                    border-radius: 10px;
                    margin-top: 68px;
                    width: 100%;
                    @media (max-width: $breakpoint-down-sm) {
                        margin-top: 30px;
                    }
                }
            }

            @media (max-width: 990px) {
                margin-top: 100px !important;
            }

            // @media (max-width: 576px) {
            //     margin-top: 30px !important;
            // }
        }

        /*style form*/
        &__form {
            .box-script-content{
                padding: 60px 50px 87px 50px;
                background-color: white;
                box-shadow: 5px 5px 25px #2F5DCD26;
                border-radius: 20px;
            }
            form[id*="gform_"] {
                padding: 60px 50px 87px 50px;
                background-color: white;
                box-shadow: 5px 5px 25px #2F5DCD26;
                border-radius: 20px;

                .gform-body {

                    legend,
                    label {
                        margin-bottom: 0;
                        line-height: normal;
                        font-size: 18px;

                        .gfield_required::after {
                            content: "*";
                            color: #191919;
                            position: relative;
                            top: -2px;
                        }

                        .gfield_required {
                            padding-left: 0;

                            .gfield_required_text {
                                display: none;
                            }
                        }
                    }

                    span:focus-visible,
                    .ginput_container:focus-visible {
                        border: none !important;
                        outline: none;
                    }

                    input {
                        border: none;
                        border-bottom: 1px solid $green;
                        padding-top: 0;
                        padding-bottom: 0;
                    }

                    input:focus-visible {
                        // border-bottom: 3px solid $green !important;
                        // outline: none;
                    }


                    .gchoice {
                        @media (max-width: 576px) {
                            /*  text-align: center; */
                        }

                        input {
                            width: 19px;
                            height: 19px;
                            margin-right: 11px;
                        }

                        label {
                            font-size: 16px;
                            max-width: calc(100% - 34px);

                            a {
                                font-size: 16px;
                                color: $green !important;

                                @media(hover : hover) {
                                    &:hover {
                                        font-weight: bold;
                                    }
                                }
                            }

                            @media(max-width: 991px) {
                                max-width: calc(100% - 35px);
                            }
                        }

                    }
                }

                .gform_footer {
                    padding-bottom: 0;

                    .gform_button {
                        background: $green 0 0 no-repeat padding-box;
                        border: none;
                        border-radius: 100px;
                        color: white;
                        // outline: none;
                        font-size: 16px;
                        font-weight: 700;
                        max-width: 173px;
                        width: 100%;
                        padding: 17px 39px;
                        margin-bottom: 0;
                    }
                    input[type="submit"]{
                        outline-color: $primary;
                    }
                }


            }
            //start--> custom css validation errors
            .gform_wrapper.gravity-theme .gform_validation_errors,
            .gform_wrapper.gravity-theme .validation_message {
                background-color: $white;

                h2 {
                    font-size: 15px;
                    color: #c02b0a !important;
                    font-weight: initial;
                    letter-spacing: normal;
                    line-height: 25px;
                }
            }
            .gform_wrapper.gravity-theme .gform_validation_errors{
                -webkit-padding-end: 16px;
                padding-inline-end: 16px;
                -webkit-padding-start: 48px;
                padding-inline-start: 48px
            }
            .gform_wrapper.gravity-theme .instruction.validation_message {
                display: none;
            }
            .gform_wrapper.gravity-theme .gfield_error label {
                color: $black_2;
            }
            .gform_wrapper.gravity-theme .gform_validation_errors>h2 .gform-icon{
                left: 12px;
            }
            //end--> custom css validation errors


        }

        /*end style form*/

        @media (max-width: 990px) {

            max-width: 770px;

            &__image {
                max-width: 536px;
                margin-top: 51px !important;
                padding-left: 30px;
                padding-right: 30px;
                padding-bottom: 30px;

                figure {
                    img {
                        width: 100%;
                    }
                }
            }

            &__form {
                form[id*="gform_"] {
                    padding: 40px;
                }
            }
        }

        @media (max-width: $breakpoint-down-md) {
            padding-top: 25px;
        }

        @media (max-width: 576px) {
            &__image {
                padding-bottom: 15px;
                /*   padding-left: 15px;
                padding-right: 15px; */
            }

            &__form {
                form[id*="gform_"] {
                    padding: 25px;

                    .gform_footer {
                        .gform_button {
                            padding: 8px 15px;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }

    .video-container {
        opacity: 0.3;
        position: relative;
        z-index: -1;

        .video-background {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            overflow: hidden;

            video {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: translate(-50%, -50%);
            }
        }

        // .overlay-content{
        //     background: url('../assets/img/overlay-gated.png') !important;
        //     background-size: cover !important;
        //     background-position: 50% 100% !important;
        //     opacity: 1 !important;
        //     margin-top: 132px;
        //     position: absolute;
        //     top: 50%;
        //     left: 50%;
        //     width: 100%;
        //     height: 103%;
        //     z-index: -1;
        //     overflow: hidden;
        //     object-fit: cover;
        //     transform: translate(-50%, -50%);
        // }
    }

    @media (max-width: $breakpoint-down-md) {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }
}
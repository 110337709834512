.block-slider-testimonials {
    .content-eyebrow {
        span{
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.9px;
        }
        hr{
            margin: initial;
            margin-top: 5px;
            border-top: 2px solid $black;
            opacity: 0.4;
        }
    }
    .progress{
        display: block;
        width: 100%;
        height: 3px;
        border-radius: 0;
        overflow: hidden;
        background-color: rgb(0 0 0 / 25%);
        background-image: linear-gradient(to right,  $black,  $black);
        background-repeat: no-repeat;
        background-size: 0 100%;
        transition: background-size .4s ease-in-out;
        margin-top: -8px;
    }
    .progress-content{
        padding: 0 clamp(60px, 5vw, 100px) 0;
    }
    .content-slider {
        .slider {
            padding: 0 clamp(60px, 5vw, 100px) 0;

            .items-slider {
                .box {
                    width: 100%;
                    max-width: 848px;
                    margin: 0 auto;
                    margin-top: clamp(40px, 5vw, 100px);
                    margin-bottom: clamp(50px, 5vw, 110px);
                    p{
                        font-size: 30px;
                        margin-bottom: 19px;
                    }
                    .figure-logo{
                        margin: 0 0 21px;
                        max-width: 210px;
                        img{
                            max-width: 100%;
                        }
                    }
                    h6,span{
                     font-size: 22px;
                     padding-left: 20px;
                    }
                    h6{
                        position: relative;
                        font-weight: initial;
                        &::before{
                            content: "-";
                            position: absolute;
                            left: 0;
                        }
                    }
                }
            }
        }

        .slick-custom-arrow {
            &::before {
                content: none;
            }
            width: clamp(30px, 5vw, 50px);
            height: clamp(30px, 5vw, 50px);
        }
        .slick-next{
            right: 0;
        }
        .slick-prev{
            left: 0;
        }
        .slick-dots{
            opacity: 0;
        }
        .slick-dots li button:before{
            background-color: $black;
        }

    }
    @media (max-width: 767px) {
        .content-slider {
            .slider {
                .items-slider {
                    .box {
                        p{
                            font-size: 20px;
                        }
                        h6,span{
                         font-size: 18px;
                         padding-left: 20px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 400px) {
        .progress-content{
            padding: 0 40px;
        }
        .content-slider {
            .slider {
                padding: 0 40px;
                .items-slider {
                    .box {
                        p{
                            font-size: 18px;
                        }
                        h6,span{
                         font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}